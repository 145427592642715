<template>
  <div class="modal fade" id="edit-evaluationTemp-modal" tabindex="-1" aria-labelledby="edit-evaluationTemp-modal-label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="EvaluationFormSubmit()" ref="editEvaluationForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title" id="team-preview-modal-label">Edit evaluation template</h2>
              </div>
              <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            </div>
          </div>
          <div class="modal-body">

            <!--Name-->
            <div class="form-group">
              <label class="form-label"> <span class="required">Name</span> </label>
              <Field type="text" name="Name" class="form-control" placeholder="Evaluation template name" v-model="evaluationProp.Name" @focusout="EvaluationNameExist" :rules="'required|min:3|max:200|nameAlreadyExist:' + evaluationNameExistProp" />
              <div v-if="errors.Name" class="fv-plugins-message-container invalid-feedback">{{ errors.Name }}</div>
            </div>

            <!--Description-->
            <div class="form-group">
              <label class="form-label"> Description </label>
              <Field name="evalDescription" v-slot="{ field }" rules="max:500" v-model="evaluationProp.Description">
                <textarea v-bind="field" class="form-control" rows="5" :class="{ 'border-solid-danger': errors.evalDescription }" type="text" placeholder="Evaluation template description" />
              </Field>
              <div v-if="errors.evalDescription" class="fv-plugins-message-container invalid-feedback">Description requires maximum 500 characters.</div>
            </div>

          </div>
          <div class="modal-footer flex-stack">
            <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-gradient-success"> Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, configure } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, min, max } from "@vee-validate/rules";
defineRule("required", required);
defineRule("min", min);
defineRule("max", max);
defineRule("nameAlreadyExist", (value, [evaluationNameExistProp]) => {
  if (evaluationNameExistProp === "true") {
    return "Evaluation name already exist.";
  }
  return true;
});
configure({
  validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
});

export default {
  Name: "edit-evaluationProp-template-component",
  components: {
    Field,
    Form,
  },
  emits: ["editEvaluationEmit", "evaluationNameExistEmit"],
  props: {
    evaluationNameExistProp: null,
    evaluationProp: { Type: Object, default: {} },
  },
  data() {
    return {};
  },

  methods: {
    EvaluationFormSubmit: function () {
      this.$emit("editEvaluationEmit", this.evaluationProp);
    },

    EvaluationNameExist: function () {
      if (this.evaluationProp.Name && this.evaluationProp.Name.length > 2) {
        this.$emit("evaluationNameExistEmit", this.evaluationProp.Name);
      }
    },
  },
};
</script>
