<template>
  <div class="modal fade" id="create-project-modal" tabindex="-1" aria-labelledby="createProjectModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="CreateProject" ref="createProjectForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title" id="team-preview-modal-label">New project</h2>
              </div>
              <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            </div>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="form-label"> <span class="required">Name</span> </label>
                  <Field type="text" name="projectName" class="form-control" placeholder="Project name" v-model="projectDetails.Name" rules="required|min:3" />
                  <div v-if="errors.projectName" class="fv-plugins-message-container invalid-feedback">Name requires minimum 3 characters.</div>
                </div>

                <div class="form-group">
                  <label class="form-label"> Description </label>
                  <Field name="description" v-slot="{ field }" rules="max: 500">
                    <textarea v-bind="field" rows="1" type="text" class="form-control" v-model="projectDetails.Description" placeholder="Project description" />
                  </Field>
                  <div v-if="errors.description" class="fv-plugins-message-container invalid-feedback">Description maximum 500 characters.</div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="form-label"> <span class="required">Customer</span> </label>
                  <Field name="customer" v-slot="{ field }" :rules="{ required: selectedCustomer == null }">
                    <VueMultiselect v-bind="field" v-model="selectedCustomer" :options="customers" placeholder="Select customer" label="Name" track-by="CustomerId" :searchable="true" :internalSearch="false" :empty="false" :multiple="false" @search-change="GetCustomers" @open="GetCustomers()" />
                  </Field>
                  <div v-if="errors.customer" class="fv-plugins-message-container invalid-feedback">Customer is required.</div>
                </div>

                <div class="form-group">
                  <label class="form-label required"> Project Manager </label>
                  <Field name="projectManager" v-slot="{ field }" :rules="{ required: projectDetails.ManagerId === null }">
                    <VueMultiselect v-bind="field" v-model="selectedProjectManager" placeholder="Select project manager" label="Name" track-by="EmployeeId" :options="employeesProps" :searchable="true" :internalSearch="false" @search-change="GetEmployees">
                    </VueMultiselect>
                  </Field>
                  <div v-if="errors.projectManager" class="fv-plugins-message-container invalid-feedback">Project manager is requires.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer flex-stack">
            <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineRule } from "vee-validate";
import { required, min_value, min } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";

import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
defineRule("required", required);
defineRule("min", min);
defineRule("min_value", min_value);

defineRule("min_start_date", (value, [start, end]) => {
  if (start && end) {
    if (moment(start).diff(moment(end), "d") > 0) {
      return `End date must be greater than ${moment(start).format(
        "DD-MM-YYYY"
      )}`;
    }
  }
  if (!end) return "End date is required.";
  return true;
});

export default {
  name: "AddProject",
  props: ["employeesProps"],
  emits: ["getEmployeesEmit", "createProjectEmit"],
  components: {
    Field,
    Form,
  },
  data() {
    return {
      projectDetails: {
        Name: "",
        Description: "",
        ManagerId: null,
        CustomerId: 0,
      },
      selectedProjectManager: {
        EmployeeId: 0,
        Name: null,
      },
      selectedCustomer: null,
      customers: [],
    };
  },
  methods: {
    GetCustomers: function (searchText) {
      if (searchText == null) {
        
          "";
      }
      this.$axios
        .get("api/Customer/getCustomersForFilter?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.customers = response.data;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },

    ClearModal: function () {
      this.projectDetails = {
        Name: "",
        Description: "",
        ManagerId: null,
        CustomerId: 0,
      };
      this.selectedProjectManager = null;
      this.$refs.createProjectForm.resetForm();
    },
    GetEmployees: async function (searchText) {
      this.$emit("getEmployeesEmit", searchText);
    },
    CreateProject: async function () {
      this.projectDetails.ManagerId = this.selectedProjectManager?.EmployeeId;
      this.projectDetails.CustomerId = this.selectedCustomer?.CustomerId;
      this.$emit("createProjectEmit", this.projectDetails);
    },
  },
  created() {},
};
</script>
