import AuthenticationContext from 'adal-angular/lib/adal.js'

// Demo
// const config = {
//     tenant: '749b1dd6-3ada-4d31-ba15-ac91aad9ea8c',
//     clientId: 'd5448f6e-01b0-4010-a820-a05c3e5a8cda',
//     redirectUri: 'http://localhost:5173/microsoft-login',
//     cacheLocation: 'localStorage'
// };

// Expertware
const config = {
    tenant: 'ed83fd36-beab-445f-b653-60fe5d3b63c4',
    clientId: '931a726c-f310-4021-938e-eea26adbf3e4',
    // redirectUri: 'http://localhost:8080/microsoft-login',
    // redirectUri: 'http://localhost:8080/microsoft-login',
      redirectUri: 'https://evaluation.expertware.ro/microsoft-login',
    // cacheLocation: 'localStorage'
};

export default {
    authenticationContext: null,
    /**
     * @return {Promise}
     */
    initialize() {
        this.authenticationContext = new AuthenticationContext(config);
        return new Promise((resolve, reject) => {
            if (this.authenticationContext.isCallback(window.location.hash) || window.self !== window.top) {
                // redirect to the location specified in the url params.
                this.authenticationContext.handleWindowCallback();
            } else {
                // try pull the user out of local storage
                let user = this.authenticationContext.getCachedUser();
                if (user) {
                    resolve();
                } else {
                    // no user at all - go sign in.
                    this.signIn();
                }
            }
        });
    },
    /**
     * @return {Promise.<String>} A promise that resolves to an ADAL token for resource access
     */
    acquireToken() {
        return new Promise((resolve, reject) => {
            this.authenticationContext.acquireToken('<azure active directory resource id>', (error, token) => {
                if (error || !token) {
                    return reject(error);
                } else {
                    return resolve(token);
                }
            });
        });
    },
    /**
     * Issue an interactive authentication request for the current user and the api resource.
     */
    acquireTokenRedirect() {
        this.authenticationContext.acquireTokenRedirect('<azure active directory resource id>');
    },
    /**
     * @return {Boolean} Indicates if there is a valid, non-expired access token present in localStorage.
     */
    isAuthenticated() {
        // getCachedToken will only return a valid, non-expired token.
        if (this.authenticationContext.getCachedToken(config.clientId)) {
            return true;
        }
        return false;
    },
    /**
     * @return An ADAL user profile object.
     */
    getUserProfile() {
        return this.authenticationContext.getCachedUser().profile;
    },
    signIn() {
        this.authenticationContext.login();
    },
    signOut() {
        this.authenticationContext.logOut();
    }
}