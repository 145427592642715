<template>
  <div class="modal fade" :id="'edit-question-modal'+(modalId?modalId:'')" tabindex="-1" aria-labelledby="edit-question-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row">
            <div class="col">
              <h2 class="modal-title" id="edit-question-modal-label">Edit question</h2>
            </div>
            <div class="col text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="Close"></button>
            </div>
          </div>
        </div>
        <Form v-slot="{ errors }" @submit="Save" ref="editQuestionForm">
          <div class="modal-body">
            <div class="mb-6">
              <label class="form-label" for="new-question-textarea">Question</label>
              <Field name="questionTitle" v-slot="{ field }" rules="required|min: 3|max: 4000" v-model="questionForEdit.Name">
                <textarea v-bind="field" class="form-control" id="new-question-textarea" rows="3" placeholder="Question"></textarea>
              </Field>
              <div v-if="errors.questionTitle" class="fv-plugins-message-container invalid-feedback">Title requires minimum 3 and maximum 4000 characters.</div>
            </div>
            <div>
              <label class="form-label" for="new-question-description-textarea"> Description</label>
              <Field name="questionDescription" v-slot="{ field }" rules="max:5000" v-model="questionForEdit.Description">
                <textarea :class="{ 'border-solid-danger': errors.questionDescription }" v-bind="field" type="text" class="form-control" placeholder="Question description" name="Description" />
              </Field>
              <div v-if="errors.questionDescription" class="fv-plugins-message-container invalid-feedback">Description requires maximum 5000 characters.</div>
            </div>
          </div>
          <div class="modal-footer flex-stack">
            <button type="button" data-bs-dismiss="modal" class="border btn btn-white">Close</button>
            <button class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, min, between, max } from "@vee-validate/rules";
defineRule("required", required);
defineRule("between", between);
defineRule("min", min);
defineRule("max", max);
export default {
  Name: "edit-question-modal-component",
  components: {
    Field,
    Form,
  },
  props: ["questionForEdit", "modalId"],
  data() {
    return {};
  },
  emits: ["editQuestionsEmit"],
  methods: {
    Save: function () {
      this.$emit("editQuestionsEmit");
    },
  },
};
</script>

<style scoped></style>
