  <template>

  <div class="row">
    <div class="col-12 col-md-6 col-xl-4  mb-6">
      <div v-on:click="OpenModal('template')" class="card darkgreen-gradient-card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <i class="fa-duotone fa-files fa-3x"></i>
            </div>
            <div class="col text-white">
              <h1>Create from template</h1>
              <p class="m-0">Use content from a template</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4  mb-6">
      <div v-on:click="OpenModal('scratch')" class="card green-gradient-card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <i class="fa-duotone fa-file fa-3x"></i>
            </div>
            <div class="col text-white">
              <h1>Create from scratch</h1>
              <p class="m-0">Add your categories and questions</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-xl-4 mb-6">
      <div v-on:click="OpenModal('duplicate')" class="card orange-gradient-card h-100">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <i class="fa-duotone fa-copy fa-3x"></i>
            </div>
            <div class="col text-white">
              <h1>Duplicate evaluation</h1>
              <p class="m-0">Use content from another evaluation</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>



<script>
export default {
  Name: "new-eval-btns-component",

  emits: ["open"],

  data() {
    return {};
  },
  methods: {
    OpenModal: function (modalType) {
      this.$emit("openModal", modalType);
    },
  },
};
</script>
