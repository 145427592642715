<template>
  <div class="card h-100 shadow-hover" data-bs-toggle="modal" data-bs-target="#add-evaluationTemp-modal" @click="ClearAddEvalTempModal(workspaceProp)">
    <div class="card-body position-relative">
      <div class="text-center center">
        <i class="fa-plus fa-light text-white"></i>
        <h1 class="text-white">Add evaluation <br />Template</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  Name: "new-eval-card-component",
  props: {
    workspaceProp: { type: Object, default: {} },
  },
  emits: ["clearAddEvalTempModalEmit"],
  methods: {
    ClearAddEvalTempModal: function (workspace) {
      this.$emit("clearAddEvalTempModalEmit", workspace);
    },
  },
};
</script>

<style scoped>
.card {
  background: linear-gradient(143.39deg, #4dc4b3 0%, #209b8a 100%);
  cursor: pointer;
  min-height: 340px;
}

h1 {
  font-weight: 400;
  margin-top: 10px;
}
i {
  font-size: 50px;
}

.center {
  position: absolute;
  top: 50%;
  left: 40%;
  margin: -50px 0 0 -50px;
}
</style>
