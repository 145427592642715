<template>
  <div class="mt-4">
    <ul v-if="numberOfPages >= 8" class="pagination">
      <li class="page-item m-1" v-if="pageNumber > 1">
        <a v-on:click="functionName(1)" class="page-link" tabindex="-1"><i class="fa-solid fa-angles-left"></i></a>
      </li>
      <li class="page-item m-1" v-if="pageNumber > 1">
        <a v-on:click="functionName(pageNumber - 1)" class="page-link" tabindex="-1"><i class="fa-solid fa-angle-left"></i></a>
      </li>

      <template v-for="page in numberOfPages">
        <li class="page-item m-1" v-if="page < 3" v-bind:class="{ active: page == pageNumber }" v-bind:key="page">
          <a v-on:click="functionName(page)" class="page-link">{{ page }} </a>
        </li>
      </template>

      <li class="page-item m-1" v-if="pageNumber > 4">
        <a class="page-link disabled">...</a>
      </li>

      <template v-for="page in numberOfPages">
        <li class="page-item m-1" v-if="page < pageNumber + 2 && page > pageNumber - 2 && numberOfPages - page >= 2 && page >= 3" v-bind:class="{ active: page == pageNumber }" v-bind:key="page">
          <a v-on:click="functionName(page)" class="page-link">{{ page }} </a>
        </li>
      </template>

      <li class="page-item m-1" v-if="numberOfPages - pageNumber > 3">
        <a class="page-link disabled">...</a>
      </li>

      <template v-for="page in numberOfPages">
        <li class="page-item m-1" v-if="numberOfPages - page < 2" v-bind:class="{ active: page == pageNumber }" v-bind:key="page">
          <a v-on:click="functionName(page)" class="page-link">{{ page }} </a>
        </li>
      </template>

      <li class="page-item m-1" v-if="pageNumber < numberOfPages">
        <a v-on:click="functionName(pageNumber + 1)" class="page-link"><i class="fa-solid fa-angle-right"></i></a>
      </li>

      <li class="page-item m-1" v-if="pageNumber < numberOfPages">
        <a v-on:click="functionName(numberOfPages)" class="page-link" tabindex="-1"><i class="fa-solid fa-angles-right"></i></a>
      </li>
    </ul>
    <ul v-if="numberOfPages > 1 && numberOfPages < 8" class="pagination">
      <li class="page-item m-1" v-if="pageNumber > 1">
        <a v-on:click="functionName(1)" class="page-link" tabindex="-1"><i class="fa-solid fa-angles-left"></i></a>
      </li>
      <li class="page-item m-1" v-if="pageNumber > 1">
        <a v-on:click="functionName(pageNumber - 1)" class="page-link" tabindex="-1"><i class="fa-solid fa-angle-left"></i></a>
      </li>
      <template v-for="page in numberOfPages" v-bind:key="page">
        <li class="page-item m-1" v-bind:class="{ active: page == pageNumber }">
          <a v-on:click="functionName(page)" class="page-link">{{ page }} </a>
        </li>
      </template>

      <li class="page-item m-1" v-if="pageNumber < numberOfPages">
        <a v-on:click="functionName(pageNumber + 1)" class="page-link"><i class="fa-solid fa-angle-right"></i></a>
      </li>

      <li class="page-item m-1" v-if="pageNumber < numberOfPages">
        <a v-on:click="functionName(numberOfPages)" class="page-link" tabindex="-1"><i class="fa-solid fa-angles-right"></i></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pagination-component",
  props: {
    numberOfPages: Number,
    pageNumber: Number,
    functionName: Function,
  },
};
</script>
