<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item"><a href="">Evaluations</a></li>
        <li class="breadcrumb-item" aria-current="page">
          <a href="#">{{ evaluationDetails.Name }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Evaluation details</li>
      </ol>
    </nav>

    <div class="row align-items-end">
      <div class="col">
        <h1 class="page-title">Evaluation details</h1>
      </div>

      <div class="col"></div>

      <div v-if="evaluationDetails.Status != 'Started'" class="col-auto">
        <button class="btn btn-gradient-success" @click="StartEvaluation()"><i class="fal fa-check-circle text-white"></i> Start evaluation</button>
      </div>
      <div class="col-auto">
        <button class="btn btn-warning" @click="DuplicateEvaluationPlan()"><i class="fa-duotone fa-copy"></i> Duplicate</button>
      </div>
    </div>

    <evaluation-information-component :evaluationDetails="evaluationDetails"></evaluation-information-component>

    <div class="page-tabs">
      <div class="row border-bottom">
        <div class="col-auto active">
          <h3>
            <router-link class="dropdown-item p-0" :to="{ name: 'PreviewEvaluationView', params: { id: evaluationId } }"> Details </router-link>
          </h3>
        </div>
        <div class="col-auto">
          <h3>
            <router-link
              v-if="[6, 8].includes(evaluationDetails.TargetId) || (evaluationDetails.TargetId == 7 && evaluationDetails.IsOwner == true)"
              class="dropdown-item p-0"
              :to="{ name: 'EvaluationGroupMembersView', params: { id: evaluationId } }"
            >
              Members
            </router-link>
            <router-link
              v-if="[3, 5, 7].includes(evaluationDetails.TargetId) && this.evaluationDetails.IsOwner == false"
              class="dropdown-item p-0"
              :to="{ name: 'LeaderEvaluationMembersView', params: { id: evaluationId } }"
            >
              Members
            </router-link>
            <router-link
              v-if="([3, 5].includes(evaluationDetails.TargetId) && evaluationDetails.IsOwner == true) || [1, 2, 4].includes(evaluationDetails.TargetId)"
              class="dropdown-item p-0"
              :to="{ name: 'EvaluationMembersView', params: { id: evaluationId } }"
            >
              Members
            </router-link>
          </h3>
        </div>
        <div v-if="[3, 5, 7].includes(this.evaluationDetails.TargetId) && this.evaluationDetails.IsOwner == false" class="col-auto">
          <h3>
            <router-link class="dropdown-item p-0" :to="{ name: 'LeaderAvarageView', params: { id: evaluationId } }"> Avarage </router-link>
          </h3>
        </div>
      </div>
      <hr />
    </div>

    <div class="card eval-content-card">
      <div class="card-body">
        <div v-bind:key="categoryIndex" v-for="(category, categoryIndex) in evaluation.Categories">
          <h2>{{ category.Name }}</h2>
          <p class="m-0">
            {{ category.Description }}
          </p>
          <div class="card-body pt-5">
            <div class="timeline-label">
              <div v-bind:key="questionIndex" v-for="(question, questionIndex) in category.Questions" class="align-items-center timeline-item">
                <div class="timeline-badge">
                  <i class="fa fa-question-square text-secondary"></i>
                </div>
                <div class="bg-light ms-10 p-4 timeline-content">
                  <h3>{{ question.Name }}</h3>
                  <p class="m-0">
                    {{ question.Description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr v-if="categoryIndex != evaluation.Categories.length - 1" class="mb-7" />
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col"></div>
          <div v-if="evaluationDetails.Status != 'Started'" class="col-auto">
            <button class="btn btn-gradient-success" @click="StartEvaluation()"><i class="fal fa-check-circle text-white"></i> Start evaluation</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import EvaluationInformationComponent from "@/components/Common/EvaluationInformationComponent.vue";

export default {
  name: "PreviewEvaluationView",
  components: {
    EvaluationInformationComponent,
  },
  data() {
    return {
      evaluation: {
        Categories: [],
      },
      evaluationId: null,
      evaluationDetails: {},
    };
  },
  methods: {
    DuplicateEvaluationPlan: async function () {
      const self = this;
      await this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to duplicate this evaluation plan?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            await self.$axios
              .post("api/EvaluationPlan/duplicate?evaluationPlanId=" + self.evaluationId, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then(async (response) => {
                store.dispatch("changeLoaderStatus", false);
                var evalId = response.data;
                self.$router.push({
                  path: `/administration/evaluations/evaluation/${evalId}/edit`,
                });
              })
              .catch(() => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: "Something went wrong",
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    StartEvaluation: async function () {
      var self = this;
      await this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to start this evaluation?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            self.$axios
              .post("api/EvaluationInterval/startEvaluation?evaluationId=" + self.evaluationId, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then(async () => {
                self.$swal.fire({
                  toast: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: "success",
                  title: "Evaluation has been started",
                });

                store.dispatch("changeLoaderStatus", false);
              })
              .catch(() => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: "Something went wrong!",
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },

    GetEvaluation: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getEvaluationDetails/" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationContent: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getEvaluationContent/" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluation.Categories = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  computed: {},
  created() {
    this.evaluationId = this.$route.params.id;
    this.GetEvaluationContent();
    this.GetEvaluation();
  },
};
</script>
<style scoped>
.timeline-content {
  border-radius: 5px;
}
h2 {
  color: #188a94;
}

p {
  color: #6c757d;
}

.fa-question-square {
  font-size: 30px;
}

.btn {
  min-width: 100px;
}

.timeline-label:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 3px;
  bottom: 0;
  background-color: #eff2f5;
}

.modal-body {
  max-height: 600px;
  overflow: auto;
}

/* .modal-header,
.modal-footer {
  display: block;
} */
</style>
