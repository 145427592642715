<template>
  <div v-bind:key="index" v-for="(category, index) in evaluationContent">
    <div class="row align-items-center mb-3">
      <div class="col-11">
        <h3>
          {{ category.Name }}
        </h3>
        <small> {{ category.Description }} </small>
      </div>
    </div>
    <div class="ps-11 mt-5">
      <div class="ps-11 mt-5" v-show="!draggingProp.CategoryDragging">
        <div v-bind:key="index" v-for="(question, index) in category.Questions" class="row mb-6">
          <div class="col-md-10 col-xxl-11">
            <div class="question-card row align-items-center">
              <h4>
                {{ question.Name }}
              </h4>
              <small> {{ question.Description }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-10 mb-10" />
  </div>
</template>
<script>
export default {
  Name: "evaluation-content-component",

  props: ["evaluationContent", "draggingProp"],

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.question-card {
  background-color: #f5f8fa;
  border-radius: 0.475rem;
  padding: 15px 10px;
  border: 1px solid #ddd;
}
</style>
