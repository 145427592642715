<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item">My evaluations</li>

        <li class="breadcrumb-item"><a href="/My-evaluations/Active">Active</a></li>
        <li class="breadcrumb-item">
          {{ evaluationDetails.Name }}
        </li>
        <li class="breadcrumb-item">
          <a href="#" @click="$router.go(-1)"> Members</a>
        </li>
        <li class="breadcrumb-item" aria-current="page"><a href="#" class="active" @click="$router.go(-1)">Answer to
            evaluation</a></li>
      </ol>
    </nav>

    <div class="row align-items-center">
      <div class="col">
        <h1 class="eval-page-title">Evaluate your teammates</h1>
      </div>

      <div class="col-auto">
        <div v-if="evaluationParticipants.length > 1" class="bg-white rounded p-4 mb-4" style="min-width: 349px">
          <VueMultiselect v-model="selectedEmployee" track-by="EmployeeId" @select="SelectMember" :allowEmpty="false"
            :options="evaluationParticipants" :placeholder="'Search for member'" label="Name" :searchable="true"
            :internalSearch="true" class="multiselect-border-0">
            <template v-slot:singleLabel>
              <div class="multiselect-single-label">
                <div class="symbol symbol-40px me-4 symbol-circle">
                  <img alt="Pic" :src="GetImageUrl(selectedEmployee.Image)" style="object-fit: cover" />
                </div>

                {{ selectedEmployee.Name }}
              </div>
            </template>

            <template v-slot:option="{ option }">
              <small class="badge badge-light float-end mt-2" v-if="option.Status != 'Completed'">In progress</small>
              <small class="badge badge-light float-end text-dark-green mt-2" v-else> {{ option.Status }}</small>
              <div class="symbol symbol-40px me-2 symbol-circle">
                <img alt="Pic" :src="GetImageUrl(option.Image)" style="object-fit: cover" />
              </div>
              {{ option.Name }}
            </template>
          </VueMultiselect>
        </div>
        <div class="row">
          <div v-if="evaluationParticipants.length > 1" class="col-auto">
            <a class="btn btn-white"
              :href="'/evaluations/active/evaluation/' + evaluationDetails.EvaluationPlanId + '/members'"> <i
                class="far fa-arrow-left"></i> Back to members</a>
          </div>
          <div v-if="!evaluationContent.IsSigned" class="col-auto">
            <button v-on:click="ResetEvaluation()" class="btn btn-white text-danger"><i
                class="far fa-arrows-rotate text-danger"></i> Reset</button>
          </div>
          <div v-if="!evaluationContent.IsSigned" class="col-auto">
            <button v-on:click="UpdateEvaluation()" class="btn btn-white"><i class="fa-light fa-files"></i> Save</button>
          </div>
          <div v-if="!evaluationContent.IsSigned" class="col-auto">
            <button type="button" class="btn btn-gradient-success" v-on:click="SignEmployeeEvaluationPlan()"><i
                class="fal fa-check-circle text-white"></i> Submit</button>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-8 mt-8 evaluation-summary-card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <h1>{{ evaluationDetails.Name }}</h1>
            <p>{{ evaluationDetails.Description }}</p>
          </div>
        </div>
        <hr />
        <div class="row align-items-center">
          <div class="col d-flex align-items-center">
            <img v-if="evaluationDetails.Scope"
              v-bind:src="require('@/assets/images/scopes-icons/' + evaluationDetails.Scope + '.png')" />
            <div>
              <small>Scope</small>
              <h5>{{ evaluationDetails.Scope }}</h5>
            </div>
          </div>

          <div class="col d-flex align-items-center">
            <img v-if="evaluationDetails.Target"
              v-bind:src="require('@/assets/images/scopes-icons/' + evaluationDetails.Target + '.png')" />
            <div>
              <small>{{ evaluationDetails.Scope }}</small>
              <h5>{{ evaluationDetails.EvaluationFor }}</h5>
            </div>
          </div>

          <div class="col">
            <small>Period</small>
            <h5>
              {{ $moment(evaluationDetails.StartDate).format("DD.MM.YYYY") }}
              -
              {{ $moment(evaluationDetails.EndDate).format("DD.MM.YYYY") }}
            </h5>
          </div>
          <div class="col d-flex align-items-center">
            <div class="symbol symbol-40px me-2 symbol-circle">
              <img alt="Pic" :src="GetImageUrl(selectedEmployee.Image)" style="object-fit: cover" />
            </div>
            <div>
              <small>Target</small>
              <h5>{{ selectedEmployee.Name }}</h5>
            </div>
          </div>
          <div class="col d-flex align-items-center">
            <div class="symbol symbol-40px me-2 symbol-circle">
              <i class="fa-regular fa-circle-exclamation fa-3x me-2" :style="{ color: 'red' }"></i>
            </div>
            <div>
              <small>Anonymous</small>
              <h5 v-if="evaluationDetails.IsAnonim == true">YES</h5>
              <h5 v-else>NO</h5>
            </div>
          </div>
          <div class="col"></div>
          <div class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
            <div class="big-score-bage bg-dark-green">
              <h1>{{ evaluationContent.EvaluationScore }}</h1>
              <span>Your score</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <employee-position-card-component v-if="evaluationContent.Categories && evaluationDetails.ScopeId == 2"
      :updateEvaluationProp="updateEvaluation"
      :isSignedProp="evaluationContent.IsSigned"></employee-position-card-component>
    <div class="card">
      <div class="card-body">
        <div v-bind:key="categoryIndex" v-for="(category, categoryIndex) in evaluationContent.Categories">
          <div class="row">
            <div class="col">
              <h1>{{ category.CategoryName }}</h1>
              <p>
                {{ category.CategoryDescription }}
              </p>
            </div>

            <div class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 text-gray-700">Category score </h5>
                </div>
                <div class="col-auto">
                  <span class="bg-gray-200 p-2 rounded text-dark-green fw-bold fs-18px ps-4 pe-4"><i
                      class="fa-sharp fa-solid fa-star text-dark-green me-2"></i>
                    {{ category.CategoryScore }}
                    <!-- 4.2 -->
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center" :id="'question-' + question.AnswerId" v-bind:key="questionIndex"
            v-for="(question, questionIndex) in category.Questions">
            <div class="col">
              <question-timeline-details-component :question="question"></question-timeline-details-component>
            </div>

            <div v-if="evaluationContent.IsSigned" class="col-auto questions-score-div">
              <preview-score-component :question="question"></preview-score-component>
            </div>

            <div v-else class="col-auto questions-score-div">
              <active-score-component :question="question" @SelectScore="SelectScore(question)"></active-score-component>
            </div>
          </div>

          <hr class="mt-15 mb-15" />
        </div>
      </div>
    </div>

    <div class="row align-items-end mt-10 mb-20">
      <div class="col-auto">
        <a class="btn btn-white"
          :href="'/evaluations/active/evaluation/' + evaluationDetails.EvaluationPlanId + '/members'"> <i
            class="far fa-arrow-left"></i> Back to members</a>
      </div>
      <div class="col"></div>
      <div v-if="!evaluationContent.IsSigned" class="col-auto">
        <button v-on:click="ResetEvaluation()" class="btn btn-white text-danger"><i
            class="far fa-arrows-rotate text-danger"></i> Reset</button>
      </div>
      <div v-if="!evaluationContent.IsSigned" class="col-auto">
        <button v-on:click="UpdateEvaluation()" class="btn btn-white"><i class="fa-light fa-files"></i> Save</button>
      </div>
      <div v-if="!evaluationContent.IsSigned" class="col-auto">
        <button type="button" class="btn btn-gradient-success" v-on:click="SignEmployeeEvaluationPlan()"><i
            class="fal fa-check-circle text-white"></i> Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import utils from "@/utils/utils.js";

import ActiveScoreComponent from "@/components/Common/Score/ActiveScoreComponent.vue";
import PreviewScoreComponent from "@/components/Common/Score/PreviewScoreComponent.vue";
import QuestionTimelineDetailsComponent from "@/components/Questions/QuestionTimelineDetailsComponent.vue";
import EmployeePositionCardComponent from "@/components/Cards/EmployeePositionCardComponent.vue";
export default {
  name: "ManagerEvaluationAnswersActiveView",
  components: {
    ActiveScoreComponent,
    QuestionTimelineDetailsComponent,
    PreviewScoreComponent,
    EmployeePositionCardComponent,
  },

  data() {
    return {
      scoreColumnWidth: 320,
      evaluationContent: {
        IsSigned: false,
      },
      evaluationDetails: {},
      newAnswers: [],
      selectedEmployee: {},
      employeeId: 0,
      evaluationParticipants: [],
      updateEvaluation: { Position: null },
    };
  },

  methods: {
    SelectMember: function (member) {
      this.$router
        .push({
          name: "ManagerEvaluationAnswersActiveView",
          params: {
            id: this.evaluationDetails.EvaluationPlanId,
            memberId: member.EmployeeId,
          },
        })
        .then(() => {
          this.$router.go();
        });
    },
    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    SelectScore: function (question) {
      const index = this.newAnswers.findIndex(
        (item) => item.Id === question.AnswerId
      );
      if (index === -1) {
        this.newAnswers.push({
          Id: question.AnswerId,
          Grade: question.Grade,
        });
      } else {
        this.newAnswers[index].Grade = question.Grade;
      }

      this.CategoryAvgScore();
    },

    CategoryAvgScore: function () {
      this.evaluationContent.EvaluationScore = 0;

      this.evaluationContent.Categories.forEach((category) => {
        category.CategoryScore = 0;
        category.Questions.forEach((question) => {
          category.CategoryScore = category.CategoryScore =
            category.CategoryScore + question.Grade;
        });

        category.CategoryScore = (
          category.CategoryScore / category.Questions.length
        ).toFixed(2);

        this.evaluationContent.EvaluationScore += Number(
          category.CategoryScore
        );
      });

      this.evaluationContent.EvaluationScore = (
        this.evaluationContent.EvaluationScore /
        this.evaluationContent.Categories.length
      ).toFixed(2);
    },

    GetEvaluationSummary: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/EvaluationPlan/getEvaluationPlanSummary?evaluationId=" +
          this.evaluationDetails.EvaluationPlanId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationContent: async function () {
      store.dispatch("changeLoaderStatus", true);

      var query = new URLSearchParams({
        employeeId: this.employeeId,
        evaluationPlanId: this.evaluationDetails.EvaluationPlanId,
      });

      await this.$axios
        .get(
          "api/Answers/getLoggedUserAnswersForManageOrTeamService?" + query,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationContent = response.data;
          this.updateEvaluation.Position = this.evaluationContent.Position;
          if (this.evaluationContent.Categories) {
            this.CategoryAvgScore();
          } else {
            this.InsertQuestions();
          }

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    InsertQuestions: async function () {
      await this.$axios
        .post(
          "api/Answers/insert",
          {
            EvaluationId: this.evaluationDetails.EvaluationPlanId,
            EmployeeEvaluationId: this.employeeId,
          },
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then(async () => {
          await this.GetEvaluationContent();
        })
        .catch((error) => {
          this.$swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: "error",
            title: error,
          });
        });
    },

    ResetEvaluation: async function () {
      this.evaluationContent.Categories.forEach((category) => {
        category.Questions.forEach((question) => {
          this.newAnswers.push({
            Id: question.AnswerId,
            Grade: 0,
          });
        });
      });

      var self = this;
      this.$swal
        .fire({
          title: "Reset answers",
          text: "Are you sure you want to reset your answers?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            await self.UpdateEvaluation();
            self.GetEvaluationContent();
          }
        });
    },

    UpdateEvaluation: async function () {
      if (
        this.newAnswers.length !== 0 ||
        this.updateEvaluation.Position != this.evaluationContent.Position
      ) {
        store.dispatch("changeLoaderStatus", true);
        this.updateEvaluation.EvaluationPlanId =
          this.evaluationDetails.EvaluationPlanId;
        this.updateEvaluation.EmployeeId = this.employeeId;
        this.updateEvaluation.EvaluationResult = this.newAnswers;
        await this.$axios
          .put("api/Answers/updateEvalResultTL", this.updateEvaluation, {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          })
          .then((response) => {
            this.$swal.fire({
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: "success",
              title: response.data,
            });
            this.newAnswers = [];
            store.dispatch("changeLoaderStatus", false);
          })
          .catch((error) => {
            this.$swal.fire({
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: "error",
              title: error.response.data.message,
            });
            store.dispatch("changeLoaderStatus", false);
          });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: "warning",
          title: "No answer modified!",
        });
      }
    },

    GetImageUrl: function (image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    SignEmployeeEvaluationPlan: function () {
      if (utils.IsEvaluationCompleted(this.evaluationContent)) {
        var self = this;
        this.$swal
          .fire({
            title: "Warning",
            text: "Are you sure you want to submit this evaluation?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          })
          .then(async function (result) {
            if (result.value) {
              await self.UpdateEvaluation();
              store.dispatch("changeLoaderStatus", true);
              self.$axios
                .put(
                  "api/Answers/updateIsSigned?evaluationPlanId=" +
                  self.evaluationDetails.EvaluationPlanId +
                  "&employeeId=" +
                  self.selectedEmployee.EmployeeId +
                  "&evaluationResults=",
                  self.newAnswers,
                  {
                    headers: {
                      Authorization: "Bearer " + auth.getJwtToken().Value,
                    },
                  }
                )
                .then(() => {
                  self.GetEvaluationSummary();
                  self.GetEvaluationContent();
                  self.GetEvaluationParticipants();
                  store.dispatch("changeLoaderStatus", false);
                })
                .catch((error) => {
                  store.dispatch("toastNotify", {
                    Type: "error",
                    Title: error.response.data.message,
                  });
                  store.dispatch("changeLoaderStatus", false);
                });
            }
          });
      }
    },

    GetEvaluationParticipants: function () {
      store.dispatch("changeLoaderStatus", true);
      this.$axios
        .get(
          "api/Employee/getEvaluationParticipants?evaluationPlanId=" +
          this.evaluationDetails.EvaluationPlanId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationParticipants = response.data;
          var self = this;
          this.evaluationParticipants.forEach(function (member) {
            if (member.EmployeeId == self.employeeId) {
              self.selectedEmployee = member;
            }
          });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  created() {
    this.evaluationDetails.EvaluationPlanId = this.$route.params.id;
    this.employeeId = this.$route.params.memberId;

    this.GetEvaluationSummary();
    this.GetEvaluationContent();
    this.GetEvaluationParticipants();
  },
};
</script>
