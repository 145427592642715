<template>
  <div class="introduction-section">
    <div class="bg"></div>
    <div class="container text-center">
      <div class="title text-green">Cum funcționează aplicația</div>
      <div class="sub-title mt-10 mb-10">
        Step into the World of Data-Driven Evaluations with Our Evaluation App. <br />
        Get a Sneak Peek and Test Drive Our Powerful Features Today!
      </div>
      <router-link :to="{ name: 'Login' }" target="_blank">
        <button type="button" class="btn btn-gradient-green">Încearcă gratuit</button>
      </router-link>
    </div>
  </div>

  <div class="create-eval-section">
    <div class="container-sm">
      <div class="section-title left">
        <div class="step">
          <div class="circle">
            <span class="number">1</span>
          </div>
          <div class="text">Crează evaluări</div>
        </div>
        <div class="step-description">
          With our demo app, you can easily create custom evaluations or use our pre-made templates to streamline the process. Our intuitive interface and powerful data analysis tools make data-driven
          evaluations accessible to everyone.
        </div>
      </div>
      <img src="../../assets/images/landing-page/ro/evaluarea-echipelor.png" class="section-img" />
    </div>
  </div>

  <div class="assign-eval-section" style="padding-top: 120px; padding-bottom: 120px">
    <div class="bg"></div>
    <div class="container-sm">
      <div class="section-title right">
        <div class="step">
          <div class="circle">
            <span class="number">2</span>
          </div>
          <div class="text">Atribuie evaluări</div>
        </div>
        <div class="step-description">
          Assigning evaluations is a breeze with our app's intuitive interface. Simply select the group of employees you want to evaluate, then specify the participants, select the time frame, and
          you're done.
        </div>
      </div>
      <div class="row row-xxl-ml-mr--15px">
        <div class="col-xl mb-15 mb-xl-0">
          <img src="../../assets/images/landing-page/ro/atribuie-evaluare-1.png" class="section-img-small" />
        </div>
        <div class="col-auto d-none d-xl-block"></div>
        <div class="col-xl">
          <img src="../../assets/images/landing-page/ro/atribuie-evaluare-2.png" class="section-img-small" />
        </div>
      </div>
    </div>
  </div>

  <div class="complete-eval-section mt-120px">
    <div class="container-sm">
      <div class="section-title left">
        <div class="step">
          <div class="circle">
            <span class="number">3</span>
          </div>
          <div class="text">Completează evaluările atribuite</div>
        </div>
        <div class="step-description">
          Completing evaluations is simple and hassle-free with our app's intuitive interface. Employees and managers can quickly provide responses to evaluation questions and view progress reports.
        </div>
      </div>
      <img src="../../assets/images/landing-page/ro/completeaza-evaluarea-echipelor.png" class="section-img" />
    </div>
  </div>

  <div class="compare-answers-section mt-120px">
    <div class="bg"></div>
    <div class="container-sm">
      <div class="section-title right">
        <div class="step">
          <div class="circle">
            <span class="number">4</span>
          </div>
          <div class="text">Compară răspunsurile</div>
        </div>
        <div class="step-description">
          Our evaluation app empowers managers to review employee responses and compare them with those of the team leader. Get a comprehensive view of employee performance and progress with our
          powerful data analytics tools.
        </div>
      </div>
      <img src="../../assets/images/landing-page/ro/compara-raspunsurile.png" class="section-img" />
    </div>
  </div>

  <div class="user-admin-section mt-120px">
    <div class="container-sm">
      <div class="section-title left">
        <div class="step">
          <div class="circle">
            <span class="number">5</span>
          </div>
          <div class="text">Administrarea utilizatorilor</div>
        </div>
        <div class="row align-items-center mt-7">
          <div class="col">
            <div class="step-description mt-0">
              Effortlessly manage users, teams, groups, and projects with our evaluation app's user-friendly interface. Easily add and remove members, assign evaluations, and view progress reports for
              individuals and teams.
            </div>
          </div>
          <div class="col-auto d-none d-md-block">
            <div class="d-flex">
              <div class="carousel-btn me-5" v-on:click="CarouselNextPage('prev')">
                <div class="circle">
                  <i class="fa-solid fa-chevron-left"></i>
                </div>
              </div>
              <div class="carousel-btn" v-on:click="CarouselNextPage('next')">
                <div class="circle">
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <Carousel ref="userAdminCarousel" :settings="carousel.settings" :breakpoints="carousel.breakpoints" :autoplay="carousel.autoplay" :wrap-around="true">
        <Slide v-for="(item, index) in carouselImages" :key="index">
          <div class="pe-md-5 ps-md-5">
            <img :src="require(`../../../src/assets/images/landing-page/carousel/ro/${item}`)" class="carousel-img" />
          </div>
        </Slide>
        <template #addons>
          <pagination class="d-none d-md-flex" />
        </template>
      </Carousel>
      <div class="d-flex d-md-none justify-content-around">
        <div class="carousel-btn me-5" v-on:click="CarouselNextPage('prev')">
          <div class="circle">
            <i class="fa-solid fa-chevron-left"></i>
          </div>
        </div>
        <div class="carousel-btn" v-on:click="CarouselNextPage('next')">
          <div class="circle">
            <i class="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="how-it-works-section text-center">
    <div class="container">
      <div class="title-section">Cum funcționează</div>
      <div class="description-section text-light-gray">
        Experience the power of our evaluation app - check <br />
        out our demo page now!
      </div>
      <router-link :to="{ name: 'Login' }" target="_blank">
        <button type="button" class="btn btn-gradient-green">Încearcă gratuit</button>
      </router-link>
    </div>
  </div>

  <div class="available-plans-section">
    <div class="bg"></div>
    <img src="../../../src/assets/images/landing-page/ro/clienti-eval_echipe-raspunde.png" />
    <div class="container-xxl">
      <div class="title-section mb-16">Planuri disponibile</div>
      <LandingPagePlanCards />
    </div>
  </div>

  <LandingPageQuestions />

  <LandingPageContact />

  <LandingPageFooter :dynamicHeight="true" />
</template>

<script>
import LandingPageQuestions from "@/components/Questions/LandingPageQuestionsComponent.vue";
import LandingPagePlanCards from "@/components/Cards/LandingPagePlanCardsComponent.vue";
import LandingPageContact from "@/components/Forms/LandingPageContactComponent.vue";
// https://ismail9k.github.io/vue3-carousel/examples.html
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import LandingPageFooter from "@/components/Footer/LandingPageFooter.vue";

export default {
  name: "HowItWorksLandingView",
  components: { Carousel, Slide, Pagination, Navigation, LandingPageQuestions, LandingPagePlanCards, LandingPageContact, LandingPageFooter },
  data() {
    return {
      carouselImages: ["clienti-proiecte.png", "grupuri.png", "echipe.png"],
      carousel: {
        autoplay: 2000,
        settings: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        breakpoints: {
          // 700px and up
          0: {
            itemsToShow: 1,
            snapAlign: "center",
          },
          768: {
            itemsToShow: 1.1,
            snapAlign: "center",
          },
          1000: {
            itemsToShow: 1.3,
            snapAlign: "center",
          },
          1200: {
            itemsToShow: 1.5,
            snapAlign: "center",
          },
          1500: {
            itemsToShow: 1.8,
            snapAlign: "center",
          },
          1800: {
            itemsToShow: 2,
            snapAlign: "center",
          },
        },
      },
    };
  },
  methods: {
    CarouselNextPage: function (where) {
      this.$refs.userAdminCarousel[where]();
    },
  },
  created() {},
};
</script>

<style scoped>
/*AVAILABLE PLANS*/

.plans-and-footer-bg {
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.15);
  background-image: url("../../assets/images/landing-page/available-plans-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  display: inline-block;
  width: 100%;
  right: 0;
  left: 0;
  z-index: -1;
  top: 32px;
  height: 100%;
}

@media only screen and (min-width: 992px) {
  .plans-and-footer-bg {
    height: 85.5%;
    top: 331px;
  }
}

/*HOW IT WORKS*/
.how-it-works-section .container {
  background-image: url("../../assets/images/landing-page/how-it-works-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.how-it-works-section {
  margin-top: 180px;
  margin-bottom: 160px;
  position: relative;
}

/* COMPARE ANSWERS */
.compare-answers-section {
  position: relative;
}
.compare-answers-section .bg {
  background-size: cover;
  z-index: -1;
  background-image: url("../../assets/images/landing-page/compare-answers-bg.png");
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
}
/* ASSIGN EVAL */
.assign-eval-section {
  position: relative;
}
.assign-eval-section .bg {
  background-size: cover;
  z-index: -1;
  background-image: url("../../assets/images/landing-page/assign-evaluations-bg.png");
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
}
/* CREATE EVAL */

/*GLOBAL*/

@media (max-width: 1600px) {
  .section-img {
    width: 100%;
  }
}
@media (min-width: 1600px) {
  .section-img {
    zoom: 0.92;
    margin-left: -25px;
  }

  .row-xxl-ml-mr--15px {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.section-img-small {
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin: auto;
  display: block;
}
@media screen and (max-width: 768px), (min-width: 1200px) {
  .section-img-small {
    width: 100%;
  }
}

.step {
  display: flex;
  align-items: center;
  color: rgba(24, 138, 148, 1);
}
.step .text {
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

.step .number {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
.step .circle {
  border: 1px solid rgba(24, 138, 148, 1);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 576px) {
  .step .circle {
    height: 64px;
    width: 64px;
  }
  .step .text {
    font-size: 24px;
  }
}

.step-description {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: rgba(108, 117, 125, 1);
}
@media (min-width: 1200px) {
  .step-description {
    max-width: 51vw;
  }
}

.section-title {
  margin-bottom: 48px;
}
.section-title.left .step .circle {
  margin-right: 24px;
}

.section-title.right .step {
  justify-content: end;
}
.section-title.right .step .circle {
  margin-left: 24px;
  order: 1 !important;
}
.section-title.right .step-description {
  text-align: right;
  margin-left: auto;
}
</style>
