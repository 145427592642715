<template>
  <div class="bg"></div>

  <div class="container-xl">
    <div class="section-title text-center">
      Intră în contul tău <br />
      pentru a folosi platforma ExperEval
    </div>

    <div class="bg-form-white m-auto" style="border-radius: 12px">
      <div class="login-form card h-100">
        <div class="card-body m-auto">
          <div class="d-block pb-20 pb-xl-0" style="min-width: 342px">
            <div class="title mb-8">Autentificare</div>

            <Form v-slot="{ errors }" ref="loginForm" @submit="Login()">
              <div class="mb-8">
                <label>Adresă de Email</label>
                <Field
                  type="text"
                  name="userName"
                  class="form-control"
                  placeholder="Introduceți adresa de email"
                  :class="{ 'border-solid-danger': errors.userName || !credentialsAreValid || accountNeedsToBeConfirmed || !tenantIsValid }"
                  v-model="user.UserName"
                  rules="required|email"
                />
                <small v-if="errors.userName" class="text-danger">Email-ul este obligatoriu.</small>
                <small v-if="!credentialsAreValid" class="text-danger d-block">Email-ul sau parola este incorectă.</small>
                <small v-if="accountNeedsToBeConfirmed" class="text-danger d-block">Pentru a vă autentifica, vă rugăm să confirmați e-mailul.</small>
                <small v-if="!tenantIsValid" class="text-danger">Acest cont nu face parte dintr-o companie.</small>
              </div>
              <label>Parolă</label>
              <Field
                type="password"
                name="userPassword"
                class="form-control"
                placeholder="Introduceți parola"
                :class="{ 'border-solid-danger': errors.userPassword }"
                v-model="user.Password"
                autocomplete="on"
                rules="required"
              />
              <small v-if="errors.userPassword" class="text-danger">Parola este obligatorie.</small>
              <div class="forgot-password">
                <div class="text">Ai uitat parola?</div>
              </div>
              <div class="row">
                <div class="col">
                  <button type="submit" class="btn text-uppercase w-100" :class="[backgroundIsGreen ? ' btn-light-gradient-green' : 'btn-light-gradient-white']">Autentificare</button>
                </div>
                <div class="col-auto">
                  <button type="button" class="btn btn-white text-center w-100 shadow btn-lg" @click="MicrosoftLogin()">
                    <img class="me-2" width="20" alt="" src="@/assets/images/icons/microsoft.svg" /> Microsoft
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <div class="section-footer">
      <div class="title">Autentificare</div>
      <p class="description">
          Pentru a te loga în contul tau de Evaluation, este nevoie să introduci email-ul furnizat de către administrator și parola creată de tine. În cazul în care nu este posibilă logarea, apasă “Ai uitat parola?” sau contactează un expert. 
      </p>
    </div>
  </div>
  <LandingPageFooter />
</template>

<script>
import LandingPageFooter from "@/components/Footer/LandingPageFooter.vue";

import authentication from "@/authentication/index.js";

import auth from "@/utils/auth.js";
import moment from "moment";
import store from "@/utils/vuex.js";

import { defineRule } from "vee-validate";
import { required, email } from "@vee-validate/rules";
import { Field, Form, configure, ErrorMessage } from "vee-validate";

configure({
  validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
});

defineRule("required", required);
defineRule("email", email);
defineRule("confirmed", (value, [target]) => {
  if (!value) {
    return "Confirmarea parolei este obligatorie.";
  }
  if (value === target) {
    return true;
  }
  return "Parolele nu coincid.";
});

export default {
  name: "LoginView",
  components: { Field, Form, ErrorMessage, LandingPageFooter },
  data() {
    return {
      backgroundIsGreen: true,
      user: {
        UserName: null,
        Password: null,
      },
      currentUser: null,
      credentialsAreValid: true,
      accountNeedsToBeConfirmed: false,
      tenantIsValid: true,
      tenantAlreadyExists: false,
      userAlreadyExists: false,
      userType: "",
      userRegister: {
        LastName: "",
        FirstName: "",
        Email: "",
        TenantName: "",
        Password: "",
        ConfirmPassword: "",
      },
    };
  },
  methods: {
    MicrosoftLogin() {
      authentication.initialize();
    },
    CreateTenant: async function () {
      store.dispatch("changeLoaderStatus", true);
      this.tenantAlreadyExists = false;
      this.userAlreadyExists = false;
      await this.$axios
        .post("api/Tenant/creareTenant", this.userRegister)
        .then(async (response) => {
          this.$refs.registerForm.resetForm();
          store.dispatch("changeLoaderStatus", false);
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Contul a fost creat cu succes! Confirmă email-ul pentru autentificare.",
          });
        })
        .catch((error) => {
          if (error.response.data === "Tenant already exists!") this.tenantAlreadyExists = true;
          if (error.response.data === "User already exists!") this.userAlreadyExists = true;
          store.dispatch("toastNotify", {
            Type: "error",
            html: "Something went wrong!",
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    ChangeFormsBackground: function (formName) {
      if (formName === "loginForm") {
        this.backgroundIsGreen = true;
        this.tenantAlreadyExists = false;
        this.userAlreadyExists = false;
        this.$refs.registerForm.resetForm();
      } else {
        this.tenantIsValid = true;
        this.credentialsAreValid = true;
        this.accountNeedsToBeConfirmed = false;
        this.backgroundIsGreen = false;
        this.$refs.loginForm.resetForm();
      }
    },

    SelectUser: function (type) {
      switch (type) {
        case "admin":
          this.user.UserName = "admin@expertware.net";
          break;
        case "teamLeader":
          this.user.UserName = "teamLeader-1@expertware.net";
          break;
        case "projectManager":
          this.user.UserName = "projectManager-1@expertware.net";
          break;
        case "groupLeader":
          this.user.UserName = "groupLeader@expertware.net";
          break;
        case "employee":
          this.user.UserName = "employee@expertware.net";
          break;
      }
      this.Login();
    },
    Login: async function () {
      this.credentialsAreValid = true;
      this.accountNeedsToBeConfirmed = false;
      this.tenantIsValid = true;
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .post("api/Auth/login", this.user)
        .then(async (response) => {
          const token = {
            Value: response.data.token,
            Expiration: moment(response.data.expiration).format("YYYY-MM-DDTHH:mm:ss"),
          };
          auth.setJwtToken(token);
          auth.setRefreshToken(response.data.RefreshToken);
          store.dispatch("fetchUsers").then(() => {
            this.$router.push("/My-evaluations/Active");
          });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          if (error.response.data.includes("User don't found") || error.response.data.includes("Wrong password")) {
            this.credentialsAreValid = false;
          } else
            store.dispatch("toastNotify", {
              Type: "error",
              Title: "Something went wrong!",
            });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1400px) {
  .bg-form-white {
    max-width: 50%;
  }
}

.forgot-password {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1200px) {
  .forgot-password {
    height: 70px;
  }
}
@media only screen and (min-width: 1200px) {
  .forgot-password {
    height: 133px;
  }
}

.login.card input::placeholder {
  font-size: 16px !important;
}

.title-section {
  font-size: 40px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0em;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0em;
  margin-top: 75px;
}

/*GET IN TOUCH*/
svg {
  color: #26a190;
}

.login-form span {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s !important;
}

.login-form span i {
  color: rgba(77, 196, 179, 1) !important;
}

.login-form a:hover span {
  background-color: #016972 !important;
}

.login-form a:hover svg {
  color: white !important;
}

.login-form .find-us-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
.login-form .form-control {
  background-color: transparent;
  color: white;
  font-size: 16px !important;
  line-height: 21px;
}

.login-form input::placeholder {
  color: white !important;
  font-size: 16px !important;
}

.login-form .title {
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  text-align: center;
}

.login-form.card {
  border-radius: 0px 12px 12px 0px;
  background-color: transparent;
  color: white;
}
label {
  color: rgba(108, 117, 125, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.section-title {
  margin-top: 74px;
  color: rgba(24, 138, 148, 1);
  font-size: 32px;
  margin-bottom: 67px;
}

.form-control {
  padding: 16px;
}

.login .title {
  color: rgba(52, 58, 64, 1);
  font-size: 32px;
  font-weight: 400;
  line-height: 37px;
}

.login.card {
  border-radius: 12px;
  text-align: left;
}

.bg {
  background-image: url("../../assets/images/landing-page/square-get-in-touch.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  display: inline-block;
  width: 100%;
  bottom: 0;
  height: 100vh;
  right: 0;
  left: 0;
  z-index: -1;
}

.section-footer {
  margin-top: 80px;
  margin-bottom: 80px;
}

.section-footer .title {
  color: rgba(24, 138, 148, 1);
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 24px;
}

.section-footer .description {
  color: rgba(108, 117, 125, 1);
  font-size: 16px;
  font-weight: 300;
}

.input-group {
  border-radius: 0.475rem !important;
}
.input-group .form-control {
  padding-left: 16px !important;
  margin-left: 0 !important;
  border-top-left-radius: 0.475rem !important;
  border-bottom-left-radius: 0.475rem !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input-group-text {
  border: 1px solid #e7e7e7 !important;
  border-left: 0px !important;
}

/* change forms bg */
.bg-form-green {
  background: linear-gradient(87.6deg, #4dc4b3 0%, #209b8a 100%);
}

.bg-form-green .login-form label {
  color: white;
}

.bg-form-white .login-form .title {
  color: rgba(52, 58, 64, 1);
}

.bg-form-white .login-form label {
  color: rgba(108, 117, 125, 1);
}

.bg-form-white .login .title {
  color: white;
}

.bg-form-white {
  background: white;
}

.bg-form-white .login.card {
  background: linear-gradient(87.6deg, #4dc4b3 0%, #209b8a 100%);
}

.bg-form-white .login.card label {
  color: white;
}

.bg-form-white .login-form .form-control {
  color: rgba(108, 117, 125, 1);
}

.bg-form-white .login-form input::placeholder {
  color: rgba(108, 117, 125, 1) !important;
}

.bg-form-white .forgot-password .text {
  color: rgba(108, 117, 125, 1);
}

.bg-form-white .forgot-password .text:hover {
  color: black;
}

.forgot-password .text {
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s;
  width: 100%;
  text-align: center;
}
</style>
