<template>
  <div class="question-score-list preview-list">
    <div class="question-score" v-bind:key="index" v-for="index in question.MaxScore" v-bind:class="{ active: index == question.Grade }">{{ index }}</div>
  </div>
</template>

<script>
export default {
  Name: "preview-score-component",
  props: {
    question: { type: Object, default: new Object() },
  },
  emits: [],

  data() {
    return {};
  },
  methods: {},
};
</script>
