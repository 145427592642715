import { createRouter, createWebHistory } from "vue-router";

import LoggedInLayout from "../views/Layouts/LoggedInLayout.vue";
import LandingPageLayout from "../views/Layouts/LandingPageLayout.vue";

//import LoginView from "../views/Login/DemoLoginView.vue";
import LoginView from "../views/Login/LoginView.vue";
import ConfirmEmailView from "../views/Login/ConfirmEmailView.vue";

//------------------- Administration  -------------------
import ActiveEvaluationsView from "../views/Administration/Evaluations/ActiveEvaluationsView.vue";
import HistoryEvaluationsView from "../views/Administration/Evaluations/HistoryEvaluationsView.vue";
import DraftEvaluationsView from "../views/Administration/Evaluations/DraftEvaluationsView.vue";
import EmployeesView from "../views/Administration/Employees/EmployeesView.vue";
import TeamsView from "../views/Administration/Teams/TeamsView.vue";
import CustomersView from "../views/Administration/Customers/CustomersView.vue";
import ProjectsView from "../views/Administration/Projects/ProjectsView.vue";
import GroupsView from "../views/Administration/Groups/GroupsView.vue";

//------------------- User invoices  -------------------
import UserActiveEvaluationsView from "../views/UserEvaluations/ActiveVew.vue";
import UserHistoryEvaluationsView from "../views/UserEvaluations/HistoryView.vue";

//------------------- Templates  -------------------
import CategoriesTemplates from "../views/Administration/Templates/CategoriesView.vue";
import QuestionsTemplates from "../views/Administration/Templates/QuestionsView.vue";
import EvaluationsTemplates from "../views/Administration/Templates/EvaluationsView.vue";
// import CreateEvaluationTemplate from "../views/Evaluation/CreateEvaluationTemplateView.vue";
import EditEvaluationTemplate from "../views/Evaluation/EditEvaluationTemplateView.vue";

//------------------- Members  -------------------
import ActiveEvaluationMembersView from "../views/Members/ActiveEvaluationMembersView.vue";
import HistoryEvaluationMembersView from "../views/Members/HistoryEvaluationMembersView.vue";
import EvaluationMembersView from "../views/Members/EvaluationMembersView.vue";
import GroupEvaluationsView from "../views/Evaluation/GroupEvaluations.vue";
import EvaluationGroupMembersView from "../views/Members/EvaluationGroupMembersView.vue";
import LeaderEvaluationMembersView from "../views/Members/LeaderEvaluationMembersView.vue";
//------------------- Self evaluation answers  -------------------
import SelfEvaluationAnswersDetailsView from "../views/Answers/Self/DetailsView.vue";
import SelfEvaluationAnswersActiveView from "../views/Answers/Self/ActiveView.vue";

import PreviewEvaluationView from "../views/Evaluation/PreviewView.vue";
import LeaderAvarageView from "../views/Evaluation/LeaderAvarage.vue";

import ManagerEvaluationAnswersDetailsView from "../views/Answers/Manager/DetailsView.vue";
import ManagerEvaluationAnswersActiveView from "../views/Answers/Manager/ActiveView.vue";

import AnswersDetailsView from "../views/Answers/DetailsView.vue";
import AnswersActiveView from "../views/Answers/ActionsView.vue";
import AnswersComparisonView from "../views/Answers/ComparisonView.vue";
import ComparisonForGroupView from "../views/Answers/ComparisonForGroupView.vue";
import EditEvaluationView from "../views/Evaluation/EditView.vue";

//------------------- Landing pages  -------------------
import HomeLandingView from "../views/LandingPage/HomeView.vue";
import HowItWorksLandingView from "../views/LandingPage/HowItWorksView.vue";

//------------------- Compare  -------------------
import CompareView from "../views/Compare/CompareViewNew.vue";

import auth from "@/utils/auth.js";
import store from "@/utils/vuex.js";

import loginLayout from "@/layouts/Login.vue";

const role = {
  Admin: "Admin",
  TeamLeader: "TeamLeader",
  ProjectManager: "ProjectManager",
  GroupLeader: "GroupLeader",
  User: "User",
};

const routes = [
  {
    path: "/Landing-page",
    name: "LandingPage",
    component: LandingPageLayout,
    children: [
      {
        path: "/Landing-page/Home",
        name: "HomeLandingView",
        component: HomeLandingView,
        meta: {
          authorize: [],
        },
      },
      {
        path: "/Landing-page/How-it-works",
        name: "HowItWorksLandingView",
        component: HowItWorksLandingView,
        meta: {
          authorize: [],
        },
      },
      {
        path: "/Login",
        name: "Login",
        component: LoginView,
      },
      {
        path: "/microsoft-login",
        name: "MicrosoftLogin",
        component: () => import("@/views/Login/MicrosoftLogin.vue"),
        meta: {
          requiresAuthentication: true,
        },
      },
      {
        path: "/ConfirmEmail",
        name: "ConfirmEmail",
        component: ConfirmEmailView,
        query: {
          userEmail: "",
          code: "",
        },
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
    ],
  },

  {
    path: "",
    component: LoggedInLayout,
    children: [
      {
        path: "/Evaluations/Active",
        name: "ActiveEvaluationsView",
        component: ActiveEvaluationsView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/Evaluations/Group/:groupId",
        name: "GroupEvaluationsView",
        component: GroupEvaluationsView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/Evaluations/History",
        name: "HistoryEvaluationsView",
        component: HistoryEvaluationsView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/Evaluations/Draft",
        name: "DraftEvaluationsView",
        component: DraftEvaluationsView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      {
        path: "/My-evaluations/Active",
        name: "UserActiveEvaluationsView",
        component: UserActiveEvaluationsView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/My-evaluations/History",
        name: "UserHistoryEvaluationsView",
        component: UserHistoryEvaluationsView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      {
        path: "/Templates/Categories",
        name: "CategoriesTemplatesView",
        component: CategoriesTemplates,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/Templates/Questions",
        name: "QuestionsTemplateView",
        component: QuestionsTemplates,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/Templates/Evaluations",
        name: "EvaluationsTemplatesView",
        component: EvaluationsTemplates,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/Templates/Evaluations/Edit/:evalTempId",
        name: "EditEvaluationTemplate",
        component: EditEvaluationTemplate,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      {
        path: "/evaluations/active/evaluation/:id/members",
        name: "ActiveEvaluationMembersView",
        component: ActiveEvaluationMembersView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/evaluations/history/evaluation/:id/members",
        name: "HistoryEvaluationMembersView",
        component: HistoryEvaluationMembersView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/administration/evaluation/:id/members",
        name: "EvaluationMembersView",
        component: EvaluationMembersView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      {
        path: "/administration/evaluation/:id/group-members",
        name: "EvaluationGroupMembersView",
        component: EvaluationGroupMembersView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/administration/evaluation/:id/leader-members",
        name: "LeaderEvaluationMembersView",
        component: LeaderEvaluationMembersView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      {
        path: "/self/evaluation/:id/answers/actions",
        name: "SelfEvaluationAnswersActiveView",
        component: SelfEvaluationAnswersActiveView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/self/evaluation/:id/answers",
        name: "SelfEvaluationAnswersDetailsView",
        component: SelfEvaluationAnswersDetailsView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      //compare
      {
        path: "/Compare",
        name: "CompareView",
        component: CompareView,
        meta: {
          authorize: [role.Admin],
        },
      },
      //settings
      {
        path: "/Administration/employees",
        name: "EmployeesView",
        component: EmployeesView,
        meta: {
          authorize: [role.Admin],
        },
      },
      {
        path: "/Administration/teams",
        name: "TeamsView",
        component: TeamsView,
        meta: {
          authorize: [role.Admin],
        },
      },
      {
        path: "/Administration/Customers",
        name: "CustomersView",
        component: CustomersView,
        meta: {
          authorize: [role.Admin],
        },
      },
      {
        path: "/Administration/Projects/:customerId?&&:cutomerName?",
        name: "ProjectsView",
        component: ProjectsView,
        meta: {
          authorize: [role.Admin],
        },
      },
      {
        path: "/Administration/Groups",
        name: "EvaluationGroupView",
        component: GroupsView,
        meta: {
          authorize: [role.Admin],
        },
      },

      {
        path: "/administration/evaluations/evaluation/:id/preview",
        name: "PreviewEvaluationView",
        component: PreviewEvaluationView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/administration/evaluations/evaluation/:id/leader-results",
        name: "LeaderAvarageView",
        component: LeaderAvarageView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/evaluation/:evalId/:employeeId/:evaluatedBy/answers/comparison",
        name: "AnswersComparisonView",
        component: AnswersComparisonView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      {
        path: "/evaluation/:evalId/member/:employeeId/answers",
        name: "AnswersDetailsView",
        component: AnswersDetailsView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      {
        path: "/evaluation/:id/answers/actions",
        name: "AnswersActiveView",
        component: AnswersActiveView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      {
        path: "/evaluation/:evalId/:employeeId/:evaluatedBy/answers/comparison-group",
        name: "ComparisonForGroupView",
        component: ComparisonForGroupView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      {
        path: "/administration/evaluations/evaluation/:id/edit",
        name: "EditEvaluationView",
        component: EditEvaluationView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      {
        path: "manager/evaluations/evaluation/:id/member/:memberId/answers",
        name: "ManagerEvaluationAnswersActiveView",
        component: ManagerEvaluationAnswersActiveView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },
      {
        path: "/manager/evaluation/:id/member/:memberId/answers/details",
        name: "ManagerEvaluationAnswersDetailsView",
        component: ManagerEvaluationAnswersDetailsView,
        meta: {
          authorize: [role.Admin, role.TeamLeader, role.ProjectManager, role.GroupLeader, role.User],
        },
      },

      // {
      //   path: "/administration/history-evaluations",
      //   name: "HistoryEvaluationsView",
      //   component: HistoryEvaluationsView,
      //   meta: {
      //     authorize: [role.Admin, role.ProjectManager, role.TeamLeader],
      //   },
      // },

      // {
      //   path: "/:pageSection/:pageName/evaluation/:evalId/interval/:intervalId/scope/leader-or-manager/member/:employeeId/review-answers",
      //   name: "AnswersLeaderManagerView",
      //   component: AnswersLeaderManagerView,
      //   meta: {
      //     authorize: [role.Admin, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/:pageSection/:pageName/evaluation/:evalId/interval/:intervalId/scope/team-or-project/member/:employeeId/review-answers",
      //   name: "AnswersTeamProjectView",
      //   component: AnswersTeamProjectView,
      //   meta: {
      //     authorize: [role.Admin, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/:pageSection/:pageName/evaluation/:evalId/interval/:intervalId/scope/global/member/:employeeId/review-answers",
      //   name: "AnswersGlobalView",
      //   component: AnswersGlobalView,
      //   meta: {
      //     authorize: [role.Admin, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/:pageSection/:pageName/evaluation/:evalId/interval/:intervalId/member/:employeeId/no-answers",
      //   name: "RedirectAnswersView",
      //   component: RedirectAnswersView,
      //   meta: {
      //     authorize: [role.Admin, role.ProjectManager, role.TeamLeader],
      //   },
      // },

      // {
      //   path: "/administration/my-evaluations",
      //   name: "MyEvaluationsView",
      //   component: MyEvaluationsView,
      //   meta: {
      //     authorize: [role.Admin, role.ProjectManager, role.TeamLeader],
      //   },
      // },

      // {
      //   path: "/administration:/:administrationPage/evaluation/:id/details",
      //   name: "EvaluationDetailsView",
      //   component: EvaluationDetailsView,
      //   meta: {
      //     authorize: [role.Admin, role.ProjectManager, role.TeamLeader],
      //   },
      // },

      // //manager
      // {
      //   path: "/manager/active-evaluations",
      //   name: "ActiveEvaluationsManagerView",
      //   component: ActiveEvaluationsManagerView,
      //   meta: {
      //     authorize: [role.Admin, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/:pageSection/:pageName/evaluation/:evalId/interval/:intervalId/member/:employeeId/answer-to-evaluation",
      //   name: "AnswerToEvaluationManagerView",
      //   component: AnswerToEvaluationManagerView,
      //   meta: {
      //     authorize: [role.Admin, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/manager/evaluations-details",
      //   name: "DetailsEvaluationsView",
      //   component: DetailsEvaluationsView,
      //   meta: {
      //     authorize: [role.Admin, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // // {
      // //   path: "/manager/evaluations-details/evaluation/:evalId/interval/:intervalId/member/:employeeId/see-answers",
      // //   name: "BothAnswersManagerView",
      // //   component: BothAnswersManagerView,
      // //   meta: { authorize: [role.Admin, role.ProjectManager, role.TeamLeader] },
      // // },

      // //team services
      // {
      //   path: "/team-services/active-evaluations",
      //   name: "ActiveEvaluationsTeamServicesView",
      //   component: ActiveEvaluationsTeamServicesView,
      //   meta: {
      //     authorize: [role.Admin, role.User, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/team-services/history-evaluations",
      //   name: "HistoryEvaluationsTeamServicesView",
      //   component: HistoryEvaluationsTeamServicesView,
      //   meta: {
      //     authorize: [role.Admin, role.User, role.ProjectManager, role.TeamLeader],
      //   },
      // },

      // //self service
      // {
      //   path: "/self-services/active-evaluations",
      //   name: "ActiveEvaluationsSelfView",
      //   component: ActiveEvaluationsSelfView,
      //   meta: {
      //     authorize: [role.Admin, role.User, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/self-services/history-evaluations",
      //   name: "HistoryEvaluationsSelfView",
      //   component: HistoryEvaluationsSelfView,
      //   meta: {
      //     authorize: [role.Admin, role.User, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/:pageSection/:pageName/evaluation/:evalId/interval/:intervalId/answer-to-evaluation",
      //   name: "AnswerToEvaluationSelfView",
      //   component: AnswerToEvaluationSelfView,
      //   meta: {
      //     authorize: [role.Admin, role.User, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/self-services/history-evaluations/evaluation/:evalId/interval/:intervalId/see-answers",
      //   name: "AnswersSelfView",
      //   component: AnswersSelfView,
      //   meta: {
      //     authorize: [role.Admin, role.User, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/team-services/history-evaluations/evaluation/:evalId/interval/:intervalId/see-answers",
      //   name: "AnswersTeamServicesView",
      //   component: AnswersSelfView,
      //   meta: {
      //     authorize: [role.Admin, role.User, role.ProjectManager, role.TeamLeader],
      //   },
      // },

      // {

      // //Templates
      // {
      //   path: "/Templates/ExamplesView",
      //   name: "ExamplesView",
      //   component: TemplateExamplesView,
      //   meta: {
      //     authorize: [role.Admin, role.User, role.ProjectManager, role.TeamLeader],
      //   },
      // },
      // {
      //   path: "/Templates/CreateEvaluationExampleView",
      //   name: "CreateEvaluationExampleView",
      //   component: CreateEvaluationExampleView,
      //   meta: {
      //     authorize: [role.Admin, role.User, role.ProjectManager, role.TeamLeader],
      //   },
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// authorize
// https://jasonwatmore.com/post/2019/03/08/vuejs-role-based-authorization-tutorial-with-example
router.beforeEach(async (to, from, next) => {
  if (to.name === "HomeLandingView" || to.name === "HowItWorksLandingView" || to.name === "ConfirmEmail" || to.name === "MicrosoftLogin") {
    console.log(to.name);
    return next();
  } else {
    if (to.name !== "Login" && auth.getRefreshToken() == null)
      next({
        name: "Login",
      });
    else {
      if (to.name !== "Login") {
        await store.dispatch("getUserRoleAndFunction").then(() => {
          let { authorize } = to.meta;
          if (authorize) {
            if (store.getters.getUser == null) {
              //user not logged in
              return next({
                path: "/Login",
              });
            }
            //verify if route is restricted by role
            let userRoles = [...store.getters.getUser.UserRoles];
            if (!authorize.some((ai) => userRoles.includes(ai))) {
              return next({
                path: "/Login",
              });
            }
            return next();
          }
          //if not authorize, redirect to login
          return next({
            path: "/Login",
          });
        });
      } else next();
    }
  }
});

export default router;
