<template>
  <div class="mt-6">
    <div class="input-group mb-5">
      <span class="input-group-text"><i class="fa-light fa-magnifying-glass"></i></span>
      <input type="text" class="form-control" placeholder="Search for teams" v-model="searchText" v-on:keyup.enter="SearchForItems()" />
    </div>
  </div>
  <table class="table table-row-bordered">
    <thead class="table-header">
      <tr>
        <th class="text-center">
          <i v-if="CheckedAllVerification()" v-on:click="UncheckAll()" class="fa-solid fa-square-check fs-2x text-green"></i>
          <i v-else v-on:click="CheckAll()" class="fa-duotone fa-square fs-2x"></i>
        </th>
        <th>Team name</th>
        <th>Team leader</th>
        <th>Members</th>
      </tr>
    </thead>
    <tbody>
      <tr v-bind:key="index" v-for="(team, index) in itemsOfChosedScopeProps">
        <td class="text-center">
          <span v-if="team.LeaderName" @click="Check(team)">
            <i v-if="team.Checked" class="fa-solid fa-square-check fa-2x text-green"></i>
            <i v-else class="fa-duotone fa-square fa-2x"></i>
          </span>
          <i v-else title="This team has no team leader" class="fa-duotone fa-2x fa-square-info text-gray-400"></i>
        </td>
        <td class="fw-bold" :class="{ 'text-muted': !team.LeaderName }">
          {{ team.Name }}
        </td>

        <td>
          <div class="symbol symbol-30px me-2 symbol-circle">
            <img alt="Pic" :src="GetImgUrl(team.LeaderImage)" style="object-fit: cover" />
          </div>
          {{ team.LeaderName }}
          <span v-if="!team.LeaderName" class="text-muted">No team leader</span>
        </td>

        <td>
          <button v-if="team.NumberOfMembers > 0" class="btn btn-duotone-success btn-sm d-flex" @click="GetMembers(team)">
            Show {{ team.NumberOfMembers }} member <span v-if="team.NumberOfMembers > 1">s</span>
          </button>
          <span v-else>No members</span>
        </td>
      </tr>
    </tbody>
  </table>
  <not-found-component v-if="itemsOfChosedScopeProps.length == 0" :text="'No teams based on your query'"></not-found-component>
</template>
<script>
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";
export default {
  Name: "check-teams-table-component",
  props: ["itemsOfChosedScopeProps"],
  emits: ["getMembersEmit", "searchForItemsEmit"],
  components: { NotFoundComponent },

  data() {
    return { searchText: null };
  },
  methods: {
    UncheckAll: function () {
      this.itemsOfChosedScopeProps.map((x) => {
        x.Checked = false;
      });
    },

    CheckAll: function () {
      this.itemsOfChosedScopeProps.map((x) => {
        if (x.LeaderName) x.Checked = true;
      });
    },

    CheckedAllVerification: function () {
      if (
        this.itemsOfChosedScopeProps.filter(
          (x) => x.Checked != true && x.LeaderName
        ).length >= 1
      ) {
        return false;
      }
      return true;
    },
    GetMembers: function (team) {
      this.$emit("getMembersEmit", team);
    },
    Check: function (item) {
      item.Checked = !item.Checked;
    },
    SearchForItems: function () {
      this.$emit("searchForItemsEmit", this.searchText);
    },
    GetImgUrl: function (image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },
  },
};
</script>

<style scoped>
.fa-square:hover:before {
  content: "\f14a";
  color: #ddd !important;
  transition: 0.1s;
}

.fa-square,
.fa-square-check {
  cursor: pointer;
}

.btn-duotone-success {
  min-width: 140px;
}
</style>
