<template>
  <div class="row justify-content-center justify-content-lg-start pe-sm-20 ps-sm-20 pe-lg-0 ps-lg-0 align-items-center">
    <div class="col-md-6 col-lg-4 order-1 mt-12 order-lg-0">
      <div class="card white">
        <div class="card-body">
          <div class="type">Starter</div>
          <div class="price">$9.99</div>
          <div class="period">/month</div>
          <div class="description">
            Get started with the <br />
            essential evaluation tools
          </div>
          <div class="d-flex justify-content-center">
            <ul class="list">
              <li>
                1 Manager Account and 5 <br />
                Employee Accounts
              </li>
              <li>Basic Question Library</li>
              <li>Real-time Evaluations</li>
              <li>Mobile App Access</li>
              <li>Email Support</li>
            </ul>
          </div>
        </div>
        <div class="card-footer">get starter pack</div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4 order-3 mt-12 order-lg-0">
      <div class="card green">
        <div class="card-body mt-12">
          <div class="type">Pro</div>
          <div class="price">$24.99</div>
          <div class="period">/month</div>
          <div class="description">
            Unlock the full potential <br />
            of employee
          </div>
          <div class="d-flex justify-content-center">
            <ul class="list">
              <li>5 Manager Accounts and <br />20 Employee Accounts</li>
              <li>Advanced Question Library</li>
              <li>Customizable Evaluations</li>
              <li>Performance Analytics</li>
              <li>Priority Support</li>
            </ul>
          </div>
        </div>
        <div class="card-footer">
          <div class="mt-6 mb-6">get pro pack</div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4 order-2 mt-12 order-lg-0">
      <div class="card white">
        <div class="card-body">
          <div class="type">Premium</div>
          <div class="price">$49.99</div>
          <div class="period">/month</div>
          <div class="description">Elevate your evaluation <br />process with powerful insights</div>
          <div class="d-flex justify-content-center">
            <ul class="list">
              <li>
                Unlimited Manager and <br />
                Employee Accounts
              </li>
              <li>Premium Question Library</li>
              <li>Automated Evaluations</li>
              <li>Team Performance Analytics</li>
              <li>24/7 VIP Support</li>
            </ul>
          </div>
        </div>
        <div class="card-footer">get premium pack</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPagePlanCardsComponent",
  data() {
    return {};
  },
  created() {},
};
</script>

<style scoped>
.card.green {
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.15);
  background: linear-gradient(193.09deg, rgba(77, 196, 179, 0.8) 18.87%, rgba(32, 155, 138, 0.9) 81.13%);
  color: white;
}
.card.green .card-body .price {
  color: white;
}
.card.green .card-footer {
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.card.white {
  background-color: rgba(255, 255, 255, 0.9);
}
.card.white .card-body .price {
  color: rgba(33, 37, 41, 1);
}
.card.white .card-footer {
  color: rgba(24, 138, 148, 1);
  border-top: 1px solid rgba(217, 217, 217, 1);
}

.card {
  box-shadow: 0px 10px 30px 0px rgba(73, 80, 87, 0.08);
  border-radius: 8px;
  text-align: center;
  color: rgba(108, 117, 125, 1);
}
.card .card-body .type {
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  text-transform: uppercase;
}
.card .card-body .price {
  color: rgba(33, 37, 41, 1);
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
}
.card .card-body .period {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.card .card-body .description {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 24px;
  margin-bottom: 32px;
}

.card .card-body .list {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.card ul {
  --icon-space: 2.5em;
  list-style: none;
  padding: 0;
}

.card li {
  padding-left: var(--icon-space);
  padding-bottom: 16px;
  display: flex;
  align-items: center;
}

.card li:before {
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
  margin-left: calc(var(--icon-space) * -1);
  width: var(--icon-space);
}

.card .card-footer {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
</style>
