<template>
  <div class="modal fade" :id="'team-preview-modal' + (modalId ? modalId : '')" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row">
            <div class="col-auto">
              <h2 class="modal-title" id="evaluation-preview-modal-label">Team information</h2>
            </div>
            <div class="col text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="Close"></button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <h4 class="mb-5">Leader</h4>
          <div class="row">
            <div class="col-12 col-md-4 mb-6" v-for="(leader, index) in previewModalItemProp.Leaders" v-bind:key="index">
              <div class="d-flex align-items-center">
                <div class="h-40px me-4 w-40px"><img class="employee-img" :src="GetImgUrl(leader.Image)" /></div>
                <div class="text-gray-800 fw-bolder">{{ leader.Name }}</div>
              </div>
            </div>
          </div>

          <hr class="bg-gray-400" />

          <h4 class="mt-10 mb-5">Members</h4>
          <div class="row">
            <div v-bind:key="index" v-for="(member, index) in previewModalItemProp.Members" class="col-12 col-md-4 mb-6 align-items-center">
              <div class="d-flex align-items-center">
                <div class="h-40px me-4 w-40px"><img class="employee-img" :src="GetImgUrl(member.Image)" /></div>
                <div class="text-gray-800 fw-bolder">{{ member.Name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  Name: "team-preview-modal-component",
  props: {
    previewModalItemProp: { type: Object, default: {} },
    modalId: { type: String, default: null },
  },
  emits: [],

  data() {
    return {};
  },
  methods: {
    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },
  },
};
</script>

<style scoped>
.timeline-content {
  border-radius: 5px;
}
h2 {
  color: #188a94;
}

.modal-backdrop.show {
  z-index: 1999 !important;
}

.modal {
  z-index: 2000;
}

p {
  color: #6c757d;
}

.fa-question-square {
  font-size: 30px;
}

.btn {
  min-width: 100px;
}

.modal-body {
  max-height: 600px;
  overflow: auto;
}

/* .modal-header,
.modal-footer {
  display: block;
} */
</style>
