<template>
  <div class="mt-6">
    <div class="row">
      <div class="col">
        <div class="input-group mb-5">
          <span class="input-group-text"><i class="fa-light fa-magnifying-glass"></i></span>
          <input type="text" class="form-control" placeholder="Search for groups" v-model="searchText" v-on:keyup.enter="SearchForItems()" />
        </div>
      </div>
      <div class="col-auto">
        <!-- <button class="btn btn-success">+ New Group</button> -->
      </div>
    </div>

    <table class="table table-row-bordered">
      <thead class="table-header">
        <tr>
          <th class="text-center">
            <i v-if="CheckedAllVerification()" v-on:click="UncheckAll()" class="fa-solid fa-square-check fs-2x text-green"></i>
            <i v-else v-on:click="CheckAll()" class="fa-duotone fa-square fs-2x"></i>
          </th>
          <th>Group name</th>
          <th>Leaders</th>
          <th>Members</th>
        </tr>
      </thead>
      <tbody>
        <tr v-bind:key="group" v-for="group in itemsOfChosedScopeProps">
          <td class="text-center">
            <span v-if="AvailableForCheck(group)" @click="Check(group)">
              <i v-if="group.Checked" class="fa-solid fa-square-check fa-2x text-green"></i>
              <i v-else class="fa-duotone fa-square fa-2x"></i>
            </span>
            <i v-else title="This group is not complete" class="fa-duotone fa-2x fa-square-info text-gray-400"></i>
          </td>
          <td :class="{ 'text-muted': ((group.Leaders && group.Leaders.length == 0) || group.NumberOfMembers == 0) && scope.Scope != 'Group Mesh' }">{{ group.Name }}</td>
          <td>
            <span v-bind:key="index" v-for="(leader, index) in group.Leaders">{{ leader }}<span v-if="group.Leaders && group.Leaders.length != index + 1">, </span></span>
            <span v-if="group.Leaders && group.Leaders.length == 0" :class="{ 'text-muted': group.Leaders && group.Leaders.length == 0 && scope.Scope != 'Group Mesh' }">No group leaders</span>
          </td>
          <td>
            <button v-if="group.NumberOfMembers > 0" class="btn btn-duotone-success btn-sm d-block" @click="GetMembers(group)">
              Show {{ group.NumberOfMembers }} member<span v-if="group.NumberOfMembers > 1">s</span>
            </button>
            <span class="text-muted" v-else>No members</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <not-found-component v-if="itemsOfChosedScopeProps.length == 0" :text="'No groups based on your query'"></not-found-component>
</template>

<script>
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";
export default {
  Name: "check-groups-table-component",
  props: ["itemsOfChosedScopeProps", "scope"],
  emits: ["getMembersEmit", "searchForItemsEmit"],
  components: { NotFoundComponent },
  data() {
    return { searchText: null };
  },
  methods: {
    AvailableForCheck: function (group) {
      if (this.scope.Scope == "Group Mesh") {
        return true;
      }

      if (group.Leaders && group.Leaders.length > 0 && group.NumberOfMembers > 0) {
        return true;
      }

      return false;
    },

    UncheckAll: function () {
      this.itemsOfChosedScopeProps.map((x) => {
        if ((x.Leaders.length > 0 && x.NumberOfMembers > 0) || this.scope.Scope == "Group Mesh") x.Checked = false;
      });
    },

    CheckAll: function () {
      this.itemsOfChosedScopeProps.map((x) => {
        if ((x.Leaders.length > 0 && x.NumberOfMembers > 0) || this.scope.Scope == "Group Mesh") x.Checked = true;
      });
    },

    CheckedAllVerification: function () {
      console.log(this.itemsOfChosedScopeProps);
      if (this.scope.Scope == "Group Mesh") {
        if (this.itemsOfChosedScopeProps.every((x) => x.Checked == true)) {
          return true;
        }
      } else {
        if (this.itemsOfChosedScopeProps.filter((x) => x.Leaders.length > 0 && x.NumberOfMembers > 0).every((x) => x.Checked == true)) {
          return true;
        }
      }

      return false;
    },

    GetMembers: function (group) {
      this.$emit("getMembersEmit", group);
    },

    Check: function (item) {
      item.Checked = !item.Checked;
    },
    SearchForItems: function () {
      this.$emit("searchForItemsEmit", this.searchText);
    },
  },
};
</script>

<style scoped>
button {
  min-width: 150px;
}
.fa-square:hover:before {
  content: "\f14a";
  color: #ddd !important;
  transition: 0.1s;
}

.fa-square,
.fa-square-check {
  cursor: pointer;
}

.btn-duotone-success {
  min-width: 140px;
}
</style>
