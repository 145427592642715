<template>
  <div class="col">
    <label for="search" class="fw-bold fs-7 text-muted">Search</label>
    <div class="input-group">
      <span class="input-group-text border-0">
        <i class="fa-solid fa-search fs-18px"></i>
      </span>
      <input type="text" class="form-control form-control-solid ps-2" id="search" placeholder="Search by name..." v-model="searchTextProps.SearchText" @input="SearchForItems()" />
    </div>
  </div>
</template>

<script>
export default {
  Name: "SearchComponent",
  props: {
    searchTextProps: {
      type: Object,
      default: {},
    },
  },
  emits: ["searchForItems"],
  data() {
    return {};
  },
  methods: {
    SearchForItems: function () {
      this.$emit("searchForItems");
    },
  },
};
</script>
