<template>
  <div class="modal fade" id="create-category-modal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="CreateCategory" ref="createCategoryForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title">Create category</h2>
              </div>
              <div class="col text-end">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
          </div>

          <div class="modal-body">
            <div class="mb-5">
              <label class="form-label">
                <span class="required">Name</span>
              </label>

              <Field
                type="text"
                name="categoryName"
                class="form-control"
                :class="{ 'border-danger': errors.categoryName }"
                placeholder="Category name"
                v-model="newCategory.Name"
                rules="required|min: 3"
              />

              <div v-if="errors.categoryName" class="fv-plugins-message-container invalid-feedback">Name requires minimum 3 and maximum 200 characters.</div>
            </div>

            <div class="mb-5">
              <label class="form-label"> Description </label>
              <Field name="categoryDescription" v-slot="{ field }" rules="max:500">
                <textarea
                  v-bind="field"
                  type="text"
                  :class="{ 'border-solid-danger': errors.categoryDescription }"
                  class="form-control"
                  placeholder="Category description..."
                  name="Category description"
                  v-model="newCategory.Description"
                />
              </Field>
              <div v-if="errors.categoryDescription" class="fv-plugins-message-container invalid-feedback">Description requires maximum 500 characters.</div>
            </div>

            <div class="modal-footer flex-stack">
              <button type="button" class="btn btn-light-dark me-3" data-bs-dismiss="modal">Close</button>

              <button class="btn btn-gradient-success">Save</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineRule } from "vee-validate";
import { required, min, max } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);
defineRule("min", min);
defineRule("max", max);

export default {
  name: "AddCategoryModal",
  components: {
    Field,
    Form,
  },
  props: {
    evaluationId: Number,
    newCategory: {
      type: Object,
      default: {},
    },
  },
  emits: ["createCategoryEmit"],
  data() {
    return {};
  },
  methods: {
    CreateCategory: async function () {
      this.newCategory.EvaluationPlanId = this.evaluationId;
      this.$emit("createCategoryEmit");
    },
  },
};
</script>
