<template>
  <div class="timeline-label">
    <div class="align-items-center timeline-item">
      <div class="timeline-badge">
        <i class="fa fa-question-square text-secondary"></i>
      </div>
      <div class="bg-light ms-10 p-4 timeline-content mt-4 mb-4">
        <h3>{{question.Title}}</h3>
        <p class="m-0">
          {{question.Description}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  Name: "question-timeline-details",
  props: {
    question: { type: Object, default: new Object() },
  },

  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
/* .timeline-label:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 3px;
  bottom: 0;
  background-color: #eff2f5;
} */

.timeline-content {
  border-radius: 5px;
}
h2 {
  color: #188a94;
}

p {
  color: #6c757d;
}

.fa-question-square {
  font-size: 30px;
}

.btn {
  min-width: 100px;
}
</style>
