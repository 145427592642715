<template>
  <div class="modal fade" id="create-question-modal" tabindex="-1" aria-labelledby="questionModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="CreateQuestion" ref="createQuestionForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title">Create question</h2>
              </div>
              <div class="col text-end">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="mb-5">
              <label class="form-label">
                <span class="required">Title</span>
              </label>
              <Field name="questionTitle" v-slot="{ field }" rules="required|min: 3|max: 4000">
                <textarea v-bind="field" rows="2" type="text" class="form-control" v-model="newQuestion.Title" placeholder="Question title" />
              </Field>
              <div v-if="errors.questionTitle" class="fv-plugins-message-container invalid-feedback">Title requires minimum 3 and maximum 4000 characters.</div>
            </div>
            <div class="row">
              <div class="col mb-5">
                <label class="form-label">
                  <span class="required">Question type</span>
                </label>
                <Field name="selectItemFromChosedScope" v-slot="{ field }" :rules="{ required: type == null }">
                  <VueMultiselect
                    select-label=""
                    v-bind="field"
                    v-model="type"
                    :allowEmpty="false"
                    :options="types"
                    placeholder="Select question type"
                    label="Name"
                    track-by="Id"
                    :internalSearch="true"
                  />
                </Field>
                <div v-if="errors.selectItemFromChosedScope" class="fv-plugins-message-container invalid-feedback">Type is require.</div>
              </div>
            </div>

            <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2"> Description </label>
            <Field name="questionDescription" v-slot="{ field }" rules="max:5000">
              <textarea rows="3" v-bind="field" type="text" class="form-control" placeholder="Question description" name="Description" v-model="newQuestion.Description" />
            </Field>
            <div v-if="errors.questionDescription" class="fv-plugins-message-container invalid-feedback">Description requires maximum 5000 characters.</div>
          </div>
          <div class="modal-footer flex-stack">
            <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineRule } from "vee-validate";
import { required, min, between, max } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);
defineRule("between", between);
defineRule("min", min);
defineRule("max", max);

export default {
  name: "add-question-modal",
  components: {
    Field,
    Form,
  },
  props: {
    newQuestion: { type: Object, default: {} },
    types: { type: Array, default: [] },
  },
  emits: ["createQuestionsEmit"],
  data() {
    return {
      type: null,
    };
  },
  methods: {
    ClearCreateQuestionModal: function () {
      this.$refs.createQuestionForm.resetForm();
      this.type = null;
    },
    CreateQuestion: async function () {
      this.newQuestion.QuestionTypeId = this.type.Id;
      this.$emit("createQuestionsEmit");
    },
  },
  created() {},
};
</script>
