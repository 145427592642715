<template>
  <nav class="green-navbar shadow-sm d-md-none" id="green-navbar">
    <div class="align-items-center h-100 row justify-content-around ms-0 me-0">
      <div class="col-auto">
        <div class="hamburger" :class="{ 'is-active': hamburgerOpen }" v-on:click="hamburgerOpen = !hamburgerOpen">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
      <div class="col-auto">
        <router-link :to="{ name: 'HomeLandingView' }">
          <span class="app-title">ExpertEval</span>
        </router-link>
      </div>
      <div class="col-auto">
        <!-- <img src="https://i.postimg.cc/NF9hG0Vg/Screenshot-6.png" alt="User profile icon" /> -->
      </div>
    </div>
  </nav>
  <div class="hamburger-menu" :class="{ 'hamburger-menu-after': hamburgerOpen }">
    <hr />
    <ul class="menu-pages">
      <li>
        <router-link :to="{ name: 'HomeLandingView' }"> Acasă </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'HowItWorksLandingView' }"> Cum funcționează </router-link>
      </li>
      <li>
        <span v-on:click="ScrollToItem('contact-us')">Contactează-ne</span>
      </li>
      <li>
        <router-link :to="{ name: 'Login' }" target="_blank">
          <button type="button" class="btn btn-gradient-green mt-5">Încearcă gratuit</button>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "landing-page-navbar-compponent-mobile",
  props: {
    routeName: {
      default: null,
      type: String,
    },
  },
  methods: {
    ScrollToItem(id) {
      this.hamburgerOpen = false;
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
  },
  watch: {
    // hamburgerOpen(value) {
    //   //disable page scrolling whem menu
    //   value ? ((document.body.style.overflowY = "hidden"), (document.body.style.position = "fixed")) : ((document.body.style.overflowY = "visible"), (document.body.style.position = "relative"));
    // },
  },
  data() {
    return {
      hamburgerOpen: false,
    };
  },
};
</script>
<style scoped>
.hamburger .line {
  width: 30px;
  height: 3px;
  background-color: white;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(9px) rotate(45deg);
  -ms-transform: translateY(9px) rotate(45deg);
  -o-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}

.hamburger-menu {
  margin-top: -20px;
  height: 0px;
  background-color: white;
  transition: height 0.2s ease;
  overflow: hidden;
}

.hamburger-menu-after {
  height: calc(100vh - 50px);
  position: fixed;
  width: 100%;
  z-index: 1;
  transition: height 0.2s ease;
}

.btn {
  transition: 0.2s;
}

.btn:hover {
  color: black !important;
}

.app-title {
  font-size: 35px;
  color: white;
}

.green-navbar {
  position: sticky;
  top: 0;
  height: 80px;
  background: linear-gradient(87.6deg, #188a94 0%, #006871 100%);
  border-bottom: 1px solid rgba(173, 181, 189, 1);
  z-index: 55;
}

.hamburger-menu h4 {
  margin-left: 5%;
  margin-top: 50px;
}
.hamburger-menu .menu-pages {
  margin-left: 12%;
}

.menu-pages {
  list-style-type: none;
  text-transform: uppercase;
  padding-left: 0px;
}

.menu-pages li a {
  font-weight: 400;
  font-size: 26px;
  line-height: 70px;
  color: #343a40;
  transform: none !important;
  cursor: pointer;
}

.menu-pages li span {
  font-weight: 400;
  font-size: 26px;
  line-height: 70px;
  color: #343a40;
  cursor: pointer;
}
.menu-pages li a.router-link-active {
  font-weight: 700;
}

.right-arrow {
  box-sizing: border-box;
  position: fixed;
  width: 80px;
  height: 80px;
  left: -40px;
  top: 0px;
  background: rgba(255, 255, 255, 0.05);
  /* White */
  border: 1px solid #ffffff;
  border-radius: 54px;
}
.right-arrow i {
  position: absolute;
  top: 15px;
  font-size: 30px;
  left: 50px;
  color: #ffffff;
}

.left-arrow {
  box-sizing: border-box;
  position: fixed;
  width: 80px;
  height: 80px;
  right: -40px;
  top: 0px;
  background: rgba(255, 255, 255, 0.05);
  /* White */
  border: 1px solid #ffffff;
  border-radius: 54px;
}
.left-arrow i {
  position: absolute;
  top: 15px;
  font-size: 30px;
  right: 50px;
  color: #ffffff;
  transform: rotate(-180deg);
}
</style>
