<template>
  <div class="container">
    <nav aria-label="breadcrumb" class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item active"><span class="active" href="#">Teams</span></li>
      </ol>
    </nav>
    <h1 class="page-title">Teams</h1>
    <div class="row mb-10 align-items-end">
      <div class="col">
        <Search @searchForItems="GetTeams" :placeholderText="'Search for teams'" :searchText="filter" />
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-gradient-success" v-on:click="GetTeams()"><i class="fa-search far text-white"></i> Search</button>
      </div>
      <div class="col"></div>
      <div class="col text-end">
        <button type="button" class="btn btn-gradient-success" data-bs-toggle="modal" data-bs-target="#create-team-modal" @click="OpenCreateTeamModal()">+ New team</button>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <table class="table table-row-dashed">
          <thead class="table-header">
            <tr>
              <th>
                <span class="pointer text-dark-green" v-on:click="OrderBy('Name')">
                  Name
                  <span v-show="filter.OrderBy === 'Name'">
                    <i class="fa-solid fa-arrow-up-short-wide"></i>
                  </span>
                  <span v-show="filter.OrderBy === 'Name_desc'">
                    <i class="fa-solid fa-arrow-down-short-wide"></i>
                  </span>
                </span>
              </th>
              <th>Leader</th>
              <th>Members</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(team, index) in teams.Items" v-bind:key="index">
              <td>
                <div class="fw-bold">{{ team.TeamName }}</div>
                <small> {{ team.TeamDescription }}</small>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="h-40px w-40px">
                    <img class="employee-img" :src="GetImgUrl(team.LeaderImage)" />
                  </div>
                  <div class="ms-5 fw-bold">
                    {{ team.LeaderName }}
                  </div>
                </div>
              </td>
              <td>
                <span v-if="team.NumberOfMembers == 1">{{ team.NumberOfMembers }} member</span>
                <span v-if="team.NumberOfMembers > 1">{{ team.NumberOfMembers }} members</span>
                <span v-if="team.NumberOfMembers == 0" class="text-muted">No members</span>
              </td>
              <td class="text-end">
                <!-- <button type="button" class="btn btn-info-alternative me-3" @click="( = team), "> See members</button> -->
                <button @click="SelectTeam(team)" class="btn btn-secondary-alternative me-3"><i class="fa-solid fa-pen"></i> Edit</button>
                <button @click="DeleteTeam(team.TeamId)" type="button" class="btn btn-danger-alternative"><i class="far fa-trash-can"></i> Delete</button>
              </td>
            </tr>
          </tbody>
        </table>

        <hr v-if="teams.NumberOfPages > 1" />

        <Pagination :teams="teams.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetTeams" />

        <not-found-component v-if="teams.Count === 0" :text="'Teams not found'"></not-found-component>
      </div>
    </div>
  </div>

  <CreateTeamModal @createTeam="CreateTeam" @searchForEmployees="GetShortEmployeesDetails" :employees="allEmployees" ref="modalCreate" />

  <EditTeamModal @editTeam="EditTeam" @SearchForEmployees="GetShortEmployeesDetails" :employees="allEmployees" :teamDetails="teamForEdit" />
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import CreateTeamModal from "@/components/Modals/Team/CreateTeamModal.vue";
import EditTeamModal from "@/components/Modals/Team/EditTeamModal.vue";
// import AddMemberModal from "@/components/Modals/Team/AddMemberModal.vue";
import Search from "@/components/Common/Search.vue";
import Pagination from "@/components/Common/Pagination.vue";
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";

export default {
  name: "TeamsView",
  components: {
    Search,
    Pagination,
    CreateTeamModal,
    EditTeamModal,
    NotFoundComponent,
  },
  emits: ["createTeamEmit", "getEmployeesEmit"],
  data() {
    return {
      teams: {},
      teamForEdit: {},
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
      },
      filterTeamMembers: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
      },
      filterUnassignedMembers: { SearchText: null, SearchLoader: false },
      allEmployees: [],
      selectedTeam: {},
      unnasignedTeamMembers: [],
      membersToAdd: {
        TeamId: 0,
        EmployeeIdList: [],
      },
      teamMembers: [],
      teamInfo: {
        TeamId: 0,
        EmployeeIdList: [],
      },
    };
  },
  watch: {
    $route(to, from) {},
  },
  methods: {
    SelectTeam: async function (team) {
      this.selectedTeam = team;
      await this.GetTeam(team.TeamId);
      await this.GetEmployeesByTeam();
      this.GetShortEmployeesDetails();
      $("#edit-team-modal").modal("show");
    },
    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    OpenCreateTeamModal: function () {
      this.teamForEdit = {};
      this.$refs.modalCreate.ClearModal();
      this.GetShortEmployeesDetails();
    },

    CreateTeam: async function (teamDetails) {
      teamDetails.TeamMembers = teamDetails.TeamMembers.map((x) => x.EmployeeId);

      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .post("api/Team/createTeam", teamDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.GetTeams();
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data.message,
          });
          $("#create-team-modal").modal("hide");
          this.$refs.modalCreate.ClearModal();

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    OrderBy: function (orderBy) {
      if (!this.filter.OrderBy.includes("_desc")) {
        this.filter.OrderBy = orderBy + "_desc";
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.GetTeams();
    },

    MembersOrderBy: function (orderBy) {
      if (!this.filterTeamMembers.OrderBy.includes("_desc")) {
        this.filterTeamMembers.OrderBy = orderBy + "_desc";
      } else {
        this.filterTeamMembers.OrderBy = orderBy;
      }
      this.GetEmployeesByTeam();
    },

    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    GetTeams: async function (page) {
      
      store.dispatch("changeLoaderStatus", true);
      this.selectedTeam = {};
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      this.filter.PageNumber = 1;
      if (page) {
        this.filter.PageNumber = page;
        window.scrollTo(0, 0);
      }
      var query = new URLSearchParams({
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
      });
      await this.$axios
        .get("api/Team/getPagedTeams?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.teams = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetTeam: async function (teamId) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Team/getByTeamId?teamId=" + teamId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.teamForEdit = response.data;
          this.teamForEdit.TeamMembers = [];
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEmployeesByTeam: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Team/getEmployeesByTeam?id=" + this.selectedTeam.TeamId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.teamForEdit.TeamMembers = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetShortEmployeesDetails: function (searchText) {
      if (!searchText) {
        searchText = "";
      }
      this.$axios
        .get("api/Employee/getShortEmployeesDetails?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.allEmployees = response.data;

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },

    EditTeam: async function (teamDetails) {
      store.dispatch("changeLoaderStatus", true);
      teamDetails.EmployeesIds = teamDetails.TeamMembers.map((x) => x.EmployeeId);
      await this.$axios
        .put("api/Team/updateTeam", teamDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.GetTeams();
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data.message,
          });
          $("#edit-team-modal").modal("hide");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    DeleteTeam: async function (id) {
      var self = this;
      await this.$swal
        .fire({
          title: "Warning",
          text: "Are you sure you want to delete this team?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            self.$axios
              .delete("api/Team/" + id, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then((response) => {
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: response.data.message,
                });
                self.GetTeams();
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data.message,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
  },

  created() {
    this.GetTeams();
  },
};
</script>
