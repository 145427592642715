<template>
  <div class="modal fade" :id="'evaluation-preview-modal' + (modalId ? modalId : '')" tabindex="-1" aria-labelledby="evaluation-preview-modal-label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row">
            <div class="col-auto">
              <h2 class="modal-title" id="evaluation-preview-modal-label">Evaluation preview</h2>
            </div>
            <div class="col text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="Close"></button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="bg-lighten p-3 rounded">
            <h3>{{ evalTemplateDetailsProp.Name }}</h3>
            <small>
              {{ evalTemplateDetailsProp.Description }}
            </small>
          </div>
          <hr />
          <div v-bind:key="categoryIndex" v-for="(category, categoryIndex) in evalTemplateDetailsProp.Categories">
            <h2>{{ category.Name }}</h2>
            <p class="m-0">
              {{ category.Description }}
            </p>
            <div class="card-body pt-5">
              <div class="timeline-label">
                <div v-bind:key="questionIndex" v-for="(question, questionIndex) in category.Questions" class="align-items-center timeline-item">
                  <div class="timeline-badge">
                    <i class="fa fa-question-square text-secondary"></i>
                  </div>
                  <div class="bg-light ms-10 p-4 timeline-content">
                    <h3>{{ question.Name }}</h3>
                    <p class="m-0">
                      {{ question.Description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="categoryIndex != evalTemplateDetailsProp.Categories.length - 1" class="mb-7" />
          </div>
        </div>
        <div class="modal-footer">
          <div v-if="buttons" class="row">
            <div class="col">
              <button type="button" class="btn btn-secondary" @click="DeleteEvaluation"><i class="far fa-trash-alt"></i> Delete</button>
            </div>
            <div class="col-auto p-0" data-bs-toggle="modal" data-bs-target="#evaluation-preview-modal">
              <router-link v-if="evalTemplateDetailsProp.Id" class="btn btn-secondary" :to="{ name: 'EditEvaluationTemplate', params: { evalTempId: evalTemplateDetailsProp.Id } }"> Edit</router-link>
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-gradient-success" @click="OpenAssignModal()" data-bs-toggle="modal" data-bs-target="#assign-evaluation-modal">Create evaluation</button>
            </div>
          </div>
          <div v-else class="row">
            <div class="col"></div>
            <div class="col-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  Name: "eval-preview-modal-Component",
  props: ["buttons", "evalTemplateDetailsProp", "var1", "selectedEvalTemplateProp", "modalId"],
  emits: ["clearModalAssignEmit", "getScopesEmit", "deleteEvaluationPlan"],

  methods: {
    OpenAssignModal: function () {
      this.selectedEvalTemplateProp.Id = this.evalTemplateDetailsProp.Id;
      this.selectedEvalTemplateProp.Name = this.evalTemplateDetailsProp.Name;
      this.$emit("clearModalAssignEmit");
      this.$emit("getScopesEmit");
    },
    DeleteEvaluation: function () {
      this.selectedEvalTemplateProp.Id = this.evalTemplateDetailsProp.Id;
      this.selectedEvalTemplateProp.WorkspaceId = this.evalTemplateDetailsProp.WorkspaceId;
      this.$emit("deleteEvaluationPlan");
    },
  },
};
</script>

<style scoped>
.modal-backdrop.show {
  z-index: 1999 !important;
}

.modal {
  z-index: 2000;
}
.timeline-content {
  border-radius: 5px;
}
h2 {
  color: #188a94;
}

p {
  color: #6c757d;
}

.fa-question-square {
  font-size: 30px;
}

.btn {
  min-width: 100px;
}

.timeline-label:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 3px;
  bottom: 0;
  background-color: #eff2f5;
}

.modal-body {
  max-height: 600px;
  overflow: auto;
}

/* .modal-header,
.modal-footer {
  display: block;
} */
</style>
