<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Evaluations</a></li>
        <li class="breadcrumb-item" aria-current="page">
          {{ group.Name }}
        </li>
        <li class="breadcrumb-item active" aria-current="page">Evaluations</li>
      </ol>
    </nav>

    <div class="row align-items-end">
      <div class="col">
        <h1 class="page-title">Evaluations</h1>
      </div>
      <div class="col m-8 text-sm-end me-0">
        <button v-if="group.AllStarted != true" class="btn btn-gradient-success me-2" @click="StartAll">Start all</button>
        <button v-if="group.AllNew != false" class="btn btn-withe bg-white me-2" data-bs-toggle="modal" data-bs-target="#edit-group-modal" @click="GetGroupForEdit">Edit</button>
        <button v-else class="btn btn-withe bg-white me-2" data-bs-toggle="modal" data-bs-target="#edit-interval-modal" @click="GetGroupForEdit">Edit active period</button>
        <button v-if="group.AllNew != false" class="btn btn-white text-danger" @click="DeleteGroup">Delete</button>
      </div>
    </div>

    <div class="card mb-8 mt-8 evaluation-summary-card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h1>{{ group.Name }}</h1>
          </div>
        </div>
        <hr />
        <div class="row align-items-center">
          <div class="col d-flex align-items-center">
            <img v-if="group.Scope" v-bind:src="require('@/assets/images/scopes-icons/' + group.Scope + '.png')" />
            <div>
              <small>Scope</small>
              <h5>{{ group.Scope }}</h5>
            </div>
          </div>
          <div class="col d-flex align-items-center">
            <img v-if="group.Target" v-bind:src="require('@/assets/images/scopes-icons/' + group.Target + '.png')" />
            <div>
              <small>Target</small>
              <h5>{{ group.Target }}</h5>
            </div>
          </div>

          <div class="col" title="The period in which the assessment is open">
            <small>Active period </small>
            <h5>{{ $utils.formatDate(group.StartInterval) }} <span class="text-green">-</span> {{ $utils.formatDate(group.EndInterval) }}</h5>
          </div>

          <div class="col">
            <small>Number of evaluations</small>
            <h5>{{ group.NumberOfEvaluations }}</h5>
          </div>
          <div class="col d-flex align-items-center">
            <i class="fa-light fa-image-user fa-3x me-3 text-green"></i>
            <div>
              <small>Self assessment</small>
              <h5 v-if="group.SelfAssessment">Yes</h5>
              <h5 v-else>No</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <!-- <div class="card-header d-block pt-4">
        <div class="row mb-5">
          <div class="col-3">
            <search @searchForItems="GetPagedEvaluationByGroup(1)" :searchText="filter" :showLabel="false" :placeholderText="'Search for members'" />
          </div>
          <div class="col"></div>
        </div>
      </div> -->

      <div class="card-body">
        <table class="table table-row-bordered">
          <thead class="table-header">
            <tr>
              <!-- <th>  {{  group.Scope  }}</th> -->
              <!-- <th></th> -->
              <th>{{ group.Scope }}</th>
              <th>Leader</th>
              <th>Evaluation period</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(groupEval, index) in groupEvaluations.Items" v-bind:key="index">
              <td>
                {{ groupEval.EvaluationFor }}
              </td>
              <td>
                <span v-for="(leader, indexLeader) in groupEval.Leaders" v-bind:key="indexLeader" class="fw-bold">
                  {{ leader }}
                  <span class="text-green" v-if="groupEval.Leaders.length > 1 && groupEval.Leaders.length != index + 1"> | </span>
                </span>
              </td>
              <td>
                {{ $utils.formatDate(groupEval.StartDate) }}
                <span class="m-3">-</span>
                {{ $utils.formatDate(groupEval.EndDate) }}
              </td>
              <td>
                <span v-if="groupEval.Status == 'New'" class="badge-light-dark badge">{{ groupEval.Status }}</span>
                <span v-else class="badge-light-success badge">{{ groupEval.Status }}</span>
              </td>
              <td v-if="groupEval.Status != 'New'" class="text-end">
                <router-link class="h-100" v-if="[6, 8].includes(groupEval.TargetId) || (groupEval.TargetId == 7 && groupEval.IsOwner == true)" :to="{ name: 'EvaluationGroupMembersView', params: { id: groupEval.Id } }">
                  <button class="btn btn-outline-success">See members</button></router-link>

                <router-link class="h-100" v-if="[3, 5, 7].includes(groupEval.TargetId) && groupEval.IsOwner == false" :to="{ name: 'LeaderEvaluationMembersView', params: { id: groupEval.Id } }">
                  <button class="btn btn-outline-success">See members</button></router-link>
                <router-link class="h-100" v-if="([3, 5].includes(groupEval.TargetId) && groupEval.IsOwner == true) || [1, 2, 4].includes(groupEval.TargetId)" :to="{ name: 'EvaluationMembersView', params: { id: groupEval.Id } }">
                  <button class="btn btn-outline-success">See members</button></router-link>
              </td>

              <td v-else class="text-end">
                <router-link :to="{ name: 'EditEvaluationView', params: { id: groupEval.Id } }"> <button class="btn btn-outline-success">Edit</button></router-link>
                <button v-if="groupEval.Status == 'New'" class="btn btn-danger-alternative ms-5" @click="DeleteEvaluation(groupEval.Id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <Pagination :numberOfPages="groupEvaluations.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetPagedEvaluationByGroup" />
      </div>
    </div>
  </div>

  <edit-group-modal :groupDetails="groupDetails" @update="EditGroup"></edit-group-modal>
  <edit-interval-modal :groupDetails="groupDetails" @update="EditGroup"></edit-interval-modal>
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import Pagination from "@/components/Common/Pagination.vue";
import Search from "@/components/Common/Search.vue";
import EditGroupModal from "@/components/Modals/Group/EditGroupModal.vue";
import EditIntervalModal from "@/components/Modals/Group/EditIntervalModal.vue";

export default {
  name: "GroupEvaluations",
  components: {
    Pagination,
    // Field,
    // Form,
    Search,
    EditGroupModal,
    EditIntervalModal,
  },
  emits: [],
  data() {
    return {
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
      },
      group: {},
      groupDetails: {},
      groupEvaluations: [],
      groupId: null,
    };
  },

  methods: {
    DeleteEvaluation: function (evalId) {
      var self = this;
      this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to delete this evaluation?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            self.$axios
              .delete("api/EvaluationPlan/" + evalId, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then(async () => {
                self.$swal.fire({
                  toast: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: "success",
                  title: "Evaluation has been deleted",
                });
                self.GetPagedEvaluationByGroup();

                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                self.$swal.fire({
                  toast: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: "error",
                  title: error,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    DeleteGroup: function () {
      var self = this;
      this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to delete this evaluations?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            self.$axios
              .delete("api/EvaluationPlan/deleteGroup/" + self.groupId, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then(async () => {
                self.$swal.fire({
                  toast: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: "success",
                  title: "Evaluations has been deleted",
                });
                self.$router.push("/Evaluations/Draft/").then(function () {
                  window.location.reload();
                });
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                self.$swal.fire({
                  toast: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: "error",
                  title: error,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    EditGroup: async function (group) {
      await this.$axios
        .put("api/EvaluationPlan/updateGroup", group, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Group has been updated",
          });

          this.GetGroup();

          $("#edit-group-modal").modal("hide");
          $("#edit-interval-modal").modal("hide");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    StartAll: async function () {
      var self = this;
      this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to start all?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            await self.$axios
              .put(
                "api/EvaluationPlan/startAll?groupId=" + self.group.GroupId,
                {
                  headers: {
                    Authorization: "Bearer " + auth.getJwtToken().Value,
                  },
                }
              )
              .then(async () => {
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: "Evaluatiuons has started",
                });

                self.GetPagedEvaluationByGroup();

                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data.message,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    GetPagedEvaluationByGroup: async function (page) {
      store.dispatch("changeLoaderStatus", true);
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      this.filter.PageNumber = 1;
      if (page) {
        this.filter.PageNumber = page;
      }
      var query = new URLSearchParams({
        groupId: this.groupId,
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
      });
      await this.$axios
        .get("api/EvaluationPlan/getPagedEvaluationByGroup?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.groupEvaluations = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetGroup: async function () {
      await this.$axios
        .get("api/EvaluationPlan/getGroup/" + this.groupId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.group = response.data;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
        });
    },
    GetGroupForEdit: async function () {
      await this.$axios
        .get("api/EvaluationPlan/getGroupDetails/" + this.groupId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.groupDetails = response.data;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
        });
    },
  },

  created() {
    this.groupId = this.$route.params.groupId;
    this.GetPagedEvaluationByGroup();
    this.GetGroup();
  },
};
</script>
