<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Evaluations</a></li>
        <li class="breadcrumb-item" aria-current="page"><a href="#" class="active" @click="$router.go(-1)">My evaluations history</a></li>
      </ol>
    </nav>

    <h1 class="page-title">My evaluations history</h1>

    <div class="row mb-10 align-items-end">
      <Search @searchForItems="GetEvaluationsSearch" :searchText="filter" :placeholderText="'Search for evaluation'" />
      <div class="col-2">
        <label class="fw-bold mb-4">Scopes</label>
        <VueMultiselect
          select-label=""
          v-model="selectedScope"
          :options="scopes"
          placeholder="Select scope"
          label="Scope"
          track-by="Id"
          @select="SelectScope"
          @remove="DeselectScope()"
        ></VueMultiselect>
      </div>
      <div v-if="[2, 3, 4, 5, 6, 7, 8].includes(filter.ScopeId)" class="col">
        <MultiselectFilter
          :multiselectPropertiesProp="multiselectProperties"
          :multiselectFilterProp="multiselectFilter"
          :filterProp="filter"
          @getElementsForMultiselect="GetElementsForMultiselect"
          @getElements="GetFiltredHistoryEvaluations"
          ref="multiselectFilterRef"
        />
      </div>
      <div class="col-2">
        <label class="fw-bold mb-4">Start date </label>
        <div class="input-group">
          <span class="input-group-text border-0">
            <i class="fa-solid fa-calendar-range fs-18px"></i>
          </span>
          <Datepicker
            v-model="filter.StartDate"
            class="form-control form-control-solid ps-2 custom-datepicker"
            placeholder="Select start date"
            :enableTimePicker="false"
            name="Start date"
            autoApply
            format="dd-MM-yyyy"
            @update:modelValue="FilterByDate()"
          ></Datepicker>
        </div>
      </div>
      <div class="col-2">
        <label class="fw-bold mb-4">End date </label>
        <div class="input-group">
          <span class="input-group-text border-0">
            <i class="fa-solid fa-calendar-range fs-18px"></i>
          </span>
          <Datepicker
            v-model="filter.EndDate"
            class="form-control form-control-solid ps-2 custom-datepicker"
            placeholder="Select end date"
            :enableTimePicker="false"
            name="End date"
            format="dd-MM-yyyy"
            autoApply
            :minDate="filter.StartDate"
            @update:modelValue="FilterByDate()"
          ></Datepicker>
        </div>
      </div>
      <div class="col"></div>
    </div>

    <div class="page-tabs">
      <div class="row border-bottom">
        <div class="col-auto">
          <h3>
            <router-link :to="{ name: 'UserActiveEvaluationsView' }"> Active </router-link>
          </h3>
        </div>
        <div class="col-auto active">
          <h3>
            <router-link :to="{ name: 'UserHistoryEvaluationsView' }"> History </router-link>
          </h3>
        </div>
      </div>
      <hr />
    </div>
    <div v-bind:key="index" v-for="(evaluationsGroup, index) in groupedEvaluations" class="mb-20">
      <div class="mb-5 mt-5">
        <h1>{{ GroupName(evaluationsGroup.Name).Name }}</h1>
        <p>{{ GroupName(evaluationsGroup.Name).Description }}</p>
      </div>
      <div class="row">
        <div v-if="evaluationsGroup.Evaluations?.length == 0 && !selectedScope" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
          <div class="bg-gray-transparent-50 border-2 border-dashed border-gray-300 card h-100">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <i class="fa-duotone fa-file-dashed-line fa-4x"></i>
                </div>
                <div class="col">
                  <h2>No evaluations</h2>
                  <p class="m-0">You don't have any active evaluation.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6" v-bind:key="indexEval" v-for="(evaluation, indexEval) in evaluationsGroup.Evaluations">
          <div v-if="evaluation.EmployeeEvaluationId" class="h-100">
            <router-link v-if="evaluation.Self == false" :to="{ name: 'ManagerEvaluationAnswersDetailsView', params: { memberId: evaluation.EmployeeEvaluationId, id: evaluation.Id } }">
              <history-evaluation-card-component :evaluation="evaluation" :icon="''"></history-evaluation-card-component>
            </router-link>
            <router-link v-else :to="{ name: 'SelfEvaluationAnswersDetailsView', params: { id: evaluation.Id } }">
              <history-evaluation-card-component :evaluation="evaluation" :icon="''"></history-evaluation-card-component>
            </router-link>
          </div>
          <div v-else class="h-100">
            <router-link :to="{ name: 'HistoryEvaluationMembersView', params: { id: evaluation.Id } }" class="h-100">
              <active-grouped-evaluations-card-component :evaluation="evaluation" :icon="''"></active-grouped-evaluations-card-component>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import ActiveGroupedEvaluationsCardComponent from "@/components/Cards/EvaluationCardComponent.vue";
import HistoryEvaluationCardComponent from "@/components/Cards/HistoryEvaluationCardComponent.vue";

import Search from "@/components/Common/Search.vue";
import MultiselectFilter from "@/components/Common/MultiselectFilter.vue";
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";

export default {
  name: "UserHistoryEvaluationsView",
  components: {
    HistoryEvaluationCardComponent,
    Search,
    MultiselectFilter,
    ActiveGroupedEvaluationsCardComponent,
    NotFoundComponent,
  },

  data() {
    return {
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
        ScopeId: 0,
      },
      groupedEvaluations: [],
      evaluations: [],
      scopes: [],
      selectedScope: null,

      multiselectFilter: {
        SearchText: "",
        OrderBy: "Name",
        IsLoading: false,
        Element: null,
        EvaluationForId: 0,
      },
      showGroup: true,
      multiselectProperties: {
        Name: null,
        Label: null,
        PlaceHolder: null,
        Elements: [],
        ScopeId: 0,
      },
    };
  },
  watch: {},

  methods: {
    GroupName: function (name) {
      switch (name) {
        case "Self Services":
          return {
            Name: "Evaluate yourself",
            Description: "Evaluate yourself",
          };
        case "Team Services":
          return {
            Name: "Evaluate your teammates",
            Description: "Evaluate your teammates",
          };
        case "Manager":
          return {
            Name: "Evaluate your teams members",
            Description: "Evaluate your teams members",
          };
        default:
          return {
            Name: name,
            Description: "",
          };
      }
    },

    // SelectEvaluation(evaluation) {
    //   if (evaluation.Status === "New") {
    //     this.InsertQuestions(evaluation);
    //   }
    //   if (evaluation.Status === "Active") {
    //     if (evaluation.ScopeId == 3 || evaluation.ScopeId == 4) {
    //       this.$router.push({
    //         name: "ManagerEvaluationAnswersActiveView",
    //         params: {
    //           id: evaluation.Id,
    //           memberId: evaluation.EmployeeEvaluationId,
    //         },
    //       });
    //     } else {
    //       this.$router.push({
    //         name: "SelfEvaluationAnswersActiveView",
    //         params: {
    //           id: evaluation.Id,
    //         },
    //       });
    //     }
    //   }

    //   if (evaluation.Status === "Completed") {
    //     if (evaluation.ScopeId == 3 || evaluation.ScopeId == 4) {
    //       this.$router.push({
    //         name: "ManagerEvaluationAnswersActiveView",
    //         params: {
    //           id: evaluation.Id,
    //           memberId: evaluation.EmployeeEvaluationId,
    //         },
    //       });
    //     } else {
    //       this.$router.push({
    //         name: "SelfEvaluationAnswersDetailsView",
    //         params: {
    //           id: evaluation.Id,
    //         },
    //       });
    //     }
    //   }
    // },
    GetEvaluationsSearch: function () {
      if (this.filter.SearchText == "" && this.selectedScope == null) {
        this.GetEvaluationsPlans();
      } else {
        this.GetFiltredHistoryEvaluations();
      }
    },
    FilterByDate: function () {
      this.GetFiltredHistoryEvaluations();
    },
    DeselectScope: function () {
      this.filter.ScopeId = null;
      this.selectedScope == null;
      this.multiselectFilter.EvaluationForId = 0;
      if (this.filter.StartDate == null && this.filter.EndDate == null) {
        this.GetEvaluationsPlans();
      } else {
        this.GetFiltredHistoryEvaluations();
      }
    },
    SelectScope: function (scope) {
      this.filter.ScopeId = scope.Id;
      this.filter.PageNumber = 1;
      this.selectedScope = scope;

      this.multiselectFilter = {
        SearchText: "",
        OrderBy: "Name",
        IsLoading: false,
        Element: null,
        EvaluationForId: 0,
      };

      if (scope.Id != 1) {
        this.GetElementsForMultiselect();
      }

      this.GetFiltredHistoryEvaluations();
    },

    GetElementsForMultiselect: async function () {
      this.multiselectProperties.ScopeId = this.filter.ScopeId;
      if (this.$refs.multiselectFilterRef) {
        this.$refs.multiselectFilterRef.element = null;
      }
      this.multiselectProperties.Elements = [];
      switch (this.filter.ScopeId) {
        //myteams
        case 2:
          this.multiselectProperties.Elements = await this.GetTeamsForFilter();
          this.multiselectProperties.Name = "Team Members";
          this.multiselectProperties.Label = "TeamName";
          this.multiselectProperties.PlaceHolder = "Select team";
          break;
        //teamleaders
        case 3:
          this.multiselectProperties.Elements = await this.GetTeamLeadersForFilter();
          this.multiselectProperties.Name = "Team leaders";
          this.multiselectProperties.Label = "Name";
          this.multiselectProperties.PlaceHolder = "Select team leader";
          break;
        //myprojects
        case 4:
          this.multiselectProperties.Elements = await this.GetProjectsForFilter();
          this.multiselectProperties.Name = "Project Members";
          this.multiselectProperties.Label = "Name";
          this.multiselectProperties.PlaceHolder = "Select project";
          break;
        //projectmanagers
        case 5:
          this.multiselectProperties.Elements = await this.GetProjectManagersForFilter();
          this.multiselectProperties.Name = "Project managers";
          this.multiselectProperties.Label = "ManagerName";
          this.multiselectProperties.PlaceHolder = "Select project manager";
          break;
        case 6:
        case 7:
        case 8:
          this.multiselectProperties.Elements = await this.GetGroups();
          this.multiselectProperties.Name = "Group";
          this.multiselectProperties.Label = "Name";
          this.multiselectProperties.PlaceHolder = "Select a group";
          break;
        default:
          break;
      }

      if (this.multiselectProperties.Elements.length == 0 && (this.multiselectFilter.SearchText == null || this.multiselectFilter.SearchText == "")) {
        this.multiselectProperties.Disabled = true;
        this.multiselectProperties.PlaceHolder = "List is empty";
      }
    },

    GetGroups: async function () {
      this.multiselectFilter.IsLoading = true;
      if (this.multiselectFilter.SearchText == null) {
        this.multiselectFilter.SearchText = "";
      }
      let groups = [];
      await this.$axios
        .get("api/EvaluationGroup/getUserGroups?searchText=" + this.multiselectFilter.SearchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async (response) => {
          groups = response.data;
          this.multiselectFilter.IsLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          this.multiselectFilter.IsLoading = false;
        });
      return groups;
    },

    GetTeamsForFilter: async function () {
      this.multiselectFilter.IsLoading = true;
      if (this.multiselectFilter.SearchText == null) {
        this.multiselectFilter.SearchText = "";
      }
      let teams = [];
      await this.$axios
        .get("api/Team/getTeamsByRole?searchText=" + this.multiselectFilter.SearchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          teams = response.data;
          this.multiselectFilter.IsLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          this.multiselectFilter.IsLoading = false;
        });
      return teams;
    },

    GetTeamLeadersForFilter: async function () {
      this.multiselectFilter.IsLoading = true;
      let leaders = [];

      await this.$axios
        .get("api/Team/getUserTeamLeaders?searchText=" + this.multiselectFilter.SearchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          leaders = response.data;
          this.multiselectFilter.IsLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          this.multiselectFilter.IsLoading = false;
        });
      return leaders;
    },

    GetProjectsForFilter: async function () {
      this.multiselectFilter.IsLoading = true;
      if (this.multiselectFilter.SearchText == null) {
        this.multiselectFilter.SearchText = "";
      }
      let projects = [];
      await this.$axios
        .get("api/Project/getUserProjects?searchText=" + this.multiselectFilter.SearchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          projects = response.data;
          this.multiselectFilter.IsLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          this.multiselectFilter.IsLoading = false;
        });
      return projects;
    },

    GetProjectManagersForFilter: async function () {
      this.multiselectFilter.IsLoading = true;
      let managers = [];
      await this.$axios
        .get("api/Project/getUserProjectManagers?searchText=" + this.multiselectFilter.SearchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          managers = response.data;
          this.multiselectFilter.IsLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          this.multiselectFilter.IsLoading = false;
        });
      return managers;
    },

    GetEvaluationsPlans: async function () {
      store.dispatch("changeLoaderStatus", true);
      this.showGroup = true;
      await this.$axios
        .get("api/EvaluationPlan/getHistoryEvaluations", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.groupedEvaluations = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetFiltredHistoryEvaluations: async function () {
      store.dispatch("changeLoaderStatus", true);
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      this.groupedEvaluations = [
        {
          Name: null,
          Description: null,
          Evaluations: [],
        },
      ];
      var query = new URLSearchParams({
        searchText: this.filter.SearchText,
        scopeId: this.filter.ScopeId ? this.filter.ScopeId : -1,
        evaluationForId: this.multiselectFilter.EvaluationForId,
        StartDate: this.filter.StartDate ? this.$moment(this.filter.StartDate).format("MM/DD/YYYY") : "",
        EndDate: this.filter.EndDate ? this.$moment(this.filter.EndDate).format("MM/DD/YYYY") : "",
      });
      await this.$axios
        .get("api/EvaluationPlan/getFiltredHistoryEvaluations?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.groupedEvaluations[0] = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetScopes: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/GetScopeForMyHistoryEval", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.scopes = [{ Id: 0, Scope: "All" }, ...response.data];
          this.scopesForModal = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  mounted() {},
  created() {
    this.GetScopes();
    this.GetEvaluationsPlans();
  },
};
</script>

<style scoped>
a {
  color: #181c32;
}
</style>
