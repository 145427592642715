<template>
  <!-- normal -->
  <nav class="navbar navbar-expand-md p-4 shadow-sm d-none d-md-block">
    <div class="container">
      <div class="col-xl col-auto">
        <router-link :to="{ name: 'Login' }" target="_blank">
          <button type="button" class="btn btn-white text-uppercase">Încearcă gratuit</button>
        </router-link>
      </div>
      <div class="col col-xl-auto d-flex justify-content-center">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link :to="{ name: 'HomeLandingView' }"> Acasă </router-link>
            <router-link :to="{ name: 'HowItWorksLandingView' }"> Cum funcționează </router-link>
            <span v-on:click="ScrollToItem('contact-us')">Contactează-ne</span>
          </li>
        </ul>
      </div>
      <div class="col d-none d-xl-block"></div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "AppHeaderComponent",
  data() {
    return {};
  },
  methods: {
    ScrollToItem(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
  },
};
</script>

<style scoped>
nav {
  background-color: rgba(24, 138, 148, 0.8);
  border-bottom: 1px solid rgba(173, 181, 189, 1);
  height: 82px;
}

.nav-item a,
span {
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  font-weight: 300;
  cursor: pointer;
}

.nav-item a:not(:last-child) {
  margin-right: 50px;
}

.router-link-active {
  font-weight: 700 !important;
}
</style>
