<template>
  <div class="row align-items-center border-bottom-1 pt-3 pb-3">

    <div class="col d-flex align-items-center">
      <i v-if="!category.IsExpanded" class="fa-duotone fa-square-chevron-down fa-3x text-green pointer" data-bs-toggle="collapse" :data-bs-target="'#questions-by-category-' + index" aria-controls="collapseExample" v-on:click="GetQuestionsByCategoryId(category, category.IsExpanded), (category.IsExpanded = !category.IsExpanded)"></i>
      <i v-else class="fa fa-square-chevron-up fa-3x text-green pointer" v-on:click="category.IsExpanded = !category.IsExpanded" data-bs-toggle="collapse" :data-bs-target="'#questions-by-category-' + index" aria-controls="collapseExample"></i>
      <div class="ms-4">
        <h4 class="mb-0">{{ category.Name }}</h4>
        <small>{{ category.Description }}</small>
      </div>

    </div>
    <div class="col-2">
      <small>{{ category.NumberOfQuestions }} Question<span v-if="category.NumberOfQuestions!=1">s</span></small>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-secondary-alternative me-3" data-bs-toggle="modal" data-bs-target="#edit-category-modal" v-on:click="$emit('getCategoryById', category)">
        <i class="fa-solid fa-pen"></i> Edit
      </button>
      <button type="button" class="btn btn-danger-alternative" v-on:click="DeleteCategory(category.Id)">
        <i class="fa-solid fa-trash-can"></i> Delete
      </button>
    </div>
  </div>
</template>


<script>
export default {
  name: "category-component",
  components: {},
  emits: ["deleteCategory", "getQuestionsEmit", "getCategoryById"],
  props: {
    category: {
      type: Object,
      default: {},
    },
    index: Number,
  },
  data() {
    return {};
  },

  methods: {
    GetQuestionsByCategoryId: async function (category, expand) {
      await this.$emit("getQuestionsEmit", category, expand);
    },
    DeleteCategory: async function (id) {
      await this.$emit("deleteCategory", id);
    },
  },
  async created() {},
};
</script>
