<template>
  <div class="bg"></div>

  <div class="container-xl">
    <div class="section-title">
      Înregistrează-te și intră în contul tău <br />
      pentru a folosi platforma ExperEval
    </div>

    <div v-if="login" class="bg-form-white">
      <div class="login-form card h-100">
        <div class="card-body m-auto">
          <div class="d-block pb-20 pb-xl-0">
            <div class="title mb-16">Mulțumim pentru înregistrare!</div>

            <button type="submit" class="btn text-uppercase btn-light-gradient-green w-100" v-on:click="ConfirmEmail()">Confirmă</button>

            <div class="find-us-title mt-16 mb-4">Rețelele noastre de socializare:</div>
            <div class="d-flex justify-content-between m-auto" style="width: 168px">
              <a title="Facebook" rel="noreferrer" target="_blank" href="https://www.facebook.com/Expertware-100567287992718/">
                <span>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M22 15.609C22 19.613 19.613 22 15.609 22H14.3C13.695 22 13.2 21.505 13.2 20.9V14.553C13.2 14.256 13.442 14.003 13.739 14.003L15.675 13.97C15.829 13.959 15.961 13.849 15.994 13.695L16.379 11.594C16.412 11.396 16.258 11.209 16.049 11.209L13.706 11.242C13.398 11.242 13.156 11 13.145 10.703L13.101 8.008C13.101 7.832 13.244 7.67801 13.431 7.67801L16.071 7.634C16.258 7.634 16.401 7.49102 16.401 7.30402L16.357 4.66399C16.357 4.47699 16.214 4.334 16.027 4.334L13.057 4.37801C11.231 4.41101 9.77901 5.907 9.81201 7.733L9.867 10.758C9.878 11.066 9.63601 11.308 9.32801 11.319L8.008 11.341C7.821 11.341 7.67801 11.484 7.67801 11.671L7.71101 13.761C7.71101 13.948 7.854 14.091 8.041 14.091L9.36101 14.069C9.66901 14.069 9.91099 14.311 9.92199 14.608L10.021 20.878C10.032 21.494 9.53698 22 8.92098 22H6.391C2.387 22 0 19.613 0 15.598V6.391C0 2.387 2.387 0 6.391 0H15.609C19.613 0 22 2.387 22 6.391V15.609Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
              <a title="YouTube" rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UC_SuMnmMxOuk1HaxpCnmt-Q">
                <span>
                  <svg width="22" height="18" viewBox="0 0 22 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5 0H5.5C2.2 0 0 2.25 0 5.625V12.375C0 15.75 2.2 18 5.5 18H16.5C19.8 18 22 15.75 22 12.375V5.625C22 2.25 19.8 0 16.5 0ZM13.079 10.1587L10.362 11.8238C9.26198 12.4988 8.35997 11.9813 8.35997 10.665V7.32376C8.35997 6.00751 9.26198 5.49001 10.362 6.16501L13.079 7.82999C14.124 8.48249 14.124 9.5175 13.079 10.1587Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
              <a title="Instagram" rel="noreferrer" target="_blank" href="https://instagram.com/expertware_net">
                <span>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.6168 0H6.39419C2.38819 0 0 2.387 0 6.391V15.598C0 19.613 2.38819 22 6.39419 22H15.6058C19.6118 22 22 19.613 22 15.609V6.391C22.011 2.387 19.6228 0 15.6168 0ZM11.0055 15.268C8.65031 15.268 6.73536 13.354 6.73536 11C6.73536 8.646 8.65031 6.732 11.0055 6.732C13.3607 6.732 15.2756 8.646 15.2756 11C15.2756 13.354 13.3607 15.268 11.0055 15.268ZM17.5207 5.368C17.4657 5.5 17.3887 5.621 17.2896 5.731C17.1796 5.83 17.0585 5.907 16.9264 5.962C16.7944 6.017 16.6513 6.05 16.5082 6.05C16.2111 6.05 15.9359 5.94 15.7268 5.731C15.6278 5.621 15.5507 5.5 15.4957 5.368C15.4407 5.236 15.4077 5.093 15.4077 4.95C15.4077 4.807 15.4407 4.664 15.4957 4.532C15.5507 4.389 15.6278 4.279 15.7268 4.169C15.98 3.916 16.3652 3.795 16.7173 3.872C16.7944 3.883 16.8604 3.905 16.9264 3.938C16.9925 3.96 17.0585 3.993 17.1245 4.037C17.1796 4.07 17.2346 4.125 17.2896 4.169C17.3887 4.279 17.4657 4.389 17.5207 4.532C17.5758 4.664 17.6088 4.807 17.6088 4.95C17.6088 5.093 17.5758 5.236 17.5207 5.368Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="justify-content-center align-items-center d-flex">
      <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <img alt="Logo" :src="require('../../assets/logo.png')" class="d-block h-50px mb-10 me-auto ms-auto" />
          <div class="text-center mb-10">
            <h1 class="text-dark mb-3">Sign In</h1>
          </div>
          <Form v-slot="{ errors }" ref="loginEvaluationForm">
            <div class="fv-row mb-10 fv-plugins-icon-container">
              <label class="form-label fs-6 fw-bolder text-dark">Email</label>
              <Field
                type="text"
                name="userName"
                class="form-control form-control-lg form-control-solid"
                :class="{ 'border-solid-danger': errors.userName || !credentialsAreValid }"
                placeholder="Email..."
                v-model="user.UserName"
                rules="required|email"
              />
              <small v-if="errors.userName" class="text-danger">Email-ul este obligatoriu.</small>
              <small v-if="!credentialsAreValid" class="text-danger d-block">Email-ul sau parola este incorectă.</small>
            </div>
            <div class="fv-row mb-10 fv-plugins-icon-container">
              <label class="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
              <Field
                type="password"
                name="userPassword"
                class="form-control form-control-lg form-control-solid"
                :class="{ 'border-solid-danger': errors.userPassword }"
                placeholder="Password..."
                v-model="user.Password"
                autocomplete="on"
                rules="required"
              />
              <div v-if="errors.userPassword" class="fv-plugins-message-container invalid-feedback">Password is required.</div>
            </div>
            <div class="text-center">
              <button type="button" class="btn text-uppercase w-100 btn-light-gradient-green" v-on:click="Onboarding()">Log In</button>
            </div>
          </Form>
        </div>
      </div>
    </div>

    <div class="section-footer">
      <div class="title">Autentificare</div>
      <p class="description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus ligula quis ante dictum euismod. Vivamus tempor nisl non nulla dictum venenatis nec nec nisi. Donec at est consequat
        dignissim diam sit amet, volutpat massa. Sed non ex nec metus ultricies placerat. Suspendisse in blandit magna, auctor sodales sapien.
      </p>
    </div>
  </div>
  <LandingPageFooter />
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";

import LandingPageFooter from "@/components/Footer/LandingPageFooter.vue";
import { defineRule } from "vee-validate";
import { required, email } from "@vee-validate/rules";
import { Field, Form, configure, ErrorMessage } from "vee-validate";

configure({
  validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
});

defineRule("required", required);
defineRule("email", email);
export default {
  name: "ConfirmEmailView",
  components: { LandingPageFooter, Field, Form, ErrorMessage },
  data() {
    return {
      userEmail: "",
      code: "",
      login: false,
      user: {
        UserName: null,
        Password: null,
      },
      credentialsAreValid: true,
    };
  },
  methods: {
    ConfirmEmail: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(`api/EmailConfirm/ConfirmEmail?userEmail=${this.userEmail}&code=${this.code}`)
        .then(() => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Email-ul a fost confirmat cu success!",
          });
          this.login = true;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch(() => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Something went wrong!",
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    delay(milliseconds) {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
      });
    },
    Onboarding: async function () {
      //login
      //get token
      //create employee
      //redirect Avatar33!!
      try {
        store.dispatch("changeLoaderStatus", true);
        const response = await this.Login();
        if (response && response.status == 200) {
          const token = {
            Value: response.data.token,
            Expiration: moment(response.data.expiration).format("YYYY-MM-DDTHH:mm:ss"),
          };
          auth.setJwtToken(token);
          auth.setRefreshToken(response.data.RefreshToken);
          await this.delay(3000);
          const responseCreateEmployee = await this.CreateEmployee();
          if (responseCreateEmployee && responseCreateEmployee.status == 200) {
            store.dispatch("toastNotify", {
              Type: "success",
              Title: "Utilizatorul a fost creat cu succes!",
            });
            store.dispatch("fetchUsers").then(() => {
              this.$router.push("/My-evaluations/Active");
            });
          } else {
            store.dispatch("toastNotify", {
              Type: "error",
              Title: "Something went wrong!",
            });
          }
          store.dispatch("changeLoaderStatus", false);
        }
      } catch (error) {
        // Handle any errors that might occur during the login process.
        console.error(error);
      }
    },
    Login: async function () {
      this.credentialsAreValid = true;
      try {
        return await this.$axios.post("api/Auth/login", this.user);
      } catch (error) {
        if (error.response.data.includes("User don't found") || error.response.data.includes("Wrong password")) {
          this.credentialsAreValid = false;
        } else
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Something went wrong!",
          });
        store.dispatch("changeLoaderStatus", false);
        return error.response;
      }
    },

    CreateEmployee: async function () {
      try {
        return await this.$axios.post("api/Onboarding/createEmployee", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        });
      } catch (error) {
        return error.response;
      }
    },
  },

  created() {
    if (this.$route.query.userEmail && this.$route.query.code) {
      this.userEmail = this.$route.query.userEmail;
      this.code = this.$route.query.code.replace(/\s+/g, "+");
    }
  },
};
</script>

<style scoped>
.login-form span {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s !important;
}

.login-form span i {
  color: rgba(77, 196, 179, 1) !important;
}

.login-form a:hover span {
  background-color: #016972 !important;
}

.login-form a:hover svg {
  color: white !important;
}

.login-form .find-us-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #016972;
}
.login-form .form-control {
  background-color: transparent;
  color: white;
  font-size: 16px !important;
  line-height: 21px;
}

.login-form input::placeholder {
  color: white !important;
  font-size: 16px !important;
}

.login-form .title {
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  text-align: center;
  color: black;
}

.login-form.card {
  border-radius: 0px 12px 12px 0px;
  background-color: transparent;
  color: white;
}
label {
  color: rgba(108, 117, 125, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.section-title {
  margin-top: 74px;
  color: rgba(24, 138, 148, 1);
  font-size: 32px;
  margin-bottom: 67px;
}

.form-control {
  padding: 16px;
}

.login .title {
  color: rgba(52, 58, 64, 1);
  font-size: 32px;
  font-weight: 400;
  line-height: 37px;
}

.bg {
  background-image: url("../../assets/images/landing-page/square-get-in-touch.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  display: inline-block;
  width: 100%;
  bottom: 0;
  top: -162px;
  right: 0;
  left: 0;
  z-index: -1;
}

.section-footer {
  margin-top: 80px;
  margin-bottom: 80px;
}

.section-footer .title {
  color: rgba(24, 138, 148, 1);
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 24px;
}

.section-footer .description {
  color: rgba(108, 117, 125, 1);
  font-size: 16px;
  font-weight: 300;
}

.input-group {
  border-radius: 0.475rem !important;
}
.input-group .form-control {
  padding-left: 16px !important;
  margin-left: 0 !important;
  border-top-left-radius: 0.475rem !important;
  border-bottom-left-radius: 0.475rem !important;
}

.bg-form-white .login-form label {
  color: rgba(108, 117, 125, 1);
}

.bg-form-white .login .title {
  color: white;
}

.bg-form-white {
  background: white;
}

.bg-form-white .login.card {
  background: linear-gradient(87.6deg, #4dc4b3 0%, #209b8a 100%);
}

.bg-form-white .login.card label {
  color: white;
}

.bg-form-white .login-form .form-control {
  color: rgba(108, 117, 125, 1);
}

.bg-form-white .login-form input::placeholder {
  color: rgba(108, 117, 125, 1) !important;
}

.bg-form-white .forgot-password .text {
  color: rgba(108, 117, 125, 1);
}

.bg-form-white .forgot-password .text:hover {
  color: black;
}

svg {
  color: #26a190;
}

.subscribe span {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s !important;
}

.subscribe span i {
  color: rgba(77, 196, 179, 1) !important;
}

.subscribe a:hover span {
  background-color: #016972 !important;
}

.subscribe a:hover svg {
  color: white !important;
}

.subscribe .sub-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 48px;
}
.subscribe .title {
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  margin-bottom: 24px;
}

.subscribe.card {
  border-radius: 0px 12px 12px 0px;
  background-color: transparent;
  text-align: center;
  color: 016972;
}
</style>
