<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Evaluations</a></li>
        <li class="breadcrumb-item" aria-current="page">
          {{ evaluationDetails.Name }}
        </li>
        <li class="breadcrumb-item active" aria-current="page">Members</li>
      </ol>
    </nav>

    <div class="row align-items-center">
      <div class="col">
        <h1 class="page-title">Group evaluation</h1>
      </div>
      <div class="col-auto" :style="{ width: scoreColumnWidth + 32 + 'px' }">
        <div class="bg-white rounded p-4">
          <label class="mb-4 fw-bold label">Evaluation for</label>
          <VueMultiselect
            v-model="selectedMember"
            track-by="EmployeeId"
            :allowEmpty="false"
            :options="evaluatedMembers"
            :placeholder="'Search for member'"
            label="Name"
            :searchable="true"
            :internalSearch="false"
            @select="GetMembersEvaluationsForEmployee"
          ></VueMultiselect>
        </div>
      </div>
    </div>

    <evaluation-information-component :evaluationDetails="evaluationDetails"></evaluation-information-component>
    <!-- <div class="card mb-8 mt-8 evaluation-summary-card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col d-flex align-items-center">
            <img v-if="evaluationDetails.Scope" v-bind:src="require('@/assets/images/scopes-icons/' + evaluationDetails.Scope + '.png')" />
            <div>
              <small>Scope</small>
              <h5>{{ evaluationDetails.Scope }}</h5>
            </div>
          </div>
          <div class="col d-flex align-items-center min-w-250px">
            <img v-if="evaluationDetails.Target" v-bind:src="require('@/assets/images/scopes-icons/' + evaluationDetails.Target + '.png')" />
            <div>
              <small>Team</small>
              <h5>{{ evaluationDetails.Target }}</h5>
            </div>
          </div>
          <div class="col">
            <small>Target</small>
            <h5>{{ evaluationDetails.EvaluationFor }}</h5>
          </div>
          <div class="col">
            <small>Period</small>
            <h5>{{ $utils.formatDate(evaluationDetails.StartDate) }} <span class="m-1">-</span>{{ $utils.formatDate(evaluationDetails.EndDate) }}</h5>
          </div>
        </div>
      </div>
    </div> -->

    <div class="page-tabs">
      <div class="row border-bottom">
        <div class="col-auto">
          <h3>
            <router-link class="dropdown-item p-0" :to="{ name: 'PreviewEvaluationView', params: { id: evaluationId } }"> Details </router-link>
          </h3>
        </div>
        <div class="col-auto active">
          <h3>
            <router-link class="dropdown-item p-0" :to="{ name: 'EvaluationMembersView', params: { id: evaluationId } }"> Members </router-link>
          </h3>
        </div>
      </div>
      <hr />
    </div>

    <div class="card">
      <div class="card-header d-block pt-4">
        <div class="row mb-5">
          <div class="col-3">
            <search @searchForItems="GetEvaluationMembers(1)" :searchText="filter" :showLabel="false" :placeholderText="'Search for members'" />
          </div>
          <div class="col"></div>
        </div>
      </div>

      <div class="card-body">
        <table class="table table-row-bordered">
          <thead class="table-header">
            <tr>
              <th class="ps-14">Name</th>
              <th>Status</th>
              <th></th>
              <th>Timeframe</th>
              <th>Score</th>
              <th v-if="[2, 3, 4, 5, 7].includes(evaluationDetails.TargetId)">Leader score</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(member, index) in evaluationMembers" v-bind:key="index">
              <td>
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-40px me-2 symbol-circle">
                    <img alt="Pic" :src="GetImageUrl(member.Image)" style="object-fit: cover" />
                  </div>
                  <h5 class="m-0">{{ member.Name }}</h5>
                </div>
              </td>
              <td>
                <div class="row mb-1">
                  <div class="col fw-bold">{{ member.Status }}</div>
                  <div class="col-auto">{{ member.Progress }}%</div>
                </div>
                <div class="progress">
                  <div
                    :class="{ 'bg-secondary': member.Status == 'New', 'bg-orange': member.Status == 'Active', 'bg-dark-green': member.Status == 'Completed' }"
                    class="progress-bar"
                    role="progressbar"
                    :style="`width:${member.Progress}%`"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </td>

              <td></td>
              <td v-if="member.Status != 'New'">
                {{ $utils.formatDate(member.StartDate) }}
                <span class="m-3">-</span>
                {{ $utils.formatDate(member.EndDate) }}
              </td>
              <td v-else>Not started</td>
              <td>
                <span v-if="member.EndDate != null" class="badge badge-gradient-success">{{ member.MemberScore.toFixed(2) }}</span>
                <span v-else class="badge badge-secondary">No answers</span>
              </td>
              <td v-if="evaluationDetails.TargetId == 7">
                <span v-if="member.LeaderEndDate != null" class="badge badge-gradient-warning">{{ member.LeaderScore.toFixed(2) }}</span>

                <span v-else class="badge badge-secondary">No answers</span>
              </td>
              <td class="text-end">
                <router-link
                  :to="{
                    name: 'ComparisonForGroupView',
                    params: {
                      employeeId: member.EmployeeId,
                      evaluatedBy: member.EvaluatedBy,
                      evalId: evaluationId,
                    },
                  }"
                >
                  <button class="btn btn-outline-success" :disabled="member.EndDate == null">Answers</button></router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <Pagination :numberOfPages="evaluationMembers.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetEvaluationMembers" />
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import Pagination from "@/components/Common/Pagination.vue";
import Search from "@/components/Common/Search.vue";
import EvaluationInformationComponent from "@/components/Common/EvaluationInformationComponent.vue";
export default {
  name: "EvaluationGroupMembersView",
  components: {
    Pagination,
    Search,
    EvaluationInformationComponent,
  },
  emits: [],
  data() {
    return {
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
      },
      evaluationDetails: {},
      evaluationMembers: [],
      evaluationId: null,
      evaluatedMembers: [],
      selectedMember: {},
    };
  },

  methods: {
    GetImageUrl: function (image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },
    GetEvaluationDetails: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getEvaluationDetails/" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetEvaluationMembers: async function () {
      // store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get("api/EvaluationPlan/getEvaluatedMembers?evaluationPlanId=" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluatedMembers = response.data;
          this.selectedMember = this.evaluatedMembers[0];
          this.GetMembersEvaluationsForEmployee();
          // store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          // store.dispatch("changeLoaderStatus", false);
        });
    },
    GetMembersEvaluationsForEmployee: async function (employee) {
      store.dispatch("changeLoaderStatus", true);
      if (employee) {
        this.selectedMember = employee;
      }
      var query = new URLSearchParams({
        evaluationPlanId: this.evaluationId,
        employeeId: this.selectedMember.EmployeeId,
      });
      await this.$axios
        .get("api/EvaluationPlan/getMembersEvaluationsForEmployee?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationMembers = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },

  created() {
    this.evaluationId = this.$route.params.id;
    this.GetEvaluationMembers();
    this.GetEvaluationDetails();
  },
};
</script>
