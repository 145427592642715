<template>
  <div class="modal fade" id="comparation-criteri-modal" tabindex="-1" aria-labelledby="comparationCriteria" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row align-items-center">
            <div class="col-auto">
              <h2 class="modal-title" id="select-comparison-criteria-modal-label">Select the comparison criteria</h2>
            </div>
            <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
          </div>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col">
              <h6>Employees</h6>
              <div class="input-group input-group-loader-search">
                <span class="input-group-text border-0"><i class="fa-solid fa-search fs-18px"></i></span>
                <input v-on:keyup="GetComparationFor()" v-model="searchProps.SearchComparationFor" type="text" class="form-control" id="searchElements" placeholder="Search for employees" />
              </div>

              <div class="mh-400px mt-6 overflow-scroll p-1 pe-2">
                <table class="table table-row-dashed">
                  <thead class="table-header">
                    <tr>
                      <th>
                        <h6>Name</h6>
                      </th>
                      <th class="text-end">
                        <h6>Add</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-bind:key="index" v-for="(employee, index) in CheckedEmployeesList">
                      <td class="align-items-center d-flex">
                        <div v-if="employee.Image" class="h-25px w-25px">
                          <img class="employee-img" :src="GetImgUrl(employee.Image)" />
                        </div>
                        <div v-else class="bg-gray-100 h-25px p-1 rounded-circle text-center w-25px">
                          <small class="fw-bold text-gray-700"
                            >{{ employee.Name.split()[0][0] }}<span v-if="employee.Name.split(' ')[1]">{{ employee.Name.split(" ")[1][0] }}</span>
                          </small>
                        </div>
                        <div class="ms-5 fw-bold">
                          {{ employee.Name }}
                        </div>
                      </td>
                      <td class="text-end">
                        <i v-if="employee.IsSelected" v-on:click="RemoveEmployee(employee)" class="fa-2x fa-check-square fa-duotone pointer text-green"></i>
                        <i v-else v-on:click="SetCheckLimit(10) === false ? AddEmployee(employee) : ''" class="fa-2x fa-duotone fa-square" :class="{ pointer: !SetCheckLimit(10) }"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <not-found-component v-if="CheckedEmployeesList && CheckedEmployeesList.length == 0" :text="'Employees not found!'" maxWidth="200px"></not-found-component>
              </div>
              <div class="row">
                <div class="col">
                  <small>Check limit:</small>
                </div>
                <div class="col-auto">
                  <small>
                    {{ CheckedEmployeesList.filter((item) => item.IsSelected).length + " / 10" }}
                  </small>
                </div>
              </div>
            </div>

            <div class="col">
              <h6>Categories template</h6>
              <div class="input-group input-group-loader-search">
                <span class="input-group-text border-0"><i class="fa-solid fa-search fs-18px"></i></span>
                <input v-on:keyup="GetCategories()" v-model="searchProps.SearchCategory" type="text" class="form-control" id="searchCatgeory" placeholder="Search for categories" />
              </div>
              <div class="mh-400px mt-6 overflow-scroll p-1 pe-2">
                <table class="table table-row-dashed">
                  <thead class="table-header">
                    <tr>
                      <th>
                        <h6>Name</h6>
                      </th>
                      <th class="text-end">
                        <h6>Remove</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-bind:key="index" v-for="(category, index) in CheckedCategoriesList">
                      <td class="align-items-center d-flex">
                        <div class="ms-5 fw-bold">
                          <div>{{ category.Name }}</div>
                          <small>{{ category.Description }}</small>
                        </div>
                      </td>
                      <td class="text-end">
                        <i v-if="category.IsSelected" v-on:click="RemoveCategory(category)" class="fa-2x fa-check-square fa-duotone pointer text-green"></i>
                        <i v-else v-on:click="AddCategory(category)" class="fa-2x fa-duotone fa-square pointer"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <not-found-component v-if="CheckedCategoriesList && CheckedCategoriesList.length == 0" :text="'Categories not found!'" maxWidth="200px"></not-found-component>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer flex-stack">
          <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-gradient-success" @click="GetCompareBySelectedCriteria">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";
export default {
  components: { NotFoundComponent },
  name: "ComparationCriteriaModal",
  emits: ["getComparationForEmit", "getCategoriesTemplatesEmit", "getCompareBySelectedCriteriaEmit"],
  props: {
    comparationForProps: { type: Array, default: [] },
    comparationForSelectedProps: { type: Array, default: [] },
    selectedCategoriesProps: { type: Array, default: [] },
    categoriesTemplatesProps: { type: Array, default: [] },
    searchProps: { type: Object, default: {} },
  },
  data() {
    return {
      comparationFor: [],
      comparationForSelected: [],
      selectedCategories: [],
      categoriesTemplates: [],
      search: [],
    };
  },
  watch: {
    comparationForProps: {
      handler(value) {
        this.comparationFor = JSON.parse(JSON.stringify(value));
      },
    },
    comparationForSelectedProps: {
      handler(value) {
        this.comparationForSelected = JSON.parse(JSON.stringify(value));
      },
    },
    selectedCategoriesProps: {
      handler(value) {
        this.selectedCategories = JSON.parse(JSON.stringify(value));
      },
      immediate: true,
    },
    categoriesTemplatesProps: {
      handler(value) {
        this.categoriesTemplates = JSON.parse(JSON.stringify(value));
      },
      immediate: true,
    },
  },
  computed: {
    CheckedEmployeesList: function () {
      this.comparationForSelected.forEach((selected) => {
        this.comparationFor.forEach((employee) => {
          if (employee.Id == selected.Id) {
            employee.IsSelected = true;
          }
        });
      });
      return this.comparationFor;
    },
    CheckedCategoriesList: function () {
      this.selectedCategories.forEach((selected) => {
        this.categoriesTemplates.forEach((category) => {
          if (category.Id == selected.Id) {
            category.IsSelected = true;
          }
        });
      });
      return this.categoriesTemplates;
    },
  },
  methods: {
    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },
    SetCheckLimit(limit) {
      //the limit should be the number of colors from CompareView (chartColors)
      const selectedItems = this.CheckedEmployeesList.filter((item) => item.IsSelected).length;
      return selectedItems >= limit ? true : false;
    },
    GetCompareBySelectedCriteria: function () {
      const checkedEmployees = this.CheckedEmployeesList.filter((item) => item.IsSelected);
      const checkedCategories = this.CheckedCategoriesList.filter((item) => item.IsSelected);
      this.$emit("getCompareBySelectedCriteriaEmit", { comparation: checkedEmployees, categories: checkedCategories });
    },
    GetCategories: function () {
      this.$emit("getCategoriesTemplatesEmit");
    },
    RemoveCategory: function (category) {
      // var categoryToDelete = this.selectedCategories
      //   .map(function (x) {
      //     return x;
      //   })
      //   .indexOf(category);
      const indexToRemove = this.selectedCategories.findIndex((selected) => selected.Id === category.Id);
      this.selectedCategories.splice(indexToRemove, 1);
      category.IsSelected = false;
    },
    AddCategory: function (category) {
      this.selectedCategories.push(category);
      category.IsSelected = true;
    },
    GetComparationFor: function () {
      this.$emit("getComparationForEmit");
    },
    RemoveEmployee: function (employee) {
      // let memberToDelete = this.comparationForSelected
      //   .map(function (x) {
      //     return x;
      //   })
      //   .indexOf(employee);
      const indexToRemove = this.comparationForSelected.findIndex((comparation) => comparation.Id === employee.Id);
      this.comparationForSelected.splice(indexToRemove, 1);
      employee.IsSelected = false;
    },
    AddEmployee: function (employee) {
      this.comparationForSelected.push(employee);
      employee.IsSelected = true;
    },
    HandleCloseModal: function () {
      let modal = document.getElementById("comparation-criteri-modal");
      if (modal) {
        let app = this;
        modal.addEventListener("hidden.bs.modal", function () {
          app.searchProps.SearchCategory = null;
          app.searchProps.SearchComparationFor = null;
          app.categoriesTemplates = [];
          app.selectedCategories = [];
        });
      }
    },
  },
  mounted() {
    this.HandleCloseModal();
  },
};
</script>
<style scoped>
@media (max-width: 1400px) {
  #comparation-criteri-modal .modal-dialog {
    max-width: 90vw !important;
  }
}
</style>
