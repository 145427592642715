<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Evaluations</a></li>
        <li class="breadcrumb-item">
          <a href="#">{{ evaluationDetails.Name }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page"><a href="#" class="active" @click="$router.go(-1)">Answer to evaluation</a></li>
      </ol>
    </nav>

    <div class="row align-items-end">
      <div class="col">
        <h1 class="page-title">{{ evaluationDetails.Name }}</h1>

        <p>{{ evaluationDetails.Description }}</p>
      </div>
      <div class="col-auto">
        <button v-on:click="ResetEvaluation()" class="btn btn-white text-danger"><i class="far fa-arrows-rotate text-danger"></i> Reset</button>
      </div>
      <div class="col-auto">
        <button v-on:click="UpdateEvaluation()" class="btn btn-white"><i class="fa-light fa-files"></i> Save</button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-gradient-success" v-on:click="SignEmployeeEvaluationPlan()"><i class="fal fa-check-circle text-white"></i> Submit</button>
      </div>
    </div>

    <div class="card mb-8 mt-8 evaluation-summary-card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col d-flex align-items-center min-w-250px">
            <img src="@/assets/images/networking.png" />
            <div>
              <small>Scope</small>
              <h5>{{ evaluationDetails.Scope }}</h5>
            </div>
          </div>
          <div class="col-auto d-flex align-items-center min-w-250px">
            <div class="symbol symbol-40px me-2 symbol-circle">
              <img :src="GetImgUrl($store.getUser.Image)" />
            </div>

            <div>
              <small>Target</small>
              <h5>{{ $store.getUser.EmployeeName }}</h5>
            </div>
          </div>
          <div class="col-auto min-w-250px">
            <small>Team</small>
            <h5>{{ evaluationDetails.EvaluationFor }}</h5>
          </div>
          <div class="col-auto min-w-250px">
            <small>Period</small>
            <h5>
              {{ $moment(evaluationDetails.StartDate).format("DD.MM.YYYY") }}
              -
              {{ $moment(evaluationDetails.EndDate).format("DD.MM.YYYY") }}
            </h5>
          </div>
         
          <div class="col"></div>
          <div class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
            <div class="big-score-bage bg-dark-green">
              <h1>
                {{ evaluationContent.EvaluationScore }}
              </h1>
              <span>Your score</span>
            </div>
          </div>
          
        </div>
      </div>
    </div>

    <!-- {{ evaluationContent }} -->

    <div class="card">
      <div class="card-body">
        <div v-bind:key="categoryIndex" v-for="(category, categoryIndex) in evaluationContent.Categories">
          <div class="row">
            <div class="col">
              <h1>{{ category.CategoryName }}</h1>
              <p>
                {{ category.CategoryDescription }}
              </p>
            </div>

            <div class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 text-gray-700">Category score</h5>
                </div>
                <div class="col-auto">
                  <span class="bg-gray-200 p-2 rounded text-dark-green fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-dark-green me-2"></i>
                    {{ category.CategoryScore }}
                    <!-- 4.2 -->
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center" :id="'question-' + question.AnswerId" v-bind:key="questionIndex" v-for="(question, questionIndex) in category.Questions">
            <div class="col">
              <question-timeline-details-component :question="question"></question-timeline-details-component>
            </div>
            <div class="col-auto questions-score-div">
              <active-score-component :question="question" @SelectScore="SelectScore(question)"></active-score-component>
            </div>
          </div>

          <hr class="mt-15 mb-15" />
        </div>
      </div>
    </div>

    <div class="row align-items-end mt-10 mb-20">
      <div class="col"> </div>
      <div class="col-auto">
        <button v-on:click="ResetEvaluation()" class="btn btn-white text-danger"><i class="far fa-arrows-rotate text-danger"></i> Reset</button>
      </div>
      <div class="col-auto">
        <button v-on:click="UpdateEvaluation()" class="btn btn-white"><i class="fa-light fa-files"></i> Save</button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-gradient-success" v-on:click="SignEmployeeEvaluationPlan()"><i class="fal fa-check-circle text-white"></i> Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import utils from "@/utils/utils.js";

import ActiveScoreComponent from "@/components/Common/Score/ActiveScoreComponent.vue";
import QuestionTimelineDetailsComponent from "@/components/Questions/QuestionTimelineDetailsComponent.vue";

export default {
  name: "AnswersActionsView",
  components: { ActiveScoreComponent, QuestionTimelineDetailsComponent },

  data() {
    return {
      scoreColumnWidth: 320,

      evaluationContent: {},
      evaluationDetails: {},

      newAnswers: [],
    };
  },

  methods: {
    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    SelectScore: function (question) {
      const index = this.newAnswers.findIndex(
        (item) => item.Id === question.AnswerId
      );
      if (index === -1) {
        this.newAnswers.push({
          Id: question.AnswerId,
          Grade: question.Grade,
        });
      } else {
        this.newAnswers[index].Grade = question.Grade;
      }

      this.CategoryAvgScore();
    },

    CategoryAvgScore: function () {
      this.evaluationContent.EvaluationScore = 0;

      this.evaluationContent.Categories.forEach((category) => {
        category.CategoryScore = 0;
        category.Questions.forEach((question) => {
          category.CategoryScore = category.CategoryScore =
            category.CategoryScore + question.Grade;
        });

        category.CategoryScore = (
          category.CategoryScore / category.Questions.length
        ).toFixed(2);

        this.evaluationContent.EvaluationScore += Number(
          category.CategoryScore
        );
      });

      this.evaluationContent.EvaluationScore = (
        this.evaluationContent.EvaluationScore /
        this.evaluationContent.Categories.length
      ).toFixed(2);
    },

    GetEvaluationSummary: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/EvaluationPlan/getEvaluationPlanSummary?evaluationId=" +
            this.evaluationDetails.EvaluationPlanId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationContent: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/Answers/getLoggedUserAnswersForSelfService?evaluationId=" +
            this.evaluationDetails.EvaluationPlanId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationContent = response.data;
          this.CategoryAvgScore();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    ResetEvaluation: async function () {
      this.evaluationContent.Categories.forEach((category) => {
        category.Questions.forEach((question) => {
          this.newAnswers.push({
            Id: question.AnswerId,
            Grade: 0,
          });
        });
      });

      var self = this;
      this.$swal
        .fire({
          title: "Reset answers",
          text: "Are you sure you want to reset your answers?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            await self.UpdateEvaluation();
            self.GetEvaluationContent();
          }
        });
    },

    UpdateEvaluation: async function () {
      if (this.newAnswers.length !== 0) {
        store.dispatch("changeLoaderStatus", true);
        await this.$axios
          .put("api/Answers/updateEvalResult", this.newAnswers, {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          })
          .then((response) => {
            this.$swal.fire({
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: "success",
              title: response.data,
            });
            this.newAnswers = [];
            store.dispatch("changeLoaderStatus", false);
          })
          .catch((error) => {
            this.$swal.fire({
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: "error",
              title: error.response.data.message,
            });
            store.dispatch("changeLoaderStatus", false);
          });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: "warning",
          title: "No answer modified!",
        });
      }
    },

    SignEmployeeEvaluationPlan: function () {
      if (utils.IsEvaluationCompleted(this.evaluationContent)) {
        var self = this;
        this.$swal
          .fire({
            title: "Warning",
            text: "Are you sure you want to submit this evaluation?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          })
          .then(function (result) {
            if (result.value) {
              store.dispatch("changeLoaderStatus", true);
              // self.$refs.toAnswerListRef.EditEvaluationAnswers();
              self.$axios
                .put(
                  "api/Answers/updateIsSigned?evaluationPlanId=" +
                    self.evaluationDetails.EvaluationPlanId +
                    "&employeeId=" +
                    0 +
                    "&evaluationResults=" +
                    this.newAnswers,
                  {
                    headers: {
                      Authorization: "Bearer " + auth.getJwtToken().Value,
                    },
                  }
                )
                .then((response) => {
                  //Redirect after sign
                  store.dispatch("toastNotify", {
                    Type: "success",
                    Title: response.data,
                  });

                  self.$router.push({
                    name: "AnswersDetailsView",
                    params: {
                      id: self.evaluationDetails.EvaluationPlanId,
                    },
                  });

                  store.dispatch("changeLoaderStatus", false);
                })
                .catch((error) => {
                  store.dispatch("toastNotify", {
                    Type: "error",
                    Title: error.response.data.message,
                  });
                  store.dispatch("changeLoaderStatus", false);
                });
            }
          });
      } else {
        // this.$swal.fire({
        //   icon: "warning",
        //   title: "Missing answers!",
        //   text: "You have to answer to the all questions.",
        //   timer: 5000,
        //   timerProgressBar: true,
        // });
      }
    },
  },
  created() {
    this.evaluationDetails.EvaluationPlanId = this.$route.params.id;
    this.GetEvaluationSummary();
    this.GetEvaluationContent();
    // this.$router.push({
    //   name: "AnswersDetailsView",
    //   params: {
    //     id: this.evaluationDetails.EvaluationPlanId,
    //   },
    // });
  },
};
</script>
