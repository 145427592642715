<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Evaluations</a></li>
        <li class="breadcrumb-item" aria-current="page">
          {{ evaluationDetails.Name }}
        </li>
        <li class="breadcrumb-item active" aria-current="page">Members</li>
      </ol>
    </nav>

    <div class="row align-items-end">
      <div class="col">
        <h1 class="page-title">Evaluation members</h1>
      </div>
    </div>

    <evaluation-information-component :evaluationDetails="evaluationDetails"></evaluation-information-component>

    <div class="page-tabs">
      <div class="row border-bottom">
        <div class="col-auto">
          <h3>
            <router-link class="dropdown-item p-0" :to="{ name: 'PreviewEvaluationView', params: { id: evaluationId } }"> Details </router-link>
          </h3>
        </div>
        <div class="col-auto active">
          <h3>
            <router-link class="dropdown-item p-0" :to="{ name: 'EvaluationMembersView', params: { id: evaluationId } }"> Members </router-link>
          </h3>
        </div>
      </div>
      <hr />
    </div>

    <div class="card">
      <div class="card-header d-block pt-4">
        <div class="row mb-5">
          <div class="col-3">
            <search @searchForItems="GetEvaluationMembers(1)" :searchText="filter" :showLabel="false" :placeholderText="'Search for members'" />
          </div>
          <div class="col"></div>
        </div>
      </div>

      <div class="card-body">
        <table class="table table-row-bordered">
          <thead class="table-header">
            <tr>
              <th>Title</th>
              <th>Status</th>
              <th></th>
              <th>Timeframe</th>
              <th>Score</th>
              <th v-if="[2, 3, 4, 5].includes(evaluationDetails.TargetId) && evaluationDetails.SelfAssessment">Leader score</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(member, index) in evaluationMembers.Items" v-bind:key="index">
              <td>
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-40px me-2 symbol-circle">
                    <img alt="Pic" :src="GetImageUrl(member.Image)" style="object-fit: cover" />
                  </div>
                  <h5 class="m-0">{{ member.Name }}</h5>
                </div>
              </td>
              <td>
                <div class="row mb-1">
                  <div class="col fw-bold">{{ member.Status }}</div>
                  <div class="col-auto">{{ member.Progress }}%</div>
                </div>
                <div class="progress">
                  <div :class="{ 'bg-secondary': member.Status == 'New', 'bg-orange': member.Status == 'Active', 'bg-dark-green': member.Status == 'Completed' }" class="progress-bar" role="progressbar" :style="`width:${member.Progress}%`" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </td>
              <td></td>

              <td v-if="member.Status != 'New'">
                {{ $utils.formatDate(member.StartDate) }}
                <span class="m-3">-</span>
                {{ $utils.formatDate(member.EndDate) }}
              </td>
              <td v-else>Not started</td>
              <td>
                <!-- <span v-if="member.EndDate != null && AccessToEvaluations()" class="badge badge-gradient-success">{{ member.MemberScore.toFixed(2) }}</span> -->
                <span v-if="member.LeaderEndDate != null || AccessToEvaluations()" class="badge badge-gradient-success">{{ member.MemberScore.toFixed(2) }}</span>
                <span v-else class="badge badge-secondary">No answers</span>
              </td>
              <td v-if="[2, 3, 4, 5].includes(evaluationDetails.TargetId) && evaluationDetails.SelfAssessment">
                <span v-if="member.LeaderEndDate != null" class="badge badge-gradient-warning">{{ member.LeaderScore.toFixed(2) }}</span>

                <span v-else class="badge badge-secondary">No answers</span>
              </td>
              <td class="text-end">

                <button v-if="member.HeadEvaluateIsSigned && member.Status == 'Completed' && (evaluationDetails.TargetId == 2 || evaluationDetails.TargetId == 4)" type="button" class="btn btn-light-success btn-sm me-2" v-on:click="Download(member.EmployeeId)">
                  <i class="fa-laptop-arrow-down fal text-black"></i>
                </button>
                <div class="d-inline-block" v-if="member.LeaderEndDate != null || AccessToEvaluations()">
                  <router-link v-if="evaluationDetails.TargetId != 1" :to="{
                    name: 'AnswersComparisonView',
                    params: {
                      employeeId: member.EmployeeId,
                      evaluatedBy: member.EvaluatedBy,
                      evalId: evaluationId,
                    },
                  }">
                    <button class="btn btn-outline-success" :disabled="member.EndDate == null">Answers</button></router-link>

                  <router-link v-else :to="{
                    name: 'AnswersDetailsView',
                    params: {
                      employeeId: member.EmployeeId,
                      evalId: evaluationId,
                    },
                  }">
                    <button class="btn btn-outline-success" :disabled="member.EndDate == null">Answers</button></router-link>
                </div>
                <button v-else class="btn btn-outline-success" disabled>Answers</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <Pagination :numberOfPages="evaluationMembers.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetEvaluationMembers" />
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import Pagination from "@/components/Common/Pagination.vue";
import Search from "@/components/Common/Search.vue";
import EvaluationInformationComponent from "@/components/Common/EvaluationInformationComponent.vue";
import { saveAs } from "file-saver";

export default {
  name: "EvaluationMembersView",
  components: {
    Pagination,
    Search,
    EvaluationInformationComponent,
  },
  emits: [],
  data() {
    return {
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
      },
      evaluationDetails: {},
      evaluationMembers: [],
      evaluationId: null,
    };
  },

  methods: {
    AccessToEvaluations: function () {
      // var roles = ["Admin", "TeamLeader", "ProjectManager", "GroupLeader"];
      var roles = ["Admin"];
      return this.$store.getUser?.UserRoles?.some((r) => roles.includes(r));
    },
    Download: async function (employeeId) {
      store.dispatch("changeLoaderStatus", true);
      var query = new URLSearchParams({
        employeeId: employeeId,
        evaluationPlanId: this.evaluationId,
      });
      await this.$axios
        .get("api/ExportData/exportEvaluationResult?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          fetch("data:application/doc;base64," + response.data.ArrayByte)
            .then(function (resp) {
              return resp.blob();
            })
            .then(function (blob) {
              saveAs(blob, response.data.FileName + ".doc");
            });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetImageUrl: function (image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },
    GetEvaluationDetails: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getEvaluationDetails/" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationDetails = response.data;
          this.GetEvaluationMembers();

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetEvaluationMembers: async function (page) {
      if (
        [3, 5].includes(this.evaluationDetails.TargetId) &&
        this.evaluationDetails.IsOwner == false
      ) {
        this.$swal
          .fire({
            title: "Acces diened!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Go back",
          })
          .then((response) => {
            this.$router.go(-1);
          });
        return;
      }
      // store.dispatch("changeLoaderStatus", true);
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      this.filter.PageNumber = 1;
      if (page) {
        this.filter.PageNumber = page;
      }
      var query = new URLSearchParams({
        evaluationPlanId: this.evaluationId,
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
      });
      await this.$axios
        .get("api/EvaluationPlan/getPagedStartedEvaluationEmployees?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationMembers = response.data;
          // store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          // store.dispatch("changeLoaderStatus", false);
        });
    },
  },

  created() {
    this.evaluationId = this.$route.params.id;
    this.GetEvaluationDetails();
  },
};
</script>
