<template>
  <!-- <div class="container-fluid"> -->
  <div class="ps-10 pe-10">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb" class="mb-8">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Home</li>
            <li class="breadcrumb-item">Evaluations</li>
            <li class="breadcrumb-item"><a href="/Evaluations/Draft">Draft </a></li>
            <li class="breadcrumb-item active" aria-current="page">Edit evaluation</li>
          </ol>
        </nav>
        <div class="row align-items-center">
          <div class="col">
            <h1>Edit evaluation</h1>
            <p class="m-0">Edit evaluation information and content</p>
          </div>
          <div class="col-auto">
            <a class="btn btn-white" href="/Evaluations/Draft"> <i class="far fa-arrow-left"></i> Back to evaluations</a>
          </div>
          <div v-if="evaluationDetails.IsOwner == true" class="col-auto">
            <button class="btn btn-white text-danger" @click="DeleteEvaluation()"><i class="fa-light fa-trash-alt text-danger"></i> Delete</button>
          </div>
          <div class="col-auto">
            <button class="btn btn-gradient-success" :disabled="!evaluationDetails.TargetId" @click="StartEvaluation()"><i class="fal fa-check-circle text-white"></i> Start evaluation</button>
          </div>
        </div>

        <div class="card mb-8 mt-8 evaluation-summary-card">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h3>{{ evaluationDetails.Name }}</h3>
                <p>{{ evaluationDetails.Description }}</p>
              </div>

              <div class="col-auto" v-if="evaluationDetails.IsEditable || evaluationDetails.IsOwner == true">
                <button v-if="evaluationDetails.IsOwner == true" class="btn btn-gradient-success" @click="GetEditingEvaluationDetails(evaluationDetails.Id)">
                  <i class="fa-light fa-pen-to-square text-white"></i> Edit
                </button>
                <button v-else class="btn btn-gradient-success" @click="GetShortEvaluationDetails(evaluationDetails.Id)"><i class="fa-light fa-pen-to-square text-white"></i> Edit</button>
              </div>
            </div>
            <hr />

            <div class="row align-items-center justify-content-between">
              <div v-if="evaluationDetails.Scope" class="col-auto d-flex align-items-center mb-3">
                <img v-if="evaluationDetails.Scope" v-bind:src="require('@/assets/images/scopes-icons/' + evaluationDetails.Scope + '.png')" />
                <div>
                  <small>Scope</small>
                  <h5>{{ evaluationDetails.Scope }}</h5>
                </div>
              </div>
              <div v-if="evaluationDetails.Target" class="col-auto d-flex align-items-center mb-3">
                <img v-if="evaluationDetails.Target" v-bind:src="require('@/assets/images/scopes-icons/' + evaluationDetails.Target + '.png')" />
                <div>
                  <small>Target</small>
                  <h5>{{ evaluationDetails.Target }}</h5>
                </div>
              </div>
              <div v-if="evaluationDetails.EvaluationFor" class="col-auto mb-3">
                <small>Evaluation for</small>
                <h5>{{ evaluationDetails.EvaluationFor }}</h5>
              </div>
              <div class="col-auto mb-3">
                <small>Period</small>
                <h5>{{ $utils.formatDate(evaluationDetails.StartDate) }} <span class="text-green">-</span> {{ $utils.formatDate(evaluationDetails.EndDate) }}</h5>
              </div>
              <div class="col-auto mb-3">
                <small>Interval</small>
                <h5>{{ $utils.formatDate(evaluationDetails.StartInterval) }} <span class="text-green">-</span> {{ $utils.formatDate(evaluationDetails.EndInterval) }}</h5>
              </div>
              <div class="col-auto mb-3">
                <small>Max score</small>
                <h5>{{ evaluationDetails.MaxScore }}</h5>
              </div>
              <!-- <div class="col-2"></div> -->
            </div>
          </div>
        </div>

        <!-- <h1>Editable: {{ evaluationDetails.IsEditable }}</h1> -->
        <div v-if="!evaluationDetails.IsEditable && evaluationDetails.IsOwner == false" class="alert alert-light opacity-100-hover opacity-75 mb-8" role="alert">
          <h4 class="alert-heading">Evaluation structure categories cannot be modified.</h4>
          <p>You can drag new categories and questions above the evaluation structure.</p>
          <hr />
          <p class="mb-0">Drag categories and questions from the <span class="text-dark-green">catalog</span></p>
        </div>

        <div class="card eval-content-card">
          <div class="card-header d-block" style="padding: 2rem 2.25rem">
            <div class="row align-items-center">
              <div class="col">
                <h2 class="m-0 text-dark-green fs-25">Evaluation content</h2>
              </div>

              <div class="col-auto">
                <button class="btn btn-white border" @click="EditEvaluationContent()"><i class="fa-light fa-files"></i> Save changes</button>
              </div>

              <div class="col-auto">
                <button class="btn btn-white border text-danger" @click="ResetEvaluationContent()"><i class="far fa-arrows-rotate text-danger"></i> Reset</button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <!-- <evaluation-content-component :draggingProp="dragging" :evaluationContent="evaluation.Categories.filter((category) => category.IsEditable == false)"></evaluation-content-component> -->

            <questions-management-component :draggingProp="dragging" :evaluationContent="evaluation.Categories.filter((category) => category.IsEditable == false)"> </questions-management-component>

            <evaluation-editable-content-component @RemoveItem="RemoveItem()" :draggingProp="dragging" :evaluationContent="evaluation"></evaluation-editable-content-component>
          </div>
        </div>

        <div class="row align-items-end mt-10 mb-20">
          <div class="col-auto">
            <a class="btn btn-white" href="/Evaluations/Draft"> <i class="far fa-arrow-left"></i> Back to evaluations</a>
          </div>
          <div class="col"></div>
          <div class="col-auto">
            <button class="btn btn-white border" @click="EditEvaluationContent()"><i class="fa-light fa-files"></i> Save changes</button>
          </div>

          <div class="col-auto">
            <button class="btn btn-white border text-danger" @click="ResetEvaluationContent()"><i class="far fa-arrows-rotate text-danger"></i> Reset</button>
          </div>
        </div>
      </div>
      <div class="col-auto"></div>
      <div class="col-auto">
        <div style="width: 500px"></div>
        <catalog
          :categories="categoriesList"
          :questions="questionsList"
          :questionsTypes="types"
          :draggingProp="dragging"
          @getTypesEmit="GetTypes"
          @getQuestionsTemplateEmit="GetQuestionsTemplate"
          @getCategoriesTemplateEmit="GetCategoriesTemplate"
          @getQuestionsTemplateSearchEmit="GetQuestionsTemplateSearch"
          @getMyQuestionsTemplateEmit="GetMyQuestionsTemplate"
          @clearCreateQuestionModalEmit="ClearCreateQuestionModal"
          ref="catalogComponentRef"
        ></catalog>
      </div>
    </div>
  </div>

  <create-question-modal-component :newQuestionProp="newQuestion" @createQuestionsEmit="CreateQuestion" ref="createNewQuestionTemp"></create-question-modal-component>
  <edit-evaluation-modal-component :evaluationDetails="editingEvaluationDetails" @update="UpdateEvaluation()" ref="editEvaluationRef"></edit-evaluation-modal-component>
  <edit-short-evaluation-modal-component :evaluationDetails="shortEvaluationDetails" @update="UpdateEvaluationDate()"></edit-short-evaluation-modal-component>
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import draggable from "vuedraggable";
import CreateQuestionModalComponent from "@/components/Modals/Question/CreateQuestionModalComponent.vue";
// import EditEvaluationTemplateModal from "@/components/Settings/EvaluationTemplate/EditEvaluationTemplateModal.vue";
import EvaluationContentComponent from "@/components/EvaluationContent/EvaluationContentComponent.vue";
import QuestionsManagementComponent from "@/components/EvaluationContent/QuestionsManagementComponent.vue";
import EvaluationEditableContentComponent from "@/components/EvaluationContent/EvaluationEditableContentComponent.vue";
import Catalog from "@/components/Common/CatalogComponent.vue";

import EditEvaluationModalComponent from "@/components/Modals/Evaluation/Edit/EditEvaluationModalComponent.vue";
import EditShortEvaluationModalComponent from "@/components/Modals/Evaluation/Edit/EditShortEvaluationModalComponent.vue";

// import EditQuestionModalComponent from "@/components/Modals/Question/EditQuestionModalComponent.vue";

export default {
  name: "CreateEvaluationView",
  components: {
    draggable,
    CreateQuestionModalComponent,
    EvaluationContentComponent,
    QuestionsManagementComponent,
    EvaluationEditableContentComponent,
    Catalog,
    EditShortEvaluationModalComponent,
    // EditEvaluationTemplateModal,
    EditEvaluationModalComponent,
  },
  data() {
    return {
      name: "clone",
      display: "Clone",
      showCategoriesList: false,
      showQuestionsList: false,
      categoriesList: [],
      types: [],
      questionsList: [],
      dragging: {
        QuestionDragging: false,
        CategoryDragging: false,
      },
      newQuestion: {},
      evaluation: {
        Categories: [],
      },
      evaluationId: null,
      evaluationNameExist: null,
      evaluationDetails: {},
      shortEvaluationDetails: {},
      editingEvaluationDetails: {},
    };
  },
  methods: {
    DeleteEvaluation: function () {
      var self = this;
      this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to delete this evaluation?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            self.$axios
              .delete("api/EvaluationPlan/" + self.evaluationDetails.Id, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then(async () => {
                self.$swal.fire({
                  toast: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: "success",
                  title: "Evaluation has been deleted",
                });
                self.$router.push("/Evaluations/Draft/").then(function () {
                  window.location.reload();
                });
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                self.$swal.fire({
                  toast: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: "error",
                  title: error,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    RemoveItem: function () {
      this.GetCategoriesTemplate();
    },
    StartEvaluation: async function () {
      await this.EditEvaluationContent();
      if (this.CheckEvaluationContent()) {
        var self = this;
        await this.$swal
          .fire({
            title: "Question",
            text: "Are you sure you want to start this evaluation?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          })
          .then(async function (result) {
            if (result.value) {
              store.dispatch("changeLoaderStatus", true);
              self.$axios
                .post("api/EvaluationInterval/startEvaluation?evaluationId=" + self.evaluationId, {
                  headers: {
                    Authorization: "Bearer " + auth.getJwtToken().Value,
                  },
                })
                .then(async () => {
                  self.$swal.fire({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    icon: "success",
                    title: "Evaluation has been started",
                  });
                  self.$router.push({
                    name: "PreviewEvaluationView",
                    params: {
                      id: self.evaluationId,
                    },
                  });
                  store.dispatch("changeLoaderStatus", false);
                })
                .catch(() => {
                  store.dispatch("toastNotify", {
                    Type: "error",
                    Title: "Something went wrong!",
                  });
                  store.dispatch("changeLoaderStatus", false);
                });
            }
          });
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "Missing questions!",
          text: "All categories must have at least one question.",
          timer: 5000,
          timerProgressBar: true,
        });
      }
    },

    CheckEvaluationContent: function () {
      var emptyCategory = true;
      this.evaluation.Categories.some(function (category) {
        emptyCategory = category.Questions.length === 0;
        return category.Questions.length === 0;
      });
      return !emptyCategory;
    },

    UpdateEvaluation: async function () {
      this.editingEvaluationDetails.StartDate = moment(this.editingEvaluationDetails.StartDate).format("YYYY-MM-DD");

      this.editingEvaluationDetails.EndDate = moment(this.editingEvaluationDetails.EndDate).format("YYYY-MM-DD");
      await this.$axios
        .put("api/EvaluationPlan/update", this.editingEvaluationDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Evaluation plan has been updated",
          });
          $("#edit-evaluation-modal").modal("hide");
          this.GetEvaluation();

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    UpdateEvaluationDate: async function () {
      this.shortEvaluationDetails.StartDate = moment(this.shortEvaluationDetails.StartDate).format("YYYY-MM-DD");

      this.shortEvaluationDetails.EndDate = moment(this.shortEvaluationDetails.EndDate).format("YYYY-MM-DD");
      this.shortEvaluationDetails.StartInterval = moment(this.shortEvaluationDetails.StartInterval).format("YYYY-MM-DD");
      this.shortEvaluationDetails.EndInterval = moment(this.shortEvaluationDetails.EndInterval).format("YYYY-MM-DD");

      await this.$axios
        .put("api/EvaluationPlan/updateDate", this.shortEvaluationDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Evaluation template has been updated",
          });
          $("#edit-short-evaluation-modal").modal("hide");
          this.GetEvaluation();

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluation: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getEvaluationDetails/" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetShortEvaluationDetails: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getShortDetailsEvaluation?evaluationId=" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.shortEvaluationDetails = response.data;
          $("#edit-short-evaluation-modal").modal("show");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEditingEvaluationDetails: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getEditingEvaluationDetails?evaluationId=" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.editingEvaluationDetails = response.data;
          $("#edit-evaluation-modal").modal("show");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    EvaluationNameExist: async function (name) {
      await this.$axios
        .get("api/EvaluationTemplate/evaluationNameExist?name=" + name, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationNameExist = response.data;
        })
        .catch(() => {});
    },
    ClearCreateQuestionModal: function () {
      this.$refs.createNewQuestionTemp.ClearCreateQuestionModal();
    },
    CreateQuestion: async function () {
      await this.$axios
        .post("api/QuestionsTemplates/addOthersTempQuestion", this.newQuestion, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Question has been added",
          });
          // this.$refs.createNewQuestionTemp.$refs.Close.Click();
          $("#create-question-modal").modal("hide");
          this.$refs.catalogComponentRef.ShowMyQuestionsList();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    ResetEvaluationContent: function () {
      var self = this;
      this.$swal
        .fire({
          title: "Reset evaluation",
          text: "Are you sure you want to reset this evaluation?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            self.evaluation.Categories = [];
            await self.EditEvaluationContent();
            await self.GetEvaluationContent();
          }
        });
    },

    EditEvaluationContent: async function () {
      await this.$axios
        .put("api/EvaluationPlan/updateEvaluationContent?evalId=" + this.evaluationId, this.evaluation.Categories, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Evaluation content has been updated",
          });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
      this.GetEvaluationContent();
    },

    GetEvaluationContent: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getEvaluationContent/" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluation.Categories = response.data;

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetMyQuestionsTemplate: async function (searchText) {
      store.dispatch("changeLoaderStatus", true);
      if (searchText == null) {
        searchText = "";
      }
      await this.$axios
        .get("api/QuestionsTemplates/getMyQuestions?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.questionsList = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetQuestionsTemplateSearch: async function (searchText) {
      store.dispatch("changeLoaderStatus", true);
      if (searchText == null) {
        searchText = "";
      }
      await this.$axios
        .get("api/QuestionsTemplates/getQuestions?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.questionsList = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetCategoriesTemplate: async function (searchText) {
      store.dispatch("changeLoaderStatus", true);
      if (searchText == null) {
        searchText = "";
      }
      await this.$axios
        .get("api/CategoriesTemplates/getCategories?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.categoriesList = response.data;
          this.evaluation.Categories.map((item) => {
            var index = this.categoriesList.findIndex((x) => x.CategoryId === item.CategoryId);
            if (index !== -1) {
              this.categoriesList.splice(index, 1);
            }
          });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetQuestionsTemplate: async function (type) {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get("api/QuestionsTemplates/getQuestionsByType?typeId=" + type.Id, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          type.QuestionsList = response.data;
          store.dispatch("changeLoaderStatus", false);

          $("#question-type" + type.Id).collapse("show");
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetTypes: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get("api/QuestionsTemplates/getQuestionsTypes", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.types = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  created() {
    this.evaluationId = this.$route.params.id;
    this.GetEvaluationContent();
    this.GetEvaluation();
  },
};
</script>

<style scoped>
h1 {
  font-size: 30px;
  color: #188a94;
}
.question-card {
  background-color: #f5f8fa;
  border-radius: 0.475rem;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
}
.c-grab {
  cursor: grab;
}

.empty-eval-content {
  position: relative;
  background: #4bc2b136;
  min-height: 200px;
  border-radius: 5px;
  border: 1px dashed #4cc3b2;
  color: #4cc3b2;
}

.empty-eval-content h1 {
  color: #16899394;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 40%;
}

.empty-eval-content .dragArea {
  height: 200px;
}

.new-card-object {
  border: 1px solid #e4e6ef;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: grab;
  background: white;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.evaluation-summary-card {
  position: relative;
}

.edit-eval-btn {
  position: relative;
  background: linear-gradient(87.6deg, #4dc4b3 0%, #209b8a 100%);
  color: #ffffffa6;
  width: 150px;
  font-size: 15px;
}

.edit-eval-btn i {
  color: #ffffffa6;
  font-size: 15px;
}

.edit-eval-btn:hover {
  color: white;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
}

.edit-eval-btn:hover i {
  color: white;
}

.fs-25 {
  font-size: 25px;
}
</style>
