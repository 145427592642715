<template>
  <div class="modal fade" id="create-question-modal" tabindex="-1" aria-labelledby="create-question-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <div class="modal-header">
          <div class="row align-items-center">
            <div class="col-auto">
              <h2 class="modal-title" id="edit-evaluation-modal-label">New question</h2>
            </div>
            <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
          </div>
        </div>

        <Form v-slot="{ errors }" @submit="CreateQuestion" ref="createQuestionForm">
          <div class="modal-body">
            <div class="mb-6">
              <label class="form-label" for="new-question-textarea">Question</label>
              <Field name="questionTitle" v-slot="{ field }" rules="required|min: 3|max: 4000">
                <textarea v-bind="field" class="form-control" id="new-question-textarea" rows="3" placeholder="Question" v-model="newQuestionProp.Title"></textarea>
              </Field>
              <div v-if="errors.questionTitle" class="fv-plugins-message-container invalid-feedback">Title requires minimum 3 and maximum 4000 characters.</div>
            </div>
            <!-- <div class="mb-6">
              <label class="form-label" for="new-question-score">Score</label>
              <Field type="number" name="questionMaxScore" class="form-control form-control-solid ps-2" placeholder="Enter the max score..." v-model="newQuestionProp.MaxScore" rules="required|between: 1,10" />
              <div v-if="errors.questionMaxScore" class="fv-plugins-message-container invalid-feedback">Max score must be between 1 and 10</div>
            </div> -->
            <div>
              <label class="form-label" for="new-question-description-textarea"> Description</label>
              <Field name="questionDescription" v-slot="{ field }" rules="max:5000">
                <textarea :class="{ 'border-solid-danger': errors.questionDescription }" rows="3" v-bind="field" type="text" class="form-control" placeholder="Question description" name="Description" v-model="newQuestionProp.Description" />
              </Field>
              <div v-if="errors.questionDescription" class="fv-plugins-message-container invalid-feedback">Description requires maximum 5000 characters.</div>
            </div>
          </div>

          <div class="modal-footer flex-stack">
            <button type="button" data-bs-dismiss="modal" class="border btn btn-white">Back</button>
            <button type="submit" class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, min, between, max } from "@vee-validate/rules";
defineRule("required", required);
defineRule("between", between);
defineRule("min", min);
defineRule("max", max);
export default {
  Name: "create-question-modal-component",
  components: {
    Field,
    Form,
  },
  props: {
    newQuestionProp: { type: Object, default: {} },
  },
  emits: ["createQuestionsEmit"],
  methods: {
    ClearCreateQuestionModal: function () {
      this.$refs.createQuestionForm.resetForm();
    },
    CreateQuestion: async function () {
      this.$emit("createQuestionsEmit");
    },
  },
};
</script>
<!-- 
<style scoped>
.modal-header,
.modal-footer {
  display: block;
}

.modal-header .row {
  align-items: center;
}
</style> -->
