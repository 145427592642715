<template>
  <div class="modal fade" id="import-member-modal" tabindex="-1" aria-labelledby="importMemberModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-1000px modal-fullscreen-mobile">
      <div class="modal-content min-h-500px">
        <div class="modal-header">
          <div class="row align-items-center">
            <div class="col-auto">
              <h2 class="modal-title">Add questions to category</h2>
            </div>
            <div class="col text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <small>Search for questions</small>
          <div class="input-group">
            <span class="input-group-text border-0">
              <i class="fa-solid fa-search fs-18px"></i>
            </span>
            <input type="text" class="form-control form-control-solid ps-2" id="search" placeholder="Search for questions" v-model="filterQuestionsProp.SearchText" @input="SearchForItems()" />
            <span v-if="filterQuestionsProp.SearchLoader" class="position-absolute top-50 end-0 translate-middle-y lh-0 me-5" style="z-index: 10">
              <span class="spinner-border h-15px w-15px text-gray-400"></span>
            </span>
          </div>

          <hr>

          <div v-if="!filterQuestionsProp.SearchText" class="mh-700px scroll-y pt-2">
            <div v-for="(type, indexType) in questionsTypesProp" v-bind:key="indexType" class="p-4">
              <div class="border-bottom border-gray-300 border-bottom-dashed mb-5" v-if="indexType > 0"></div>
              <div class=" d-flex align-items-center">
                <i v-if="type.IsExpanded" class="fa fa-square-chevron-up fa-3x text-green pointer" v-on:click="type.IsExpanded= !type.IsExpanded" data-bs-toggle="collapse" :data-bs-target="'#question-type-collapse-'+indexType" :aria-controls="'#question-type-collapse-'+indexType"></i>

                <i v-else class="fa-duotone fa-square-chevron-down fa-3x text-green pointer" v-on:click="GetQuestionsByType(type)" data-bs-toggle="collapse" :data-bs-target="'#question-type-collapse-'+indexType" :aria-controls="'#question-type-collapse-'+indexType"></i>
                <div class=" ms-3">
                  <h4 class="m-0"> {{ type.Name }}</h4>
                  <small>Question type</small>
                </div>
              </div>

              <div class="collapse" :id="'question-type-collapse-'+indexType">
                <div class="card rounded bg-lighten border border-1 mt-5">
                  <div class="card-body">
                    <div v-if="type.Questions?.length > 0" class="collapse" v-bind:class="{ show: type.IsExpanded }" :id="'questions-by-type-' + indexType">
                      <div v-for="(question, index) in type.Questions" v-bind:key="index">
                        <div class="separator separator-dashed mb-10" v-if="index > 0"></div>
                        <import-question :question="question" :index="index" @SelectQuestion="$emit('SelectQuestionForImport',question)" />
                      </div>
                    </div>
                    <div v-else class="fs-15 text-center">All questions of this type are already assigned to the category</div>
                  </div>
                </div>
              </div>

            </div>
            <img alt="No content found" v-if="questionsTypesProp.length == 0" class="d-block me-auto ms-auto" :src="require('../../../assets/images/no-content-found.png')" style="max-width: 350px" />
          </div>
          <div v-else class="mh-700px scroll-y">
            <div v-for="(question, index) in questionsForImportProp" v-bind:key="index">
              <div class="separator separator-dashed mb-10" v-if="index > 0"></div>
              <import-question :question="question" :index="index" @SelectQuestion="$emit('SelectQuestionForImport',question)" />
            </div>
            <div v-if="questionsForImportProp.length==0" class="alert alert-secondary">
              No questions based on your criteria!
            </div>

          </div>

        </div>
        <div class="modal-footer flex-stack">
          <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
          <button class="btn btn-gradient-success" @click="AddQuestions"> Save </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { defineRule } from "vee-validate";
import { required, min } from "@vee-validate/rules";
import SearchComponent from "@/components/Common/SearchComponent.vue";
import ImportQuestion from "@/components/Modals/Question/ImportQuestion.vue";
defineRule("required", required);
defineRule("min", min);

export default {
  name: "import-questions-modal",
  props: {
    questionsTypesProp: {
      type: Array,
      default: [],
    },
    filterQuestionsProp: {
      type: Object,
      default: {},
    },
    questionsForImportProp: {
      type: Array,
      default: [],
    },
  },

  emits: [
    "searchForItems",
    "importQuestionsEmit",
    "getQuestionsByType",
    "SelectQuestionForImport",
  ],
  components: {
    SearchComponent,
    ImportQuestion,
  },
  data() {
    return { questionsToAdd: [] };
  },

  methods: {
    SearchForItems: function () {
      this.$emit("searchForItems", this.questionsToAdd);
    },

    GetQuestionsByType: async function (type) {
      if (!type.IsExpanded || type.IsExpanded == false) {
        await this.$emit("getQuestionsByType", type, this.questionsToAdd);
      }
      type.IsExpanded = !type.IsExpanded;
    },

    AddQuestions: async function () {
      await this.$emit("importQuestionsEmit", this.questionsToAdd);
    },
  },
  created() {},
};
</script>
