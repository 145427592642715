<template>
  <div class="get-in-touch-section">
    <div class="bg"></div>
    <div>
      <div class="title-section text-center" style="margin-top: 160px">Ținem legătura</div>
      <div class="container-xl" style="margin-bottom: -10%; margin-top: 64px">
        <div class="row bg-color">
          <div class="col-md-7 p-0">
            <div class="contact-us card h-100">
              <div class="card-body">
                <div class="title mb-8" id="contact-us">Contactează-ne</div>
                <Form v-slot="{ errors }" @submit="SendContactUsEmail" ref="contactUsForm">
                  <div class="row" style="margin-left: -8px">
                    <div class="col-12 mb-8">
                      <label for="full-name">Nume <span class="text-danger">*</span></label>
                      <Field
                        id="full-name"
                        type="text"
                        name="fullName"
                        class="form-control"
                        :class="{ 'border-danger': errors.fullName }"
                        placeholder="Introduceți numele"
                        v-model="contact.Name"
                        rules="required"
                      />

                      <small v-if="errors.fullName" class="text-danger">Numele este obligatoriu.</small>
                    </div>
                    <div class="col-lg-6 mb-8">
                      <label for="email-address">Adresă de email <span class="text-danger">*</span></label>
                      <Field
                        id="email-address"
                        type="email"
                        name="email"
                        class="form-control"
                        :class="{ 'border-danger': errors.email }"
                        placeholder="Introduceți adresa de email"
                        v-model="contact.Email"
                        rules="required|email"
                      />
                      <small v-if="errors.email" class="text-danger">Email-ul este obligatoriu.</small>
                    </div>
                    <div class="col-lg-6 mb-8">
                      <label for="phone-number">Telefon <span class="text-danger">*</span></label>
                      <Field
                        id="phone-number"
                        type="tel"
                        name="phoneNumber"
                        class="form-control"
                        :class="{ 'border-danger': errors.phoneNumber }"
                        placeholder="Introduceți  numărul de telefon"
                        v-model="contact.PhoneNumber"
                        rules="required"
                      />
                      <small v-if="errors.phoneNumber" class="text-danger">Numărul de telefon este obligatoriu.</small>
                    </div>
                  </div>
                  <div>
                    <label for="message">Mesaj</label>
                    <Field name="message" v-slot="{ field }" rules="max: 200">
                      <textarea
                        v-bind="field"
                        id="message"
                        type="text"
                        class="form-control"
                        :class="{ 'border-danger': errors.message }"
                        placeholder="Lasă-ne un mesaj"
                        rows="5"
                        v-model="contact.Message"
                      ></textarea>
                    </Field>
                    <small v-if="errors.message" class="text-danger">Mesajul poate să conțină maximum 200 de caractere.</small>
                  </div>
                  <button type="submit" class="btn btn-light-gradient-green text-uppercase mt-8">Trimite <i class="ms-2 fa-solid fa-paper-plane text-white"></i></button>
                </Form>
              </div>
            </div>
          </div>
          <div class="col-md p-0">
            <div class="subscribe card h-100">
              <div class="align-items-center card-body d-flex justify-content-center">
                <div>
                  <div class="title">
                    Abonează-te la <br />
                    newsletter
                  </div>
                  <div class="sub-title">
                    Rămâi la curent cu toate noutățile <br />
                    despre serviciile și aplicațiile noastre!
                  </div>
                  <input id="subscribe-email-address" type="email" class="form-control" placeholder="Introduceți adresa de email" />
                  <button type="button" class="btn btn-white text-uppercase w-100 mt-4">Abonează-te</button>
                  <div class="find-us-title mt-16 mb-4">Rețelele noastre de socializare:</div>
                  <div class="d-flex justify-content-between m-auto" style="width: 168px">
                    <a title="Facebook" rel="noreferrer" target="_blank" href="https://www.facebook.com/Expertware-100567287992718/">
                      <span>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M22 15.609C22 19.613 19.613 22 15.609 22H14.3C13.695 22 13.2 21.505 13.2 20.9V14.553C13.2 14.256 13.442 14.003 13.739 14.003L15.675 13.97C15.829 13.959 15.961 13.849 15.994 13.695L16.379 11.594C16.412 11.396 16.258 11.209 16.049 11.209L13.706 11.242C13.398 11.242 13.156 11 13.145 10.703L13.101 8.008C13.101 7.832 13.244 7.67801 13.431 7.67801L16.071 7.634C16.258 7.634 16.401 7.49102 16.401 7.30402L16.357 4.66399C16.357 4.47699 16.214 4.334 16.027 4.334L13.057 4.37801C11.231 4.41101 9.77901 5.907 9.81201 7.733L9.867 10.758C9.878 11.066 9.63601 11.308 9.32801 11.319L8.008 11.341C7.821 11.341 7.67801 11.484 7.67801 11.671L7.71101 13.761C7.71101 13.948 7.854 14.091 8.041 14.091L9.36101 14.069C9.66901 14.069 9.91099 14.311 9.92199 14.608L10.021 20.878C10.032 21.494 9.53698 22 8.92098 22H6.391C2.387 22 0 19.613 0 15.598V6.391C0 2.387 2.387 0 6.391 0H15.609C19.613 0 22 2.387 22 6.391V15.609Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </a>
                    <a title="YouTube" rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UC_SuMnmMxOuk1HaxpCnmt-Q">
                      <span>
                        <svg width="22" height="18" viewBox="0 0 22 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M16.5 0H5.5C2.2 0 0 2.25 0 5.625V12.375C0 15.75 2.2 18 5.5 18H16.5C19.8 18 22 15.75 22 12.375V5.625C22 2.25 19.8 0 16.5 0ZM13.079 10.1587L10.362 11.8238C9.26198 12.4988 8.35997 11.9813 8.35997 10.665V7.32376C8.35997 6.00751 9.26198 5.49001 10.362 6.16501L13.079 7.82999C14.124 8.48249 14.124 9.5175 13.079 10.1587Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </a>
                    <a title="Instagram" rel="noreferrer" target="_blank" href="https://instagram.com/expertware_net">
                      <span>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.6168 0H6.39419C2.38819 0 0 2.387 0 6.391V15.598C0 19.613 2.38819 22 6.39419 22H15.6058C19.6118 22 22 19.613 22 15.609V6.391C22.011 2.387 19.6228 0 15.6168 0ZM11.0055 15.268C8.65031 15.268 6.73536 13.354 6.73536 11C6.73536 8.646 8.65031 6.732 11.0055 6.732C13.3607 6.732 15.2756 8.646 15.2756 11C15.2756 13.354 13.3607 15.268 11.0055 15.268ZM17.5207 5.368C17.4657 5.5 17.3887 5.621 17.2896 5.731C17.1796 5.83 17.0585 5.907 16.9264 5.962C16.7944 6.017 16.6513 6.05 16.5082 6.05C16.2111 6.05 15.9359 5.94 15.7268 5.731C15.6278 5.621 15.5507 5.5 15.4957 5.368C15.4407 5.236 15.4077 5.093 15.4077 4.95C15.4077 4.807 15.4407 4.664 15.4957 4.532C15.5507 4.389 15.6278 4.279 15.7268 4.169C15.98 3.916 16.3652 3.795 16.7173 3.872C16.7944 3.883 16.8604 3.905 16.9264 3.938C16.9925 3.96 17.0585 3.993 17.1245 4.037C17.1796 4.07 17.2346 4.125 17.2896 4.169C17.3887 4.279 17.4657 4.389 17.5207 4.532C17.5758 4.664 17.6088 4.807 17.6088 4.95C17.6088 5.093 17.5758 5.236 17.5207 5.368Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";

import { defineRule } from "vee-validate";
import { required, max, email } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);
defineRule("max", max);
defineRule("email", email);

// defineRule("phoneNumber", (value) => {
//   if (!value || !value.length) {
//     return "Numărul de telefon este obligatoriu.";
//   }
//   console.log(value, " | ", /^\+(?:[0-9] ?){6,14}[0-9]$/.test(value));
//   // Check if phoneNumber
//   if (!/^[+]{1}(?:[0-9\\-\\(\\)\\/""\\.]\\s?){6, 15}[0-9]{1}$/.test(value)) {
//     return "Numărul de telefon nu este valid.";
//   }
//   return true;
// });

export default {
  name: "LandingPageContact",
  components: {
    Field,
    Form,
  },
  data() {
    return {
      contact: {
        Name: "",
        Email: "",
        PhoneNumber: "",
        Message: "",
      },
    };
  },
  methods: {
    SendContactUsEmail: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .post("api/EmailConfirm/SendContactUsEmail", this.contact)
        .then(() => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Email-ul a fost trimis cu succes!",
          });
          this.$refs.contactUsForm.resetForm();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.title-section {
  font-size: 40px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0em;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0em;
  margin-top: 75px;
}

/*GET IN TOUCH*/
svg {
  color: #26a190;
}

.subscribe span {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s !important;
}

.subscribe span i {
  color: rgba(77, 196, 179, 1) !important;
}

.subscribe a:hover span {
  background-color: #016972 !important;
}

.subscribe a:hover svg {
  color: white !important;
}

.subscribe .find-us-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.subscribe .form-control {
  background-color: transparent;
  color: white;
  text-align: center;
  font-size: 16px !important;
}

.subscribe input::placeholder {
  color: white !important;
  font-size: 16px !important;
}

.subscribe .sub-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 48px;
}
.subscribe .title {
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  margin-bottom: 24px;
}

.subscribe.card {
  border-radius: 0px 12px 12px 0px;
  background-color: transparent;
  text-align: center;
  color: white;
}
.get-in-touch-section label {
  color: rgba(108, 117, 125, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.get-in-touch-section .form-control {
  padding: 16px;
}
.contact-us .title {
  color: rgba(52, 58, 64, 1);
  font-size: 32px;
  font-weight: 400;
  line-height: 37px;
}
.bg-color {
  background: linear-gradient(87.6deg, #4dc4b3 0%, #209b8a 100%);
  border-radius: 12px;
}

.contact-us.card {
  border-radius: 12px;
  text-align: left;
}

.get-in-touch-section .bg {
  background-image: url("../../assets/images/landing-page/square-get-in-touch.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  display: inline-block;
  width: 100%;
  bottom: 0;
  top: -162px;
  right: 0;
  left: 0;
  z-index: -1;
}
.get-in-touch-section {
  position: relative;
  text-align: center;
  padding-bottom: 32px;
}
</style>
