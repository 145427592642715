<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item">Templates</li>
        <li class="breadcrumb-item" aria-current="page"><a href="#" class="active" @click="$router.go(-1)">Evaluations</a></li>
      </ol>
    </nav>

    <h1 class="page-title">Evaluations</h1>

    <div class="row mb-10 align-items-end">
      <Search @searchForItems="GetEvaluationsSearch" :searchText="filterEval" :placeholderText="'Search by evaluation name...'" />
      <div class="col-3">
        <label class="fw-bold mb-4">Workspace filter</label>
        <VueMultiselect select-label="" v-model="filterEval.Workspace" :options="workspacesList" placeholder="Select workspace type" label="Name" track-by="Id" :internalSearch="true" @remove="SelectedWorkspace(null)" @select="SelectedWorkspace" />
      </div>
      <div class="col text-end">
        <button type="button" class="btn btn-gradient-success" @click="ClearAddWorkspaceModal" data-bs-toggle="modal" data-bs-target="#create-workspace-modal">
          <i class="fa-solid fa-plus text-white"></i>
          New workspace
        </button>
      </div>
    </div>

    <div class="row">
      <workspace-component v-if="filterEval.Workspace" :workspaceProp="filterEval.Workspace" @getWorkspaceEmit="GetWorkspace" @deleteWorkspaceEmit="DeleteWorkspace" @clearAddEvalTempModalEmit="ClearAddEvalTempModal"></workspace-component>
      <div v-if="filterEval.Workspace" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
        <new-eval-card-component :workspaceProp="filterEval.Workspace" @clearAddEvalTempModalEmit="ClearAddEvalTempModal"></new-eval-card-component>
      </div>
      <div v-bind:key="index" v-for="(evaluationTemplate, index) in evaluationsTemplates?.Items" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
        <eval-card-component :evalTemplateProp="evaluationTemplate" :selectedEvalTemplateProp="selectedEvalTemplate" @deleteEvaluationPlan="DeleteEvaluation" @duplicateEvaluationPlanEmit="DuplicateEvaluationPlan" @clearModalAssignEmit="ClearAssignModal()" @getEvaluationDetailsEmit="GetEvaluationDetails"></eval-card-component>
      </div>
      <div v-if="evaluationsTemplates?.Count == 0" class="card-body pb-0 pt-5">
        <img alt="No content found" class="d-block me-auto ms-auto" :src="require('@/assets/images/no-content-found.png')" />
      </div>
      <div v-if="evaluationsTemplates?.Count > 20" class="card-body pb-5 pt-5">
        <Pagination :numberOfPages="evaluationsTemplates.NumberOfPages" :pageNumber="filterEval.PageNumber" :functionName="GetEvaluations" />
      </div>
    </div>
    <div v-for="(workspace, index) in workspaces?.Items" v-bind:key="index">
      <workspace-component :workspaceProp="workspace" @getWorkspaceEmit="GetWorkspace" @deleteWorkspaceEmit="DeleteWorkspace" @clearAddEvalTempModalEmit="ClearAddEvalTempModal"></workspace-component>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
          <new-eval-card-component :workspaceProp="workspace" @clearAddEvalTempModalEmit="ClearAddEvalTempModal"></new-eval-card-component>
        </div>
        <div v-bind:key="index" v-for="(evaluationTemplate, index) in workspace.EvaluationsTemplates" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
          <eval-card-component :evalTemplateProp="evaluationTemplate" :selectedEvalTemplateProp="selectedEvalTemplate" @deleteEvaluationPlan="DeleteEvaluation" @duplicateEvaluationPlanEmit="DuplicateEvaluationPlan" @clearModalAssignEmit="ClearAssignModal()" @getEvaluationDetailsEmit="GetEvaluationDetails"></eval-card-component>
        </div>
        <div v-if="workspace.EvaluationTemplateCount > 7 && workspace.EvaluationsTemplates.length < workspace.EvaluationTemplateCount" class="text-center">
          <button type="button" class="btn btn-bg-secondary btn-sm mt-10" @click="SeeMore(workspace)">See more</button>
        </div>
        <div v-if="workspace.EvaluationsTemplates.length === workspace.EvaluationTemplateCount && workspace.EvaluationTemplateCount > 7" class="text-center">
          <button type="button" class="btn btn-bg-secondary btn-sm mt-10" @click="SeeLess(workspace)">See less</button>
        </div>
      </div>
    </div>
    <div v-if="workspaces.Count > 3" class="card-body pb-5 pt-5">
      <Pagination :numberOfPages="workspaces.NumberOfPages" :pageNumber="filterWorksapces.PageNumber" :functionName="GetPagedWorkspaces" />
    </div>
  </div>
  <add-evaluation-template-modal-component @createEvaluationEmit="CreateEvaluationTemplate" @evaluationNameExistEmit="EvaluationNameExist" :evaluationNameExist="evaluationNameExist" ref="createEvaluationTempModalRef"></add-evaluation-template-modal-component>

  <eval-preview-modal-component :buttons="true" :evalTemplateDetailsProp="evalTemplateDetails" :selectedEvalTemplateProp="selectedEvalTemplate" @deleteEvaluationPlan="DeleteEvaluation" @clearModalAssignEmit="ClearAssignModal()">
  </eval-preview-modal-component>
  <add-workspace @createWorkspaceEmit="CreateWorkspace" ref="addWorkspaceModal"></add-workspace>
  <edit-workspace @editWorkspaceEmit="EditWorkspace" :workspaceProp="workspace" ref="editWorkspaceModal"></edit-workspace>

  <assign-eval-modal-component :selectedEvalTemplateProp="selectedEvalTemplate" @assignEvaluationEmit="AssignEvaluation" ref="assignEvaluationModal"></assign-eval-modal-component>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import utils from "@/utils/utils.js";
import Search from "@/components/Common/Search.vue";
import Pagination from "@/components/Common/Pagination.vue";
// import EvaluationTemplate from "@/components/Settings/EvaluationTemplate/EvaluationTemplateComponent.vue";
// import AssignEvaluationModal from "@/components/Settings/EvaluationTemplate/AssignModal.vue";
import EvalCardComponent from "@/components/Cards/EvalCardComponent.vue";
import WorkspaceComponent from "@/components/Modals/Workspace/WorkspaceComponent.vue";
import NewEvalCardComponent from "@/components/Cards/NewEvalCardComponent.vue";
import EvalPreviewModalComponent from "@/components/Modals/Evaluation/Preview/EvaluationPreviewModalComponent.vue";
import AssignEvalModalComponent from "@/components/Modals/Evaluation/Assign/AssignEvaluationModalComponent.vue";
import AddEvaluationTemplateModalComponent from "@/components/Modals/Evaluation/Template/AddEvaluationTemplateComponent.vue";
import AddWorkspace from "@/components/Modals/Workspace/AddWorkspaceModal.vue";
import EditWorkspace from "@/components/Modals/Workspace/EditWorkspaceModal.vue";

export default {
  name: "EvaluationsTemplatesView",
  components: {
    AddEvaluationTemplateModalComponent,
    Search,
    Pagination,
    NewEvalCardComponent,
    EvalCardComponent,
    WorkspaceComponent,
    EvalPreviewModalComponent,
    AssignEvalModalComponent,
    AddWorkspace,
    EditWorkspace,
  },
  data() {
    return {
      filterEval: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: null,
        PageSize: 7,
        Workspace: null,
      },
      filterWorksapces: {
        PageNumber: 1,
        OrderBy: "Name",
        PageSize: 3,
      },
      evaluationsTemplates: {},
      evaluationNameExist: null,
      workspaces: {},
      selectedWorkspace: 0,
      selectedEvalTemplate: {},
      evalTemplateDetails: [],
      workspace: {},
      workspacesList: [],
    };
  },
  methods: {
    AssignEvaluation: async function (assignEval) {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .post("api/EvaluationTemplate/assignEvaluationTemp", assignEval, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async (response) => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Evaluation has been assigned",
          });

          $("#assign-evaluation-modal").modal("hide");
          this.$swal
            .fire({
              title: "Evaluations has been created",
              text: "To start the evaluations go to see your evaluations in drafts.",
              icon: "success",
              showCancelButton: true,
              confirmButtonColor: "#209b8a",
              confirmButtonText: " Go to draft evaluations",
              cancelButtonText: "Close",
            })

            .then((result) => {
              if (result.isConfirmed) {
                this.$router.push("/Evaluations/Draft/").then(function () {
                  window.location.reload();
                });
              }
            });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    ClearAddWorkspaceModal: function () {
      this.$refs.addWorkspaceModal.ClearModal();
    },
    DeleteWorkspace: async function (workspaceId) {
      const self = this;
      await this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to delete this workspace?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            await self.$axios
              .delete("api/EvaluationTemplate/workspace/" + workspaceId, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then(async () => {
                self.GetPagedWorkspaces();
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: "Workspace has been deleted!",
                });
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data.message,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },

    EditWorkspace: async function () {
      await this.$axios
        .put("api/EvaluationTemplate/updateWorkspace", this.workspace, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Workspace has been updated",
          });
          this.GetPagedWorkspaces();
          $("#edit-workspace-modal").modal("hide");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetWorkspace: async function (workspaceId) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationTemplate/getWorkspace?workspaceId=" + workspaceId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.workspace = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    CreateWorkspace: async function (name) {
      store.dispatch("changeLoaderStatus", true);
      var workspace = {
        Name: null,
      };
      workspace.Name = name;
      await this.$axios
        .post("api/EvaluationTemplate/createWorksapce", workspace, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Workspace has been added",
          });
          $("#create-workspace-modal").modal("hide");
          this.GetPagedWorkspaces();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    ClearAssignModal: function () {
      this.$refs.assignEvaluationModal.ClearModalAssign();
    },

    ClearAddEvalTempModal: function (workspace) {
      this.selectedWorkspace = workspace;
      this.$refs.createEvaluationTempModalRef.ClearModal();
    },
    EvaluationNameExist: async function (name) {
      await this.$axios
        .get("api/EvaluationTemplate/evaluationNameExist?name=" + name, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationNameExist = response.data;
        })
        .catch(() => {});
    },
    OrderBy: function (orderBy) {
      if (orderBy == this.filterEval.OrderBy) {
        this.filterEval.OrderBy = orderBy + "_desc";
      } else {
        this.filterEval.OrderBy = orderBy;
      }
      this.GetEvaluationsByWorkspace();
    },
    CreateEvaluationTemplate: async function (newEvaluationTemp) {
      newEvaluationTemp.WorkspaceId = this.selectedWorkspace.Id;

      await this.$axios
        .post("api/EvaluationTemplate/add", newEvaluationTemp, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Evaluation has been added",
          });
          $("#add-evaluationTemp-modal").modal("hide");
          if (this.evaluationsTemplates === null) {
            const evalTemplates = await this.GetEvaluationsByWorkspace(
              1,
              newEvaluationTemp.WorkspaceId
            );
            this.workspaces.Items.find(
              (x) => x.Id === newEvaluationTemp.WorkspaceId
            ).EvaluationsTemplates = evalTemplates.Items;

            this.workspaces.Items.find(
              (x) => x.Id === newEvaluationTemp.WorkspaceId
            ).EvaluationTemplateCount += 1;
          } else {
            this.GetEvaluations(this.filterEval.PageNumber);
          }
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    DuplicateEvaluationPlan: async function () {
      const self = this;
      await this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to duplicate this evaluation template?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            await self.$axios
              .post(
                "api/EvaluationTemplate/dublicateEvaluationTemp?evalTempId=" +
                  self.selectedEvalTemplate.Id,
                {
                  headers: {
                    Authorization: "Bearer " + auth.getJwtToken().Value,
                  },
                }
              )
              .then(async () => {
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: "Evaluation has been duplicated",
                });
                if (self.evaluationsTemplates === null) {
                  const evalTemplates = await self.GetEvaluationsByWorkspace(
                    1,
                    self.selectedEvalTemplate.WorkspaceId
                  );
                  self.workspaces.Items.find(
                    (x) => x.Id === self.selectedEvalTemplate.WorkspaceId
                  ).EvaluationsTemplates = evalTemplates.Items;
                  self.workspaces.Items.find(
                    (x) => x.Id === self.selectedEvalTemplate.WorkspaceId
                  ).EvaluationTemplateCount += 1;
                } else {
                  self.GetEvaluations(self.filterEval.PageNumber);
                }
                store.dispatch("changeLoaderStatus", false);
              })
              .catch(() => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: "Something went wrong",
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    GetEvaluationsSearch: function () {
      if (this.filterEval.SearchText == "" && !this.filterEval.Workspace) {
        // this.evaluationsTemplates = null;
        this.GetPagedWorkspaces();
      } else {
        this.GetEvaluations();
      }
    },

    GetEvaluationsByWorkspace: async function (pageNumber, workspaceId) {
      store.dispatch("changeLoaderStatus", true);
      this.filterEval.PageNumber = 1;
      if (this.filterEval.SearchText == null) {
        this.filterEval.SearchText = "";
      }
      if (pageNumber) {
        this.filterEval.PageNumber = pageNumber;
        // window.scrollTo(0, 0);
      }
      var query = new URLSearchParams({
        searchText: this.filterEval.SearchText,
        pageNumber: this.filterEval.PageNumber,
        orderBy: this.filterEval.OrderBy,
        pageSize: this.filterEval.PageSize,
        workspaceId: workspaceId,
      });
      var result = {};
      await this.$axios
        .get(
          "api/EvaluationTemplate/getPagedEvaluationTempByWorkspace?" + query,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          result = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
      return result;
    },
    GetEvaluationDetails: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/EvaluationTemplate/getEvaluationDetails?evalTempId=" +
            this.selectedEvalTemplate.Id,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evalTemplateDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    SeeMore: async function (workspace) {
      if (!workspace.PageNumber) {
        workspace.PageNumber = 1;
      }
      workspace.PageNumber += 1;
      const evalTemplates = await this.GetEvaluationsByWorkspace(
        workspace.PageNumber,
        workspace.Id
      );
      workspace.EvaluationsTemplates.push(...evalTemplates.Items);
    },
    SeeLess: async function (workspace) {
      workspace.PageNumber = 1;
      const evalTemplates = await this.GetEvaluationsByWorkspace(
        workspace.PageNumber,
        workspace.Id
      );
      workspace.EvaluationsTemplates = evalTemplates.Items;
      window.scrollTo(0, 0);
    },
    GetEvaluations: async function (pageNumber) {
      store.dispatch("changeLoaderStatus", true);
      this.filterEval.PageNumber = 1;
      if (this.filterEval.SearchText == null) {
        this.filterEval.SearchText = "";
      }
      if (pageNumber) {
        this.filterEval.PageNumber = pageNumber;
        window.scrollTo(0, 0);
      }
      var query = new URLSearchParams({
        searchText: this.filterEval.SearchText,
        pageNumber: this.filterEval.PageNumber,
        orderBy: this.filterEval.OrderBy,
        workspaceId: this.filterEval.Workspace?.Id ?? 0,
      });
      await this.$axios
        .get("api/EvaluationTemplate/getPagedEvaluations?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationsTemplates = response.data;
          this.workspaces = {};
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    SelectedWorkspace: function (val) {
      this.filterEval.Workspace = val;
      if (this.filterEval.SearchText == "" && !this.filterEval.Workspace) {
        // this.evaluationsTemplates = null;
        this.GetPagedWorkspaces();
      } else {
        this.GetEvaluations();
      }
    },
    GetPagedWorkspaces: async function (pageNumber, workspaceId) {
      store.dispatch("changeLoaderStatus", true);
      this.filterWorksapces.PageNumber = 1;

      if (pageNumber) {
        this.filterWorksapces.PageNumber = pageNumber;
        window.scrollTo(0, 0);
      }
      var query = new URLSearchParams({
        pageNumber: this.filterWorksapces.PageNumber,
        orderBy: this.filterWorksapces.OrderBy,
        pageSize: this.filterWorksapces.PageSize,
      });
      await this.$axios
        .get("api/EvaluationTemplate/getPagedWorkspaces?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationsTemplates = null;
          this.workspaces = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetWorkspaces: async function () {
      this.$axios
        .get("api/EvaluationTemplate/getWorkspaces", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.workspacesList = response.data;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    DeleteEvaluation: async function () {
      const self = this;
      await this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to delete this evaluation template?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            await self.$axios
              .delete(
                "api/EvaluationTemplate/" + self.selectedEvalTemplate.Id,
                {
                  headers: {
                    Authorization: "Bearer " + auth.getJwtToken().Value,
                  },
                }
              )
              .then(async () => {
                if (self.evaluationsTemplates === null) {
                  const evalTemplates = await self.GetEvaluationsByWorkspace(
                    1,
                    self.selectedEvalTemplate.WorkspaceId
                  );
                  self.workspaces.Items.find(
                    (x) => x.Id === self.selectedEvalTemplate.WorkspaceId
                  ).EvaluationsTemplates = evalTemplates.Items;
                  self.workspaces.Items.find(
                    (x) => x.Id === self.selectedEvalTemplate.WorkspaceId
                  ).EvaluationTemplateCount -= 1;
                } else {
                  self.GetEvaluations(self.filterEval.PageNumber);
                }

                $("#evaluation-preview-modal").modal("hide");

                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: "Evaluation has been deleted!",
                });
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data.message,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
  },
  created() {
    this.GetPagedWorkspaces();
    this.GetWorkspaces();
  },
};
</script>
