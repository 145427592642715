<template>
  <div class="align-items-center row pt-3 pb-3">
    <div class="col">
      <div class="d-flex align-items-top">
        <i class="fa-3x fa-square-question fa-duotone text-green me-3"></i>
        <div>
          <h4 class="m-0">{{ question.Name }}</h4>
          <small>{{ question.Description }}</small>
        </div>
      </div>
    </div>

    <div class="col-auto">
      <button type="button" class="btn btn-danger-alternative" v-on:click="$emit('setSelectedCategory'), DeleteQuestion()"><i class="fa-solid fa-trash-can"></i> Delete</button>
    </div>
  </div>
</template>

<script>
import { defineRule } from "vee-validate";
import { required, min, between } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);
defineRule("between", between);
defineRule("min", min);

export default {
  name: "question-component",
  emits: ["deleteQuestionEmit", "setSelectedCategory"],
  props: {
    category: {},
    question: {
      type: Object,
      default: {},
    },
    index: Number,
  },
  components: {
    Field,
    Form,
  },
  data() {
    return {
      types: [],
      questionsTemplates: [],
    };
  },
  methods: {
    DeleteQuestion: async function () {
      await this.$emit("deleteQuestionEmit", this.question.Id);
    },
  },
  created() {},
};
</script>
