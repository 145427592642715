<template>
  <div class="modal fade" id="edit-interval-modal" tabindex="-1" aria-labelledby="editIntervalModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="Save" ref="editIntervalForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title" id="edit-interval-modal-label">Edit active period</h2>
              </div>
              <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <div class="form-group">
                <label class="label">Start date</label>
                <div class="input-group mb-5">
                  <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>

                  <!-- <Field v-model="groupDetails.StartInterval" name="startInterval" v-slot="{ field }" :rules="{ start_date_rule:[groupDetails.StartInterval] }"> -->
                  <Field v-model="groupDetails.StartInterval" name="startInterval" v-slot="{ field }" :rules="{ required:groupDetails.StartInterval == null }">
                    <Datepicker v-bind="field" v-model="groupDetails.StartInterval" class="form-control form-control-solid ps-2 custom-datepicker" placeholder="Select start date" :enableTimePicker="false" autoApply format="dd-MM-yyyy"></Datepicker>
                  </Field>
                </div>
                <div v-if="errors.startInterval" class="fv-plugins-message-container invalid-feedback">Start date is required.</div>
              </div>
            </div>

            <div>
              <div class="form-group">
                <label class="label">End date</label>
                <div class="input-group mb-5">
                  <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                  <!-- <Field name="endInterval" v-slot="{ field }" :rules="{ end_date_rule: [groupDetails.StartInterval, groupDetails.EndInterval] }"> -->
                  <Field name="endInterval" v-slot="{ field }" :rules="{ required:groupDetails.EndInterval == null }">
                    <Datepicker v-bind="field" v-model="groupDetails.EndInterval" class="form-control custom-datepicker" placeholder="Select end date" :enableTimePicker="false" autoApply format="dd-MM-yyyy"></Datepicker>
                  </Field>
                </div>
                <div v-if="errors.endInterval" class="fv-plugins-message-container invalid-feedback">{{ errors.endInterval }}</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-bs-dismiss="modal" class="border btn btn-white">Close</button>
            <button type="submit" :disabled="Object.keys(errors).length != 0" class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";

export default {
  name: "edit-interval-modal-component",
  components: { Field, Form },
  props: ["groupDetails"],

  data() {
    return {
      itemsOfChosedScope: [],
      selectedScope: null,
      scopeCategories: [],
      scopes: [],
    };
  },
  emits: ["update"],

  methods: {
    Save: function () {
      this.groupDetails.StartInterval = moment(
        this.groupDetails.StartInterval
      ).format("YYYY-MM-DD");
      this.groupDetails.EndInterval = moment(
        this.groupDetails.EndInterval
      ).format("YYYY-MM-DD");
      this.$emit("update", this.groupDetails);
    },
  },
  created: function () {},
};
</script>

<style scoped>
.fa-square:hover:before {
  content: "\f14a";
  color: #ddd !important;
  transition: 0.1s;
}

.card-body img {
  width: 30px;
}

.card {
  cursor: pointer;
  border: 1px solid white;
}

.scroll-table {
  max-height: 490px;
  overflow: auto;
}

.card:hover {
  border: 1px solid #4dc4b3;
  background: #f6fcfb;
}

.fa-square,
.fa-square-check {
  cursor: pointer;
}

.selected-scope {
  border: 1px solid #4dc4b3;
  background: #f6fcfb;
}

.card-body {
  padding: 2rem 5px;
}
</style>
