let auth = {
  getJwtToken: function () {
    if (localStorage.getItem("jwt")) return JSON.parse(localStorage.getItem("jwt"));
  },

  setJwtToken: function (token) {
    localStorage.setItem("jwt", JSON.stringify(token));
  },

  getRefreshToken: function () {
    if (localStorage.getItem("refreshToken")) return JSON.parse(localStorage.getItem("refreshToken"));
  },

  setRefreshToken: function (refreshToken) {
    localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
  },
};

export default auth;
