<template>
  <div class="modal fade" id="create-employee-modal" tabindex="-1" aria-labelledby="createEmployeeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row align-items-center">
            <div class="col-auto">
              <h2 class="modal-title" id="team-preview-modal-label">New employee</h2>
            </div>
            <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
          </div>
        </div>
        <div class="modal-body">
          <Form v-slot="{ errors }" @submit="CreateEmployee" ref="createEmployeeForm">

            <div class="row">
              <div class="col-7">
                <div class="form-group">
                  <label class="form-label"> <span class="required">Name</span> </label>
                  <Field type="text" name="employeeName" class="form-control" placeholder="Employee name" v-model="employeeDetails.Name" rules="required|min: 3" />
                  <div v-if="errors.employeeName" class="fv-plugins-message-container invalid-feedback">Name requires minimum 3 characters.</div>
                </div>
                <div class="form-group">
                  <label class="form-label"> <span class="required">Email</span> </label>
                  <Field type="text" class="form-control" placeholder="Email" name="email" v-model="employeeDetails.Email" rules="required|email" />
                  <div v-if="errors.email" class="fv-plugins-message-container invalid-feedback">Enter valid email.</div>
                </div>

                <div class="form-group">
                  <label class="form-label"> <span class="required">Role</span> </label>
                  <Field name="roleRules" v-slot="{ field }" :rules="{ required: employeeDetails.Roles == null }">
                    <VueMultiselect v-bind="field" v-model="employeeDetails.Roles" placeholder="Select role" label="Name" track-by="RoleId" class="w-100" :options=" rolesProp">
                    </VueMultiselect>
                  </Field>
                  <div class="fv-plugins-message-container invalid-feedback" v-if="errors.roleRules">Role is required.</div>
                </div>
              </div>
              <div class="col text-center">
                <img v-if="urlFile" :src="urlFile" class="mw-100px mh-100px d-block m-auto  mt-10">
                <img v-else :src="require('@/assets/images/user.svg')" class="mw-100px mh-100px d-block m-auto  mt-10">

                <input class="d-none" type="file" id="new-user-image" accept=".png, .jpg, .jpeg" @change="onFileChange" />

                <label for="new-user-image" class="btn btn-gradient-success mt-10">
                  Change image
                </label>
              </div>
            </div>

            <div class="modal-footer flex-stack">
              <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
              <button class="btn btn-gradient-success">Save</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";

import { defineRule } from "vee-validate";
import { required, min } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);
defineRule("min", min);

export default {
  name: "CreateEmployeeModal",
  props: ["rolesProp"],
  emits: ["createEmployeeEmit"],
  components: {
    Field,
    Form,
  },
  data() {
    return {
      urlFile: null,
      employeeDetails: {
        Name: null,
        Email: null,
        Roles: null,
        Image: null,
      },
    };
  },
  methods: {
    onFileChange: function (e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.urlFile = rawImg;
      };
      reader.readAsDataURL(file);
    },
    RemoveImage: function () {
      this.urlFile = null;
    },
    ClearModal: function () {
      this.urlFile = null;
      this.employeeDetails.Roles = null;
      this.$refs.createEmployeeForm.resetForm();
    },

    CreateEmployee: async function () {
      this.employeeDetails.Image = this.urlFile;
      const employeeData = {
        ...this.employeeDetails,
        Roles: [],
      };
      employeeData.Roles.push(this.employeeDetails?.Roles.Name);
      this.$emit("createEmployeeEmit", employeeData);
    },
  },
  created() {},
};
</script>
