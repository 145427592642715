<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Templates</a></li>
        <li class="breadcrumb-item" aria-current="page"><a href="#" class="active" @click="$router.go(-1)">Categories</a></li>
      </ol>
    </nav>

    <h1 class="page-title">Categories</h1>
    <div class="row align-items-end mb-8">
      <div class="col-3">
        <search @searchForItems="GetCategories" :searchText="filter" :placeholderText="'Search for categories'" />
      </div>
      <div class="col"></div>
      <div class="col-auto">
        <button type="button" class="btn btn-gradient-success" data-bs-toggle="modal" data-bs-target="#create-category-modal" @click="ClearCreateCategoryModal()">+ New category</button>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row border-bottom">
          <div class="col">
            <h4 class="text-dark-green">Title</h4>
          </div>
          <div class="col-2">
            <h4>Assigned questions</h4>
          </div>
          <div class="col-auto" style="width: 240px"></div>
        </div>
        <div v-for="(category, index) in categories.Items" v-bind:key="index">
          <category-component :category="category" :index="index" @deleteCategory="DeleteCategory" @getQuestionsEmit="GetQuestionsByCategoryId" @getCategoryById="GetCategoryById" />
          <div class="collapse" :id="'questions-by-category-' + index">
            <div class="pt-5 pb-5">
              <div class="border border-1 card rounded bg-lighten">
                <div class="card-header d-block pt-4">
                  <h4>Category questions</h4>
                  <small>{{ category.NumberOfQuestions }} Question<span v-if="category.NumberOfQuestions != 1">s</span></small>
                </div>
                <div class="card-body">
                  <div v-for="(question, indexQuestion) in category.Questions" v-bind:key="indexQuestion">
                    <hr class="mt-2 mb-2 bg-gray-400" v-if="indexQuestion > 0" />
                    <question-component :question="question" :index="indexQuestion" @deleteQuestionEmit="DeleteQuestion" @setSelectedCategory="SelectCategory(category)" />
                  </div>

                  <div class="text-center">
                    <button data-bs-toggle="modal" data-bs-target="#create-question-modal" class="btn btn-peach me-5 btn-sm" v-on:click="SelectCategory(category)">
                      <i class="fa-plus fa-solid fs-2 me-2"></i>
                      <span>New question</span>
                    </button>
                    <button data-bs-toggle="modal" data-bs-target="#import-member-modal" class="btn btn-secondary-alternative btn-sm" v-on:click="SelectCategoryForImportModal(category)">
                      <div><i class="fa-regular fa-upload"></i> <span>Import question</span></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <not-found-component v-if="categories.Items.length == 0" :text="'No elements found'"></not-found-component>
      </div>
      <div class="card-footer">
        <pagination :numberOfPages="categories.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetCategories" />
      </div>
    </div>
  </div>

  <import-question-to-category-modal :questionsTypesProp="typesForImport" :filterQuestionsProp="filterQuestions" :questionsForImportProp="questionsForImport" @importQuestionsEmit="ImportQuestions" @searchForItems="GetAllQuestionsByForImport" @getQuestionsByType="GetQuestionsByType" @SelectQuestionForImport="SelectQuestion"></import-question-to-category-modal>
  <add-template-question-modal :newQuestion="newQuestion" :types="types" @createQuestionsEmit="CreateQuestion" ref="addQuestionModal" />
  <add-category-modal :newCategory="newCategory" @createCategoryEmit="CreateCategoryTemplate" ref="createCategoryModalRef" />
  <edit-category-modal :categoryDetails="categoryDetails" @editCategory="EditCategory" ref="editCategoryModalRef" />
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import Search from "@/components/Common/Search.vue";
import QuestionComponent from "@/components/Questions/QuestionWithDeleteBtn.vue";
import CategoryComponent from "@/components/Categories/CategoryComponent.vue";
import AddCategoryModal from "@/components/Modals/Category/AddCategoryModal.vue";
import EditCategoryModal from "@/components/Modals/Category/EditCategoryModal.vue";

import AddTemplateQuestionModal from "@/components/Modals/Question/CreateTemplateQuestion.vue";
import ImportQuestionToCategoryModal from "@/components/Modals/Question/ImportQuestionToCategoryModal.vue";
import Pagination from "@/components/Common/Pagination.vue";

import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";
export default {
  name: "CategoriesTemplatesView",
  components: {
    Search,
    Pagination,
    CategoryComponent,
    AddCategoryModal,
    EditCategoryModal,
    NotFoundComponent,
    ImportQuestionToCategoryModal,
    AddTemplateQuestionModal,
    QuestionComponent,
  },
  emits: [],
  data() {
    return {
      categories: {
        Items: [
          {
            Questions: [],
          },
        ],
      },
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
      },
      filterQuestions: {
        SearchText: null,
        SearchLoader: false,
      },
      selectedCategory: {},
      newCategory: {},
      types: [],
      typesForImport: [],
      newQuestion: {},
      questionsForImport: [],
      categoryDetails: {},
      questionsToAdd: [],
    };
  },

  methods: {
    SelectQuestion: function (question) {
      if (!this.questionsToAdd.includes(question.Id)) {
        this.questionsToAdd.push(question.Id);
      } else {
        this.questionsToAdd.splice(this.questionsToAdd.indexOf(question.Id), 1);
      }
    },
    ClearCreateCategoryModal: function () {
      this.$refs.createCategoryModalRef.$refs.createCategoryForm.resetForm();
    },
    OpenCreateCreateCategoryModal: function () {
      $("#create-category-modal").modal("hide");
    },
    ClearImportModal: function () {
      this.filterQuestions.SearchText = null;
    },
    ClearAddQuestionModal: function () {
      this.$refs.addQuestionModal.ClearCreateQuestionModal();
    },

    SelectCategoryForImportModal: function (category) {
      this.selectedCategory = category;
      this.GetQuestionsTypesForImport();
      this.ClearImportModal();
    },

    SelectCategory: function (category) {
      this.selectedCategory = category;
      this.GetTypes();
      this.ClearAddQuestionModal();
    },
    GetCategories: async function (pageNumber) {
      store.dispatch("changeLoaderStatus", true);
      this.filter.PageNumber = 1;
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      if (pageNumber) {
        this.filter.PageNumber = pageNumber;
        window.scrollTo(0, 0);
      }
      var query = new URLSearchParams({
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
      });
      await this.$axios
        .get("api/CategoriesTemplates/getPagedCategories?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.categories = response.data;
          for (var index in this.categories.Items) {
            $("#questions-by-category-" + index).collapse("hide");
          }
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    CreateCategoryTemplate: async function () {
      await this.$axios
        .post("api/CategoriesTemplates/add", this.newCategory, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(() => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Category has been added",
          });
          $("#create-category-modal").modal("hide");
          this.GetCategories();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    ImportQuestions: async function () {
      const self = this;
      const count = this.questionsToAdd.length;

      this.$swal
        .fire({
          title: "Are you sure you want to add " + count + " questions?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(function (result) {
          if (result.value) {
            self.$axios
              .post(
                "api/QuestionsTemplates/import?categoryTemplateId=" +
                  self.selectedCategory.Id,
                self.questionsToAdd,
                {
                  headers: {
                    Authorization: "Bearer " + auth.getJwtToken().Value,
                  },
                }
              )
              .then(() => {
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: "Questions has been imported",
                });
                self.selectedCategory.NumberOfQuestions += count;
                self.GetQuestionsByCategoryId(self.selectedCategory, false);
                self.GetQuestionsTypesForImport();
                self.questionsToAdd = [];
                $("#import-member-modal").modal("hide");
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data.message,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    CreateQuestion: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .post(
          "/api/QuestionsTemplates/addCategoryQuestion?categoryId=" +
            this.selectedCategory.Id,
          this.newQuestion,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then(() => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Question has been added",
          });

          $("#create-question-modal").modal("hide");

          this.$refs.addQuestionModal.ClearCreateQuestionModal();

          this.selectedCategory.NumberOfQuestions++;

          this.GetQuestionsByCategoryId(this.selectedCategory);
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetQuestionsByCategoryId: async function (category, expand) {
      if (!expand || expand == false) {
        store.dispatch("changeLoaderStatus", true);
        await this.$axios
          .get(
            "api/QuestionsTemplates/getQuestionsCategory?categoryId=" +
              category.Id,
            {
              headers: {
                Authorization: "Bearer " + auth.getJwtToken().Value,
              },
            }
          )
          .then((response) => {
            store.dispatch("changeLoaderStatus", false);
            category.Questions = response.data;
          })
          .catch(() => {
            store.dispatch("toastNotify", {
              Type: "error",
              Title: "Category can not be found",
            });
            store.dispatch("changeLoaderStatus", false);
          });
      }
    },
    DeleteQuestion: async function (id) {
      const self = this;
      await this.$swal
        .fire({
          title: "Warning",
          text: "Are you sure you want to delete this question?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            await self.$axios
              .delete("api/QuestionsTemplates/deleteCategoryQuestion/" + id, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then(() => {
                self.selectedCategory.NumberOfQuestions--;
                self.GetQuestionsByCategoryId(self.selectedCategory);
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: "Question has been deleted",
                });
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    DeleteCategory: async function (id) {
      const self = this;
      await this.$swal
        .fire({
          title: "Warning",
          text: "Are you sure you want to delete this category?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            await self.$axios
              .delete("api/CategoriesTemplates/" + id)
              .then(() => {
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: "Category has been deleted",
                });
                self.GetCategories();
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    EditCategory: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .put("api/CategoriesTemplates/update", this.categoryDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(() => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Category has been updated",
          });
          this.GetCategories();
          $("#edit-category-modal").modal("hide");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetTypes: function () {
      this.$axios
        .get("api/QuestionsTemplates/getQuestionsTypes", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.types = response.data;
        })
        .catch(() => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Question can not be found",
          });
        });
    },
    GetQuestionsTypesForImport: async function () {
      for (var index in this.typesForImport) {
        $("#question-type-collapse-" + index).collapse("hide");
      }
      await this.$axios
        .get("api/QuestionsTemplates/getQuestionsTypesForImport", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.typesForImport = response.data;
        })
        .catch(() => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Question can not be found",
          });
        });
    },

    GetQuestionsByType: async function (type) {
      var query = new URLSearchParams({
        typeId: type.Id,
        categoryId: this.selectedCategory.Id,
      });
      await this.$axios
        .get("api/QuestionsTemplates/getQuestionsByTypeForImport?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          type.Questions = response.data;

          //Get only types from where questions has been imported

          //Keep selected questions for collapse
          this.questionsToAdd?.map((id) => {
            const index = type.Questions?.findIndex((item) => item.Id === id);
            if (index !== -1) {
              type.Questions[index].Checked = true;
            }
          });
          this.filterQuestions.SearchLoader = false;
        })
        .catch(() => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Question can not be found",
          });
          this.filterQuestions.SearchLoader = false;
        });
    },
    GetAllQuestionsByForImport: async function () {
      this.filterQuestions.SearchLoader = true;
      if (
        this.filterQuestions.SearchText == null ||
        this.filterQuestions.SearchText == ""
      ) {
        this.filterQuestions.SearchText = "";
        this.GetQuestionsTypesForImport();
      }
      var query = new URLSearchParams({
        searchText: this.filterQuestions.SearchText,
      });
      await this.$axios
        .get("api/QuestionsTemplates/getAllQuestionsForImport?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.questionsForImport = response.data;
          this.questionsToAdd?.map((id) => {
            const index = this.questionsForImport?.findIndex(
              (item) => item.Id === id
            );
            if (index !== -1) {
              this.questionsForImport[index].Checked = true;
            }
          });
          this.filterQuestions.SearchLoader = false;
        })
        .catch(() => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Question can not be found",
          });
          this.filterQuestions.SearchLoader = false;
        });
    },
    GetCategoryById: async function (category) {
      await this.$axios
        .get("api/CategoriesTemplates/" + category.Id, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.categoryDetails = response.data;
        })
        .catch(() => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Category can not be found",
          });
        });
    },
  },

  mounted() {
    this.GetCategories();
  },
};
</script>
