<template>
  <div class="modal fade" id="membersProject-modal" tabindex="-1" aria-labelledby="membersProjectLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row align-items-center">
            <div class="col-auto">
              <h2 class="modal-title" id="team-preview-modal-label">Members management</h2>
            </div>
            <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
          </div>
        </div>

        <div class="modal-body">
          <div class="mb-5">Project details</div>

          <div class="row">
            <div class="col-12 col-md-4 col-lg-3 mb-5">
              <div class="border border-1 h-100 p-4 rounded">
                <div>Project name</div>
                <span class="d-block fw-bold">{{ selectedProjectProps.Name }}</span>
              </div>
            </div>

            <div class="col-12 col-md-4 col-lg-3 mb-5">
              <div class="border border-1 h-100 p-4 rounded">
                <span>Project manager</span>
                <span v-if="selectedProjectProps.ManagerName" class="d-block fw-bold">{{ selectedProjectProps.ManagerName }}</span>
                <span v-else class="d-block fw-bold text-muted">No manager</span>
              </div>
            </div>

            <div v-if="selectedProjectProps.Description" class="col mb-5">
              <div class="border border-1 h-100 p-4 rounded">
                <span>Description</span>
                <span class="d-block fw-bold"> {{ selectedProjectProps.Description }}</span>
              </div>
            </div>
          </div>

          <hr class="mt-7 mb-10" />

          <div class="row">
            <div class="col">
              <h6>Employees</h6>
              <div class="input-group input-group-loader-search">
                <span class="input-group-text border-0"><i class="fa-solid fa-search fs-18px"></i></span>
                <input v-on:keyup="GetUnassigneProjectMembers()" v-model="filterUnassignedMembers.SearchText" type="text" class="form-control" id="search" placeholder="Search for employees" />
              </div>

              <div class="mh-400px mt-6 overflow-scroll p-1 pe-2">
                <table class="table table-row-dashed">
                  <thead class="table-header">
                    <tr>
                      <th>
                        <h6>Name</h6>
                      </th>
                      <th class="text-end">
                        <h6>Add</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-bind:key="index" v-for="(employee, index) in unassignedProjectMembersProps">
                      <td class="align-items-center d-flex">
                        <div v-if="employee.Image" class="h-25px w-25px">
                          <img class="employee-img" :src="GetImgUrl(employee.Image)" />
                        </div>
                        <div v-else class="bg-gray-100 h-25px p-1 rounded-circle text-center w-25px">
                          <small class="fw-bold text-gray-700"
                            >{{ employee.EmployeeName.split()[0][0] }}<span v-if="employee.EmployeeName.split(' ')[1]">{{ employee.EmployeeName.split(" ")[1][0] }}</span>
                          </small>
                        </div>
                        <div class="ms-5 fw-bold">
                          {{ employee.EmployeeName }}
                        </div>
                      </td>
                      <td class="text-end">
                        <i v-on:click="AddMemberProject(employee)" class="fa-2x fa-duotone fa-square pointer"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <small v-if="unassignedProjectMembersProps?.length == 0 && filterUnassignedMembers.SearchText?.length == 0">No available employee to assign to this project.</small>
              <small v-if="unassignedProjectMembersProps?.length == 0 && filterUnassignedMembers.SearchText?.length > 0">No employee based on your search.</small>
            </div>

            <div class="col">
              <h6>Project members</h6>
              <div class="input-group input-group-loader-search">
                <span class="input-group-text border-0"><i class="fa-solid fa-search fs-18px"></i></span>
                <input v-on:keyup="GetProjectMembers()" v-model="filterMembers.SearchText" type="text" class="form-control" id="search" placeholder="Search for project members" />
              </div>
              <div class="mh-400px mt-6 overflow-scroll p-1 pe-2">
                <table class="table table-row-dashed">
                  <thead class="table-header">
                    <tr>
                      <th>
                        <h6>Name</h6>
                      </th>
                      <th class="text-end">
                        <h6>Remove</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-bind:key="index" v-for="(projectMember, index) in projectMembersProps">
                      <td class="align-items-center d-flex">
                        <div v-if="projectMember.Image" class="h-25px w-25px">
                          <img class="employee-img" :src="GetImgUrl(projectMember.Image)" />
                        </div>
                        <div v-else class="bg-gray-100 h-25px p-1 rounded-circle text-center w-25px">
                          <small class="fw-bold text-gray-700"
                            >{{ projectMember.EmployeeName.split()[0][0] }}<span v-if="projectMember.EmployeeName.split(' ')[1]">{{ projectMember.EmployeeName.split(" ")[1][0] }}</span>
                          </small>
                        </div>
                        <div class="ms-5 fw-bold">
                          {{ projectMember.EmployeeName }}
                        </div>
                      </td>
                      <td class="text-end">
                        <i v-on:click="DeleteMember(projectMember.MemberId)" class="fa-duotone fa-trash-alt far fs-18px pointer text-hover-danger"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <small v-if="projectMembersProps?.length == 0 && filterMembers.SearchText?.length == 0">Add first member to this project.</small>
              <small v-if="projectMembersProps?.length == 0 && filterMembers.SearchText?.length > 0">No member based on your search.</small>
            </div>
          </div>
        </div>

        <div class="modal-footer flex-stack">
          <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MembersModal",
  props: ["selectedProjectProps", "projectMembersProps", "unassignedProjectMembersProps", "filterMembers", "filterUnassignedMembers"],
  emits: ["addProjectMemberEmit", "deleteMemberEmit", "getUnassignedProjectMembersEmit", "getProjectMembersEmit"],
  components: {},
  data() {
    return {
      memberAdded: {
        EmployeeId: 0,
        ProjectId: 0,
      },
    };
  },
  methods: {
    GetImgUrl: function (image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },
    GetProjectMembers: async function () {
      await this.$emit("getProjectMembersEmit");
    },
    GetUnassigneProjectMembers: async function () {
      await this.$emit("getUnassignedProjectMembersEmit");
    },
    ClearModal: function () {
      this.filterUnassignedMembers.SearchText = null;
      this.filterMembers.SearchText = null;
    },
    AddMemberProject: async function (member) {
      this.memberAdded.EmployeeId = member.EmployeeId;
      this.memberAdded.ProjectId = this.selectedProjectProps.ProjectId;
      this.$emit("addProjectMemberEmit", this.memberAdded);
    },
    DeleteMember: function (memberId) {
      this.$emit("deleteMemberEmit", memberId);
    },
  },
  created() {},
};
</script>
