<template>
  <label class="fw-bold mb-4">{{ multiselectPropertiesProp.Name }} </label>
  <VueMultiselect
    select-label=""
    :disabled="multiselectPropertiesProp.Disabled"
    v-model="multiselectFilterProp.Element"
    :options="multiselectPropertiesProp.Elements"
    :placeholder="multiselectPropertiesProp.PlaceHolder"
    :label="multiselectPropertiesProp.Label"
    @remove="GetAllElements"
    :searchable="true"
    :internalSearch="false"
    :loading="multiselectFilterProp.IsLoading"
    @search-change="GetElementsForMultiselect"
    @select="GetElementsById"
  >
    <template v-slot:option="{ option }">
      <div class="option__desc" v-html="ContentByScopeId(option)"></div>
    </template>
  </VueMultiselect>
</template>

<script>
export default {
  Name: "MultiselectFilter",
  props: ["multiselectPropertiesProp", "multiselectFilterProp", "filterProp"],

  emits: ["getElements", "getElementsForMultiselect"],
  data() {
    return {
      element: null,
    };
  },
  methods: {
    ContentByScopeId: function (option) {
      switch (this.multiselectPropertiesProp.ScopeId) {
        //team members
        case 2:
          return '<span class="option__title">' + option.TeamName + "</span>" + '<small class="option__small d-block mt-2" > Leader: ' + option.LeaderName + " </small>";
        //team leaders
        case 3:
          // return (
          //   '<span class="option__title">' +
          //   option?.Name +
          //   "</span>" +
          //   '<small class="option__small d-block mt-2"> Team: ' +
          //   option?.Teams[0]?.TeamName +
          //   "</small>"
          // );
          return '<span class="option__title">' + option?.Name + "</span>" + '<small class="option__small d-block mt-2"> Team: ' + option?.Teams[0] + "</small>";
        //project members
        case 4:
          return '<span class="option__title">' + option.Name + " - " + option.CustomerName + "</span>" + '<small class="option__small d-block mt-2" > Manager: ' + option.ManagerName + " </small>";
        //project managers
        case 5:
          return '<span class="option__title">' + option.ManagerName + "</span>";
        case 6:
        case 7:
        case 8:
          return '<span class="option__title">' + option.Name + "</span>";
        default:
          break;
      }
    },
    GetAllElements: function () {
      this.multiselectFilterProp.EvaluationForId = 0;
      this.filterProp.SelectedEvalPlan = null;
      this.$emit("getElements", 1);
    },
    GetElementsForMultiselect: function (searchText) {
      this.multiselectFilterProp.SearchText = searchText;
      this.filterProp.SelectedEvalPlan = null;

      this.$emit("getElementsForMultiselect");
    },
    GetElementsById: function (val) {
      this.filterProp.SelectedEvalPlan = null;
      switch (this.multiselectPropertiesProp.ScopeId) {
        //team members
        case 2:
          this.multiselectFilterProp.EvaluationForId = val.TeamId;
          this.$emit("getElements", 1);
          break;
        //team leaders
        case 3:
          this.multiselectFilterProp.EvaluationForId = val.LeaderId;
          this.$emit("getElements", 1);
          break;
        //project members
        case 4:
          this.multiselectFilterProp.EvaluationForId = val.ProjectId;
          this.$emit("getElements", 1);
          break;
        //project managers
        case 5:
          this.multiselectFilterProp.EvaluationForId = val.ManagerId;
          this.$emit("getElements", 1);
          break;
        case 6:
        case 7:
        case 8:
          this.multiselectFilterProp.EvaluationForId = val.Id;
          this.$emit("getElements", 1);
          break;
        default:
          break;
      }
    },
  },
};
</script>
