<template>
  <!-- eslint-disable vue/no-v-html -->
  <div v-if="$store.isLoading" class="page-loader-background"></div>
  <div v-if="$store.isLoading" class="loader">
    <div class="mesh-loader">
      <div class="set-one">
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
      <div class="set-two">
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </div>
  </div>
  <router-view :key="$route.fullPath"></router-view>
</template>
