<template>

  <div class="card mb-8">
    <div v-if="isSignedProp" class="card-body ">
      <div class="row align-items-center ">
        <div class="col-auto p-0 ">
          <i class="fa fa-question-square fs-2qx text-secondary"></i>
        </div>
        <div class="bg-light rounded col ms-8 p-4 ">
          <h3>Încadrare profesională</h3>
          <p class="m-0">Încadrare profesională în funcție de competențe profesionale și experiență</p>

        </div>

        <div class="col-auto"><span class="bg-gray-200  rounded text-dark-green fw-bold fs-18px p-4 h-100"> {{ updateEvaluationProp.Position }}</span></div>

      </div>

    </div>
    <div v-else class="card-body ">
      <div class="row align-items-center ">
        <div class="col-auto p-0 ">
          <i class="fa fa-question-square fs-2qx text-secondary"></i>
        </div>
        <div class="bg-light rounded ms-8 p-4 mt-4 mb-4 col">
          <h3>Încadrare profesională</h3>
          <p>Încadrare profesională în funcție de competențe profesionale și experiență</p>

        </div>
      </div>
      <div class=" ps-13 row">
        <div class="col">
          <div v-bind:key="index" v-for="(level, index) in beginer">
            <div class="form-check m-2 level col">
              <input :value="level" v-model="updateEvaluationProp.Position" class="form-check-input " type="radio" name="flexRadioDefault" :id="'level1-' + index">
              <label class="form-check-label fs-5 " :for="'level1-' + index">
                {{ level }}
              </label>
            </div>
          </div>
        </div>
        <div class="col">
          <div v-bind:key="index" v-for="(level, index) in junior">
            <div class="form-check m-2 level col ">
              <input :value="level" v-model="updateEvaluationProp.Position" class="form-check-input " type="radio" name="flexRadioDefault" :id="'level2-' + index">
              <label class="form-check-label fs-5 " :for="'level2-' + index">
                {{ level }}
              </label>
            </div>
          </div>
        </div>
        <div class="col">
          <div v-bind:key="index" v-for="(level, index) in medium">
            <div class="form-check m-2 level col">
              <input :value="level" v-model="updateEvaluationProp.Position" class="form-check-input " type="radio" name="flexRadioDefault" :id="'level3-' + index">
              <label class="form-check-label fs-5 " :for="'level3-' + index">
                {{ level }}
              </label>
            </div>

          </div>
        </div>
        <div class="col">
          <div v-bind:key="index" v-for="(level, index) in senior">
            <div class="form-check m-2 level col">
              <input :value="level" v-model="updateEvaluationProp.Position" class="form-check-input " type="radio" name="flexRadioDefault" :id="'level4-' + index">
              <label class="form-check-label fs-5 " :for="'level4-' + index">
                {{ level }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h3 v-if="!isSignedProp">Evaluare pentru pozitia de <span class="text-dark-green">{{ updateEvaluationProp.Position }}</span> </h3>

</template>
<script>
export default {
  props: {
    updateEvaluationProp: { type: Object, default: new Object() },
    isSignedProp: { type: Boolean, default: false },
  },
  data() {
    return {
      beginer: ["Beginner", "Beginner +"],
      junior: ["Junior -", "Junior", "Junior +"],
      medium: ["Medium -", "Medium", "Medium +"],
      senior: ["Senior -", "Senior", "Senior +"],
    };
  },
};
</script>