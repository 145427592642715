<template>
  <div class="modal fade" id="edit-workspace-modal" tabindex="-1" aria-labelledby="edit-workspace-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="EditWorkspace" ref="editWorkspaceForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title" id="team-preview-modal-label">Edit workspace</h2>
              </div>
              <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            </div>
          </div>
          <div class="modal-body">
            <label class="form-label">
              <span class="required">Name</span>
            </label>
            <Field type="text" name="workspaceName" class="form-control" placeholder="Workspace name" v-model="workspaceProp.Name" rules="required|min:3|max:200" />
            <div v-if="errors.workspaceName" class="fv-plugins-message-container invalid-feedback">Name requires minimum 3 and maximum 100 characters.</div>
          </div>
          <div class="modal-footer flex-stack">
            <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-gradient-success">
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);

export default {
  name: "edit-workspace-modal",
  components: {
    Field,
    Form,
  },
  props: { workspaceProp: { type: Object, default: {} } },
  emit: ["editWorkspaceEmit"],
  data() {
    return {};
  },
  methods: {
    EditWorkspace: function () {
      this.$emit("editWorkspaceEmit");
    },
  },
};
</script>
