<template>
  <td>
    <div class="d-flex align-items-top">
      <i class="fa-3x fa-square-question fa-duotone text-green me-3"></i>
      <div>
        <h4 class="m-0">{{ question.Name }}</h4>
        <small>{{ question.Description }}</small>
      </div>
    </div>
  </td>
  <td>
    {{ question.Type.Name }}
  </td>

  <td class="text-end">
    <button :disabled="question.AvailableToEdit === true" :title="question.AvailableToEdit == true ? 'This question cannot be edited because is already used in evaluations.' : ''" type="button" class="btn btn-secondary-alternative me-3" v-on:click="GetQuestionByIdEmit(question)">
      <i class="fa-solid fa-pen"></i> Edit
    </button>
    <button type="button" class="btn btn-danger-alternative" v-on:click="DeleteQuestion()"><i class="far fa-trash-can"></i> Delete</button>
  </td>
</template>

<script>
import { defineRule } from "vee-validate";
import { required, min, between } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);
defineRule("between", between);
defineRule("min", min);

export default {
  name: "question-with-delete-and-editBtn",
  emits: ["deleteQuestionEmit", "getQuestionByIdEmit", "getTypesEmit"],
  props: {
    category: {
      type: Object,
      default: {},
    },
    question: {
      type: Object,
      default: {},
    },
  },
  components: {
    Field,
    Form,
  },
  data() {
    return {
      questionsTemplates: [],
    };
  },
  methods: {
    GetQuestionByIdEmit: function (question) {
      this.$emit("getQuestionByIdEmit", question);
    },

    DeleteQuestion: async function () {
      await this.$emit("deleteQuestionEmit", this.question.Id);
    },
  },
  created() {},
};
</script>
