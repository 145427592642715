<template>
  <!-- <div class="container-fluid"> -->
  <div class="ps-10 pe-10">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb " class="mb-8">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Home</li>
            <li class="breadcrumb-item">Templates</li>
            <li class="breadcrumb-item"><a href="/Templates/Evaluations">Evaluation template</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ evaluation.Name }}</li>
          </ol>
        </nav>

        <div class="row align-items-center" style="min-height: 75px">
          <div class="col">
            <h1 :class="{ 'mb-0': !evaluation.Description }">{{ evaluation.Name }}</h1>
            <p v-if="evaluation.Description">{{ evaluation.Description }}</p>
          </div>

          <div class="col-auto text-end">
            <button class="btn btn-white me-3" data-bs-toggle="modal" data-bs-target="#edit-evaluationTemp-modal" @click="GetEvaluation(evaluation.Id)">
              <i class="fa-light fa-pen-to-square"></i> Edit
            </button>
            <button class="btn btn-white me-3" @click="EditEvaluationContent"><i class="fa-light fa-files"></i> Save changes</button>
          </div>
        </div>

        <div class="card eval-content-card">
          <div class="card-body">
            <evaluation-editable-content-component @RemoveItem="RemoveItem()" :draggingProp="dragging" :evaluationContent="evaluation"></evaluation-editable-content-component>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col"></div>

              <div class="col-auto">
                <button class="btn btn-white me-3" @click="EditEvaluationContent"><i class="fa-light fa-files"></i> Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto"></div>
      <div class="col-auto">
        <div style="width: 500px"></div>
        <catalog
          :categories="categoriesList"
          :questions="questionsList"
          :questionsTypes="types"
          :draggingProp="dragging"
          @getTypesEmit="GetTypes"
          @getQuestionsTemplateEmit="GetQuestionsTemplate"
          @getCategoriesTemplateEmit="GetCategoriesTemplate"
          @getQuestionsTemplateSearchEmit="GetQuestionsTemplateSearch"
          @getMyQuestionsTemplateEmit="GetMyQuestionsTemplate"
          @clearCreateQuestionModalEmit="ClearCreateQuestionModal"
          ref="catalogComponentRef"
        ></catalog>
      </div>
    </div>
  </div>

  <create-question-modal-component :newQuestionProp="newQuestion" @createQuestionsEmit="CreateQuestion" ref="createNewQuestionTemp"></create-question-modal-component>
  <edit-evaluation-template-modal
    :evaluationNameExistProp="evaluationNameExist"
    :evaluationProp="evaluationDetails"
    @editEvaluationEmit="UpdateEvaluation"
    ref="editEvalTempModal"
  ></edit-evaluation-template-modal>
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import draggable from "vuedraggable";
import CreateQuestionModalComponent from "@/components/Modals/Question/CreateQuestionModalComponent.vue";
import EditEvaluationTemplateModal from "@/components/Modals/Evaluation/Edit/EditEvaluationTemplateModal.vue";
import EvaluationEditableContentComponent from "@/components/EvaluationContent/EvaluationEditableContentComponent.vue";
import Catalog from "@/components/Common/CatalogComponent.vue";

export default {
  name: "EditEvaluationTemplateView",
  components: {
    draggable,
    CreateQuestionModalComponent,
    EvaluationEditableContentComponent,
    Catalog,
    EditEvaluationTemplateModal,
  },
  data() {
    return {
      name: "clone",
      display: "Clone",
      showCategoriesList: false,
      showQuestionsList: false,
      categoriesList: [],
      types: [],
      questionsList: [],
      dragging: {
        QuestionDragging: false,
        CategoryDragging: false,
      },
      controlOnStart: true,
      newQuestion: {},
      evaluation: {
        Categories: [],
      },
      evaluationId: null,
      evaluationNameExist: null,
      evaluationDetails: {},
    };
  },
  methods: {
    RemoveItem: function () {
      this.GetCategoriesTemplate();
    },

    UpdateEvaluation: async function (evaluation) {
      await this.$axios
        .put("api/EvaluationTemplate/update", evaluation, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Evaluation template has been updated",
          });

          this.GetEvaluationTemplate(this.evaluationId);
          $("#edit-evaluationTemp-modal").modal("hide");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluation: async function (evaluationId) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationTemplate/" + evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    EvaluationNameExist: async function (name) {
      await this.$axios
        .get("api/EvaluationTemplate/evaluationNameExist?name=" + name, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationNameExist = response.data;
        })
        .catch(() => {});
    },

    ClearCreateQuestionModal: function () {
      this.$refs.createNewQuestionTemp.ClearCreateQuestionModal();
    },

    CreateQuestion: async function () {
      await this.$axios
        .post("api/QuestionsTemplates/addOthersTempQuestion", this.newQuestion, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Question has been added",
          });
          // this.$refs.createNewQuestionTemp.$refs.Close.click();
          $("#create-question-modal").modal("hide");
          this.$refs.catalogComponentRef.ShowMyQuestionsList();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    EditEvaluationContent: async function () {
      await this.$axios
        .post("api/EvaluationTemplate/addEvalutionContent?evalId=" + this.evaluationId, this.evaluation.Categories, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Evaluation content has been updated",
          });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
      this.GetEvaluationTemplate(this.evaluationId);
    },

    GetEvaluationTemplate: async function (evalTempId) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationTemplate/getEvaluationDetails?evalTempId=" + evalTempId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluation = response.data;

          // if (this.evaluation.Categories.length > 0) {
          //   this.evaluation.Name = response.data.Name;
          //   this.evaluation.Description = response.data.Description;
          //   this.evaluation.Id = response.data.Id;
          // } else {
          //   this.evaluation = response.data;
          // }
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetMyQuestionsTemplate: async function (searchText) {
      store.dispatch("changeLoaderStatus", true);
      if (searchText == null) {
        searchText = "";
      }
      await this.$axios
        .get("api/QuestionsTemplates/getMyQuestions?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.questionsList = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetQuestionsTemplateSearch: async function (searchText) {
      store.dispatch("changeLoaderStatus", true);
      if (searchText == null) {
        searchText = "";
      }
      await this.$axios
        .get("api/QuestionsTemplates/getQuestions?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.questionsList = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetCategoriesTemplate: async function (searchText) {
      store.dispatch("changeLoaderStatus", true);
      if (searchText == null) {
        searchText = "";
      }
      await this.$axios
        .get("api/CategoriesTemplates/getCategories?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.categoriesList = response.data;
          this.evaluation.Categories.map((item) => {
            var index = this.categoriesList.findIndex((x) => x.CategoryId === item.CategoryId);
            if (index !== -1) {
              this.categoriesList.splice(index, 1);
            }
          });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetQuestionsTemplate: async function (type) {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get("api/QuestionsTemplates/getQuestionsByType?typeId=" + type.Id, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          type.QuestionsList = response.data;
          store.dispatch("changeLoaderStatus", false);
          $("#question-type" + type.Id).collapse("show");
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetTypes: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get("api/QuestionsTemplates/getQuestionsTypes", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.types = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  computed: {},
  created() {
    this.evaluationId = this.$route.params.evalTempId;
    this.GetEvaluationTemplate(this.evaluationId);
  },
};
</script>

<style scoped>
h1 {
  font-size: 30px;
  color: #188a94;
}
.question-card {
  background-color: #f5f8fa;
  border-radius: 0.475rem;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
}
.c-grab {
  cursor: grab;
}

.empty-eval-content {
  position: relative;
  background: #4bc2b136;
  min-height: 200px;
  border-radius: 5px;
  border: 1px dashed #4cc3b2;
  color: #4cc3b2;
}

.empty-eval-content h1 {
  color: #16899394;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 40%;
}

.empty-eval-content .dragArea {
  height: 200px;
}

.new-card-object {
  border: 1px solid #e4e6ef;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: grab;
  background: white;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
