<template>
  <div class="container">
    <nav aria-label="breadcrumb" class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item active"><span class="active" href="#">Projects</span></li>
      </ol>
    </nav>
    <h1 class="page-title">Projects</h1>
    <div class="row mb-10 align-items-end">
      <div class="col">
        <Search @searchForItems="GetProjects" :placeholderText="'Search for projects'" :searchText="filter" />
      </div>

      <div class="col-auto">
        <VueMultiselect select-label="" v-model="filter.Customer" :options="customers" placeholder="Search for customer" label="Name" track-by="CustomerId" :searchable="true" :internalSearch="false" :multiple="false" @search-change="GetCustomers" @select="SelectCustomerForFilter" @open="GetCustomers()" @remove="SelectCustomerForFilter()" />
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-gradient-success" v-on:click="GetProjects()"><i class="fa-search far text-white"></i> Search</button>
      </div>
      <div class="col"></div>
      <div class="col text-end">
        <button class="btn btn-gradient-success" data-bs-toggle="modal" data-bs-target="#create-project-modal" @click="OpenCreateProjectModal()">+ New project</button>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-row-dashed">
            <thead class="table-header">
              <tr>
                <th>
                  <span class="pointer text-dark-green" v-on:click="OrderBy('Name')">
                    Name
                    <span v-if="filter.OrderBy != 'Name_desc'">
                      <i class="fa-solid fa-arrow-up-short-wide"></i>
                    </span>
                    <span v-if="filter.OrderBy === 'Name_desc'">
                      <i class="fa-solid fa-arrow-down-short-wide"></i>
                    </span>
                  </span>
                </th>

                <th>Customer</th>
                <th>Project manager</th>
                <th>Members number</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(project, index) in projects.Items" v-bind:key="index">
                <td>
                  <div class="d-flex align-items-center">
                    <div class="h-40px w-40px rounded-circle bg-gray-100 text-center">
                      <h4 class="fw-bold mt-3 text-gray-700">
                        {{ project.Name.split()[0][0] }}<span v-if="project.Name.split(' ')[1]">{{ project.Name.split(" ")[1][0] }}</span>
                      </h4>
                    </div>
                    <div class="ms-5">
                      <h6 class="m-0">{{ project.Name }}</h6>
                      <small class="d-block">{{ project.Description }}</small>
                    </div>
                  </div>
                </td>
                <td>{{ project.CustomerName }}</td>
                <td>
                  {{ project.ManagerName }}

                  <span v-if="!project.ManagerName" class="text-muted">No manager</span>
                </td>
                <td>
                  <div v-if="project.NumberOfMembers > 0">
                    <i class="fa-light fa-users text-green me-2 fs"></i> {{ project.NumberOfMembers }} member<span v-if="project.NumberOfMembers != 1">s</span>
                  </div>
                  <span v-else class="text-muted">No members</span>
                </td>
                <td class="text-end">
                  <button type="button" class="btn btn-info-alternative me-3" @click="OpenProjectMembersModal(project)">Members</button>

                  <button type="button" data-bs-toggle="modal" data-bs-target="#edit-project-modal" @click="OpenEditProjectModal(project)" class="btn btn-secondary-alternative me-3">
                    <i class="fa-solid fa-pen"></i> Edit
                  </button>
                  <button type="button" class="btn btn-danger-alternative" @click="DeleteProject(project.ProjectId)"><i class="far fa-trash-can"></i> Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr v-if="projects.NumberOfPages > 1" />

        <Pagination :numberOfPages="projects.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetProjects" />

        <not-found-component v-if="projects.Count === 0" :text="'Projects not found'"></not-found-component>
      </div>
    </div>
  </div>

  <AddProjectModal :employeesProps="employeeNames" @createProjectEmit="CreateProject" @getEmployeesEmit="GetShortEmployeesDetails" ref="modalProjectCreate" />
  <EditProjectModal @getEmployeesEmit="GetShortEmployeesDetails" @editProjectEmit="EditProject" :employeesProps="employeeNames" :projectDetails="projectDetails" />
  <ProjectMembersModal :selectedProjectProps="selectedProjectDetails" :projectMembersProps="projectMembers" :unassignedProjectMembersProps="unassignedProjectMembers" :filterMembers="filterMembers" :filterUnassignedMembers="filterUnassignedMembers" @addProjectMemberEmit="AddProjectMember" @getProjectMembersEmit="GetProjectMembers" @getUnassignedProjectMembersEmit="GetUnassignedProjectMembers" @deleteMemberEmit="DeleteMember" ref="projectMembersRef" />
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import Search from "@/components/Common/Search.vue";
import Pagination from "@/components/Common/Pagination.vue";

import AddProjectModal from "@/components/Modals/Project/AddNewProjectModal.vue";
import EditProjectModal from "@/components/Modals/Project/EditProjectModal.vue";
import ProjectMembersModal from "@/components/Modals/Project/MembersModal.vue";
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";

export default {
  name: "ProjectsView",
  components: {
    Search,
    Pagination,
    AddProjectModal,
    EditProjectModal,
    ProjectMembersModal,
    NotFoundComponent,
  },
  emits: [
    "createProjectEmit",
    "editProjectEmit",
    "getEmployeesEmit",
    "getProjectMembersEmit",
    "getUnassignedProjectMembersEmit",
    "addProjectMemberEmit",
    "deleteMemberEmit",
  ],
  data() {
    return {
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
        Customer: null,
        CustomerId: 0,
      },
      customers: [],

      projects: [],
      projectDetails: {},
      employeeNames: [],
      projectMembers: [],
      unassignedProjectMembers: [],
      selectedProjectDetails: {},
      filterMembers: {
        SearchText: null,
        SearchLoader: false,
      },
      filterUnassignedMembers: {
        SearchText: null,
        SearchLoader: false,
      },
    };
  },

  methods: {
    SelectCustomerForFilter(customer) {
      this.filter.Customer = null;
      this.filter.CustomerId = 0;
      if (customer) {
        this.filter.Customer = customer;
        this.filter.CustomerId = customer.CustomerId;
      }

      this.GetProjects();
    },

    GetCustomers: function (searchText) {
      if (searchText == null) {
        searchText = "";
      }
      this.$axios
        .get("api/Customer/getCustomersForFilter?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.customers = response.data;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },

    OpenProjectMembersModal: async function (project) {
      this.selectedProjectDetails = JSON.parse(JSON.stringify(project));
      this.$refs.projectMembersRef.ClearModal();
      await this.GetProjectMembers();
      await this.GetUnassignedProjectMembers();
      $("#membersProject-modal").modal("show");
    },
    OpenEditProjectModal: async function (project) {
      this.GetProject(project.ProjectId);
      await this.GetShortEmployeesDetails();
    },
    OpenCreateProjectModal: function () {
      this.GetShortEmployeesDetails();
      this.$refs.modalProjectCreate.ClearModal();
    },
    GetShortEmployeesDetails: async function (searchText) {
      if (!searchText) {
        searchText = "";
      }
      await this.$axios
        .get("api/Employee/getShortEmployeesDetails?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.employeeNames = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },

    DeleteProject: async function (id) {
      var self = this;
      await this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to delete this project?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            self.$axios
              .put("api/Project/deleteProject?projectId=" + id, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then((response) => {
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: response.data.message,
                });
                self.GetProjects();
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data.message,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    DeleteMember: async function (id) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .delete("api/Project/deleteMember?memberId=" + id, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data.message,
          });
          this.GetProjectMembers();
          this.GetUnassignedProjectMembers();
          this.GetProjects();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    EditProject: async function (projectDetails) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .put("api/Project/updateProject", projectDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          // this.GetCustomers();
          this.GetProjects();
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data.message,
          });
          $("#edit-project-modal").modal("hide");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    ProjectsOrderBy: function (orderBy) {
      if (!this.filter.OrderBy.includes("_desc")) {
        this.filter.OrderBy = orderBy + "_desc";
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.GetProjects();
    },

    GetProjects: async function (page) {
      this.filter.PageNumber = 1;

      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }

      if (page) {
        this.filter.PageNumber = page;
        window.scrollTo(0, 0);
      }

      var query = new URLSearchParams({
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
        customerId: this.filter.CustomerId,
      });

      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Project/GetPagedProjects?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.projects = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetProject: async function (projectId) {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get("api/Project/GetProjectForEdit?projectId=" + projectId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.projectDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetProjectMembers: async function () {
      this.filterMembers.SearchLoader = true;
      if (this.filterMembers.SearchText == null) {
        this.filterMembers.SearchText = "";
      }
      var query = new URLSearchParams({
        projectId: this.selectedProjectDetails.ProjectId,
        searchText: this.filterMembers.SearchText,
      });
      await this.$axios
        .get("api/Project/getProjectMembers?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.projectMembers = response.data;
          this.filterMembers.SearchLoader = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          this.searchLoader = false;
        });
    },

    GetUnassignedProjectMembers: async function () {
      this.filterUnassignedMembers.SearchLoader = true;

      if (this.filterUnassignedMembers.SearchText == null) {
        this.filterUnassignedMembers.SearchText = "";
      }
      var query = new URLSearchParams({
        projectId: this.selectedProjectDetails.ProjectId,
        searchText: this.filterUnassignedMembers.SearchText,
      });
      await this.$axios
        .get("api/Project/getUnassignedProjectMembers?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.unassignedProjectMembers = response.data;
          this.filterUnassignedMembers.SearchLoader = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          this.searchLoader = false;
        });
    },

    CreateProject: async function (projectDetails) {
      store.dispatch("changeLoaderStatus", true);
      // projectDetails.CustomerId = this.selectedCustomerProjects.CustomerId;
      await this.$axios
        .post("api/Project/createProject", projectDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.GetProjects();
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data.message,
          });
          $("#create-project-modal").modal("hide");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    AddProjectMember: async function (memberAdded) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .post("api/Project/addProjectMember", memberAdded, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.GetUnassignedProjectMembers();
          this.GetProjectMembers();
          this.GetProjects();
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },

  created() {
    if (this.$route.params.customerId) {
      this.filter.Customer = {
        CustomerId: this.$route.params.customerId,
        Name: this.$route.params.cutomerName,
      };
      this.filter.CustomerId = this.$route.params.customerId;
      this.$router.replace({ cutomerName: undefined, customerId: undefined });
    }
    this.GetProjects();
  },
};
</script>
