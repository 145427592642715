import {
  createStore
} from "vuex";
import auth from "@/utils/auth.js";
import axiosConfig from "@/main";
import {
  backendURL
} from "@/main";

const store = createStore({
  state: {
    user: {
      UserRole: null,
      UserName: null,
      UserId: null,
    },
    employeeFunction: null,
    loader: false,
  },
  getters: {
    getUser: (state) => state.user,
    isTeamLeaderOrPM: (state) => state.employeeFunction,
    isLoading: (state) => state.loader,
  },
  actions: {
    changeLoaderStatus({
      commit
    }, status) {
      commit("SET_LOADER", status);
    },
    toastNotify({
      commit
    }, {
      Type,
      Title,
      Text
    }) {
      if (Type == 'error') {
        Title = 'Something went wrong!';
        Text = 'Please contact us about this event.';
      }

      // eslint-disable-next-line no-undef
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        icon: Type,
        title: Title,
        text: Text
      });
    },
    async getUserRoleAndFunction() {
      await this.dispatch("fetchUsers");
      //await this.dispatch("EmployeeIsTLorPM");
    },
    async fetchUsers({
      commit
    }) {
      await axiosConfig
        .get("api/Employee/user-details", {
          baseURL: backendURL,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            tenant: "1",
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            commit("SET_USERS", response.data);
          }
        })
        .catch((error) => {});
    },

    // async EmployeeIsTLorPM({ commit }) {
    //   await axiosConfig
    //     .get("api/Employee/employeeIsTLorPM", {
    //       baseURL: backendURL,
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         tenant: "1",
    //         Authorization: "Bearer " + auth.getJwtToken().Value,
    //       },
    //     })
    //     .then((response) => {
    //       if (response.status == 200) {
    //         commit("SET_EMPLOYEE_FUNCTION", response.data);
    //       }
    //     })
    //     .catch((error) => {});
    // },

    removeLoggedUser({
      commit
    }) {
      commit("SET_USERS", null);
    },
  },
  mutations: {
    SET_USERS(state, user) {
      state.user = user;
    },
    SET_EMPLOYEE_FUNCTION(state, functionE) {
      state.employeeFunction = functionE;
    },
    SET_LOADER(state, loader) {
      state.loader = loader;
    },
  },
});

export default store;