<template>
  <div class="modal fade" id="create-customer-modal" tabindex="-1" aria-labelledby="createCustomerModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="CreateCustomer" ref="createCustomerForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title" id="team-preview-modal-label">New customer</h2>
              </div>
              <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            </div>
          </div>

          <div class="modal-body">

            <div class="row">

              <div class="col-7">
                <div class="form-group">
                  <label class="form-label"> <span class="required">Name </span> </label>
                  <Field type="text" name="customerName" class="form-control" placeholder="Customer name" v-model="customerDetails.Name" rules="required|min:3" />
                  <div v-if="errors.customerName" class="fv-plugins-message-container invalid-feedback">Name requires minimum 3 characters.</div>
                </div>
                <div class="form-group">
                  <label class="form-label"> Description </label>
                  <Field name="description" v-slot="{ field }" rules="max: 50">
                    <textarea v-bind="field" rows="3" type="text" class="form-control" v-model="customerDetails.Description" placeholder="Enter the description..." />
                  </Field>
                  <div v-if="errors.description" class="fv-plugins-message-container invalid-feedback">Description maximum 50 characters.</div>

                </div>
              </div>
              <div class="col text-center">
                <img v-if="urlFile" :src="urlFile" class="mw-100px mh-100px d-block m-auto  mt-10">
                <img v-else :src="require('@/assets/images/evaluation-icons/active.png')" class="mw-100px mh-100px d-block m-auto  mt-10">

                <input class="d-none" type="file" id="new-customer-image" accept=".png, .jpg, .jpeg" @change="onFileChange" />

                <label for="new-customer-image" class="btn btn-gradient-success mt-10">
                  Change image
                </label>
              </div>
            </div>

          </div>

          <div class="modal-footer flex-stack ">
            <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-gradient-success">Save </button>
          </div>
        </Form>

      </div>
    </div>
  </div>
</template>

<script>
import { defineRule } from "vee-validate";
import { required, min } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);
defineRule("min", min);

export default {
  name: "AddCustomer",
  props: {},
  components: {
    Field,
    Form,
  },
  emits: ["createCustomerEmit"],
  data() {
    return {
      customerDetails: {
        Name: "",
        Description: "",
        Logo64: null,
      },
      urlFile: null,
    };
  },
  methods: {
    onFileChange: function (e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.urlFile = rawImg;
      };
      reader.readAsDataURL(file);
    },
    RemoveImage: function () {
      this.urlFile = null;
    },
    ClearModal: function () {
      this.urlFile = null;
      (this.customerDetails = {
        Name: "",
        Description: "",
        Logo64: null,
      }),
        this.$refs.createCustomerForm.resetForm();
    },
    CreateCustomer: async function () {
      this.customerDetails.Logo64 = this.urlFile;
      this.$emit("createCustomerEmit", this.customerDetails);
    },
  },
  created() {},
};
</script>
