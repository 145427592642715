<template>
  <div v-bind:key="index" v-for="(category, index) in evaluationContent">
    <div class="row align-items-center mb-3">
      <div class="col-11">
        <h3>
          {{ category.Name }}
        </h3>
        <small> {{ category.Description }} </small>
      </div>
    </div>
    <div class="ps-11 mt-5" v-show="!draggingProp.CategoryDragging">
      <draggable class="dragArea" id="questionsDragZone" v-bind:class="{ questionsDragZone: draggingProp.QuestionDragging }" tag="transition-group" :component-data="{ tag: 'div', type: 'transition-group', name: !draggingProp.QuestionDragging ? 'flip-list' : null }" :move="CheckMove" v-model="category.Questions" v-bind="DragOptions" @start="draggingProp.QuestionDragging = true" @end="draggingProp.QuestionDragging = false" item-key="order" group="questions" handle=".fa-grip-vertical">
        <template #item="{ element: question }">
          <div class="row mb-6">
            <div class="col-auto vertical-align-center">
              <i class="fa-2x fa-grip-vertical fal c-grab"></i>
            </div>
            <div class="col-md-10 col-xxl-11">
              <div class="question-card row align-items-center">
                <h4>
                  {{ question.Name }}
                </h4>
                <small> {{ question.Description }}</small>
              </div>
            </div>
            <div class="col-auto p-0" v-if="question.IsExtra">
              <div class="question-card-actions vertical-align-center">
                <div class="w-100">
                  <i class="fa-light fa-pen-to-square pointer d-block" data-bs-toggle="modal" data-bs-target="#edit-question-modal-extra" @click="SelectQuestion(question, category.Questions)"></i>
                  <hr />
                  <i class="fa-light fa-trash-can pointer d-block text-danger" @click="RemoveItemFromList(category.Questions, question)"></i>
                </div>
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <hr class="mt-10 mb-10" />
  </div>
  <edit-question-modal-component :modalId="'-extra'" :questionForEdit="selectedQuestion" @editQuestionsEmit="EditQuestion"></edit-question-modal-component>
</template>
<script>
import draggable from "vuedraggable";
import EditQuestionModalComponent from "@/components/Modals/Question/EditQuestionModalComponent.vue";

export default {
  Name: "evaluation-editable-content-component",
  emits: ["RemoveItem"],
  components: {
    draggable,
    EditQuestionModalComponent,
  },

  props: ["evaluationContent", "draggingProp"],

  data() {
    return {
      selectedQuestion: {},
      questionsList: [],
    };
  },

  methods: {
    CheckMove: function (e) {
      if (e.to.id != "questionsDragZone") {
        return false;
      }
      return true;
    },
    SelectQuestion(question, questionsList) {
      this.selectedQuestion = JSON.parse(JSON.stringify(question));
      this.questionsList = questionsList;
    },
    RemoveItemFromList(list, item) {
      list.splice(list.indexOf(item), 1);
      this.$emit("RemoveItem");
    },
    EditQuestion() {
      var index = this.questionsList
        .map(function (x) {
          return x.Id;
        })
        .indexOf(this.selectedQuestion.Id);
      this.questionsList.splice(index, 1, this.selectedQuestion);

      $("#edit-question-modal-extra").modal("hide");
    },
    computed: {
      DragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost",
        };
      },
    },
  },
};
</script>

<style scoped>
.questionsDragZone {
  position: relative;
  background: linear-gradient(
    87.6deg,
    hsl(171deg 50% 54% / 20%) 0%,
    rgb(32 155 138 / 20%) 100%
  );
  min-height: 100px;
  border-radius: 5px;
  border: 1px dashed #4cc3b2;
  padding: 10px 10px 10px 10px;
}

.categoriesDragZone {
  position: relative;
  background: linear-gradient(
    87.6deg,
    hsl(185deg 72% 34% / 10%) 0%,
    hsl(185deg 100% 22% / 10%) 100%
  );
  min-height: 100px;
  border-radius: 5px;
  border: 1px dashed #4cc3b2;
  padding: 10px 10px 10px 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

h1 {
  font-size: 30px;
  color: #188a94;
}
.question-card {
  background-color: #f5f8fa;
  border-radius: 0.475rem;
  padding: 15px 10px;
  border: 1px solid #ddd;
}
.c-grab {
  cursor: grab;
}

.question-card-actions {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: none;
  -o-border-image: initial;
  border-image: initial;
  text-align: center;
  border-radius: 0px 5px 5px 0px;
  padding: 0px 10px 0px 13px;
  position: relative;
  left: -3px;
  height: 100%;
  width: 50px;
}

.question-card-actions i {
  font-size: 17px;
}

.question-card-actions hr {
  margin-top: 12px;
  margin-bottom: 12px;
}

.empty-eval-content {
  position: relative;
  background: #4bc2b136;
  min-height: 200px;
  border-radius: 5px;
  border: 1px dashed #4cc3b2;
  color: #4cc3b2;
}

.empty-eval-content h1 {
  color: #16899394;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 40%;
}

.empty-eval-content .dragArea {
  height: 200px;
}

.new-card-object {
  border: 1px solid #e4e6ef;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: grab;
  background: white;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.vertical-align-center {
  display: flex;
  align-items: center;
}
</style>
