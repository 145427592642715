<template>
  <div class="question-score-list active-list">
    <div class="question-score" v-bind:key="index" v-for="index in question.MaxScore" v-on:click="SelectScore(question,index)" v-bind:class="{'active':index == question.Grade}">{{ index }}</div>
  </div>
</template>

<script>
export default {
  Name: "active-score-component",
  props: {
    question: { type: Object, default: new Object() },
  },
  emits: ["SelectScore"],

  data() {
    return {};
  },
  methods: {
    SelectScore: function (question, score) {
      question.Grade = score;
      this.$emit("SelectScore", score);
    },
  },
};
</script>