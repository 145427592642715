<template>
  <div class="position-relative h-100">
    <div class="card-shadow-1"></div>
    <div class="card-shadow-2"></div>
    <div class="card h-100 shadow-hover shadow-sm">
      <div class="card-body">
        <div class="mb-10">
          <!-- <h4 class="fw-normal eval-title">
            <span v-bind:key="index" v-for="(item, index) in evaluation.EvaluationFor">
              {{item}}<span v-if="evaluation.EvaluationFor.length>1 && evaluation.EvaluationFor.length != index+1">, </span>
            </span>
            <span v-if="evaluation.EvaluationFor.length>0" class="text-green m-2">-</span>
            <span class="fw-normal text-muted fs-15"> {{ evaluation.Name }} </span>
          </h4> -->
          <h4 class="fw-normal eval-title">
            <span class="text-green me-2">#{{ evaluation.GroupId }} </span>
            <span class="fw-normal text-muted fs-15">- {{ evaluation.Name }} </span>
          </h4>
          <hr />
          <div class="eval-description">
            <small v-if="!evaluation.Description">
              {{ ScopeDescription(evaluation) }}
            </small>
            <small>
              {{ evaluation.Description }}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-auto w-40px">
                <i class="fa fa-bullseye fs-18px text-green"></i>
              </div>
              <div class="col" :title="evaluation.EvaluationFor">
                <div class="dropdown">
                  <span type="button" :id="'for-dropdown'+index" data-bs-toggle="dropdown" aria-expanded="false">
                    For : <span v-bind:key="index" v-for="(item, index) in evaluation.EvaluationFor?.slice(0, 4) " class="fw-bold">
                      {{ item }}
                      <span class="text-green" v-if="evaluation.EvaluationFor?.length > 1 && evaluation.EvaluationFor?.length != index + 1"> | </span>
                    </span>
                    <span v-if="evaluation.EvaluationFor?.length>4"> + {{ evaluation.EvaluationFor?.length-4 }}</span>
                  </span>
                  <ul class="dropdown-menu mh-200px overflow-auto p-0" :aria-labelledby="'for-dropdown'+index">
                    <li>
                      <span class="bg-gray-300 border-bottom dropdown-item fw-bold small">Group of {{evaluation.EvaluationFor?.length}} evaluations</span>
                    </li>
                    <li v-bind:key="index" v-for="(item, index) in evaluation.EvaluationFor">
                      <span class="dropdown-item" href="#">{{ item }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-auto w-40px">
                <i class="fa-duotone fa-user-group fs-18px text-green"></i>
              </div>
              <div class="col">
                <!-- Participants : -->
                <span class="fw-bold">{{ evaluation.NumberOfEval }} </span> team participants
                <!-- <span v-if="[2,4,7].includes(evaluation.ScopeId)"> members</span> -->
              </div>
            </div>
          </div>
          <div class="col-auto">
            <img v-if="evaluation.Scope" :src="require('@/assets/images/scopes-icons/' + evaluation.Scope + '.png')" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-auto w-40px">
            <i class="fa-duotone fa-calendar-days fs-18px text-green"></i>
          </div>
          <div class="col">
            Period :
            <span v-if="evaluation.StartDate" class="fw-bold">{{ $moment(evaluation.StartDate).format("DD.MM.YYYY") }}</span>
            <span class="text-green"> - </span>
            <span v-if="evaluation.EndDate" class="fw-bold">{{ $moment(evaluation.EndDate).format("DD.MM.YYYY") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  Name: "eval-group-card-component",
  props: {
    evaluation: { type: Object, default: new Object() },
  },

  data() {
    return {};
  },
  methods: {
    ScopeDescription: function (evaluation) {
      switch (evaluation.ScopeId) {
        case 0:
          return "All active evaluations";
        case 1:
          return "Every user need to answer to this kind of evaluation";
        case 2:
          return "Team leader will evaluate his team members";
        case 3:
          return "Team leader will be evaluated by team members";
        case 4:
          return "Project manager will evaluate his project members";
        case 5:
          return "Project manager will be evaluated by project members";
        case 6:
          return "Group leaders will evaluate group members";
        case 7:
          return "Group leaders will be evaluated by group members";
        case 8:
          return "Every member from a group will evaluate every member of that group";
        case 99999:
          return "This evaluations are not assigned to any scope";
      }
    },
  },
};
</script>

<style scoped>
.card-shadow-1 {
  height: 100%;
  width: 100%;
  background: #fff;
  position: absolute;
  z-index: 1;
  left: 5px;
  top: 5px;
  /* border-radius: 10px; */
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;
  border-radius: 0.475rem;
}

.card-shadow-2 {
  height: 100%;
  width: 100%;
  background: #fff;
  position: absolute;
  z-index: 0;
  left: 10px;
  top: 12px;
  border-radius: 0.475rem;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}
.card {
  position: relative;
  z-index: 5;
  height: 100%;
}
.card .h1 {
  min-height: auto !important;
  margin-bottom: 5px !important;
}

img {
  max-width: 100px;
}

.card #add-evaluationTemp-modal {
  min-height: 549px;
}

a {
  color: #181c32;
}
</style>
