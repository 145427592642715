<template>

  <div class="align-items-center row pt-3 pb-3 m-0">
    <div class="col">
      <div class="d-flex align-items-top"><i class="fa-3x fa-square-question fa-duotone text-green me-3"></i>
        <div>
          <h4 class="m-0"> {{ question.Name }}</h4>
          <small>{{ question.Description }}</small>

        </div>
      </div>
    </div>

    <div v-if="question.Type" class="col-3">
      <h6 class="m-0">{{question.Type }}</h6>
      <small> Question type</small>
    </div>

    <div class="col-auto">
      <input :id="'import-question-' + question.Id" class="d-none" type="checkbox" name="users" v-on:click="$emit('SelectQuestion',question)" v-model="question.Checked" />

      <label :for="'import-question-' + question.Id" class="pointer">
        <i v-if="question.Checked" class="fa-solid fa-square-check fa-2x text-green"></i>
        <i v-else class="fa-duotone fa-square fa-2x"></i>
      </label>
    </div>
  </div>
</template>

<script>
import { defineRule } from "vee-validate";
import { required, min } from "@vee-validate/rules";

defineRule("required", required);
defineRule("min", min);

export default {
  name: "ImportQuestionComponent",
  props: ["question"],

  data() {
    return {};
  },
  emits: "SelectQuestion",

  methods: {},
  created() {},
};
</script>
