<template>
  <div class="page-tabs">
    <div class="row border-bottom">
      <div class="col-auto" v-bind:class="{'active':page == 'Active'}">
        <h3>
          <router-link :to="{ name: 'ActiveEvaluationsView'}">
            Active
          </router-link>
        </h3>
      </div>
      <div class="col-auto" v-bind:class="{'active':page == 'History'}">
        <h3>
          <router-link :to="{ name: 'HistoryEvaluationsView'}">
            History
          </router-link>
        </h3>
      </div>
      <div class="col-auto" v-bind:class="{'active':page == 'Draft'}">
        <h3>
          <router-link :to="{ name: 'DraftEvaluationsView'}">
            Draft
          </router-link>
        </h3>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  Name: "evaluations-stage-nav-component",
  props: {
    page: { type: String, default: "" },
  },
};
</script>