<template>
  <div class="container">
    <nav aria-label="breadcrumb" class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item active"><span class="active" href="#">Customers</span></li>
      </ol>
    </nav>
    <h1 class="page-title">Customers</h1>
    <div class="row mb-10 align-items-end">
      <div class="col">
        <Search @searchForItems="GetCustomers" :placeholderText="'Search for customers'" :searchText="filter" />
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-gradient-success" v-on:click="GetCustomers()"><i class="fa-search far text-white"></i> Search</button>
      </div>
      <div class="col"></div>
      <div class="col text-end">
        <button class="btn btn-gradient-success" data-bs-toggle="modal" data-bs-target="#create-customer-modal" @click="this.$refs.modalCreate.ClearModal()">+ New customer</button>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-row-dashed">
            <thead class="table-header">
              <tr>
                <th>
                  <span class="pointer text-dark-green" v-on:click="OrderBy('Name')">
                    Name
                    <span v-if="filter.OrderBy != 'Name_desc'">
                      <i class="fa-solid fa-arrow-up-short-wide"></i>
                    </span>
                    <span v-if="filter.OrderBy === 'Name_desc'">
                      <i class="fa-solid fa-arrow-down-short-wide"></i>
                    </span>
                  </span>
                </th>
                <th>Number of projects</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in customers.Items" v-bind:key="index">
                <td>
                  <div class="d-flex align-items-center">
                    <div v-if="item.Image">
                      <img width="40" :src="GetImgUrl(item.Image)" />
                    </div>
                    <div v-else class="h-40px w-40px rounded-circle bg-gray-100 text-center">
                      <h4 class="fw-bold mt-3 text-gray-700">
                        {{ item.Name.split()[0][0] }}<span v-if="item.Name.split(' ')[1]">{{ item.Name.split(" ")[1][0] }}</span>
                      </h4>
                    </div>

                    <div class="ms-5">
                      <h6 class="m-0">{{ item.Name }}</h6>
                      <small class="d-block">{{ item.Description }}</small>
                    </div>
                  </div>
                </td>
                <td><i class="fa-light fa-cubes text-green me-2 fs"></i> {{ item.NumberOfProjects }} project<span v-if="item.NumberOfProjects != 1">s</span></td>
                <td class="text-end">
                  <router-link :to="{ name: 'ProjectsView', params: { customerId: item.CustomerId, cutomerName: item.Name } }">
                    <button type="button" class="btn btn-info-alternative me-3">Projects</button>
                  </router-link>

                  <button type="button" data-bs-toggle="modal" data-bs-target="#edit-customer-modal" @click="OpenEditModal(item)" class="btn btn-secondary-alternative me-3">
                    <i class="fa-solid fa-pen"></i> Edit
                  </button>
                  <button type="button" class="btn btn-danger-alternative" @click="DeleteCustomer(item.CustomerId)"><i class="far fa-trash-can"></i> Delete</button>
                </td>
              </tr>
            </tbody>
          </table>

          <not-found-component v-if="customers.Count == 0" :text="'Customers not found!'"></not-found-component>

          <Pagination :numberOfPages="customers.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetCustomers" />
        </div>
      </div>
    </div>
  </div>

  <AddCustomerModal @createCustomerEmit="CreateCustomer" ref="modalCreate" />
  <EditCustomer @editCustomerEmit="EditCustomer" :customerDetails="selectedCustomer" />
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import Search from "@/components/Common/Search.vue";
import Pagination from "@/components/Common/Pagination.vue";
import AddCustomerModal from "@/components/Modals/Customer/AddCustomerModal.vue";
import EditCustomer from "@/components/Modals/Customer/EditCustomerModal.vue";
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";

export default {
  name: "CustomersView",
  components: {
    Search,
    Pagination,
    AddCustomerModal,
    EditCustomer,
    NotFoundComponent,
  },

  data() {
    return {
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
      },

      customers: {},
      selectedCustomer: {},
      employeeNames: [],
      filterMembers: {
        SearchText: null,
        SearchLoader: false,
      },
      filterUnassignedMembers: {
        SearchText: null,
        SearchLoader: false,
      },
    };
  },

  methods: {
    DeleteCustomer: async function (id) {
      var self = this;
      await this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to delete this customer?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            self.$axios
              .put("api/Customer/deleteCustomer?customerId=" + id, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then((response) => {
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: response.data.message,
                });
                self.GetCustomers();
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data.message,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },

    OpenEditModal: function (item) {
      this.selectedCustomer = JSON.parse(JSON.stringify(item));
      if (this.selectedCustomer.Image) {
        this.selectedCustomer.urlFile = "data:image/jpeg;base64," + this.selectedCustomer.Image;
      }
    },

    GetImgUrl(image) {
      return "data:image/jpeg;base64," + image;
    },

    EditCustomer: async function (customerDetails) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .put("api/Customer/updateCustomer", customerDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.GetCustomers();
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data.message,
          });
          $("#edit-customer-modal").modal("hide");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    OrderBy: function (orderBy) {
      if (!this.filter.OrderBy.includes("_desc")) {
        this.filter.OrderBy = orderBy + "_desc";
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.GetCustomers();
    },

    GetCustomers: async function (page) {
      store.dispatch("changeLoaderStatus", true);
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      this.filter.PageNumber = 1;

      if (page) {
        this.filter.PageNumber = page;
        window.scrollTo(0, 0);
      }
      var query = new URLSearchParams({
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
      });
      await this.$axios
        .get("api/Customer/getPagedCustomers?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.customers = response.data;

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    CreateCustomer: async function (customerDetails) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .post("api/Customer/createCustomer", customerDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.GetCustomers();
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data.message,
          });
          $("#create-customer-modal").modal("hide");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },

  created() {
    this.GetCustomers();
  },
};
</script>
