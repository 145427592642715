import {
  defineRule
} from "vee-validate";
import {
  required,
  email,
  min
} from "@vee-validate/rules";
import {
  Field,
  Form,
  ErrorMessage
} from "vee-validate";
defineRule("required", required);
defineRule("email", email);
defineRule("min", min);




defineRule("start_date_rule", (value, [start]) => {
  if (start) {
    if (moment().diff(start, "days") > 0) {
      return `Start date must be greater than ${moment(start).format("DD-MM-YYYY")}`;
    }
  }
  if (!start) return "Start date is required.";
  return true;
});

defineRule("end_date_rule", (value, [start, end]) => {
  if (start && end) {
    if (moment(start).diff(moment(end), "d") > 0) {
      return `End date must be greater than ${moment(start).format("DD-MM-YYYY")}`;
    }
  }

  if (moment(moment(Date(Date.now())).format("DD-MM-YYYY")).diff(moment(end), "d") > 0) {
    return `End date must be greater than curent day`;
  }
  if (!end) return "End date is required. ";
  return true;
});


// defineRule("max_date", (value, [end]) => {
//   console.log(moment().diff(end, "days"))
//   if (!end) return "Start date is required.";

//   if (end) {
//     if (moment().diff(end, "days") > 0) {
//       return `Date must be before  ${moment(end).format("DD-MM-YYYY")}`;
//     }
//   }
//   return true;
// });


// defineRule("date_between", (value, [start, end]) => {
//   if (!start) {
//     return 'Start date is required';
//   }

//   if (!end) {
//     return 'End date is required';
//   }

//   if (start && end) {
//     if (moment(start).diff(moment(end), "d") > 0) {
//       return `mai mare ca 0 ${moment(start).format("DD-MM-YYYY")}`;
//     }
//   }

//   return true;
// });

defineRule("max_date", (value, [start, end]) => {
  if (!start) {
    return 'Start date is required';
  }

  if (!end) {
    return 'End date is required';
  }

  if (start && end) {
    if (moment(start).diff(moment(end), "h") > 1) {
      return `Date must be before ${moment(end).format("DD-MM-YYYY")}.`;
    }
  }

  return true;
});


defineRule("min_date", (value, [start, end]) => {
  if (!start) {
    return 'Start date is required';
  }

  if (!end) {
    return 'End date is required';
  }

  if (start && end) {
    if (moment(start).diff(moment(end), "d") > 0) {
      return `Date must be after ${moment(start).format("DD-MM-YYYY")}.`;
    }
  }

  return true;
});