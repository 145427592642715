<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Templates</a></li>
        <li class="breadcrumb-item" aria-current="page"><a href="#" class="active" @click="$router.go(-1)">Questions</a></li>
      </ol>
    </nav>
    <h1 class="page-title">Questions</h1>
    <div class="row align-items-end mb-8">
      <div class="col-3">
        <search @searchForItems="GetPagedQuestionTemplates(1)" :searchText="filter" :placeholderText="'Search for questions'" />
      </div>
      <div class="col-3">
        <label class="fw-bold mb-4">Type filter</label>
        <VueMultiselect select-label="" v-model="filter.Type" :options="types" placeholder="Select question type" label="Name" track-by="Id" :internalSearch="true" @remove="GetPagedQuestionsForMultiselect(null)" @select="GetPagedQuestionsForMultiselect" />
      </div>
      <div class="col"></div>
      <div class="col-auto">
        <button type="button" class="btn btn-gradient-success" data-bs-toggle="modal" data-bs-target="#create-question-modal" ref="createQuestionModalRef" @click="OpenCreateQuestionModal()">
          + New question
        </button>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <table class="table table-row-bordered">
          <thead class="table-header">
            <tr>
              <th v-bind:class="{ 'text-dark-green': filter.OrderBy == 'Title' || filter.OrderBy == 'Title_desc' }">
                <span class="pointer" v-on:click="OrderBy('Title')">
                  Title
                  <span v-show="filter.OrderBy === 'Title'">
                    <i class="fa-solid fa-arrow-up-short-wide text-dark-green"></i>
                  </span>
                  <span v-show="filter.OrderBy === 'Title_desc'">
                    <i class="fa-solid fa-arrow-down-short-wide text-dark-green"></i>
                  </span>
                </span>
              </th>
              <th>Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(question, indexQuestion) in questions.Items" v-bind:key="indexQuestion">
              <question-component :question="question" @deleteQuestionEmit="DeleteQuestion" @getQuestionByIdEmit="GetQuestionById" />
            </tr>
          </tbody>
        </table>

        <div v-if="questions.Count == 0" class="card-body pb-0 pt-5">
          <img alt="No content found" class="d-block me-auto ms-auto" :src="require('@/assets/images/no-content-found.png')" />
        </div>
      </div>
      <div class="card-footer">
        <pagination :numberOfPages="questions.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetPagedQuestionTemplates" />
      </div>
    </div>

    <add-question-modal :newQuestion="newQuestion" :types="typesForAdd" @createQuestionsEmit="CreateQuestion" ref="addQuestionModal" />
    <edit-question-modal :questionProps="questionDetails" :types="types" @editQuestionsEmit="EditQuestion" ref="editQuestionModal" />
  </div>
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";

import Search from "@/components/Common/Search.vue";
import CategoryListComponent from "@/components/Categories/CategoryComponent.vue";
import AddQuestionModal from "@/components/Modals/Question/CreateTemplateQuestion.vue";
import EditQuestionModal from "@/components/Modals/Question/EditQuestionModal.vue";
import QuestionComponent from "@/components/Questions/QuestionWithDeleteAndEditBtn.vue";
import Pagination from "@/components/Common/Pagination.vue";
export default {
  name: "QuestionsTemplatesView",
  components: {
    Search,
    CategoryListComponent,
    AddQuestionModal,
    EditQuestionModal,
    QuestionComponent,
    Pagination,
  },
  emits: [],
  data() {
    return {
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Title",
        Type: null,
      },
      questions: [],
      selectedQuestion: {},
      types: [],
      newQuestion: {},
      questionDetails: [],
      typesForAdd: [],
    };
  },

  methods: {
    OpenCreateQuestionModal: function () {
      this.$refs.addQuestionModal.ClearCreateQuestionModal();
      this.GetTypesForAdd();
    },
    OrderBy: function (orderBy) {
      if (orderBy == this.filter.OrderBy) {
        this.filter.OrderBy = orderBy + "_desc";
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.GetPagedQuestionTemplates();
    },
    DeleteQuestion: async function (id) {
      const self = this;
      await this.$swal
        .fire({
          title: "Warning",
          text: "Are you sure you want to delete this question?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            await self.$axios
              .delete("api/QuestionsTemplates/" + id, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then((response) => {
                self.GetPagedQuestionTemplates();
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: "Question has been deleted",
                });
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data.message,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },
    CreateQuestion: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .post("/api/QuestionsTemplates/add?", this.newQuestion, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(() => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Question has been added",
          });
          $("#create-question-modal").modal("hide");
          this.GetPagedQuestionTemplates();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    EditQuestion: async function (question) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .put("/api/QuestionsTemplates/update?", question, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(() => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Question has been edited",
          });

          $("#edit-question-modal").modal("hide");
          this.GetPagedQuestionTemplates();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetTypes: async function () {
      await this.$axios
        .get("api/QuestionsTemplates/getAllQuestionsTypes", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.types = response.data;
        })
        .catch(() => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Question can not be found",
          });
        });
    },
    GetTypesForAdd: async function () {
      await this.$axios
        .get("api/QuestionsTemplates/getQuestionsTypes", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.typesForAdd = response.data;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Question can not be found",
          });
        });
    },
    SelectedQuestion: function (question) {
      this.selectedQuestion = question;
    },
    GetPagedQuestionsForMultiselect: function (val) {
      this.filter.Type = val;
      this.GetPagedQuestionTemplates(1);
    },
    GetPagedQuestionTemplates: async function (pageNumber) {
      store.dispatch("changeLoaderStatus", true);
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      if (pageNumber) {
        this.filter.PageNumber = pageNumber;
        window.scrollTo(0, 0);
      }
      var query = new URLSearchParams({
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
        typeId: this.filter.Type?.Id || 0,
      });
      await this.$axios
        .get("api/QuestionsTemplates/getPagedQuestions?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.questions = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Question can not be found",
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetQuestionById: async function (question) {
      await this.$axios
        .get("api/QuestionsTemplates/" + question.Id, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.questionDetails = response.data;
          $(this.$refs.editQuestionModal.$el).modal("show");
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: "Question can not be found",
          });
        });
    },
  },

  created() {
    this.GetPagedQuestionTemplates();
    this.GetTypes();
  },
};
</script>
