<template>
  <div class="card new-objects-card">
    <!-- Catalog -->
    <div v-if="!showCategoriesList && !showTypesQuestionsList">
      <div class="card-header">
        <h3><i class="fa-sharp fa-solid fa-book-open"></i> Catalog</h3>
      </div>
      <div class="card-body">
        <div v-on:click="ShowCategoriesList()" class="row bg-gradient-black-green new-evaluation-object align-items-center shadow-hover">
          <div class="col-auto">
            <i class="fa-sharp fa-solid fa-box-archive fa-2x"></i>
          </div>
          <div class="col">
            <h4>Add category</h4>
          </div>
          <div class="col-auto"><i class="fa-sharp fa-solid fa-caret-right"></i></div>
        </div>

        <div v-on:click="ShowQuestionsList()" class="row bg-gradient-light-green new-evaluation-object align-items-center shadow-hover">
          <div class="col-auto">
            <i class="fa-solid fa-message-question fa-2x"></i>
          </div>
          <div class="col">
            <h4>Add Question</h4>
          </div>
          <div class="col-auto"><i class="fa-sharp fa-solid fa-caret-right"></i></div>
        </div>

        <!-- <div class="row bg-gradient-light-orange new-evaluation-object align-items-center shadow-hover">
          <div class="col-auto">
            <i class="fa-solid fa-message-question fa-2x"></i>
          </div>
          <div class="col">
            <h4>Add Personal Objectives</h4>
          </div>
          <div class="col-auto"><i class="fa-sharp fa-solid fa-caret-right"></i></div>
        </div> -->
      </div>
    </div>
    <!--End Catalog -->

    <!-- Categories -->
    <div v-if="showCategoriesList">
      <div class="card-header" v-on:click="BackToCatalog()">
        <i class="fa-sharp fa-solid fa-caret-left pointer"></i>
        <h3 class="fw-normal">Catalog <span class="me-1">/</span></h3>
        <h3>Categories</h3>
      </div>

      <div class="card-body">
        <!-- Search -->
        <div class="input-group mb-5 bg-white">
          <span class="input-group-text"><i class="fa-light fa-magnifying-glass"></i></span>
          <input type="text" class="form-control" placeholder="Search for categories" v-model="searchText" v-on:keyup.enter="GetCategoriesTemplate" />
        </div>
        <!--End Search -->

        <!-- Categories list -->
        <draggable class="dragArea" tag="transition-group" :component-data="{ tag: 'div', type: 'transition-group', name: !draggingProp.CategoryDragging ? 'flip-list' : null }" v-model="categories" v-bind="DragOptions" @start="draggingProp.CategoryDragging = true" @end="CheckMoveCategory" item-key="order" :clone="CloneCategory" :group="{ name: 'categories', pull: true, put: false }">
          <template #item="{ element }">
            <div class="border card p-5 mb-3">
              <div class="row">
                <div class="col pb-2 pointer" :key="element.Id">
                  <h5>{{ element.Name }}</h5>
                  <small>{{ element.Description }}</small>
                </div>
                <i v-if="element.Questions.length > 0" class="fa-sharp fa-solid text-black col-auto pointer" :class="[element.IsExtend ? 'fa-caret-up' : 'fa-caret-down']" @click="element.IsExtend = !element.IsExtend" data-bs-toggle="collapse" :href="'#collapse-category-' + element.CategoryId"></i>
                <small class="col-auto" v-else>No questions</small>
                <div class="collapse p-7 pb-0 pt-0" :id="'collapse-category-' + element.CategoryId" v-for="(question, index) in element.Questions" v-bind:key="index">
                  <div class="question-card align-items-center">
                    <h5>{{ question.Name }}</h5>
                    <small>{{ question.Description }}</small>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </draggable>

        <div v-if="categories.length == 0" class="alert alert-white">
          <h4>No categories!</h4>
          <p>All categories are imported to this evaluation</p>
        </div>
        <!--End Categories list -->
      </div>
    </div>
    <!--End Categories -->

    <!-- Questions -->
    <div v-if="showTypesQuestionsList" class="position-relative h-100">
      <div class="card-header" v-on:click="BackToCatalog()">
        <i class="fa-sharp fa-solid fa-caret-left pointer"></i>
        <h3 class="fw-normal">Catalog <span class="me-1">/</span></h3>
        <h3>Questions</h3>
      </div>

      <div class="card-body">
        <!-- Search -->
        <div class="input-group mb-5 bg-white">
          <span class="input-group-text"><i class="fa-light fa-magnifying-glass"></i></span>
          <input type="text" class="form-control" placeholder="Search for questions" v-model="searchText" v-on:keyup.enter="GetQuestionsTemplateSearch" />
        </div>
      </div>

      <div class="row border-bottom ps-10 pe-10">
        <div class="col text-center cursor-pointer" :class="{ 'border-bottom-green': !showMyQuestionsList }" @click="ShowQuestionsList()">
          <h3 class="fw-light text-muted text-hover-dark-green" :class="{ 'text-dark-green fw-bold': !showMyQuestionsList }">Catalog Question</h3>
        </div>
        <div class="col text-center cursor-pointer" :class="{ 'border-bottom-green': showMyQuestionsList }" @click="ShowMyQuestionsList()">
          <h3 class="fw-light text-muted text-hover-dark-green" :class="{ 'text-dark-green fw-normal': showMyQuestionsList }">My questions</h3>
        </div>
      </div>

      <div class="card-body">
        <div class="overflow-scroll" style="max-height: 50vh">
          <div v-if="isSearch === false && showMyQuestionsList === false">
            <div v-bind:key="index" v-for="(questionType, index) in questionsTypes">
              <!--End Search -->
              <div class="row pointer mb-3 align-items-center pt-2 pb-2 m-0" @click="GetQuestionsTemplate(questionType)">
                <div class="col">
                  <h6 class="m-0">{{ questionType.Name }}</h6>
                </div>
                <div class="col-auto">
                  <i v-if="questionType.showQuestionsList" class="fa-sharp fa-solid fa-caret-up"></i>
                  <i v-else class="fa-sharp fa-solid fa-caret-down text-black"></i>
                </div>
              </div>

              <!-- Questions list -->
              <div class="collapse p-2" :id="'question-type' + questionType.Id">
                <draggable class="dragArea" tag="transition-group" :component-data="{ tag: 'div', type: 'transition-group', name: !draggingProp.QuestionDragging ? 'flip-list' : null }" v-model="questionType.QuestionsList" v-bind="DragOptions" :move="CheckMove" @start="draggingProp.QuestionDragging = true" @end="draggingProp.QuestionDragging = false" item-key="order" :clone="CloneQuestion" :group="{ name: 'questions', pull: 'clone', put: false }">
                  <template #item="{ element }">
                    <div class="new-card-object">
                      <h5>{{ element.Name }}</h5>
                      <small>{{ element.Description }}</small>
                    </div>
                  </template>
                </draggable>
              </div>
              <hr />
            </div>
          </div>
          <draggable v-if="isSearch === true || showMyQuestionsList === true" class="dragArea" tag="transition-group" :component-data="{ tag: 'div', type: 'transition-group', name: !draggingProp.QuestionDragging ? 'flip-list' : null }" v-model="questions" v-bind="DragOptions" :move="CheckMove" @start="draggingProp.QuestionDragging = true" @end="draggingProp.QuestionDragging = false" item-key="order" :clone="clone" :group="{ name: 'questions', pull: 'clone', put: false }">
            <template #item="{ element }">
              <div class="new-card-object">
                <h5>{{ element.Name }}</h5>
                <small>{{ element.Description }}</small>
              </div>
            </template>
          </draggable>
        </div>

        <!--End Questions list -->
      </div>
      <div class="card-footer">
        <button class="btn btn-success-dark pt-6 pb-6 new-question-modal-btn" data-bs-toggle="modal" data-bs-target="#create-question-modal" @click="ClearCreateQuestionModal">Create Question</button>
      </div>
    </div>
    <!--End Questions -->
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
  Name: "catalog-component",
  components: {
    draggable,
  },
  props: ["questionsTypes", "draggingProp", "categories", "questions"],
  // watch: {},
  emits: [
    "clearCreateQuestionModalEmit",
    "getTypesEmit",
    "getQuestionsTemplateEmit",
    "getCategoriesTemplateEmit",
    "getQuestionsTemplateSearchEmit",
    "getMyQuestionsTemplateEmit",
  ],
  data() {
    return {
      showCategoriesList: false,
      showTypesQuestionsList: false,
      searchText: null,
      isSearch: false,
      showMyQuestionsList: false,
      clonedCategory: {},
    };
  },

  methods: {
    ClearCreateQuestionModal: function () {
      this.$emit("clearCreateQuestionModalEmit");
    },

    GetMyQuestionsTemplate: function (searchText) {
      this.$emit("getMyQuestionsTemplateEmit", searchText);
    },

    GetCategoriesTemplate: function () {
      this.$emit("getCategoriesTemplateEmit", this.searchText);
    },

    GetQuestionsTemplate: function (type) {
      if (!type.showQuestionsList || type.showQuestionsList == false) {
        type.showQuestionsList = !type.showQuestionsList;
        this.$emit("getQuestionsTemplateEmit", type);
      } else {
        $("#question-type" + type.Id).collapse("hide");
        type.showQuestionsList = !type.showQuestionsList;
      }
    },

    GetQuestionsTemplateSearch: function () {
      if (this.showMyQuestionsList === true) {
        this.GetMyQuestionsTemplate(this.searchText);
        return;
      }
      if (this.searchText && this.searchText != "") {
        this.isSearch = true;
        this.$emit("getQuestionsTemplateSearchEmit", this.searchText);
      } else {
        this.isSearch = false;
      }
    },

    GetTypes: function () {
      this.$emit("getTypesEmit");
    },

    CloneCategory(category) {
      this.clonedCategory = {
        Questions: category.Questions,
        Name: category.Name,
        Description: category.Description,
        CategoryId: category.CategoryId,
        IsEditable: true,
      };
      return this.clonedCategory;
    },
    CheckMoveCategory: function (e) {
      const self = this;
      if (this.clonedCategory.Questions.length == 0) {
        self.draggingProp.CategoryDragging = false;
        return self.clonedCategory;
      }

      this.$swal
        .fire({
          title: "Import options",
          text:
            "Select how do you want to import this " +
            self.clonedCategory.Name +
            " category",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "With questions",
          cancelButtonText: "No questions",
        })
        .then(async function (result) {
          if (result.isConfirmed == true) {
            self.draggingProp.CategoryDragging = false;
            return self.clonedCategory;
          } else {
            self.clonedCategory.Questions = [];
            self.draggingProp.CategoryDragging = false;
            return self.clonedCategory;
          }
        });
    },
    // guid() {
    //   return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    //     (
    //       c ^
    //       (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    //     ).toString(16)
    //   );
    // },

    CloneQuestion(question) {
      return {
        Id: Math.floor(
          Math.random() * (2147483647 - 2000000000 + 1) + 2000000000
        ),
        IsExtra: true,
        Name: question.Name,
        Description: question.Description,
        QuestionTempId: question.QuestionTempId,
      };
    },

    RemoveItemFromList(list, item) {
      list.splice(list.indexOf(item), 1);
    },

    BackToCatalog() {
      this.showCategoriesList = false;
      this.showTypesQuestionsList = false;
    },

    ShowCategoriesList() {
      this.searchText = null;
      this.showCategoriesList = true;
      this.GetCategoriesTemplate();
    },

    ShowQuestionsList() {
      this.searchText = null;
      this.isSearch = false;
      this.showTypesQuestionsList = true;
      this.showMyQuestionsList = false;
      this.GetTypes();
    },
    ShowMyQuestionsList() {
      this.searchText = null;
      this.isSearch = false;
      this.showTypesQuestionsList = true;
      this.showMyQuestionsList = true;
      this.GetMyQuestionsTemplate();
    },
    CheckMove: function (e) {
      if (e.to.id != "questionsDragZone") {
        return false;
      }
      return true;
    },

    computed: {
      DragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost",
        };
      },
    },
  },
};
</script>

<style scoped>
.questionsDragZone {
  position: relative;
  background: linear-gradient(
    87.6deg,
    hsl(171deg 50% 54% / 20%) 0%,
    rgb(32 155 138 / 20%) 100%
  );
  min-height: 100px;
  border-radius: 5px;
  border: 1px dashed #4cc3b2;
  /* color: #4cc3b2; */
  padding: 10px 10px 10px 10px;
}

.categoriesDragZone {
  position: relative;
  background: linear-gradient(
    87.6deg,
    hsl(185deg 72% 34% / 10%) 0%,
    hsl(185deg 100% 22% / 10%) 100%
  );
  min-height: 100px;
  border-radius: 5px;
  border: 1px dashed #4cc3b2;
  /* color: #4cc3b2; */
  padding: 10px 10px 10px 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

h1 {
  font-size: 30px;
  color: #188a94;
}
.question-card {
  background-color: #f5f8fa;
  border-radius: 0.475rem;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
}
.c-grab {
  cursor: grab;
}

.empty-eval-content {
  position: relative;
  background: #4bc2b136;
  min-height: 200px;
  border-radius: 5px;
  border: 1px dashed #4cc3b2;
  color: #4cc3b2;
}

.empty-eval-content h1 {
  color: #16899394;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 40%;
}

.empty-eval-content .dragArea {
  height: 200px;
}
.new-objects-card {
  /* position: fixed;
  height: 90vh;
  width: 500px;
  background: rgb(255 255 255 / 70%);
  overflow: auto; */

  position: fixed;
  height: 87vh;
  width: 500px;
  background: rgb(255 255 255 / 70%);
  /* right: 25px; */
  overflow: auto;
  top: 150px;
}

.new-objects-card .card-header {
  min-height: auto;
  padding: 2rem 2.25rem !important;
  width: 100%;
  display: -webkit-inline-box;
  cursor: pointer;
}

.new-objects-card .card-header i {
  color: #188a94;
  font-size: 18px;
  margin-right: 5px;
}

.new-objects-card .card-header h3 {
  margin: 0px;
  color: #188a94;
}

.new-card-object {
  border: 1px solid #e4e6ef;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: grab;
  background: white;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.new-question-modal-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  margin: 5%;
}
</style>
