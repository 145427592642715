<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item"><a href="">Evaluations</a></li>
        <li class="breadcrumb-item" aria-current="page">
          <a href="#">{{ evaluationDetails.Name }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Evaluation details</li>
      </ol>
    </nav>

    <div class="row align-items-end">
      <div class="col">
        <h1 class="page-title">Evaluation details</h1>
      </div>

      <div class="col"></div>
    </div>

    <leader-evaluation-information-component :evaluationDetails="evaluationDetails" :averageDetails="leaderAvarageDetails"></leader-evaluation-information-component>

    <div class="page-tabs">
      <div class="row border-bottom">
        <div class="col-auto">
          <h3>
            <router-link class="dropdown-item p-0" :to="{ name: 'PreviewEvaluationView', params: { id: evaluationId } }"> Details </router-link>
          </h3>
        </div>
        <div class="col-auto">
          <h3>
            <router-link v class="dropdown-item p-0" :to="{ name: 'LeaderEvaluationMembersView', params: { id: evaluationId } }"> Members </router-link>
          </h3>
        </div>
        <div class="col-auto active">
          <h3>
            <router-link class="dropdown-item p-0" :to="{ name: 'LeaderAvarageView', params: { id: evaluationId } }"> Avarage </router-link>
          </h3>
        </div>
      </div>
      <hr />
    </div>
    <div class="card">
      <div class="card-body">
        <div v-bind:key="categoryIndex" v-for="(category, categoryIndex) in leaderResultDetails">
          <div class="row">
            <div class="col">
              <h1>{{ category.CategoryName }}</h1>
              <p>
                {{ category.CategoryDescription }}
              </p>
            </div>

            <div v-if="evaluationDetails.SelfAssessment" class="col-1" :style="{ width: scoreColumnWidth + 'px' }">
              <h5 class="mb-4 text-gray-700">Your score</h5>
              <span class="bg-gray-200 p-2 rounded text-dark-green fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-dark-green me-2"></i>
                {{ category.CategoryScore }}
                <!-- 4.2 -->
              </span>
            </div>
            <div class="col-1" :style="{ width: scoreColumnWidth + 'px' }">
              <h5 class="mb-4 text-gray-700">Members Avg.</h5>
              <span class="bg-gray-200 p-2 rounded text-dark-green fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-dark-green me-2"></i>
                {{ category.AverageScoreOfMembersOnCategory }}
                <!-- 4.2 -->
              </span>
            </div>
          </div>

          <div class="row align-items-center" :id="'question-' + question.AnswerId" v-bind:key="questionIndex" v-for="(question, questionIndex) in category.Questions">
            <div class="col">
              <question-timeline-details-component :question="question"></question-timeline-details-component>
            </div>
            <div v-if="evaluationDetails.SelfAssessment" class="col-1">
              <span class="bg-gray-200 p-2 rounded text-dark-green fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-dark-green me-2"></i>
                {{ question.Grade }}
                <!-- 4.2 -->
              </span>
            </div>
            <div class="col-1">
              <span class="bg-gray-200 p-2 rounded text-dark-green fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-dark-green me-2"></i>
                {{ question.AverageScoreOfMembersOnQuestion }}
                <!-- 4.2 -->
              </span>
            </div>
          </div>

          <hr class="mt-15 mb-15" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import LeaderEvaluationInformationComponent from "@/components/Common/LeaderEvalInformationComponent.vue";
import PreviewScoreComponent from "@/components/Common/Score/PreviewScoreComponent.vue";
import QuestionTimelineDetailsComponent from "@/components/Questions/QuestionTimelineDetailsComponent.vue";
export default {
  name: "PreviewEvaluationView",
  components: {
    LeaderEvaluationInformationComponent,
    PreviewScoreComponent,
    QuestionTimelineDetailsComponent,
  },
  data() {
    return {
      evaluation: {
        Categories: [],
      },
      evaluationId: null,
      evaluationDetails: {},
      leaderAvarageDetails: {},
      leaderResultDetails: [],
    };
  },
  methods: {
    GetEvaluation: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getEvaluationDetails/" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationDetails = response.data;
          this.GetLeaderAvargeDetails();

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetLeaderAvargeDetails: async function () {
      await this.$axios
        .get("api/Answers/getLeaderAvargeDetails/" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.leaderAvarageDetails = response.data;
          if (
            response.data.LeaderSigned == true ||
            this.evaluationDetails.SelfAssessment == false
          ) {
            this.GetLeaderEvaluationResultDetails();
          } else {
            this.$swal
              .fire({
                title: "You have to answer the challenge first!",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Go evaluations",
              })
              .then((response) => {
                this.$router.push({
                  name: "UserActiveEvaluationsView",
                });
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error,
                });
              });
          }
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    GetLeaderEvaluationResultDetails: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/Answers/GetLeaderEvaluationResultDetails/" + this.evaluationId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.leaderResultDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  computed: {},
  created() {
    this.evaluationId = this.$route.params.id;
    this.GetEvaluation();
  },
};
</script>
<style scoped>
.timeline-content {
  border-radius: 5px;
}
h2 {
  color: #188a94;
}

p {
  color: #6c757d;
}

.fa-question-square {
  font-size: 30px;
}

.btn {
  min-width: 100px;
}

.timeline-label:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 3px;
  bottom: 0;
  background-color: #eff2f5;
}

.modal-body {
  max-height: 600px;
  overflow: auto;
}

/* .modal-header,
.modal-footer {
  display: block;
} */
</style>
