<template>
  <div class="col">
    <label v-if="showLabel" for="search" class="fw-bold  mb-4">Search</label>
    <div class="input-group rounded">
      <span class="input-group-text border-0">
        <i class="fa-solid fa-search fs-18px"></i>
      </span>
      <input type="text" class="form-control" id="search" :placeholder="placeholderText || 'Search by name...'" v-model="searchText.SearchText" v-on:keyup.enter="SearchForItems()" />
    </div>
  </div>
</template>

<script>
export default {
  Name: "Search",
  props: {
    placeholderText: String,
    searchText: {
      type: Object,
      default: new Object(),
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["searchForItems"],
  methods: {
    SearchForItems: function () {
      this.$emit("searchForItems");
    },
  },
};
</script>
