<template>
  <div class="row mb-5 mt-15">
    <div class="col">
      <h1>{{ workspaceProp.Name }}</h1>
    </div>
    <div class="col-auto">
      <div class="dropdown">
        <button class="btn btn-white btn-sm p-4" id="dropdown-workspace-actions" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa-2x fa-ellipsis fa-regular text-dark-green"></i>
        </button>

        <ul class="dropdown-menu dropdown-menu-alternative" aria-labelledby="dropdown-workspace-actions">
          <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#add-evaluationTemp-modal" @click="ClearAddEvalTempModal">Add Template</a></li>
          <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit-workspace-modal" @click="GetWorkspace">Edit</a></li>
          <li class="border-top">
            <a class="dropdown-item mt-2 text-danger" href="#" @click="DeleteWorkspace">Delete</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  Name: "workspace-component",
  props: { workspaceProp: { type: Object, default: {} } },
  data() {
    return {};
  },
  emits: [
    "getWorkspaceEmit",
    "deleteWorkspaceEmit",
    "clearAddEvalTempModalEmit",
  ],
  methods: {
    GetWorkspace: function () {
      this.$emit("getWorkspaceEmit", this.workspaceProp.Id);
    },
    DeleteWorkspace: function () {
      this.$emit("deleteWorkspaceEmit", this.workspaceProp.Id);
    },
    ClearAddEvalTempModal: function () {
      this.$emit("clearAddEvalTempModalEmit", this.workspaceProp);
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 30px;
  font-weight: 400;
}
</style>
