<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item">Evaluation</li>
        <li class="breadcrumb-item">
          {{ evaluationDetails.Name }}
        </li>
        <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Members</a></li>
        <li class="breadcrumb-item active" aria-current="page">Answers</li>
      </ol>
    </nav>

    <div class="row align-items-center">
      <div class="col">
        <h1 class="eval-page-title">Evaluation answers</h1>
        <!-- <p v-if="selectedMember"> {{ selectedMember.Name }} answers for evaluation {{ evaluationDetails.Name }}</p>
        <p v-else>Evaluation answers</p> -->
      </div>

      <div class="col-auto" :style="{ width: scoreColumnWidth + 32 + 'px' }">
        <div class="bg-white rounded p-4">
          <label class="mb-4 fw-bold label">Change member</label>
          <VueMultiselect v-model="selectedMember" track-by="EmployeeId" :allowEmpty="false" :options="evaluationMembers" :placeholder="'Search for member'" label="Name" :searchable="true" :internalSearch="false" @select="SelectMember">
            <template v-slot:option="{ option }">
              <div class="option__desc">
                <small class="badge badge-light float-end" v-if="option.Status != 'Completed'">In progress</small>
                <small class="badge badge-light float-end text-dark-green" v-else> {{ option.Status }}</small>
                {{ option.Name }}
              </div>
            </template>
          </VueMultiselect>
        </div>
      </div>
    </div>

    <div class="card mb-8 mt-8 evaluation-summary-card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h3>{{ evaluationDetails.Name }}</h3>
            <p>{{ evaluationDetails.Description }}</p>
          </div>

          <div v-if="selectedMember" class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
            <div class="big-score-bage bg-dark-green">
              <h1>{{ answers.EvaluationScore }}</h1>
              <span>{{ selectedMember.Name }}</span>
            </div>
          </div>
        </div>

        <hr />

        <div class="row align-items-center">
          <div class="col d-flex align-items-center mb-3">
            <img v-if="evaluationDetails.Scope" :src="require('@/assets/images/scopes-icons/' + evaluationDetails.Scope + '.png')" />
            <div>
              <small>Scope</small>
              <h5>{{ evaluationDetails.Scope }}</h5>
            </div>
          </div>
          <div class="col d-flex align-items-center mb-3">
            <img v-if="evaluationDetails.Target" :src="require('@/assets/images/scopes-icons/' + evaluationDetails.Target + '.png')" />
            <div>
              <small>Target</small>
              <h5>All members</h5>
            </div>
          </div>
          <div class="col mb-3">
            <small>Period</small>
            <h5>{{ $utils.formatDate(evaluationDetails.StartDate) }} <span class="text-green">-</span> {{ $utils.formatDate(evaluationDetails.EndDate) }}</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-bind:key="categoryIndex" v-for="(category, categoryIndex) in answers.Categories">
          <div class="row">
            <div class="col">
              <h1>{{ category.CategoryName }}</h1>
              <p>
                {{ category.CategoryDescription }}
              </p>
            </div>

            <div v-if="answers.IsSigned == true" class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 text-gray-700">{{ answers.WhoEvaluatedName }} Score</h5>
                </div>
                <div class="col-auto">
                  <span class="bg-gray-200 p-2 rounded text-warning fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-warning me-2"></i> {{ category.CategoryScore }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center" v-bind:key="questionIndex" v-for="(question, questionIndex) in category.Questions">
            <div class="col">
              <question-timeline-details-component :question="question"></question-timeline-details-component>
            </div>

            <div v-if="answers.IsSigned == true" class="col-auto">
              <preview-score-component :question="question"></preview-score-component>
            </div>
          </div>

          <hr class="mt-15 mb-15" />
        </div>

        <not-found-component v-if="!answers.Categories" :text="'User has not answered to this evaluation'"></not-found-component>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";

import PreviewScoreComponent from "@/components/Common/Score/PreviewScoreComponent.vue";
import QuestionTimelineDetailsComponent from "@/components/Questions/QuestionTimelineDetailsComponent.vue";
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";

export default {
  name: "AnswersDetailsView",
  components: {
    PreviewScoreComponent,
    QuestionTimelineDetailsComponent,
    NotFoundComponent,
  },

  data() {
    return {
      scoreColumnWidth: 320,
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
        Scope: null,
      },

      answers: {
        Categories: [],
        EvaluationScore: 0,
      },
      selectedMember: null,
      evaluationMembers: [],
      evaluationDetails: {},
    };
  },

  methods: {
    SelectMember: function (selected) {
      this.$router
        .push(
          "/evaluation/" +
            this.$route.params.evalId +
            "/member/" +
            selected.EmployeeId +
            "/answers"
        )
        .then(function () {
          window.location.reload();
        });
    },

    GetWhoEvaluateAnswers: async function () {
      store.dispatch("changeLoaderStatus", true);
      var query = new URLSearchParams({
        employeeId: this.$route.params.employeeId,
        evaluatedBy: this.$route.params.employeeId,
        evaluationId: this.$route.params.evalId,
      });
      await this.$axios
        .get("api/Answers/GetEmployeeWhoEvaluateAnswers?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.answers = response.data;

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationMembers: function () {
      store.dispatch("changeLoaderStatus", true);
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      var query = new URLSearchParams({
        evaluationId: this.$route.params.evalId,
        orderBy: this.filter.OrderBy,
      });
      this.$axios
        .get("api/Employee/getMembersForReviewAnswers?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationMembers = response.data;

          this.selectedMember = this.evaluationMembers.find(
            (member) => member.EmployeeId == this.$route.params.employeeId
          );

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationDetails: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/EvaluationPlan/getEvaluationDetails/" +
            this.$route.params.evalId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },

  created() {
    this.GetEvaluationDetails();
    this.GetEvaluationMembers();
    this.GetWhoEvaluateAnswers();
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  color: #188a94;
}
</style>
