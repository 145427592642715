<template>
  <div class="modal fade" id="create-team-modal" tabindex="-1" aria-labelledby="createTeamModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="CreateTeam" ref="createTeamForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title" id="team-preview-modal-label">Create new team</h2>
              </div>
              <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            </div>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="form-label"> <span class="required">Name</span> </label>
                  <Field type="text" name="teamName" class="form-control" placeholder="Team name" v-model="teamDetails.TeamName" rules="required|min: 3" />
                  <small v-if="errors.teamName" class="text-danger mt-2">Name requires minimum 3 characters.</small>
                </div>

                <div class="form-group">
                  <label class="form-label"> <span class="required">Leader</span> </label>
                  <Field name="leader" v-slot="{ field }" :rules="{ required: teamDetails.LeaderId == null }">
                    <VueMultiselect
                      :empty="false"
                      v-bind="field"
                      v-model="selectedTeamLeader"
                      placeholder="Select team leader"
                      label="Name"
                      track-by="EmployeeId"
                      :options="leaders"
                      :searchable="true"
                      :internalSearch="false"
                      @open="SearchForTeamLeaders"
                      @search-change="SearchForTeamLeaders"
                    >
                    </VueMultiselect>
                  </Field>
                  <small v-if="errors.leader" class="text-danger mt-2">Leader is required.</small>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="form-label"> Description </label>
                  <Field name="description" v-slot="{ field }" rules="max: 50">
                    <textarea v-bind="field" rows="5" type="text" class="form-control" v-model="teamDetails.Description" placeholder="Team description" />
                  </Field>
                  <small v-if="errors.description" class="text-danger mt-2">Description is maximum 50 characters.</small>
                </div>
              </div>
            </div>

            <hr />

            <div class="row mt-3">
              <div class="col">
                <h6>All employees</h6>
                <div class="input-group input-group-loader-search">
                  <span class="input-group-text border-0"><i class="fa-solid fa-search fs-18px"></i></span>
                  <input v-on:keyup="SearchForEmployees()" v-model="allMembersSearch" type="text" class="form-control form-control-solid" id="search" placeholder="Search for employees" />
                </div>

                <div class="mh-400px mt-6 overflow-scroll p-1 pe-2">
                  <table class="table table-row-dashed">
                    <thead class="table-header">
                      <tr>
                        <th>
                          <h6>Name</h6>
                        </th>
                        <th class="text-end">
                          <h6>Add</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-bind:key="index" v-for="(employee, index) in CheckedEmployeesList">
                        <td>{{ employee.Name }}</td>
                        <td class="text-end">
                          <i v-if="employee.IsSelected" v-on:click="RemoveMember(employee)" class="fa-2x fa-check-square fa-duotone pointer text-green"></i>
                          <i v-else v-on:click="AddMember(employee)" class="fa-2x fa-duotone fa-square pointer"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col">
                <h6>Team members</h6>
                <div class="input-group input-group-loader-search">
                  <span class="input-group-text border-0"><i class="fa-solid fa-search fs-18px"></i></span>
                  <input type="text" v-model="searchInTeam" class="form-control form-control-solid" id="search" placeholder="Search for employees" />
                </div>

                <div class="mh-400px mt-6 overflow-scroll p-1 pe-2">
                  <table class="table table-row-dashed">
                    <thead class="table-header">
                      <tr>
                        <th>
                          <h6>Name</h6>
                        </th>
                        <th class="text-end">
                          <h6>Remove</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-bind:key="index" v-for="(member, index) in FilteredMembers">
                        <td>{{ member.Name }}</td>
                        <td class="text-end">
                          <i v-on:click="RemoveMember(member)" class="fa-duotone fa-trash-alt far fs-18px pointer text-hover-danger"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <small v-if="teamDetails.TeamMembers?.length == 0">Add first member to this team.</small>
                  <small v-if="teamDetails.TeamMembers && teamDetails.TeamMembers.length > 0 && FilteredMembers.length == 0">No member based on your search.</small>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer flex-stack">
            <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import { defineRule } from "vee-validate";
import { required, min } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);
defineRule("min", min);

export default {
  name: "CreateTeamModal",
  emits: ["createTeam", "searchForEmployees"],
  props: ["employees"],
  components: {
    Field,
    Form,
  },
  data() {
    return {
      teamDetails: {
        TeamName: null,
        Description: null,
        LeaderId: null,
        TeamMembers: [],
      },
      leaders: [],
      allMembersSearch: "",
      searchInTeam: "",
      selectedTeamLeader: null,
    };
  },

  computed: {
    CheckedEmployeesList: function () {
      this.teamDetails.TeamMembers.forEach((selected) => {
        this.employees.every((element) => {
          if (element.EmployeeId == selected.EmployeeId) {
            element.IsSelected = true;
            return true;
          }
        });
      });
      if (this.selectedTeamLeader) {
        const index = this.employees.findIndex((x) => x.EmployeeId == this.selectedTeamLeader.EmployeeId);
        this.employees.splice(index, 1);
      }
      return this.employees;
    },

    FilteredMembers: function () {
      return this.teamDetails.TeamMembers?.filter((employee) => employee.Name.toLowerCase().includes(this.searchInTeam.toLowerCase()));
    },
  },

  methods: {
    SearchForTeamLeaders: function (searchText) {
      if (!searchText) {
        searchText = "";
      }
      this.$axios
        .get("api/Employee/getShortEmployeesDetails?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.leaders = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },

    SearchForEmployees: async function () {
      this.$emit("searchForEmployees", this.allMembersSearch);
    },

    AddMember: function (member) {
      this.teamDetails.TeamMembers.push(member);
      member.IsSelected = true;
    },

    RemoveMember: function (member) {
      this.teamDetails.TeamMembers.splice(this.teamDetails.TeamMembers.indexOf(member), 1);
      member.IsSelected = false;
    },

    ClearModal: function () {
      this.teamDetails = {
        TeamName: null,
        Description: null,
        LeaderId: null,
        TeamMembers: [],
      };
      this.selectedTeamLeader = null;
      this.$refs.createTeamForm.resetForm();
    },

    CreateTeam: async function () {
      this.teamDetails.LeaderId = this.selectedTeamLeader.EmployeeId;
      this.$emit("createTeam", this.teamDetails);
    },
  },
};
</script>
