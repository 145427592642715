<template>
  <div class="container">
    <nav aria-label="breadcrumb" class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item active"><span class="active" href="#">Employees</span></li>
      </ol>
    </nav>
    <h1 class="page-title">Employees</h1>
    <div class="row mb-10 align-items-end">
      <div class="col">
        <Search @searchForItems="GetEmployees" :placeholderText="'Search for employees'" :searchText="filter" />
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-gradient-success" v-on:click="GetEmployees()"><i class="fa-search far text-white"></i> Search</button>
      </div>
      <div class="col"></div>
      <div class="col text-end">
        <button type="button" class="btn btn-gradient-success" @click="OpenCreateModal()">+ New employee</button>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-row-dashed">
            <thead class="table-header">
              <tr>
                <th>
                  <span class="pointer text-dark-green" v-on:click="OrderBy('Name')">
                    Name
                    <span v-show="filter.OrderBy === 'Name'">
                      <i class="fa-solid fa-arrow-up-short-wide"></i>
                    </span>
                    <span v-show="filter.OrderBy === 'Name_desc'">
                      <i class="fa-solid fa-arrow-down-short-wide"></i>
                    </span>
                  </span>
                </th>
                <th>Email</th>
                <th>Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in employees.Items" v-bind:key="index">
                <td>
                  <div class="d-flex align-items-center">
                    <div v-if="item.Image" class="h-40px w-40px">
                      <img class="employee-img" :src="GetImgUrl(item.Image)" />
                    </div>
                    <div v-else class="h-40px w-40px rounded-circle bg-gray-100 text-center">
                      <h4 class="fw-bold mt-3 text-gray-700">
                        {{ item.Name.split()[0][0] }}<span v-if="item.Name.split(' ')[1]">{{ item.Name.split(" ")[1][0] }}</span>
                      </h4>
                    </div>
                    <div class="ms-5 fw-bold">
                      {{ item.Name }}
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <small>
                      {{ item.Email }}
                    </small>
                  </div>
                </td>
                <td>
                  <div class="dropdown" v-if="item.DelegateRoles.length > 1">
                    <span class="badge badge-light p-4 w-100px" type="button" :id="'employee-roles-dropdown-' + index" data-bs-toggle="dropdown" aria-expanded="false">
                      {{ item.Roles[0]?.Name }}
                      <i class="fa-solid fa-chevron-down ms-2"></i>
                    </span>
                    <ul class="dropdown-menu p-0" :aria-labelledby="'employee-roles-dropdown-' + index">
                      <div class="bg-light border-bottom-1 ps-3">
                        <small> Roles </small>
                      </div>
                      <li v-for="(role, index) in item.Roles" v-bind:key="index" class="w-100 ms-0">
                        <span class="dropdown-item">{{ role.Name }} </span>
                      </li>
                      <div class="bg-light border-bottom-1 p-1 ps-3">
                        <small>Delegate Roles </small>
                      </div>
                      <li v-for="(role, index) in item.DelegateRoles" v-bind:key="index" class="w-100 ms-0">
                        <span class="dropdown-item">{{ role.Name }} </span>
                      </li>
                    </ul>
                  </div>
                  <div v-else class="badge badge-light p-4 w-100px">
                    {{ item.Roles[0]?.Name }}
                  </div>
                </td>
                <td class="text-end">
                  <button data-bs-toggle="modal" data-bs-target="#edit-employee-modal" @click="GetEmployeeById(item.EmployeeId)" class="btn btn-secondary-alternative me-3">
                    <i class="fa-solid fa-pen"></i> Edit
                  </button>
                  <button @click="DeleteEmployee(item.EmployeeId)" type="button" class="btn btn-danger-alternative"><i class="far fa-trash-can"></i> Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr v-if="employees.NumberOfPages > 1" />
          <pagination :numberOfPages="employees.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetEmployees" />

          <not-found-component v-if="employees.Count == 0" text="No employees found"></not-found-component>
        </div>
      </div>
    </div>
  </div>
  <EditEmployeeModal :employeeDetails="employeeDetails" @editEmployeeEmit="EditEmployee" :rolesProp="roles" ref="editEmployeeRef" />
  <CreateEmployeeModal @createEmployeeEmit="CreateEmployee" :rolesProp="roles" ref="createEmployeeRef" />
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import EditEmployeeModal from "@/components/Modals/Employee/EditEmployeeModal.vue";
import CreateEmployeeModal from "@/components/Modals/Employee/CreateEmployeeModal.vue";

import Search from "@/components/Common/Search.vue";
import Pagination from "@/components/Common/Pagination.vue";
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";

export default {
  name: "EmployeesView",
  components: {
    Search,
    Pagination,
    EditEmployeeModal,
    CreateEmployeeModal,
    NotFoundComponent,
  },
  emits: ["editEmployeeEmit", "createEmployeeEmit"],
  data() {
    return {
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
      },
      employees: [],
      employeeDetails: {},
      urlFile: null,
      roles: [],
      employeesResponsibilities: {},
    };
  },
  watch: {
    $route(to, from) {},
  },
  methods: {
    OpenCreateModal: function () {
      this.$refs.createEmployeeRef.ClearModal();
      $("#create-employee-modal").modal("show");
    },
    OrderBy: function (orderBy) {
      if (orderBy == this.filter.OrderBy) {
        this.filter.OrderBy = orderBy + "_desc";
      } else {
        this.filter.OrderBy = orderBy;
      }
      this.GetEmployees();
    },
    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },
    GetEmployeeById:  function (employeeId) {
      store.dispatch("changeLoaderStatus", true);

       this.$axios
        .get("api/Employee/getEmployeeById?employeeId=" + employeeId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.employeeDetails = response.data;
          if (this.employeeDetails.Image) {
            this.employeeDetails.urlFile =
              "data:image/jpeg;base64," + this.employeeDetails.Image;
          }
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetEmployees: async function (page) {
      this.filter.PageNumber = 1;
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      this.filter.PageNumber = 1;

      if (page) {
        this.filter.PageNumber = page;
        window.scrollTo(0, 0);
      }
      var query = new URLSearchParams({
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
      });
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Employee/getAllEmployees?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.employees = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    EditEmployee: async function (employeeDetails) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .put("api/Employee/updateEmployee", employeeDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.GetEmployees();
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data,
          });
          $("#edit-employee-modal").modal("hide");
          this.$refs.editEmployeeRef.ClearModal();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    CreateEmployee: async function (employeeDetails) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .post("api/Account/register", employeeDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.GetEmployees();
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data,
          });
          $("#create-employee-modal").modal("hide");
          this.$refs.createEmployeeRef.ClearModal();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetRoles: function () {
      this.$axios
        .get("api/Role/getRoles", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.roles = response.data;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    GetCanBeDelete: async function (employeeId) {
      await this.$axios
        .get("api/Employee/getCanBeDelete?employeeId=" + employeeId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.employeesResponsibilities = response.data;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    DeleteEmployee: async function (id) {
      await this.GetCanBeDelete(id);
      var self = this;
      if (Object.keys(this.employeesResponsibilities).length === 0) {
        await this.$swal
          .fire({
            title: "Warning",
            text: "Are you sure you want to delete this employee?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          })
          .then(function (result) {
            if (result.value) {
              store.dispatch("changeLoaderStatus", true);
              self.$axios
                .delete("api/Account/delete?employeeId=" + id, {
                  headers: {
                    Authorization: "Bearer " + auth.getJwtToken().Value,
                  },
                })
                .then((response) => {
                  store.dispatch("toastNotify", {
                    Type: "success",
                    Title: response.data,
                  });
                  self.GetEmployees();
                  store.dispatch("changeLoaderStatus", false);
                })
                .catch((error) => {
                  store.dispatch("toastNotify", {
                    Type: "error",
                    Title: error.response.data.message,
                  });
                  store.dispatch("changeLoaderStatus", false);
                });
            }
          });
      } else {
        const app = this;
        var result = Object.keys(app.employeesResponsibilities).map((key) => [
          key,
          app.employeesResponsibilities[key],
        ]);

        await this.$swal.fire({
          title: "Warning",
          text: "You can't delete this employee because is responsible for:",
          html: `<div> You can't delete this employee because is :
                ${result
                  .map(
                    (key) =>
                      `<div class='row align-items-baseline text-start'>
                      <h6 class='col-auto'> ${key[0]} :</h6>
                      <div class='col-auto ps-0 d-inline-block text-truncate' style="max-width: 175px;" title=" ${
                        key[1]
                      }"> 
                        ${key[1]}  
                        </div><a class= "col" href="/Administration/${app.GetPageName(
                          key[0]
                        )}" target="_blank"> go to </a>
                    </div>`
                  )
                  .join("")}
                    </div>`,
          icon: "warning",
          showConfirmButton: false,
        });
      }
    },
    GetPageName: function (a) {
      switch (a) {
        case "project manager":
          return "Projects";
        case "team leader":
          return "teams";
        case "group leader":
          return "Groups";
      }
      return null;
    },
  },
  created() {
    this.GetEmployees();
    this.GetRoles();
  },
};
</script>
