<template>
  <div class="container">
    <nav aria-label="breadcrumb" class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item active"><span class="active" href="#">Groups</span></li>
      </ol>
    </nav>
    <h1 class="page-title">Groups</h1>
    <div class="row mb-10 align-items-end">
      <div class="col-12 col-md-4 col-lg-3">
        <Search @searchForItems="GetGroups" :searchText="filter" :placeholderText="'Search for groups'" />
      </div>
      <div class="col-12 col-md-4 col-lg-3">
        <label class="fw-bold mb-4">Member group filter</label>
        <VueMultiselect select-label="" v-model="selectedEmployee" :options="employees" placeholder="Search for member" label="Name" track-by="EmployeeId" class="" :searchable="true" :internalSearch="false" :multiple="false" :loading="isLoading" @search-change="GetEmployees" @select="SelectMemberForFilter" @open="GetEmployees()" @remove="GetGroups()" />
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-gradient-success" v-on:click="GetGroups()"> <i class="fa-search far text-white"></i> Search </button>
      </div>
      <div class="col"></div>
      <div class="col text-end">
        <button type="button" class="btn btn-gradient-success d-inline-block mt-6" data-bs-toggle="modal" data-bs-target="#create-group-modal" @click="ClearModal()">+ New group</button>
      </div>
    </div>

    <div class="card">

      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-row-bordered">
            <thead class="table-header">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Leaders</th>
                <th scope="col">Members</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="index" v-for="(group, index) in groups.Items">
                <td>
                  <span class="fw-bold"> {{ group.Name }}</span>
                  <small class="d-block"> {{ group.Description }}</small>
                </td>
                <td>
                  <span class="text-muted " v-if="group.NumberOfLeaders == 0">No Leaders</span>
                  <div v-if="group.NumberOfLeaders==1" class="d-flex align-items-center">
                    <div v-if="group.Leaders[0].Image" class="h-35px w-35px">
                      <img class="employee-img" :src="GetImgUrl(group.Leaders[0].Image)" />
                    </div>
                    <div v-else class="h-35px w-35px rounded-circle bg-gray-100 text-center p-2">
                      <h4 class="fw-bold  text-gray-700"> {{ group.Leaders[0].Name.split()[0][0]  }}<span v-if="group.Leaders[0].Name.split(' ')[1]">{{ group.Leaders[0].Name.split(' ')[1][0]  }}</span> </h4>
                    </div>
                    <span class="ms-3"> {{  group.Leaders[0].Name }}</span>
                  </div>

                  <div v-if="group.NumberOfLeaders>1" class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <span class="symbol-label bg-dark-green text-white fs-8 fw-bold me-3">{{ group.NumberOfLeaders }}</span>
                    </div>
                    Leaders
                  </div>
                </td>
                <td>
                  <span class="text-muted " v-if="group.NumberOfMembers == 0">No Members</span>
                  <div v-if="group.NumberOfMembers==1" class="d-flex align-items-center">
                    <div v-if="group.Members[0].Image" class="h-35px w-35px">
                      <img class="employee-img" :src="GetImgUrl(group.Members[0].Image)" />
                    </div>
                    <div v-else class="h-35px w-35px rounded-circle bg-gray-100 text-center p-2">
                      <h4 class="fw-bold  text-gray-700"> {{ group.Members[0].Name.split()[0][0]  }}<span v-if="group.Members[0].Name.split(' ')[1]">{{ group.Members[0].Name.split(' ')[1][0]  }}</span> </h4>
                    </div>
                    <span class="ms-3"> {{  group.Members[0].Name }}</span>
                  </div>

                  <div v-if="group.NumberOfMembers>1" class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <span class="symbol-label bg-dark-green text-white fs-8 fw-bold me-3">{{ group.NumberOfMembers}}</span>
                    </div>
                    Members
                  </div>
                </td>

                <td class="text-end">
                  <button @click="OpenEditModal(group.Id)" data-bs-toggle="modal" data-bs-target="#edit-group-modal" type=" button" class="btn btn-secondary-alternative me-3">
                    <i class="fa-solid fa-pen"></i> Edit </button>
                  <button @click="DeleteGroup(group.Id)" type="button" class="btn btn-danger-alternative"><i class="far fa-trash-can"></i> Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr v-if="groups.NumberOfPages>1">
        <pagination :numberOfPages="groups.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetGroups" />

        <not-found-component v-if="this.groups.Count == 0" text="No groups found"></not-found-component>

        <!-- <div v-if="this.groups.Count == 0" class="card-body pb-0 pt-5">
          <img alt="No content found" class="d-block me-auto ms-auto" :src="require('@/assets/images/no-content-found.png')" />
        </div> -->
      </div>
    </div>

  </div>
  <!-- Create Group Modal -->
  <div class="modal fade" id="create-group-modal" tabindex="-1" aria-labelledby="create-group-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-1000px">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row align-items-center">
            <div class="col-auto">
              <h2 class="modal-title" id="team-preview-modal-label">Create group</h2>
            </div>
            <div class="col text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="Close"></button>
            </div>
          </div>
        </div>
        <Form v-slot="{ errors }" @submit="CreateGroup" ref="createGroupForm">
          <div class="modal-body">
            <div class="row mb-10">
              <div class="col">
                <label class="form-label">Name</label>
                <Field type="text" id="new-group-name" name="groupName" class="form-control" :class="{ 'border-danger': errors.groupName }" placeholder="Group name" v-model="newGroup.Name" @focusout="NameExist(newGroup.Name)" :rules="'required|min:3|max:100|nameAlreadyExist:' + nameExist" />
                <div v-if="errors.groupName" class="fv-plugins-message-container invalid-feedback">{{ errors.groupName }}</div>
              </div>
              <div class="col">
                <label class="form-label" for="new-group-description">Description</label>
                <textarea id="new-group-description" type="text" class="form-control" rows="1" placeholder="Group description" v-model="newGroup.Description" />
              </div>
            </div>

            <hr class="bg-gray-300">

            <div class="row mt-8">
              <div class="col-6">
                <label class="form-label" for="new-group-leader-textarea"> Leaders</label>
                <div class="input-group mb-3">
                  <span class="input-group-text border-0"><i class="fa-search fa-solid fs-18px"></i></span>
                  <VueMultiselect select-label="" :options="UnnasignedGroupMembers" placeholder="Search for leaders" label="Name" track-by="EmployeeId" class="multiselect-bg-gray multiselect-border-0 multiselect-with-icon" :searchable="true" :internalSearch="false" :multiple="true" :loading="isLoading" @search-change="GetEmployees" @select="SelectLeader" />
                </div>
                <div v-if="selectedLeaders?.length > 0" class="p-4">
                  <div v-for="(employee, index) in selectedLeaders" v-bind:key="index">
                    <div class="row align-items-center pt-3 pb-3">
                      <div class="col">
                        <span class="fs-15">{{ employee.Name }}</span>
                      </div>
                      <div class="col-auto">
                        <i class="fa-regular fa-trash-can pointer fs-18px text-hover-danger" @click="RemoveLeaders(employee)"></i>
                      </div>
                    </div>
                    <div v-if="selectedLeaders?.length>1 && selectedLeaders?.length!= index+1" class="border-bottom border-gray-300 border-bottom-dashed"></div>
                  </div>
                </div>
                <div v-else>Add group leaders</div>
              </div>
              <div class="col-6">
                <label class="form-label" for="new-group-leader-textarea"> Members</label>
                <div class="input-group mb-3">
                  <span class="input-group-text border-0"><i class="fa-search fa-solid fs-18px"></i></span>
                  <VueMultiselect select-label="" :options="UnnasignedGroupMembers" placeholder="Search for members" label="Name" track-by="EmployeeId" class="multiselect-bg-gray multiselect-border-0 multiselect-with-icon" :searchable="true" :multiple="true" :loading="isLoading" @search-change="GetEmployees" @select="SelectMember" />
                </div>
                <div v-if="selectedMembers?.length > 0" class="p-4">
                  <div v-for="(employee, index) in selectedMembers" v-bind:key="index">
                    <div class="row align-items-center pt-3 pb-3">
                      <div class="col">
                        <span class="fs-15">{{ employee.Name }}</span>
                      </div>
                      <div class="col-auto">
                        <i class="fa-regular fa-trash-can pointer fs-18px text-hover-danger" @click="RemoveMembers(employee)"></i>
                      </div>
                    </div>
                    <div v-if="selectedMembers?.length>1 && selectedMembers?.length!= index+1" class="border-bottom border-gray-300 border-bottom-dashed"></div>
                  </div>
                </div>
                <div v-else>Add group members</div>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-stack">
            <button type="button" class="border btn btn-white" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <!-- End Group Modal -->

  <!-- Edit Group Modal -->
  <div class="modal fade" id="edit-group-modal" tabindex="-1" aria-labelledby="edit-group-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mw-1000px">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row align-items-center">
            <div class="col-auto">
              <h2 class="modal-title" id="team-preview-modal-label">Edit group</h2>
            </div>
            <div class="col text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="Close"></button>
            </div>
          </div>
        </div>
        <Form v-slot="{ errors }" @submit="EditGroup" ref="editGroupForm">
          <div class="modal-body">
            <div class="mb-10">
              <div class="row">
                <div class="col">
                  <label class="form-label required" for="new-group-name">Name</label>
                  <Field type="text" id="new-group-name" name="groupName" class="form-control" placeholder="Group name" v-model="group.Name" @focusout="NameExist(group.Name)" :rules="'required|min:3|max:100|nameAlreadyExist:' + nameExist" />
                  <!-- <input v-bind="field" class="form-control" placeholder="Group name" /> -->
                  <!-- </Field> -->
                  <div v-if="errors.groupName" class="fv-plugins-message-container invalid-feedback">{{ errors.groupName }}</div>
                </div>
                <div class="col">
                  <label class="form-label" for="edit-group-description-textarea"> Description</label>
                  <textarea type="text" class="form-control" id="edit-group-description-textarea" rows="1" placeholder="Group description" v-model="group.Description" />
                </div>
              </div>
            </div>

            <hr class="bg-gray-300">

            <div class="row mt-8">
              <div class="col-6">
                <label class="form-label" for="new-group-leader-textarea"> Leaders</label>
                <div class="input-group mb-3">
                  <span class="input-group-text border-0"><i class="fa-search fa-solid fs-18px"></i></span>
                  <VueMultiselect select-label="" :options="UnnasignedGroupMembers" placeholder="Search for leaders" label="Name" track-by="EmployeeId" class="multiselect-bg-gray multiselect-border-0 multiselect-with-icon" :searchable="true" :internalSearch="false" :multiple="true" :loading="isLoading" @search-change="GetEmployees" @select="SelectLeader" />
                </div>
                <div v-if="selectedLeaders?.length > 0" class="p-4">
                  <div v-for="(employee, index) in selectedLeaders" v-bind:key="index">
                    <div class="row align-items-center pt-3 pb-3">
                      <!-- <div class="symbol symbol-35px symbol-circle col-auto pe-1"><img alt="Pic" :src="GetImgUrl(projectMember.Image)" style="object-fit: cover" /></div> -->
                      <div class="col">
                        <span class="fs-15">{{ employee.Name }}</span>
                      </div>
                      <div class="col-auto">
                        <i class="fa-regular fa-trash-can pointer fs-18px text-hover-danger" @click="RemoveLeaders(employee)"></i>
                      </div>
                    </div>
                    <div v-if="selectedLeaders?.length>1 && selectedLeaders?.length!= index+1" class="border-bottom border-gray-300 border-bottom-dashed"></div>
                  </div>
                </div>
                <div v-else>No leaders</div>
              </div>
              <div class="col-6">
                <label class="form-label" for="new-group-leader-textarea"> Members</label>
                <div class="input-group mb-3">
                  <span class="input-group-text border-0"><i class="fa-search fa-solid fs-18px"></i></span>
                  <VueMultiselect select-label="" :options="UnnasignedGroupMembers" placeholder="Search for members" label="Name" track-by="EmployeeId" class="multiselect-bg-gray multiselect-border-0 multiselect-with-icon" :searchable="true" :multiple="true" :loading="isLoading" @search-change="GetEmployees" @select="SelectMember" />
                </div>
                <div v-if="selectedMembers?.length > 0" class="p-4">
                  <div v-for="(employee, index) in selectedMembers" v-bind:key="index">
                    <div class="row align-items-center pt-3 pb-3">
                      <!-- <div class="symbol symbol-35px symbol-circle col-auto pe-1"><img alt="Pic" :src="GetImgUrl(projectMember.Image)" style="object-fit: cover" /></div> -->
                      <div class="col">
                        <span class="fs-15">{{ employee.Name }}</span>
                      </div>
                      <div class="col-auto">
                        <i class="fa-regular fa-trash-can pointer fs-18px text-hover-danger" @click="RemoveMembers(employee)"></i>
                      </div>
                    </div>
                    <div v-if="selectedMembers?.length>1 && selectedMembers?.length!= index+1" class="border-bottom border-gray-300 border-bottom-dashed"></div>
                  </div>
                </div>
                <div v-else>No members</div>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-stack">
            <button type="button" class="border btn btn-white" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <!-- End Group Modal -->
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import Search from "@/components/Common/Search.vue";
import Pagination from "@/components/Common/Pagination.vue";
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";
import { Field, Form } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, min, between, max } from "@vee-validate/rules";
defineRule("required", required);
defineRule("between", between);
defineRule("min", min);
defineRule("max", max);
defineRule("nameAlreadyExist", (value, [nameExist]) => {
  if (nameExist === "true") {
    return "Group name already exist.";
  }
  return true;
});
export default {
  name: "GroupsView",
  components: { Search, Field, Form, Pagination, NotFoundComponent },
  emits: [],
  data() {
    return {
      newGroup: {
        Name: null,
        Description: null,
      },
      selectedMembers: [],
      selectedLeaders: [],
      employees: [],
      isLoading: false,
      filter: {
        SearchText: "",
        PageNumber: 1,
        OrderBy: null,
        PageSize: 20,
      },
      groups: {},
      group: { Leaders: [], Members: [] },
      nameExist: false,
      selectedEmployee: null,
    };
  },

  methods: {
    SelectMemberForFilter: function (member) {
      this.GetGroups(1, member.EmployeeId);
    },

    DeleteGroup: async function (id) {
      const self = this;
      await this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to delete this group?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            await self.$axios
              .delete("api/EvaluationGroup/" + id, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then(async () => {
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: "Group has been deleted!",
                });
                self.GetGroups();
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data.message,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },

    EditGroup: async function () {
      this.group.LeadersGroupId = this.selectedLeaders.map((i) => i.EmployeeId);
      this.group.MembersGroupId = this.selectedMembers.map((i) => i.EmployeeId);
      await this.$axios
        .put("api/EvaluationGroup/update", this.group, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Group has been added",
          });
          $("#edit-group-modal").modal("hide");
          this.GetGroups();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    OpenEditModal: async function (groupId) {
      await this.GetGroup(groupId);
      this.GetEmployees();
    },

    GetImgUrl: function (image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    GetGroups: async function (pageNumber, employeeId) {
      store.dispatch("changeLoaderStatus", true);
      this.filter.PageNumber = 1;
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      if (pageNumber) {
        this.filter.PageNumber = pageNumber;
        // window.scrollTo(0, 0);
      }
      if (!employeeId) {
        employeeId = 0;
      }
      var query = new URLSearchParams({
        employeeId: employeeId,
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
        pageSize: this.filter.PageSize,
      });

      await this.$axios
        .get("api/EvaluationGroup/getPagedEvaluationGroup?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.groups = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    ClearModal: function () {
      this.$refs.createGroupForm.resetForm();
      this.selectedMembers = [];
      this.selectedLeaders = [];
      this.GetEmployees();
    },

    CreateGroup: async function () {
      this.newGroup.LeadersGroupId = this.selectedLeaders.map(
        (i) => i.EmployeeId
      );
      this.newGroup.MembersGroupId = this.selectedMembers.map(
        (i) => i.EmployeeId
      );
      await this.$axios
        .post("api/EvaluationGroup/createEvaluationGroup", this.newGroup, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async () => {
          store.dispatch("toastNotify", {
            Type: "success",
            Title: "Group has been created!",
          });
          this.selectedLeaders = [];
          this.selectedMembers = [];
          this.newGroup = {};

          $("#create-group-modal").modal("hide");

          this.GetGroups();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    SelectLeader: function (item) {
      this.selectedLeaders.push(item);
    },

    SelectMember: function (item) {
      this.selectedMembers.push(item);
    },

    RemoveLeaders: function (item) {
      const index = this.selectedLeaders.findIndex(
        (x) => x.EmployeeId == item.EmployeeId
      );
      this.selectedLeaders.splice(index, 1);
    },

    RemoveMembers: function (item) {
      const index = this.selectedMembers.findIndex(
        (x) => x.EmployeeId == item.EmployeeId
      );
      this.selectedMembers.splice(index, 1);
    },

    GetEmployees: function (searchText) {
      this.isLoading = true;
      if (searchText == null) {
        searchText = "";
      }
      this.$axios
        .get("api/Employee/getShortEmployeesDetails?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.employees = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          this.isLoading = false;
        });
    },
    NameExist: function (name) {
      if (name && name.length > 2) {
        this.$axios
          .get("api/EvaluationGroup/nameExist?name=" + name, {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          })
          .then((response) => {
            this.nameExist = response.data;
          })
          .catch((error) => {
            store.dispatch("toastNotify", {
              Type: "error",
              Title: error.response.data.message,
            });
          });
      }
    },
    GetGroup: async function (groupId) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationGroup/getGroupMembersDetails?groupId=" + groupId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.group = response.data;
          this.selectedLeaders = response.data.Leaders;
          this.selectedMembers = response.data.Members;

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },

  computed: {
    UnnasignedGroupMembers() {
      let selectedEmployees = this.selectedLeaders.concat(this.selectedMembers);
      let unnasignedMembers = [];

      this.employees.forEach(function (employee) {
        selectedEmployees.forEach(function (selectedEmployee) {
          if (selectedEmployee.EmployeeId == employee.EmployeeId) {
            employee.Found = true;
          }
        });
        if (!employee.Found) {
          unnasignedMembers.push(employee);
        }
      });

      return unnasignedMembers;
    },
  },

  created() {
    this.GetGroups();
  },
};
</script>
