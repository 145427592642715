import {
  createApp,
  resolveDirective
} from "vue";
import App from "./App.vue";

//sweetAlert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

//fontawesome
//import "@fortawesome/fontawesome-free/css/all.css";
import "@/assets/FontAwesome6/css/all.css";

//global style css file
import "@/assets/css/style.css";

//for page routes
import router from "./router";

import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

//datepicker
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

//defineRules global
import "@/utils/VeeValidate.js";

//bootstrap5
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap";

//moment js
import moment from "moment";
import store from "@/utils/vuex.js";
import utils from "@/utils/utils.js";

const app = createApp(App);
// global registration - can be used anywhere
app.use(router);
app.use(store);
app.use(VueSweetalert2);
app.component("Datepicker", Datepicker);
app.component("VueMultiselect", VueMultiselect);
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$store = store.getters;
app.config.globalProperties.$moment = moment;

//axios configuration
import axios from "axios";
// const backendURL = "https://localhost:44312/";
const backendURL = "/api";
const axiosConfig = axios.create({
  baseURL: backendURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    tenant: "1",
  },
});

import auth from "@/utils/auth.js";

axiosConfig.interceptors.request.use(
  async (config) => {
      if (config.url != "api/Auth/login" || config.url != "api/Tenant/creareTenant") {
        const token = auth.getJwtToken();
        if (token) {
          if (token.Expiration > moment().format("YYYY-MM-DDTHH:mm:ss")) {
            axiosConfig.defaults.headers.Authorization = "Bearer " + token.Value;
          }
          //if accesToken is expired, we use refresh token to get new accesToken
          else {
            const originalRequest = config;
            const tokenModel = {
              AccessToken: auth.getJwtToken().Value,
              RefreshToken: auth.getRefreshToken(),
            };
            //i used basic axios because axiosConfig is used for *interceptors*
            //so, the api/Auth/refresh-token doesnt need to be intercepted
            //--
            return await axios
              .post("api/Auth/refresh-token", tokenModel, {
                baseURL: backendURL,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  tenant: "1",
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  //--clear local storage
                  auth.setJwtToken(null);
                  auth.setRefreshToken(null);
                  //--
                  const newToken = {
                    Value: response.data.accessToken,
                    Expiration: moment(response.data.expiration).format("YYYY-MM-DDTHH:mm:ss"),
                  };
                  // console.log(
                  //   "NEW ",
                  //   "  ||  ",
                  //   newToken.Expiration,
                  //   newToken.Value
                  // );
                  auth.setJwtToken(newToken);
                  auth.setRefreshToken(response.data.refreshToken);
                  originalRequest.headers.Authorization = "Bearer " + newToken.Value;
                  //we continue the original request with the new token
                  // console.log(
                  //   "originalRequest, | ",
                  //   originalRequest.headers.Authorization
                  // );
                  return originalRequest;
                } else {
                  router.push({
                    name: "Login",
                  });
                }
              })
              .catch((error) => {
                //remove old tokens
                auth.setJwtToken(null);
                auth.setRefreshToken(null);
                //if refreshToken is expired, user needs to login again
                router.push({
                  name: "Login",
                });
                return Promise.reject(error);
              });
          }
        }
      }
      // console.log("interceptors | return", config.headers.Authorization);
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
);
export {
  backendURL
};
export default axiosConfig;
app.config.globalProperties.$axios = {
  ...axiosConfig,
};

app.mount("#app");