<template>
  <router-link :to="{ name: evaluationLink, params: { id: evaluation.Id } }">
    <div class="card h-100 shadow-hover">
      <div class="card-body">
        <div class=" mb-10">
          <!-- <h4 class="fw-normal eval-title">
            <span v-bind:key="index" v-for="(item, index) in evaluation.EvaluationFor">
              {{item}}<span v-if="evaluation.EvaluationFor.length>1 && evaluation.EvaluationFor.length != index+1">, </span>
            </span>
            <span v-if="evaluation.EvaluationFor.length>0" class="text-green m-2">-</span>
            <span class="fw-normal text-muted fs-15"> {{ evaluation.Name }} </span>
          </h4> -->

          <h4 class="fw-normal eval-title">
            <span class="text-green me-2">#{{ evaluation.Id }} </span>
            <span class="fw-normal text-muted fs-15">- {{ evaluation.Name }} </span>
          </h4>
          <hr>
          <div class="eval-description">
            <small v-if="!evaluation.Description">
              {{ ScopeDescription(evaluation) }}
            </small>
            <small>
              {{ evaluation.Description }}
            </small>
          </div>
        </div>

        <div class="row">

          <div class="col">
            <div class="row">
              <div class="col-auto w-40px">
                <i class="fa fa-bullseye fs-18px text-green"></i>
              </div>
              <div class="col">
                For :
                <span v-bind:key="index" v-for="(item, index) in evaluation.EvaluationFor" class="fw-bold">
                  {{ item }}

                  <span class="text-green" v-if="evaluation.EvaluationFor.length>1 && evaluation.EvaluationFor.length != index+1"> | </span>
                </span>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-auto w-40px">
                <i class="fa-duotone fa-user-group fs-18px text-green"></i>
              </div>
              <div class="col">
                Participants :
                <span class="fw-bold">{{ evaluation.Participants }} </span>
              </div>
            </div>

          </div>

          <div class="col-auto">
            <img v-if="evaluation.Scope" :src="require('@/assets/images/scopes-icons/' + evaluation.Scope + '.png')" />
            <img v-else width="30" :src="require('@/assets/images/scopes-icons/default.png')" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-auto w-40px">
            <i class="fa-duotone fa-calendar-days fs-18px text-green"></i>
          </div>
          <div class="col">
            Period :
            <span v-if="evaluation.StartDate" class="fw-bold">{{ $moment(evaluation.StartDate).format("DD.MM.YYYY") }}</span>
            <span class="text-green"> - </span>
            <span v-if="evaluation.EndDate" class="fw-bold">{{ $moment(evaluation.EndDate).format("DD.MM.YYYY") }}</span>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  Name: "evaluation-details-card-component",
  props: {
    evaluation: { type: Object, default: new Object() },
    icon: { type: String },
  },
  emits: ["delete,duplicate"],

  data() {
    return {
      linkTo: "PreviewEvaluationView",
    };
  },
  computed: {
    evaluationLink() {
      return this.evaluation.Status == "New"
        ? "EditEvaluationView"
        : "PreviewEvaluationView";
    },
  },
  methods: {
    OpenAssignModal: function () {
      this.selectedEvalTemplateProp.Id = this.evalTemplateProp.Id;
      this.selectedEvalTemplateProp.Name = this.evalTemplateProp.Name;
      this.$emit("clearModalAssignEmit");
    },

    ScopeDescription: function (evaluation) {
      switch (evaluation.ScopeId) {
        case 0:
          return "All active evaluations";
        case 1:
          return "Every user need to answer to this kind of evaluation";
        case 2:
          return "Team leader will evaluate his team members";
        case 3:
          return "Team leader will be evaluated by team members";
        case 4:
          return "Project manager will evaluate his project members";
        case 5:
          return "Project manager will be evaluated by project members";
        case 6:
          return "Group leaders will evaluate group members";
        case 7:
          return "Group leaders will be evaluated by group members";
        case 8:
          return "Every member from a group will evaluate every member of that group";
        case 99999:
          return "This evaluations are not assigned to any scope";
      }
    },

    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    IsGlobal: function (evaluation) {
      if (
        evaluation.Team ||
        evaluation.Project ||
        evaluation.ProjectManager ||
        evaluation.TeamLeader ||
        evaluation.GroupLeaders.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    },

    Delete: async function () {
      this.$emit("delete");
    },

    Duplicate: async function () {
      this.$emit("duplicate");
    },
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}
.card .h1 {
  min-height: auto !important;
  margin-bottom: 5px !important;
}

img {
  max-width: 100px;
}

.card #add-evaluationTemp-modal {
  min-height: 549px;
}

a {
  color: #181c32;
}
</style>
