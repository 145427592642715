<template>
  <div id="landing-page-layout">
    <LandingPageNavbarVue />

    <LandingPageNavbarMobileVue />

    <router-view></router-view>

    <i class="fa-solid fa-chevron-up btn-scroll-up hide-btn-scroll-up" id="scroll-up-btn" v-on:click="ScrollToTop()"></i>
  </div>
</template>
<style>
@import "../../assets/css/landingPageStyle.css";
</style>
<script>
import LandingPageNavbarVue from "@/components/Menu/LandingPageNavbar.vue";
import LandingPageNavbarMobileVue from "@/components/Menu/LandingPageNavbarMobile.vue";
export default {
  components: { LandingPageNavbarVue, LandingPageNavbarMobileVue },

  data() {
    return {
      currentRoute: null,
    };
  },
  methods: {
    ScrollToTop: function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    ToggleShowScrollUpButton: function () {
      window.onscroll = function () {
        //scrollup
        let scrollUpBtn = document.getElementById("scroll-up-btn");
        if (scrollUpBtn) {
          if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
            scrollUpBtn.classList.remove("hide-btn-scroll-up");
          } else {
            scrollUpBtn.classList.add("hide-btn-scroll-up");
          }
        }

        // //navbar
        // let navbar = document.getElementById("white-navbar");
        // if (navbar) {
        //   if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        //     navbar.classList.add("bg-white");
        //   } else {
        //     navbar.classList.remove("bg-white");
        //   }
        // }
      };
    },
  },
  created() {
    this.ToggleShowScrollUpButton();
    this.currentRoute = this.$route.name;
  },
  watch: {
    $route(currentRoute, oldRoute) {
      //using router-view, if we change the page, created doesnt work
      if (currentRoute != oldRoute) {
        this.currentRoute = currentRoute.name;
      }
    },
  },
};
</script>
