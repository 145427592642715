<template>
  <div class="card evaluation-card">
    <div class="card-body pb-0">

      <div class="dropdown">
        <i class="fa-2x fa-ellipsis fa-regular text-active-gray-600 pointer float-end" id="dropdown-eval-card-actions" data-bs-toggle="dropdown" aria-expanded="false"></i>
        <ul class="dropdown-menu dropdown-menu-alternative" aria-labelledby="dropdown-eval-card-actions">
          <li>
            <div class="dropdown-item" @click="OpenAssignModal()" data-bs-toggle="modal" data-bs-target="#assign-evaluation-modal">Create evaluation</div>
          </li>
          <li>
            <div class="dropdown-item" data-bs-toggle="modal" data-bs-target="#evaluation-preview-modal" @click="GetEvaluationDetails">Preview</div>
          </li>
          <li>
            <div class="dropdown-item " @click="DuplicateEvaluationPlan">Duplicate</div>
          </li>
          <li>
            <router-link class="dropdown-item" :to="{name: 'EditEvaluationTemplate',params: {evalTempId: evalTemplateProp.Id}}"> Edit</router-link>
          </li>
          <li class="border-top">
            <div class="dropdown-item mt-2 text-danger " @click="DeleteEvaluation">Delete</div>
          </li>
        </ul>
      </div>
      <div class="text-center">
        <img class="ms-10" src="@/assets/images/evaluation-template.svg" />
        <h1>{{ evalTemplateProp.Name }}</h1>
      </div>
      <p>
        {{ evalTemplateProp.Description }}
      </p>
      <div class="align-items-center justify-content-center row summary-row">
        <div class="col text-center mb-3">
          <small> {{ evalTemplateProp.NumberOfCategory }} Categories</small>
        </div>
        <div class="col text-center mb-3">
          <small> {{ evalTemplateProp.NumberOfQuestion }} Questions</small>
        </div>
        <div class="col-auto ps-0 text-center mb-3">
          <button type="button" class="btn btn-gradient-success btn-sm" @click="OpenAssignModal()" data-bs-toggle="modal" data-bs-target="#assign-evaluation-modal"> Create evaluation </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  Name: "eval-card-component",
  props: {
    evalTemplateProp: { type: Object, default: {} },
    selectedEvalTemplateProp: {
      type: Object,
      default: {},
    },
  },
  emits: ["clearModalAssignEmit", "getEvaluationDetailsEmit"],

  data() {
    return {};
  },
  methods: {
    OpenAssignModal: function () {
      this.selectedEvalTemplateProp.Id = this.evalTemplateProp.Id;
      this.selectedEvalTemplateProp.Name = this.evalTemplateProp.Name;
      // this.selectedEvalTemplateProp.WorkspaceId = this.evalTemplateProp.WorkspaceId;
      this.$emit("clearModalAssignEmit");
    },

    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    DuplicateEvaluationPlan: function () {
      this.selectedEvalTemplateProp.Id = this.evalTemplateProp.Id;
      this.selectedEvalTemplateProp.WorkspaceId =
        this.evalTemplateProp.WorkspaceId;
      this.$emit("duplicateEvaluationPlanEmit");
    },
    DeleteEvaluation: function () {
      this.selectedEvalTemplateProp.Id = this.evalTemplateProp.Id;
      this.selectedEvalTemplateProp.WorkspaceId =
        this.evalTemplateProp.WorkspaceId;
      this.$emit("deleteEvaluationPlan");
    },
    GetEvaluationDetails: function () {
      this.selectedEvalTemplateProp.Id = this.evalTemplateProp.Id;
      this.$emit("getEvaluationDetailsEmit");
    },
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}
/* .evaluation-card h1 {
  min-height: 55px;
  max-height: 55px;
  overflow: hidden;
}


.card p {
  min-height: 40px;
  max-height: 40px;
  overflow: hidden;
} */

.card #add-evaluationTemp-modal {
  min-height: 549px;
}
</style>
