<template>
  <div class="modal fade" id="edit-category-modal" tabindex="-1" aria-labelledby="editCategoryTemplateModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="EditCategory" ref="editCategoryForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title">Edit category</h2>
              </div>
              <div class="col text-end">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="mb-5">
              <label class="fw-bold form-label">
                <span class="required">Name</span>
              </label>
              <Field type="text" name="categoryName" class="form-control" placeholder="Category name" v-model="categoryDetails.Name" rules="required|min: 3" />
              <div v-if="errors.categoryName" class="fv-plugins-message-container invalid-feedback">Name requires minimum 3 characters.</div>
            </div>
            <div class="mb-5">
              <label class="form-label"> Description </label>
              <textarea type="text" class="form-control" rows="3" placeholder="Category description" name="Category description" v-model="categoryDetails.Description" />
            </div>
            <div class="modal-footer flex-stack">
              <button type="button" class="btn btn-light-dark me-3" data-bs-dismiss="modal">Close</button>
              <button class="btn btn-gradient-success"> Save </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineRule } from "vee-validate";
import { required, min, max } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);
defineRule("min", min);
defineRule("max", max);

export default {
  name: "EditCategoryModal",
  props: {
    categoryDetails: {},
  },
  emits: ["editCategory"],
  components: {
    Field,
    Form,
  },
  methods: {
    ClearEditCategoryModal: function () {
      this.$refs.editCategoryForm.resetForm();
    },
    EditCategory: async function () {
      this.$emit("editCategory");
    },
  },
};
</script>
