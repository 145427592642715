<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item">Evaluations</li>
        <li class="breadcrumb-item active" aria-current="page">Draft</li>
      </ol>
    </nav>

    <h1 class="page-title">Draft evaluations</h1>

    <div class="row mb-10 align-items-end">
      <Search @searchForItems="GetEvaluationsSearch" :searchText="filter" :placeholderText="'Search for evaluation'" />
      <div class="col-2">
        <label class="fw-bold mb-4">Scopes</label>
        <VueMultiselect
          select-label=""
          v-model="selectedScope"
          :options="scopes"
          placeholder="Select scope"
          label="Scope"
          track-by="Id"
          @select="SelectScope"
          @remove="DeselectScope()"
        ></VueMultiselect>
      </div>
      <div v-if="[2, 3, 4, 5, 6, 7, 8].includes(filter.ScopeId)" class="col">
        <MultiselectFilter
          :multiselectPropertiesProp="multiselectProperties"
          :multiselectFilterProp="multiselectFilter"
          :filterProp="filter"
          @getElementsForMultiselect="GetElementsForMultiselect"
          @getElements="GetPagedEvaluationPlans"
          ref="multiselectFilterRef"
        />
      </div>
      <div class="col-2">
        <label class="fw-bold mb-4">Start date </label>
        <div class="input-group">
          <span class="input-group-text border-0">
            <i class="fa-solid fa-calendar-range fs-18px"></i>
          </span>
          <Datepicker
            v-model="filter.StartDate"
            class="form-control form-control-solid ps-2 custom-datepicker"
            placeholder="Select start date"
            :enableTimePicker="false"
            name="Start date"
            autoApply
            format="dd-MM-yyyy"
            @update:modelValue="FilterByDate()"
          ></Datepicker>
        </div>
      </div>
      <div class="col-2">
        <label class="fw-bold mb-4">End date </label>
        <div class="input-group">
          <span class="input-group-text border-0">
            <i class="fa-solid fa-calendar-range fs-18px"></i>
          </span>
          <Datepicker
            v-model="filter.EndDate"
            class="form-control form-control-solid ps-2 custom-datepicker"
            placeholder="Select end date"
            :enableTimePicker="false"
            name="End date"
            format="dd-MM-yyyy"
            autoApply
            :minDate="filter.StartDate"
            @update:modelValue="FilterByDate()"
          ></Datepicker>
        </div>
      </div>
    </div>

    <evaluations-stage-nav-component :page="'Draft'"></evaluations-stage-nav-component>

    <div v-if="showAll == false" class="row">
      <div v-if="selectedScope" class="mb-5">
        <h1>{{ selectedScope.Scope }}</h1>
        <p>{{ ScopeDescription(selectedScope) }}</p>
      </div>
      <div class="row" v-if="unnasignedEvaluations.Count > 0 && selectedScope?.Scope == 'Unnasigned'">
        <div v-bind:key="index" v-for="(evaluation, index) in unnasignedEvaluations.Items" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
          <evaluation-details-card-component :evaluation="evaluation" @delete="DeleteEvaluation(evaluation, scope)" @duplicate="Duplicate(evaluation)"></evaluation-details-card-component>
        </div>
        <div class="text-center" v-if="unnasignedEvaluations.Count > 4 && unnasignedEvaluations.Items?.length < unnasignedEvaluations.Count">
          <button type="button" class="btn btn-bg-secondary btn-sm mt-10" @click="(unassignedFilter.PageNumber += 1), GetPagedUnnasignedEvaluations()">See more</button>
        </div>
        <div v-if="unnasignedEvaluations.Items?.length === unnasignedEvaluations.Count && unnasignedEvaluations.Count > 4" class="text-center">
          <button type="button" class="btn btn-bg-secondary btn-sm mt-10" @click="(unassignedFilter.PageNumber = 1), GetPagedUnnasignedEvaluations()">See less</button>
        </div>
      </div>

      <div v-bind:key="index" v-for="(evaluation, index) in evaluationPlans.Items" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
        <evaluation-details-card-component :evaluation="evaluation" :icon="''" @delete="DeleteEvaluation(evaluation)" @duplicate="Duplicate(evaluation)"></evaluation-details-card-component>
      </div>

      <div v-if="evaluationPlans.Count == 0 || unnasignedEvaluations.Count == 0" class="card-body pb-0 pt-5">
        <img alt="No content found" class="d-block me-auto ms-auto" :src="require('@/assets/images/no-content-found.png')" />
      </div>
      <div v-if="evaluationPlans.Count > 20" class="card-body pb-5 pt-5">
        <Pagination :numberOfPages="evaluationPlans.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetPagedEvaluationPlans" />
      </div>
    </div>
    <div v-if="showAll == true">
      <div class="mb-20">
        <div class="mb-5">
          <h1>Unassigned</h1>
          <p>This evaluations are not assigned to any scope</p>
        </div>
        <div class="row" v-if="unnasignedEvaluations.Count == 0 && !selectedScope">
          <div class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
            <div class="bg-gray-transparent-50 border-2 border-dashed border-gray-300 card h-100">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-duotone fa-file-dashed-line fa-4x"></i>
                  </div>
                  <div class="col">
                    <h2>No evaluations</h2>
                    <p class="m-0">No <span class="fw-bold">unnasigned</span> evaluations in draft.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-bind:key="index" v-for="(evaluation, index) in unnasignedEvaluations.Items" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
            <evaluation-details-card-component
              v-if="evaluation.GroupId == null"
              :evaluation="evaluation"
              @delete="DeleteEvaluation(evaluation, scope)"
              @duplicate="Duplicate(evaluation)"
            ></evaluation-details-card-component>
            <draft-grouped-evaluations-card-component v-else :evaluation="evaluation"></draft-grouped-evaluations-card-component>
          </div>
          <div class="text-center" v-if="unnasignedEvaluations.Count > 4 && unnasignedEvaluations.Items?.length < unnasignedEvaluations.Count">
            <button type="button" class="btn btn-bg-secondary btn-sm mt-10" @click="(unassignedFilter.PageNumber += 1), GetPagedUnnasignedEvaluations()">See more</button>
          </div>
          <div v-if="unnasignedEvaluations.Items?.length === unnasignedEvaluations.Count && unnasignedEvaluations.Count > 4" class="text-center">
            <button type="button" class="btn btn-bg-secondary btn-sm mt-10" @click="(unassignedFilter.PageNumber = 1), GetPagedUnnasignedEvaluations()">See less</button>
          </div>
        </div>
      </div>
      <div v-for="(scope, index) in evaluationsGroupedByScopes.Items" v-bind:key="index" class="mb-20">
        <div class="mb-5">
          <h1>{{ scope.Name }}</h1>
          <p>{{ ScopeDescription(scope) }}</p>
        </div>
        <div class="row">
          <div v-if="scope.EvaluationDetails.length == 0 && !selectedScope" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
            <div class="bg-gray-transparent-50 border-2 border-dashed border-gray-300 card h-100">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <i class="fa-duotone fa-file-dashed-line fa-4x"></i>
                  </div>
                  <div class="col">
                    <h2>No evaluations</h2>
                    <p class="m-0">
                      No evaluation for <span class="fw-bold"> {{ scope.Name }}</span> in draft.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-bind:key="index" v-for="(evaluation, index) in scope.EvaluationDetails" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-6">
            <evaluation-details-card-component
              v-if="evaluation.GroupId == null"
              :evaluation="evaluation"
              :icon="scope.Name"
              @delete="DeleteEvaluation(evaluation, scope)"
              @duplicate="Duplicate(evaluation)"
            ></evaluation-details-card-component>
            <router-link class="h-100" v-else :to="{ name: 'GroupEvaluationsView', params: { groupId: evaluation.GroupId } }">
              <draft-grouped-evaluations-card-component :evaluation="evaluation"></draft-grouped-evaluations-card-component>
            </router-link>
          </div>
          <div class="text-center" v-if="scope.EvaluationsCount > 4 && scope.EvaluationDetails.length < scope.EvaluationsCount">
            <button type="button" class="btn btn-bg-secondary btn-sm mt-10" @click="SeeMore(scope)">See more</button>
          </div>
          <div v-if="scope.EvaluationDetails.length === scope.EvaluationsCount && scope.EvaluationsCount > 4" class="text-center">
            <button type="button" class="btn btn-bg-secondary btn-sm mt-10" @click="SeeLess(scope)">See less</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";

import Search from "@/components/Common/Search.vue";
import Pagination from "@/components/Common/Pagination.vue";
import MultiselectFilter from "@/components/Common/MultiselectFilter.vue";

import EvaluationDetailsCardComponent from "@/components/Cards/EvaluationDetailsCardComponent.vue";
import EvaluationsStageNavComponent from "@/components/Navs/EvaluationsStageNavComponent.vue";
import DraftGroupedEvaluationsCardComponent from "@/components/Cards/EvalGroupCardComponent.vue";

export default {
  name: "DraftEvaluationsView",
  components: {
    EvaluationDetailsCardComponent,
    Search,
    Pagination,
    MultiselectFilter,
    EvaluationsStageNavComponent,
    DraftGroupedEvaluationsCardComponent,
  },

  data() {
    return {
      filter: {
        SearchText: "",
        PageNumber: 1,
        OrderBy: "Name",
        ScopeId: 0,
        StartDate: null,
        EndDate: null,
        PageSize: 4,
      },
      unassignedFilter: {
        PageNumber: 1,
        PageSize: 4,
      },
      evaluationPlans: {},

      multiselectProperties: {
        Name: null,
        Label: null,
        PlaceHolder: null,
        Elements: [],
        ScopeId: 0,
      },
      multiselectFilter: {
        SearchText: "",
        OrderBy: "Name",
        IsLoading: false,
        Element: null,
        EvaluationForId: 0,
      },
      evaluationsGroupedByScopes: [],
      scopes: [],
      selectedScope: null,
      showAll: true,
      unnasignedEvaluations: {},
    };
  },
  watch: {
    $route(to) {
      const allEvaluations = {
        filter: this.filter,
        intervalFilter: this.filter,
        multiselectFilter: this.multiselectFilter,
      };
      if (to.name === "MembersByIntervalView" || to.name === "EditEvaluationView") {
        localStorage.setItem("allEvaluationsProp", JSON.stringify(allEvaluations));
      }
    },
  },

  methods: {
    GetAllEvaluation: function () {},
    ScopeDescription: function (scope) {
      switch (scope.Id) {
        case 0:
          return "All evaluations from draft";
        case 1:
          return "Every user need to answer to this kind of evaluation";
        case 2:
          return "Team leader will evaluate his team members";
        case 3:
          return "Team leader will be evaluated by team members";
        case 4:
          return "Project manager will evaluate his project members";
        case 5:
          return "Project manager will be evaluated by project members";
        case 6:
          return "Group leaders will evaluate group members";
        case 7:
          return "Group leaders will be evaluated by group members";
        case 8:
          return "Every member from a group will evaluate every member of that group";
        case 99999:
          return "This evaluations are not assigned to any scope";
      }
    },

    SeeMore: async function (scope) {
      if (!scope.PageNumber) {
        scope.PageNumber = 1;
      }
      scope.PageNumber += 1;
      this.filter.SearchText = "";
      this.filter.ScopeId = scope.Id;
      const evaluation = await this.GetEvaluationPlansByScope(scope.PageNumber);
      scope.EvaluationDetails.push(...evaluation.Items);
    },

    SeeLess: async function (scope) {
      scope.PageNumber = 1;
      this.filter.ScopeId = scope.Id;
      const evaluation = await this.GetEvaluationPlansByScope(scope.PageNumber);
      scope.EvaluationDetails = evaluation.Items;
      window.scrollTo(0, 0);
    },

    FilterByDate: function () {
      if (this.filter.StartDate == null && this.filter.EndDate == null && this.selectedScope == null && this.multiselectFilter.EvaluationForId == 0) {
        this.evaluationPlans = {};
        this.showAll = true;
        this.unassignedFilter.PageNumber = 1;
        this.GetPagedUnnasignedEvaluations();
        this.GetPagedScopes();
      } else this.GetPagedEvaluationPlans();
    },

    DeselectScope: function () {
      this.showAll = true;
      this.filter.ScopeId = null;
      this.filter.PageNumber = 1;
      this.selectedScope == null;
      this.multiselectFilter.EvaluationForId = 0;
      if (this.filter.StartDate == null && this.filter.EndDate == null && this.multiselectFilter.EvaluationForId == 0) {
        this.evaluationPlans = {};
        this.unassignedFilter.PageNumber = 1;
        this.GetPagedUnnasignedEvaluations();
        this.GetPagedScopes();
      }
    },

    GetScopes: async function () {
      await this.$axios
        .get("api/EvaluationPlan/getScopeByRole", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.scopes = [{ Id: 0, Scope: "All" }, { Id: 99999, Scope: "Unnasigned" }, ...response.data];
          this.scopesForModal = response.data;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
        });
    },

    SelectScope: async function (scope) {
      this.unnasignedEvaluations = {};
      this.evaluationPlans = {};
      this.showAll = false;
      this.filter.ScopeId = scope.Id;
      this.filter.PageNumber = 1;
      this.selectedScope = scope;
      this.multiselectFilter = {
        SearchText: "",
        OrderBy: "Name",
        IsLoading: false,
        Element: null,
        EvaluationForId: 0,
      };
      if (scope.Id == 0) {
        this.showAll = true;
        this.filter = {
          SearchText: "",
          PageNumber: 1,
          OrderBy: "Name",
          ScopeId: 0,
          StartDate: null,
          EndDate: null,
        };
        this.multiselectFilter = {
          SearchText: "",
          OrderBy: "Name",
          IsLoading: false,
          Element: null,
          EvaluationForId: 0,
        };
        this.GetPagedEvaluationPlans();
      }

      if (scope.Id == 99999) {
        this.GetPagedUnnasignedEvaluations();
      }

      if (scope.Id > 0 && scope.Id < 99999) {
        this.GetPagedEvaluationPlans();
      }

      if (scope.Id != 1 || scope.Id != 99999) {
        await this.GetElementsForMultiselect();
      }
    },

    GetEvaluationsSearch: function () {
      if (this.filter.SearchText == "" && this.selectedScope == null) {
        this.showAll = true;
        this.unassignedFilter.PageNumber = 1;
        this.GetPagedUnnasignedEvaluations();
        this.GetPagedScopes();
      } else {
        this.showAll = false;
        this.GetPagedEvaluationPlans();
      }
    },

    GetPagedScopes: async function (page) {
      store.dispatch("changeLoaderStatus", true);
      this.filter.PageNumber = 1;
      if (page) {
        this.filter.PageNumber = page;
        window.scrollTo(0, 0);
      }
      var query = new URLSearchParams({
        pageNumber: this.filter.PageNumber,
      });
      await this.$axios
        .get("api/EvaluationPlan/getPagedDraftScopesDetails?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationsGroupedByScopes = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationPlansByScope: async function (page) {
      store.dispatch("changeLoaderStatus", true);
      var evaluations = {};
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      this.filter.PageNumber = 1;
      if (page) {
        this.filter.PageNumber = page;
      }

      var query = new URLSearchParams({
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
        scopeId: this.filter.ScopeId ? this.filter.ScopeId : -1,
        evaluationForId: this.multiselectFilter.EvaluationForId,
        pageSize: this.filter.PageSize,
        StartDate: this.filter.StartDate ? this.$moment(this.filter.StartDate).format("MM/DD/YYYY") : "",
        EndDate: this.filter.EndDate ? this.$moment(this.filter.EndDate).format("MM/DD/YYYY") : "",
      });
      await this.$axios
        .get("api/EvaluationPlan/getPagedDraftEvaluationPlans?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          evaluations = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
      return evaluations;
    },

    GetPagedEvaluationPlans: async function (page) {
      store.dispatch("changeLoaderStatus", true);
      this.showAll = false;

      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      this.filter.PageNumber = 1;
      if (page) {
        this.filter.PageNumber = page;
        window.scrollTo(0, 0);
      }

      var query = new URLSearchParams({
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
        scopeId: this.filter.ScopeId ? this.filter.ScopeId : -1,
        evaluationForId: this.multiselectFilter.EvaluationForId,
        StartDate: this.filter.StartDate ? this.$moment(this.filter.StartDate).format("MM/DD/YYYY") : "",
        EndDate: this.filter.EndDate ? this.$moment(this.filter.EndDate).format("MM/DD/YYYY") : "",
      });
      await this.$axios
        .get("api/EvaluationPlan/getPagedDraftEvaluationPlans?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationPlans = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetPagedUnnasignedEvaluations: function () {
      var query = new URLSearchParams({
        pageSize: this.unassignedFilter.PageSize,
        pageNumber: this.unassignedFilter.PageNumber,
      });
      this.$axios
        .get("api/EvaluationPlan/getPagedDraftScratchEvaluationPlans?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.unnasignedEvaluations = response.data;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
        });
    },
    GetElementsForMultiselect: async function () {
      this.multiselectProperties.ScopeId = this.filter.ScopeId;
      if (this.$refs.multiselectFilterRef) {
        this.$refs.multiselectFilterRef.element = null;
      }
      this.multiselectProperties.Elements = [];
      switch (this.filter.ScopeId) {
        //myteams
        case 2:
          this.multiselectProperties.Elements = await this.GetTeamsForFilter();
          this.multiselectProperties.Name = "Team Members";
          this.multiselectProperties.Label = "TeamName";
          this.multiselectProperties.PlaceHolder = "Select a team";
          break;
        //teamleaders
        case 3:
          this.multiselectProperties.Elements = await this.GetTeamLeadersForFilter();
          this.multiselectProperties.Name = "Team leaders";
          this.multiselectProperties.Label = "Name";
          this.multiselectProperties.PlaceHolder = "Select a team leader";
          break;
        //myprojects
        case 4:
          this.multiselectProperties.Elements = await this.GetProjectsForFilter();
          this.multiselectProperties.Name = "Project Members";
          this.multiselectProperties.Label = "Name";
          this.multiselectProperties.PlaceHolder = "Select a project";
          break;
        //projectmanagers
        case 5:
          this.multiselectProperties.Elements = await this.GetProjectManagersForFilter();
          this.multiselectProperties.Name = "Project managers";
          this.multiselectProperties.Label = "ManagerName";
          this.multiselectProperties.PlaceHolder = "Select a project manager";
          break;
        case 6:
        case 7:
        case 8:
          this.multiselectProperties.Elements = await this.GetGroups();
          this.multiselectProperties.Name = "Group";
          this.multiselectProperties.Label = "Name";
          this.multiselectProperties.PlaceHolder = "Select a group";
          break;
        default:
          break;
      }

      if (this.multiselectProperties.Elements.length == 0 && (this.multiselectFilter.SearchText == null || this.multiselectFilter.SearchText == "")) {
        this.multiselectProperties.Disabled = true;
        this.multiselectProperties.PlaceHolder = "List is empty";
      } else {
        this.multiselectProperties.Disabled = false;
      }
    },

    GetGroups: async function () {
      this.multiselectFilter.IsLoading = true;
      if (this.multiselectFilter.SearchText == null) {
        this.multiselectFilter.SearchText = "";
      }
      let groups = [];
      await this.$axios
        .get("api/EvaluationGroup/getGroups?searchText=" + this.multiselectFilter.SearchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then(async (response) => {
          groups = response.data;
          this.multiselectFilter.IsLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          this.multiselectFilter.IsLoading = false;
        });
      return groups;
    },

    GetTeamsForFilter: async function () {
      this.multiselectFilter.IsLoading = true;
      if (this.multiselectFilter.SearchText == null) {
        this.multiselectFilter.SearchText = "";
      }
      let teams = [];
      await this.$axios
        .get("api/Team/getTeamsByRole?searchText=" + this.multiselectFilter.SearchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          teams = response.data;
          this.multiselectFilter.IsLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          this.multiselectFilter.IsLoading = false;
        });
      return teams;
    },

    // GetGroupMembersByEvalId: async function (evaluationPlanId) {
    //   store.dispatch("changeLoaderStatus", true);
    //   let groupMembers = [];
    //   await this.$axios
    //     .get("api/EvaluationPlan/getGroupMembersByEvalId?evaluationPlanId=" + evaluationPlanId, {
    //       headers: {
    //         Authorization: "Bearer " + auth.getJwtToken().Value,
    //       },
    //     })
    //     .then((response) => {
    //       groupMembers = response.data;
    //       store.dispatch("changeLoaderStatus", false);
    //     })
    //     .catch((error) => {
    //       store.dispatch("toastNotify", {
    //         Type: "error",
    //         Title: error.response.data,
    //       });
    //       store.dispatch("changeLoaderStatus", false);
    //     });
    //   return groupMembers;
    // },

    GetTeamLeadersForFilter: async function () {
      this.multiselectFilter.IsLoading = true;
      let leaders = [];

      await this.$axios
        .get("api/Team/getUserTeamLeaders?searchText=" + this.multiselectFilter.SearchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          leaders = response.data;
          this.multiselectFilter.IsLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          this.multiselectFilter.IsLoading = false;
        });
      return leaders;
    },

    GetProjectsForFilter: async function () {
      this.multiselectFilter.IsLoading = true;
      if (this.multiselectFilter.SearchText == null) {
        this.multiselectFilter.SearchText = "";
      }
      let projects = [];
      await this.$axios
        .get("api/Project/getProjectsByRole?searchText=" + this.multiselectFilter.SearchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          projects = response.data;
          this.multiselectFilter.IsLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          this.multiselectFilter.IsLoading = false;
        });
      return projects;
    },

    GetProjectManagersForFilter: async function () {
      this.multiselectFilter.IsLoading = true;
      let managers = [];
      await this.$axios
        .get("api/Project/getProjectManagers?searchText=" + this.multiselectFilter.SearchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          managers = response.data;
          this.multiselectFilter.IsLoading = false;
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          this.multiselectFilter.IsLoading = false;
        });
      return managers;
    },

    DeleteEvaluation: function (evaluation, scope) {
      var self = this;
      this.$swal
        .fire({
          title: "Question",
          text: "Are you sure you want to delete this evaluation?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            self.$axios
              .delete("api/EvaluationPlan/" + evaluation.Id, {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then(async () => {
                self.$swal.fire({
                  toast: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: "success",
                  title: "Evaluation has been deleted",
                });

                if (self.showAll === true) {
                  self.filter.ScopeId = scope.Id;
                  const evaluation = await self.GetEvaluationPlansByScope(1);
                  scope.EvaluationDetails = evaluation.Items;
                  scope.EvaluationsCount -= 1;
                } else {
                  self.GetPagedEvaluationPlans();
                }
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                self.$swal.fire({
                  toast: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: "error",
                  title: error,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },

    // OrderBy: function (val) {
    //   // if (orderBy == this.filter.OrderBy) {
    //   //   this.filter.OrderBy = orderBy + "_desc";
    //   // } else {
    //   //   this.filter.OrderBy = orderBy;
    //   // }
    //   this.filter.OrderBy = val;
    //   this.GetPagedEvaluationPlans();
    // },

    Duplicate: function (evalPlan) {
      var self = this;
      this.$swal
        .fire({
          title: "Are you sure you want to duplicate " + `<span class="text-primary">` + evalPlan.Name + "</span>" + "?",
          text: "Duplication will be done along with categories and questions",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(function (result) {
          if (result.value) {
            store.dispatch("changeLoaderStatus", true);
            self.$axios
              .post("api/EvaluationPlan/duplicateForAdmin?evaluationPlanId=" + parseInt(evalPlan.Id), {
                headers: {
                  Authorization: "Bearer " + auth.getJwtToken().Value,
                },
              })
              .then(() => {
                store.dispatch("toastNotify", {
                  Type: "success",
                  Title: "Evaluation has been duplicated",
                });
                // self.$router.push(
                //   `/administration/all-evaluations/evaluation/${response.data.DuplicatedEvaluationPlanId}/edit`
                // );
                store.dispatch("changeLoaderStatus", false);
              })
              .catch((error) => {
                store.dispatch("toastNotify", {
                  Type: "error",
                  Title: error.response.data,
                });
                store.dispatch("changeLoaderStatus", false);
              });
          }
        });
    },

    // GetFunctions: function () {
    //   if (JSON.parse(localStorage.getItem("allEvaluationsProp")) != null) {
    //     this.filter = JSON.parse(localStorage.getItem("allEvaluationsProp")).filter;
    //     this.intervalFilter = JSON.parse(localStorage.getItem("allEvaluationsProp")).intervalFilter;
    //     this.multiselectFilter = JSON.parse(localStorage.getItem("allEvaluationsProp")).multiselectFilter;
    //     if (this.filter.SelectedEvalPlan !== null) {
    //       this.GetEvaluationInterval();
    //     }
    //     if (this.filter.SelectedEvalPlan !== null) {
    //       this.GetEvaluationInterval();
    //     }
    //     if (this.filter.ScopeId !== 0) {
    //       this.GetElementsForMultiselect();
    //     }
    //     localStorage.removeItem("allEvaluationsProp");
    //   } else {
    //     localStorage.removeItem("allEvaluationsProp");
    //   }
    // },
  },
  mounted() {
    // this.GetFunctions();
  },
  created() {
    this.GetScopes();
    this.GetPagedUnnasignedEvaluations();
    this.GetPagedScopes();
  },
};
</script>
<style scoped>
a {
  color: #181c32;
}
</style>
