<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item">My evaluations</li>
        <li class="breadcrumb-item"><a href="/My-evaluations/Active">Active</a></li>
        <li class="breadcrumb-item" aria-current="page">
          {{ evaluationDetails.Name }}
        </li>
        <li class="breadcrumb-item active" aria-current="page">Members</li>
      </ol>
    </nav>

    <div class="row align-items-end">
      <div class="col">
        <h1 class="page-title">Evaluation members</h1>
      </div>
    </div>

    <evaluation-information-component :evaluationDetails="evaluationDetails"></evaluation-information-component>

    <div class="page-tabs">
      <div class="row border-bottom">
        <!-- <div class="col-auto">
          <h3>
            <router-link class="dropdown-item p-0" :to="{ name: 'PreviewEvaluationView', params: { id: evaluationId } }"> Details </router-link>
          </h3>
        </div> -->
        <div class="col-auto active">
          <h3 class="text-dark-green">
            Members
            <!-- <router-link class="dropdown-item p-0" :to="{ name: 'EvaluationMembersView', params: { id: evaluationId } }"> Members </router-link> -->
          </h3>
        </div>
      </div>
      <hr />
    </div>

    <div class="card">
      <div class="card-header d-block pt-4">
        <div class="row mb-5">
          <div class="col-3">
            <search @searchForItems="GetEvaluationMembers(1)" :searchText="filter" :showLabel="false" :placeholderText="'Search for members'" />
          </div>
          <div class="col"></div>
        </div>
      </div>
      <!-- {{ evaluationMembers }} -->
      <div class="card-body">
        <table class="table table-row-bordered">
          <thead class="table-header">
            <tr>
              <th>Title</th>
              <th>Status</th>
              <th></th>
              <th>Timeframe</th>
              <th>Score</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(member, index) in evaluationMembers.Items" v-bind:key="index">
              <th>
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-40px me-2 symbol-circle">
                    <img alt="Pic" :src="GetImageUrl(member.Image)" style="object-fit: cover" />
                  </div>
                  <h5 class="m-0">{{ member.EmployeeName }}</h5>
                </div>
              </th>
              <td>
                <div class="row mb-1">
                  <div class="col fw-bold">{{ member.Status }}</div>
                  <div class="col-auto">{{ member.Progress }}%</div>
                </div>
                <div class="progress">
                  <div :class="{ 'bg-secondary': member.Status == 'New', 'bg-orange': member.Status == 'Active', 'bg-dark-green': member.Status == 'Completed' }" class="progress-bar" role="progressbar" :style="`width:${member.Progress}%`" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </td>

              <td></td>
              <td v-if="member.Status != 'New'">
                {{ $utils.formatDate(member.StartDate) }}
                <span class="m-3">-</span>
                {{ $utils.formatDate(member.EndDate) }}
              </td>
              <td v-else>Not started</td>
              <td>
                <span v-if="member.EndDate != null" class="badge badge-gradient-success">{{ member.Score.toFixed(2) }}</span>
                <span v-else class="badge badge-secondary">No answers</span>
              </td>

              <td class="text-end">
                <button v-if="member.Status == 'New'" v-on:click="InsertQuestions(member)" class="btn btn-outline-success">Start</button>
                <router-link v-if="member.Status == 'Active'" class="btn btn-gradient-orange" :to="{ name: 'ManagerEvaluationAnswersActiveView', params: { id: evaluationDetails.Id, memberId: member.EmployeeId } }">
                  Continue
                </router-link>
                <router-link v-if="member.Status == 'Completed'" class="btn btn-gradient-success" :to="{ name: 'ManagerEvaluationAnswersActiveView', params: { id: evaluationDetails.Id, memberId: member.EmployeeId } }">
                  Details
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <Pagination :numberOfPages="evaluationMembers.NumberOfPages" :pageNumber="filter.PageNumber" :functionName="GetEvaluationMembers" />
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import Pagination from "@/components/Common/Pagination.vue";
import EvaluationInformationComponent from "@/components/Common/EvaluationInformationComponent.vue";
import Search from "@/components/Common/Search.vue";

export default {
  name: "ActiveEvaluationMembersView",
  components: {
    Pagination,
    Search,
    EvaluationInformationComponent,
  },
  emits: [],
  data() {
    return {
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
      },
      evaluationDetails: {},
      evaluationMembers: [],
      evaluationId: null,
    };
  },

  methods: {
    InsertQuestions: async function (member) {
      await this.$axios
        .post(
          "api/Answers/insert",
          {
            EvaluationId: this.evaluationDetails.Id,
            EmployeeEvaluationId: member.EmployeeId,
          },
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then(() => {
          this.$router.push({
            name: "ManagerEvaluationAnswersActiveView",
            params: {
              id: this.evaluationDetails.Id,
              memberId: member.EmployeeId,
            },
          });
        })
        .catch((error) => {
          this.$swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: "error",
            title: error,
          });
        });
    },

    GetImageUrl: function (image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    GetEvaluationDetails: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getEvaluationDetails/" + this.evaluationId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationMembers: async function (page) {
      store.dispatch("changeLoaderStatus", true);
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      this.filter.PageNumber = 1;
      if (page) {
        this.filter.PageNumber = page;
      }
      var query = new URLSearchParams({
        evaluationId: this.evaluationId,
        searchText: this.filter.SearchText,
        pageNumber: this.filter.PageNumber,
        orderBy: this.filter.OrderBy,
      });
      await this.$axios
        .get("api/Employee/getEmployeesForEvaluation?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationMembers = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },

  created() {
    this.evaluationId = this.$route.params.id;
    this.GetEvaluationDetails();
    this.GetEvaluationMembers();
  },
};
</script>
