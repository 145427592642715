<template>
  <div class="container-sm text-center">
    <div class="title-section">Întrebări puse frecvent</div>
    <div class="description-section text-light-gray">Ai o întrebare? Suntem aici să te ajutăm.</div>
    <div class="accordion" id="accordionFaq">
      <div class="accordion-item" v-for="(item, index) in faqs" :key="index">
        <h2 class="accordion-header" :id="'heading-' + index">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-' + index" aria-expanded="false" :aria-controls="'collapse-' + index">
            {{ item.Question }}
          </button>
        </h2>
        <div :id="'collapse-' + index" class="accordion-collapse collapse" :class="{ show: index == 0 }" :aria-labelledby="'heading-' + index" data-bs-parent="#accordionFaq">
          <div class="accordion-body">
            {{ item.Answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPageQuestionsComponent",
  data() {
    return {
      faqs: [
        {
          Question: "What is an evaluation app? ",
          Answer: "evaluation 1",
        },
        {
          Question: "Can I customize the evaluation templates?  ",
          Answer:
            "With our evaluation app, you have the power to create personalized evaluations that accurately reflect your company culture and goals.  Choose from pre-made templates or create custom evaluations with ease, and access advanced data analysis tools to interpret and track results. Plus, our user-friendly interface and comprehensive support ensure that you can make the most of the app's features, every step of the way.",
        },
        {
          Question: "Can multiple users access the evaluation app?  ",
          Answer: "evaluation 1",
        },
        {
          Question: "Can managers view employee evaluation responses? ",
          Answer: "evaluation 1",
        },
        {
          Question: "What kind of data analysis tools does the app provide?  ",
          Answer: "evaluation 1",
        },
        {
          Question: "How does the app protect the privacy of employee evaluations?  ",
          Answer: "evaluation 1",
        },
        {
          Question: "Is the evaluation app easy to use?  ",
          Answer: "evaluation 1",
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
.accordion-item {
  background-color: transparent;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
}

/*FAQ ACCORDION*/
.accordion-button {
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: rgba(73, 80, 87, 1);
}

.accordion-body {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding: 0px 24px 24px 50px;
  text-align: left;
  color: rgba(108, 117, 125, 1);
}

.accordion-button::after {
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23181C32'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
</style>
