<template>
  <div class="modal fade" id="add-evaluation-modal" tabindex="-1" aria-labelledby="add-evaluation-modal-label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="EvaluationFormSubmit()" ref="createEvaluationForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title" id="team-preview-modal-label">Create evaluation</h2>
              </div>
              <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            </div>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="form-label">
                <span class="required">Name</span>
              </label>
              <Field type="text" name="Name" class="form-control" placeholder="Evaluation name" v-model="newEvaluation.Name" :rules="'required|min:3|max:200'" />
              <div v-if="errors.Name" class="fv-plugins-message-container invalid-feedback">{{ errors.Name }}</div>
            </div>

            <div class="form-group">
              <label class="form-label"> Description </label>
              <Field name="evalDescription" v-slot="{ field }" rules="max:500">
                <textarea v-bind="field" class="form-control" rows="3" type="text" placeholder="Evaluation description" v-model="newEvaluation.Description" />
              </Field>
              <div v-if="errors.evalDescription" class="fv-plugins-message-container invalid-feedback">Description requires maximum 500 characters.</div>
            </div>
          </div>
          <div class="modal-footer flex-stack">
            <button type="button" class="btn btn-light-dark" data-bs-dismiss="modal">Close</button>
            <button class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, configure } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, min, max } from "@vee-validate/rules";
defineRule("required", required);
defineRule("min", min);
defineRule("max", max);

configure({
  validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
});

export default {
  Name: "add-evaluation-modal-component",
  components: {
    Field,
    Form,
  },
  emits: ["createEvaluationEmit"],
  props: {},
  data() {
    return {
      newEvaluation: {},
    };
  },

  methods: {
    ClearModal: function () {
      this.$refs.createEvaluationForm.resetForm();
    },
    EvaluationFormSubmit: function () {
      this.$emit("createEvaluationEmit", this.newEvaluation);
    },
  },
};
</script>
