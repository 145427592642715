<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item">My evaluations</li>
        <li class="breadcrumb-item"><a href="/My-evaluations/History">History</a></li>
        <li class="breadcrumb-item">
          {{ evaluationDetails.Name }}
        </li>
        <li class="breadcrumb-item active" aria-current="page">Self Assessment</li>
      </ol>
    </nav>

    <div class="row align-items-end mt-20 mb-10">
      <div class="col">
        <h1 class="eval-page-title">Self Assessment</h1>
      </div>
    </div>

    <div class="card mb-8 mt-8 evaluation-summary-card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <h1>{{ evaluationDetails.Name }}</h1>
            <p>{{ evaluationDetails.Description }}</p>
          </div>
        </div>
        <hr />
        <div class="row align-items-center">
          <div class="col d-flex align-items-center">
            <img v-if="evaluationDetails.Scope" :src="require('@/assets/images/scopes-icons/' + evaluationDetails.Scope + '.png')" />
            <div>
              <small>Scope</small>
              <h5>{{ evaluationDetails.Scope }}</h5>
            </div>
          </div>

          <div class="col">
            <small>Team</small>
            <h5>{{ evaluationDetails.EvaluationFor }}</h5>
          </div>
          <div class="col-auto d-flex align-items-center min-w-250px">
            <div class="symbol symbol-40px me-2 symbol-circle">
              <img :src="GetImgUrl($store.getUser.Image)" />
            </div>
            <div>
              <small>Target</small>
              <h5>{{ $store.getUser.EmployeeName }}</h5>
            </div>
          </div>
          <div class="col">
            <small>Period</small>
            <h5>
              {{ $moment(evaluationDetails.StartDate).format("DD.MM.YYYY") }}
              -
              {{ $moment(evaluationDetails.EndDate).format("DD.MM.YYYY") }}
            </h5>
          </div>
          <div class="col d-flex align-items-center"
            v-if="evaluationDetails.ScopeId != 2 && evaluationDetails.ScopeId != 4 && evaluationDetails.ScopeId != 6">
            <i class="fa-regular fa-circle-exclamation fa-3x me-2" :style="{ color: 'red' }"></i>
            <div>
              <small>Anonymous</small>
              <h5 v-if="evaluationDetails.IsAnonim">Yes </h5>
              <h5 v-else>No</h5>
            </div>
          </div>
          <div class="col-1"></div>
          <div class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
            <div class="big-score-bage bg-dark-green">
              <h1>{{ evaluationContent.EvaluationScore }}</h1>
              <span> {{ $store.getUser.EmployeeName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="evaluationContent.Categories">
      <div class="card-body">
        <div v-bind:key="categoryIndex" v-for="(category, categoryIndex) in evaluationContent.Categories">
          <div class="row">
            <div class="col">
              <h1>{{ category.CategoryName }}</h1>
              <p>
                {{ category.CategoryDescription }}
              </p>
            </div>

            <div class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 text-gray-700">Category score</h5>
                </div>
                <div class="col-auto">
                  <span class="bg-gray-200 p-2 rounded text-dark-green fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-dark-green me-2"></i>
                    {{ category.CategoryScore }}
                    <!-- 4.2 -->
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center" :id="'question-' + question.AnswerId" v-bind:key="questionIndex" v-for="(question, questionIndex) in category.Questions">
            <div class="col">
              <question-timeline-details-component :question="question"></question-timeline-details-component>
            </div>
            <div class="col-auto questions-score-div">
              <preview-score-component :question="question"></preview-score-component>
            </div>
          </div>

          <hr class="mt-15 mb-15" />
        </div>
      </div>
    </div>

    <not-found-component v-else :text="'You did not answered the questions from this assessment!'"></not-found-component>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";

import PreviewScoreComponent from "@/components/Common/Score/PreviewScoreComponent.vue";
import QuestionTimelineDetailsComponent from "@/components/Questions/QuestionTimelineDetailsComponent.vue";
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";

export default {
  name: "SelfEvaluationAnswersDetailsView",
  components: {
    PreviewScoreComponent,
    QuestionTimelineDetailsComponent,
    NotFoundComponent,
  },

  data() {
    return {
      scoreColumnWidth: 320,
      evaluationContent: {},
      evaluationDetails: {},
      evaluationParticipants: [],
      selectedMember: null,
    };
  },

  methods: {
    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    GetEvaluationSummary: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/EvaluationPlan/getEvaluationPlanSummary?evaluationId=" +
            this.evaluationDetails.EvaluationPlanId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationContent: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/Answers/getLoggedUserAnswersForSelfService?evaluationId=" +
            this.evaluationDetails.EvaluationPlanId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationContent = response.data;
          // this.CategoryAvgScore();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },

  created() {
    this.evaluationDetails.EvaluationPlanId = this.$route.params.id;
    this.GetEvaluationSummary();
    this.GetEvaluationContent();
  },
};
</script>
