<template>
  <div class="card h-100">
    <div class="card-body">
      <div class="mb-10">
        <!-- <h4 class="fw-normal eval-title">
          <span v-if="evaluation.Self">Self assessment</span>
          <span v-else>Evaluate teammate</span>
          <span class="text-green m-2">-</span>
          <span class="fw-normal text-muted fs-15"> {{ evaluation.Name }} </span>
        </h4> -->

        <h4 class="fw-normal eval-title">
          <span class="text-green me-2">#{{ evaluation.Id }} </span>
          <span class="fw-normal text-muted fs-15">- {{ evaluation.Name }} </span>
        </h4>
        <hr>
        <div class="eval-description">
          <small v-if="evaluation.Description">
            {{ evaluation.Description }}
          </small>
          <small v-else>
            {{ EvaluationDescription(evaluation) }}
          </small>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col-auto w-40px">
          <i class="fa fa-bullseye fs-18px text-green"></i>
        </div>
        <div class="col">
          For :
          <span v-bind:key="index" v-for="(item, index) in evaluation.EvaluationFor" class="fw-bold">
            {{ item }}
            <span class="text-green fw-boldest" v-if="evaluation.EvaluationFor.length>1 && evaluation.EvaluationFor.length != index+1"> | </span>
          </span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-auto w-40px">
          <i class="fa-duotone fa-user-group fs-18px text-green"></i>
        </div>
        <div class="col">
          Participants :
          <span class="fw-bold">{{ evaluation.Participants }} </span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-auto w-40px">
          <i class="fa-duotone fa-calendar-days fs-18px text-green"></i>
        </div>
        <div class="col-auto">
          Period :
          <span v-if="evaluation.StartDate" class="fw-bold">{{ $moment(evaluation.StartDate).format("DD.MM.YYYY") }}</span>
          <span class="text-green">-</span>
          <span v-if="evaluation.EndDate" class="fw-bold">{{ $moment(evaluation.EndDate).format("DD.MM.YYYY") }}</span>
        </div>
      </div>

    </div>
    <div class="card-footer border-0 pt-0">
      <button class="btn btn-gradient-success w-100" v-on:click="$emit('selectEvaluation')" v-if="evaluation.Status == 'New'">Start</button>
      <button class="btn btn-gradient-orange w-100" v-on:click="$emit('selectEvaluation')" v-if="evaluation.Status == 'Active'">Continue</button>
      <button class="btn bg-gradient-black-green w-100" v-on:click="$emit('selectEvaluation')" v-if="evaluation.Status == 'Completed'">Details</button>
    </div>
  </div>
</template>

<script>
export default {
  Name: "evaluation-details-card-component",
  props: {
    evaluation: { type: Object, default: new Object() },
    icon: { type: String },
  },
  emits: ["selectEvaluation"],

  data() {
    return {
      linkTo: "PreviewEvaluationView",
    };
  },
  computed: {
    evaluationLink() {
      return this.evaluation.Status == "New"
        ? "EditEvaluationView"
        : "PreviewEvaluationView";
    },
  },
  methods: {
    EvaluationDescription: function (evaluation) {
      if (evaluation.Self) {
        return "A self assessment is an individual review performed to identify elements that can be improved";
      }
      return "Evaluate your teammate";
    },
    OpenAssignModal: function () {
      this.selectedEvalTemplateProp.Id = this.evalTemplateProp.Id;
      this.selectedEvalTemplateProp.Name = this.evalTemplateProp.Name;
      this.$emit("clearModalAssignEmit");
    },

    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    IsGlobal: function (evaluation) {
      if (
        evaluation.Team ||
        evaluation.Project ||
        evaluation.ProjectManager ||
        evaluation.TeamLeader ||
        evaluation.GroupLeaders.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    },

    Delete: async function () {
      this.$emit("delete");
    },

    Duplicate: async function () {
      this.$emit("duplicate");
    },
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}
.card .h1 {
  min-height: auto !important;
  margin-bottom: 5px !important;
}

img {
  max-width: 100px;
}

.card #add-evaluationTemp-modal {
  min-height: 549px;
}

a {
  color: #181c32;
}
</style>
